import React from 'react';

export interface BadgeProps {
    variant?: 'standard' | 'circular' | 'alert';
    type?: 'info' | 'danger' | 'warning' | 'success' | 'neutral' | 'error';
    size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
    className?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
}

const Badge: React.FC<BadgeProps> = ({
    variant = 'standard',
    type = 'info',
    size = 'medium',
    className = '',
    children,
    style,
    onClick
}) => {
    // Set the default start values
    let badgeVariant = 'standard';
    let badgeType = 'info';
    let badgeSize = '';

    // Set badge variant
    switch (variant.toLowerCase()) {
        case 'standard':
            badgeVariant = 'pill';
            break;
        case 'circular':
            badgeVariant = 'circular ';
            break;
        case 'alert':
            badgeVariant = 'alert-badge';
            break;
        default:
            break;
    }

    // Set badge type
    switch (type.toLowerCase()) {
        case 'info':
            badgeType = 'info';
            break;
        case 'danger':
            badgeType = 'danger';
            break;
        case 'warning':
            badgeType = 'warning';
            break;
        case 'success':
            badgeType = 'success';
            break;
        case 'neutral':
            badgeType = 'neutral';
            break;
        default:
            break;
    }

    // Set badge size
    if (variant.toLowerCase() === 'circular') {
        switch (size.toLowerCase()) {
            case 'extra-small':
                badgeSize = 'badge-circular-xs';
                break;
            case 'small':
                badgeSize = 'badge-circular-sm';
                break;
            case 'medium':
                badgeSize = '';
                break;
            case 'large':
                badgeSize = 'badge-circular-lg';
                break;
            case 'extra-large':
                badgeSize = 'badge-circular-xl';
                break;
            default:
                break;
        }
    }

    return (
        <>
            {badgeVariant !== 'alert-badge' ? (
                <span className={`badge badge-${badgeVariant} badge-${badgeType} ${badgeSize} ${className}`} style={style}>
                    {children}
                </span>
            ) : (
                <div className='alert-badge' style={style} onClick={onClick}>
                    {children}
                </div>
            )}
        </>
    );
};

export default Badge;
