import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../portal/common/models/ReduxSlices';
import { GetEDMClientServices } from '../../redux/actions/teamManagement';
import {
    resetClientsAndPermissions,
    toggleSelectAllClientServices,
    updateClientServicesCheckbox
} from '../../redux/reducers/clientsAndPermissionsSlice';
import { RootState } from '../../redux/store';
import { ClientsAndPermissionsSlice } from '../models/ReduxSlices';
import ClientsAndPermissionsTable from './ClientsAndPermissionsTable';
import TeamSectionWrapper from './TeamSectionWrapper';

const ClientsAndPermissions = () => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { data, isPending, adminRole, isLoading, isLoadingClientStatuses } = useSelector<RootState, ClientsAndPermissionsSlice>(
        (state) => state.clientsAndPermissions
    );
    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const dispatch = useDispatch();
    const translate = useTranslation();

    useEffect(() => {
        if (data?.ParentServices.length && isLoadingClientStatuses === undefined) {
            dispatch(GetEDMClientServices(data.ParentServices.map((x) => x.Number)));
        }
    }, [data]);

    // Reset "clientsAndPermissions" slice on unmount
    useEffect(() => {
        return () => {
            dispatch(resetClientsAndPermissions());
        };
    }, [dispatch]);

    return (
        <TeamSectionWrapper title={translate('ClientsAndPermissionsHeader_Label')} isLoading={isLoading || isLoadingClientStatuses}>
            <>
                {data?.ParentServices.length &&
                    data?.ParentServices.map((service, index) => (
                        <ClientsAndPermissionsTable
                            key={index}
                            disabled={!isEditable || isPending}
                            title={service.Number + ' - ' + service.Name}
                            clientServices={service.ClientServices}
                            adminRole={adminRole}
                            onSelect={(options) => dispatch(updateClientServicesCheckbox(options))}
                            onToggleSelectAll={(options) => {
                                dispatch(toggleSelectAllClientServices(options));
                            }}
                        />
                    ))}
            </>
        </TeamSectionWrapper>
    );
};

export default ClientsAndPermissions;
