import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import MilestoneDropdown from '../../../../portal/common/features/MilestoneDropdown';
import OnHoldToggle from '../../../../portal/common/features/OnHoldToggle';
import Tooltip from '../../../../portal/common/features/Tooltip/Tooltip';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { Subscription } from '../../../../portal/common/models/ClearanceSubscriptions';
import { HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import { Notifications } from '../../../../portal/common/models/ResponseData';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { UpdateSubscription } from '../../../redux/actions/clearanceSubscribers';
import { setSubscriptionUpdateAction } from '../../../redux/reducers/clearanceSubscribersSlice';
import { RootState } from '../../../redux/store';
import ActionButton from './ActionButton';

interface DataItem extends Subscription {
    selected?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    onRemoveSubscriber: (id?: null | number) => void;
    isLocked: boolean;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    isLocked,
    onRemoveSubscriber,
    selectionChange,
    style,
    className
}) => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state?.shipmentDetails);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const translate = useTranslation();
    const dispatch = useDispatch();
    let cellContent;

    const handleSubscriptionUpdate = (
        action: string,
        customerNumber: null | string,
        subscriptionId: null | number,
        milestones: null | Notifications[]
    ) => {
        if (user?.InternalUser) return;

        dispatch(setSubscriptionUpdateAction(action));
        if (shipmentDetails?.CustomsInfoID) {
            dispatch(
                UpdateSubscription({
                    customsInfoId: shipmentDetails.CustomsInfoID,
                    milestones,
                    requestModel: dataItem
                })
            );
        }
    };

    switch (field) {
        case 'FullName':
            cellContent = dataItem.FullName;
            break;
        case 'Email':
            cellContent = dataItem.Email;
            break;
        case 'Milestones':
            cellContent = (
                <MilestoneDropdown
                    {...dataItem}
                    updateMilestones={handleSubscriptionUpdate}
                    disabled={isLocked}
                    applyButtonLabel={
                        !user?.InternalUser ? (
                            translate('Apply_Label')
                        ) : (
                            <Tooltip label='Disabled for LII users.'>{translate('Apply_Label')}</Tooltip>
                        )
                    }
                    cancelButtonLabel={translate('Cancel_Label')}
                />
            );
            break;
        case 'OnHoldNotifications':
            cellContent = (
                <OnHoldToggle
                    data={dataItem}
                    handleUpdate={(milestones) =>
                        handleSubscriptionUpdate('onhold', dataItem.CustomerNumber || null, dataItem.SubscriptionId || null, milestones)
                    }
                    disabled={user?.InternalUser || isLocked}
                />
            );
            break;
        case 'Actions':
            cellContent = (
                <ActionButton
                    onClick={() => !user?.InternalUser && onRemoveSubscriber(dataItem.SubscriptionId)}
                    disabled={user?.InternalUser ? true : isLocked}
                />
            );
            break;
        case 'Type':
            cellContent = dataItem.IsExternal ? 'External-user' : 'User';
            break;
        case 'Language':
            cellContent = dataItem.Language;
            break;
        case 'RoleName':
            cellContent = dataItem.RoleName ? translate(`${dataItem.RoleName}_Label`) : null;
            break;
        case 'Phone':
            cellContent = dataItem.Phone;
            break;
        case 'selected':
            cellContent = <Checkbox value={!!dataItem.selected} onChange={selectionChange} />;
            break;
        default:
            cellContent = null;
    }

    return (
        <td
            className={`${className} ${dataItem?.isNew ? 'new-row-highlight' : ''}`}
            style={{ ...style, backgroundColor: dataItem?.selected ? '#DDE9F9' : '', cursor: 'default' }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
