import React from 'react';
import ClientServiceRow from './ClientServiceRow';
import ParentServiceHeader from './ParentServiceHeader';

const ClientServiceGrid = (props) => {
    const clientServices = props.parentService.ClientServices.map((s, index) => {
        return (
            <ClientServiceRow
                key={'cs-' + index}
                clientService={s}
                availableFeatures={props.parentService.AvailableFeatures}
                clientIndex={index}
                parentIndex={props.parentIndex}
                readonly={props.readonly}
            />
        );
    });

    return (
        <div>
            <br />
            <br />
            <h4 className='bold'>
                {props.parentService.Number} - {props.parentService.Name}
            </h4>
            <table className='table table-striped'>
                <ParentServiceHeader
                    parentService={props.parentService}
                    parentIndex={props.parentIndex}
                    availableFeatures={props.parentService.AvailableFeatures}
                    readonly={props.readonly}
                />
                <tbody>{clientServices}</tbody>
            </table>
        </div>
    );
};

export default ClientServiceGrid;
