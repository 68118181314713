import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { REACT_APP_ASSETS_URL } from '../../portal/common/constants/constants-portal';
import Button from '../../portal/common/features/Button';
import Spinner from '../../portal/common/features/Spinner';
import { HydrationSlice } from '../../portal/common/models/ReduxSlices';
import { ContactsSlice } from '../common/models/ReduxSlices';
import { RootState } from '../redux/store';

interface EmptyStateProps {
    onAdd: () => void;
    descriptionLabel: string;
    addButtonLabel: React.ReactNode;
    disabled?: boolean;
}

const EmptyStateContacts: React.FC<EmptyStateProps> = ({ onAdd, descriptionLabel, addButtonLabel, disabled }) => {
    const { teamsAdmins } = useSelector<RootState, ContactsSlice>((state) => state.contacts);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);

    return (
        <div className='container-fluid mt-4 mb-4 d-flex flex-column justify-content-center align-items-center flex-fill'>
            <img
                className='image-150px'
                src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/objects/object-inbox-zero.svg`}
                alt='Empty state'
            />
            <h3>{descriptionLabel}</h3>
            <Button
                variant='primary'
                size='medium'
                disabled={disabled || (!teamsAdmins.data.length && user?.InternalUser) || (teamsAdmins.isLoading && user?.InternalUser)}
                onClick={() => onAdd()}
            >
                {teamsAdmins.isLoading ? (
                    <Spinner size='small' color='white' className='mr-1' />
                ) : (
                    <FontAwesomeIcon icon={faUserPlus} className='mr-1' />
                )}
                {addButtonLabel}
            </Button>
        </div>
    );
};

export default EmptyStateContacts;
