import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import useTranslationHydration from '../../../../portal/common/hooks/useTranslationHydration';
import CloseButton from '../../../common/features/CloseButton';
import Spinner from '../../../common/features/Spinner';
import { AlertsSlice, NoticesSlice } from '../../../common/models/ReduxSlices';
import { removeUnreadLobbyItems } from '../../../redux/reducers/alertsSlice';
import { RootState } from '../../../redux/store';
import AlertDrawerCard from './AlertDrawerCard';
import DownloadLobbyDrawerCard from './DownloadLobbyDrawerCard';

interface AlertsDrawerProps {
    onToggleAlerts: () => void;
}

const AlertsDrawer: React.FC<AlertsDrawerProps> = ({ onToggleAlerts }) => {
    const { alerts, downloadLobby } = useSelector<RootState, AlertsSlice>((state) => state.alerts);
    const { isLoading: noticesLoading, error: noticesError } = useSelector<RootState, NoticesSlice>((state) => state.notices);
    const [showAnimation, setShowAnimation] = useState<boolean>(false);
    const exitDelay = useRef<NodeJS.Timeout>();
    const translate = useTranslationHydration();
    const parentContainer = document && document.querySelector('#portalDrawer');
    const dispatch = useDispatch();

    useEffect(() => {
        const enterDelay = setTimeout(() => setShowAnimation(true), 50);
        return () => {
            clearTimeout(enterDelay);
            clearTimeout(exitDelay.current);
        };
    }, []);

    useEffect(() => {
        // Remove all unread download lobby items
        dispatch(removeUnreadLobbyItems());
    }, []);

    const handleExit = () => {
        setShowAnimation(false);
        exitDelay.current = setTimeout(() => onToggleAlerts(), 150);
    };

    return (
        <>
            {alerts &&
                parentContainer &&
                ReactDOM.createPortal(
                    <>
                        <aside className={`offcanvas alerts-drawer ${showAnimation ? 'show' : ''}`}>
                            {/* Heading */}
                            <div
                                className='alerts-drawer-heading sticky-top bg-white border-bottom p-4'
                                style={{ display: 'flex', alignItems: 'start' }}
                            >
                                <h3 className='mb-0'>{translate('AlertsTitle_Label')}</h3>
                                <CloseButton onClick={handleExit} style={{ position: 'relative', top: '0.5rem', right: 0 }} />
                            </div>
                            {/* Alert cards */}
                            {/* Status: pending (waiting for the notices to load) */}
                            {noticesLoading && (
                                <div className='w-100 h-50 d-flex align-items-center justify-content-center'>
                                    <Spinner />
                                </div>
                            )}
                            {/* Status: fulfilled */}
                            {!noticesLoading && !noticesError && (
                                <div className='alert-cards p-4' style={{ minWidth: '0px' }}>
                                    {/* Download lobby */}
                                    {downloadLobby.map((item) => (
                                        <DownloadLobbyDrawerCard key={item.packageId} {...item} handleClose={handleExit} />
                                    ))}
                                    {/* Status alerts */}
                                    {alerts.statusAlerts.map((alert) => (
                                        <AlertDrawerCard
                                            key={alert.id}
                                            type={alert.type}
                                            date={alert.date}
                                            entryNumber={alert.entryNumber}
                                            agencyKey={alert.agencyKey}
                                            customsInfoID={alert.customsInfoID}
                                            handleClose={handleExit}
                                        />
                                    ))}
                                    {/* Notices */}
                                    {alerts.notices.map((alert) => (
                                        <AlertDrawerCard
                                            key={alert.id}
                                            type={alert.type}
                                            date={alert.date}
                                            title={alert.title}
                                            description={alert.description}
                                        />
                                    ))}
                                    {/* No new alerts message */}
                                    {!alerts.statusAlerts.length && !alerts!.notices.length && !downloadLobby.length && (
                                        <p>{translate('AlertsNoNew_Label')}</p>
                                    )}
                                </div>
                            )}
                        </aside>
                        <div className={`screen-overlay ${showAnimation ? 'show' : ''}`} onClick={handleExit}></div>
                    </>,
                    parentContainer
                )}
        </>
    );
};

export default React.memo(AlertsDrawer);
