import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewSubscriberLanguages, NewSubscriberRoles } from '../../../../portal/common/constants/constants-portal';
import Button from '../../../../portal/common/features/Button';
import FormInputField from '../../../../portal/common/features/FormInputField';
import Select from '../../../../portal/common/features/Select';
import {
    alphaNumericAndCommaValidator,
    emailValidator,
    hyphenValueValidator,
    inputHasValueValidator,
    uniqueContactFromGridEmail,
    uniqueContactFromGridMobile,
    uniqueNewContactEmail,
    uniqueNewContactMobile
} from '../../../../portal/common/functions/validators';
import useInputValidation from '../../../../portal/common/hooks/useInputValidation';
import useMaskedInputValidation from '../../../../portal/common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ContactsModals } from '../../../../portal/common/models/ContactsModals';
import { HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import { Contact as EditContact } from '../../../common/models/Contact';
import { ContactsSlice } from '../../../common/models/ReduxSlices';
import {
    removeNewContact,
    setEditContactsValues,
    setEditedRoleChecker,
    setNewContactAdmin,
    setNewContactCarrierNumber,
    setNewContactEmail,
    setNewContactFullName,
    setNewContactLanguage,
    setNewContactMobileNumber,
    setNewContactRole
} from '../../../redux/reducers/contactsSlice';
import { RootState } from '../../../redux/store';

interface ContactProps {
    id: number;
    email: string;
    fullName: string;
    phone: string;
    role: string;
    language: string;
    modal: ContactsModals;
    selectedContact: EditContact;
    setDisplayCarrierLabel: (arg: boolean) => void;
    someAreTrue: boolean;
}

const Contact: React.FC<ContactProps> = ({ id, role, language, modal, selectedContact, setDisplayCarrierLabel, someAreTrue }) => {
    const { formIsSubmitted, contacts, newContacts, teamsAdmins } = useSelector<RootState, ContactsSlice>((state) => state.contacts);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const [isCarrier, setIsCarrier] = useState(false);
    const translate = useTranslation();
    const dispatch = useDispatch();
    const carrierRef = useRef<HTMLInputElement>(null);

    const {
        value: name,
        handleChange: nameChangeHandler,
        handleBlur: nameBlurHandler,
        handleReset: nameResetHandler,
        isValid: nameIsValid,
        hasError: nameHasError
    } = useInputValidation({
        validators: [inputHasValueValidator],
        validateOnSubmit: formIsSubmitted,
        initial: modal.edit ? selectedContact?.FullName : ''
    });
    const {
        value: email,
        handleChange: emailChangeHandler,
        handleBlur: emailBlurHandler,
        handleReset: resetEmail,
        isValid: emailIsValid,
        hasError: emailHasError,
        errorIndex: emailErrorIndex
    } = useInputValidation({
        validators: [
            inputHasValueValidator,
            emailValidator,
            (value) =>
                uniqueContactFromGridEmail(
                    value.trim(),
                    !modal.edit ? contacts : contacts.filter((contact) => contact.Email !== selectedContact?.Email)
                ),
            !modal.edit ? (value) => uniqueNewContactEmail(value.trim(), newContacts) : () => true
        ],
        validateOnSubmit: formIsSubmitted,
        required: true,
        initial: modal.edit ? selectedContact?.Email : ''
    });
    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex,
        handleReset: resetPhoneNumber
    } = useMaskedInputValidation({
        validators: [
            (value) => isValidPhoneNumber(value, 'US'),
            (value) =>
                uniqueContactFromGridMobile(
                    value.trim(),
                    !modal.edit ? contacts : contacts.filter((contact) => contact.MobileNumber !== selectedContact?.MobileNumber)
                ),
            !modal.edit ? (value) => uniqueNewContactMobile(value.trim(), newContacts) : () => true
        ],
        validateOnSubmit: formIsSubmitted,
        inputMask: '(###) ###-####',
        required: false,
        initial: modal.edit ? selectedContact?.MobileNumber?.trim() : ''
    });
    const {
        value: selectedAdmin,
        handleReset: adminResetHandler,
        handlePicker: adminChangeHandler,
        handleBlur: adminBlurHandler,
        hasError: adminHasError,
        isValid: adminIsValid
    } = useInputValidation({
        validators: [inputHasValueValidator],
        validateOnSubmit: formIsSubmitted,
        initial: ''
    });
    const {
        value: selectedRole,
        handleReset: roleResetHandler,
        handlePicker: roleChangeHandler,
        handleBlur: roleBlurHandler,
        hasError: roleHasError,
        isValid: roleIsValid
    } = useInputValidation({
        validators: [inputHasValueValidator],
        validateOnSubmit: formIsSubmitted,
        initial: modal.edit ? selectedContact?.RoleLabel.split('_')[0] : role.split('_')[0]
    });
    const {
        value: carrierCode,
        handleChange: carrierCodeChangeHandler,
        handleBlur: carrierCodeBlurHandler,
        handleReset: carrierCodeResetHandler,
        hasError: carrierCodeHasError,
        isValid: carrierCodesIsValid
    } = useInputValidation({
        validators: [inputHasValueValidator, alphaNumericAndCommaValidator],
        validateOnSubmit: formIsSubmitted,
        initial: modal.edit ? selectedContact?.CarrierCodes : ''
    });
    const {
        value: selectedLanguage,
        handleReset: languageResetHandler,
        handlePicker: languageChangeHandler,
        handleBlur: languageBlurHandler,
        hasError: languageHasError,
        isValid: languageIsValid
    } = useInputValidation({
        validators: [hyphenValueValidator],
        validateOnSubmit: formIsSubmitted,
        initial: modal.edit ? selectedContact?.Language.toUpperCase() : language
    });

    useEffect(() => {
        if (!modal.edit) {
            dispatch(setNewContactFullName({ id, value: name.trim(), isValid: nameIsValid! }));
        } else {
            dispatch(setEditContactsValues({ value: name.trim(), name: 'FullName', isValid: nameIsValid }));
        }
    }, [name, nameIsValid, nameHasError, modal.edit, dispatch, id]);

    useEffect(() => {
        if (modal.edit) {
            dispatch(setEditContactsValues({ value: email.trim(), name: 'Email', isValid: emailIsValid }));
        } else {
            dispatch(setNewContactEmail({ id, value: email.trim(), isValid: emailIsValid! }));
        }
    }, [email, emailIsValid, emailHasError, modal.edit, dispatch, id]);

    useEffect(() => {
        if (modal.edit) {
            dispatch(setEditContactsValues({ value: phoneNumber.trim(), name: 'MobileNumber', isValid: phoneNumberIsValid }));
        } else {
            dispatch(setNewContactMobileNumber({ id, value: phoneNumber.trim(), isValid: phoneNumberIsValid! }));
        }
    }, [phoneNumber, phoneNumberIsValid, phoneNumberHasError, modal.edit, dispatch, id]);

    useEffect(() => {
        if (modal.edit) {
            dispatch(setEditContactsValues({ value: carrierCode.trim(), name: 'CarrierCodes', isValid: carrierCodesIsValid }));
        } else {
            dispatch(setNewContactCarrierNumber({ id, value: carrierCode.trim(), isValid: carrierCodesIsValid! }));
        }
    }, [carrierCode, carrierCodesIsValid, carrierCodeHasError, modal.edit, dispatch, id]);

    useEffect(() => {
        if (selectedContact && selectedContact.RoleLabel.includes('Carrier')) {
            setIsCarrier(true);
            setDisplayCarrierLabel(true);
        } else {
            setIsCarrier(false);
            setDisplayCarrierLabel(false);
        }
    }, [modal.edit]);

    useEffect(() => {
        if (carrierRef.current) {
            const regexPattern = /[a-zA-Z0-9,\s]/g;
            const result = carrierCode.match(regexPattern)?.join('') ?? '';
            const syntheticEvent = {
                target: { value: result } as Partial<HTMLInputElement>
            } as React.ChangeEvent<HTMLInputElement>;
            carrierCodeChangeHandler(syntheticEvent);
        }
    }, [carrierCodeChangeHandler, carrierCode]);

    const getCompanyFieldContent = () => {
        if (selectedContact) {
            return (
                <td style={{ width: 250 }}>
                    {/* Admin select - only for LII users */}
                    <FormInputField
                        label=''
                        defaultValue={teamsAdmins.data.find((x) => x.TeamId === selectedContact.TeamId)?.TeamAdminUserEmail}
                        readOnly
                    />
                </td>
            );
        } else {
            return (
                <td style={{ width: 200 }}>
                    {/* Admin select - only for LII users */}
                    <Select
                        value={selectedAdmin}
                        onBlur={adminBlurHandler}
                        onChange={(value) => {
                            adminChangeHandler(value);
                            if (!modal.edit) {
                                const val = teamsAdmins.data.find((item) => item.TeamAdminUserId === parseInt(value));
                                if (val) {
                                    dispatch(setNewContactAdmin({ id, value: val }));
                                }
                            }
                        }}
                        options={[
                            { value: '', label: translate('SelectAdmin_Label'), isDisabled: true },
                            ...teamsAdmins.data.map((item) => {
                                return { value: `${item?.TeamAdminUserId}`, label: item.TeamAdminUserEmail, isDisabled: false };
                            })
                        ]}
                        isValid={adminIsValid}
                        hasError={adminHasError}
                        className='custom-select role'
                        disabled={false}
                    />
                    {adminHasError && <div className='error-message'>{translate('RequiredAdmin_Label')}</div>}
                </td>
            );
        }
    };

    const content = (
        <>
            <tr>
                {user?.InternalUser && getCompanyFieldContent()}
                <td>
                    {/* Full name */}
                    <FormInputField
                        label=''
                        value={name}
                        onChange={nameChangeHandler}
                        onBlur={nameBlurHandler}
                        isValid={nameIsValid}
                        hasError={nameHasError}
                    />
                    {nameHasError && <div className='error-message'>{translate('RequiredName_Label')}</div>}
                </td>
                <td>
                    {/* Email */}
                    <FormInputField
                        label=''
                        value={email}
                        isValid={emailIsValid}
                        hasError={emailHasError}
                        onChange={emailChangeHandler}
                        onBlur={emailBlurHandler}
                    />
                    {emailHasError && emailErrorIndex === 0 && <div className='error-message'>{translate('EmailRequired_Label')}</div>}
                    {emailHasError && emailErrorIndex === 1 && <div className='error-message'>{translate('EmailInvalid_Label')}</div>}
                    {emailHasError && emailErrorIndex === 2 && <div className='error-message'>{translate('EmailContains_Label')}</div>}
                    {emailHasError && emailErrorIndex === 3 && <div className='error-message'>{translate('EmailEntered_Label')}</div>}
                </td>
                <td>
                    {/* Mobile number */}

                    <FormInputField
                        label=''
                        value={phoneNumber}
                        onChange={phoneNumberChangeHandler}
                        onBlur={phoneNumberBlurHandler}
                        isValid={phoneNumberIsValid}
                        hasError={phoneNumber.length > 0 && phoneNumberHasError}
                    />
                    {phoneNumber.length > 0 && phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                        <div className='error-message'>{translate('MobileInvalid_Label')}</div>
                    )}
                    {phoneNumber.length > 0 && phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                        <div className='error-message'>{translate('AlreadyUsedMobile_Label')}</div>
                    )}
                    {phoneNumber.length > 0 && phoneNumberHasError && phoneNumberErrorIndex === 2 && (
                        <div className='error-message'>{translate('PhoneAlreadyEntered_Label')}</div>
                    )}
                </td>
                <td>
                    {/* Role */}
                    <Select
                        value={selectedRole}
                        onBlur={roleBlurHandler}
                        onChange={(value) => {
                            roleChangeHandler(value);
                            if (value === 'Carrier') {
                                setIsCarrier(true);
                                setDisplayCarrierLabel(true);
                            } else {
                                setIsCarrier(false);
                                setDisplayCarrierLabel(false);
                            }
                            if (!modal.edit) {
                                dispatch(setNewContactRole({ id, value: value as (typeof NewSubscriberRoles)[number]['value'] }));
                            } else {
                                if (value !== '') {
                                    dispatch(
                                        setEditContactsValues({
                                            value: value as (typeof NewSubscriberRoles)[number]['value'],
                                            name: 'RoleLabel',
                                            isValid: roleIsValid
                                        })
                                    );
                                    dispatch(setEditedRoleChecker(true));
                                } else dispatch(setEditedRoleChecker(false));
                            }
                        }}
                        options={NewSubscriberRoles.map((item) => {
                            return { ...item, label: translate(item.label) };
                        })}
                        isValid={roleIsValid}
                        hasError={roleHasError}
                        className='custom-select role'
                        disabled={false}
                    />
                    {roleHasError && <div className='error-message'>{translate('RequiredRole_Label')}</div>}
                </td>
                {isCarrier && (
                    <td>
                        {/* Carrier codes */}
                        <FormInputField
                            maxLength={50}
                            label=''
                            value={carrierCode}
                            onChange={carrierCodeChangeHandler}
                            onBlur={carrierCodeBlurHandler}
                            isValid={carrierCodesIsValid}
                            hasError={carrierCodeHasError}
                            ref={carrierRef}
                        />
                        {carrierCodeHasError && <div className='error-message'>{'Carrier code required'}</div>}
                        {(someAreTrue || isCarrier) && <p style={{ width: '165px' }}>{translate('CarrierCodeMessage_Label')}</p>}
                    </td>
                )}
                {!isCarrier && someAreTrue && (
                    <td>
                        {/* Carrier codes field filler when other role is selected */}
                        <FormInputField label='' styleAdditional={{ opacity: '0' }} isActive={true} />
                    </td>
                )}
                <td>
                    {/* Language */}
                    <Select
                        value={selectedLanguage}
                        options={NewSubscriberLanguages.map((item) => {
                            return { ...item, label: translate(item.label) };
                        })}
                        onBlur={languageBlurHandler}
                        onChange={(value) => {
                            languageChangeHandler(value);
                            if (!modal.edit) {
                                dispatch(setNewContactLanguage({ id, value: value as (typeof NewSubscriberLanguages)[number]['value'] }));
                            } else {
                                dispatch(
                                    setEditContactsValues({
                                        value: value as (typeof NewSubscriberLanguages)[number]['value'],
                                        name: 'Language',
                                        isValid: languageIsValid
                                    })
                                );
                            }
                        }}
                        isValid={languageIsValid}
                        hasError={languageHasError}
                        disabled={false}
                    />
                    {languageHasError && <div className='error-message'>{translate('RequiredLanguage_Label')}</div>}
                </td>

                {
                    <td className='row-action bg-white'>
                        {/* Remove button */}
                        {!modal.edit && (
                            <Button
                                variant='danger-outline'
                                onClick={() => {
                                    adminResetHandler();
                                    nameResetHandler();
                                    resetEmail();
                                    resetPhoneNumber();
                                    roleResetHandler();
                                    carrierCodeResetHandler();
                                    languageResetHandler();
                                    dispatch(removeNewContact(id));
                                }}
                            >
                                <FontAwesomeIcon icon={faMinus} />
                            </Button>
                        )}
                    </td>
                }
            </tr>
        </>
    );

    return <>{modal.edit ? modal.edit && selectedContact && content : content}</>;
};

export default Contact;
