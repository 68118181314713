import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import useDestinations from './common/hooks/useDestinations';
import { setDestinations } from './redux/reducers/shipmentDestinationSlice';
import ShipmentDetails from './screens/shipmentdetails/ShipmentDetails';
import ShipmentList from './screens/shipmentlist/ShipmentList';

const Clearances = () => {
    const destinations = useDestinations();
    const dispatch = useDispatch();

    // Update destination(s) based on user's client numbers list
    useEffect(() => {
        if (destinations) {
            dispatch(setDestinations(destinations));
        }
    }, [destinations, dispatch]);

    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path='/Clearances' exact component={ShipmentList} />
                    <Route path='/Clearances/:customsInfoID' exact component={ShipmentDetails} />
                </Switch>
            </div>
        </main>
    );
};

export default React.memo(Clearances);
