import { Component } from 'react';
import { connect } from 'react-redux';
import CheckBox from '../../../common/CheckBox/CheckBox';
import MoreDetailsIcon from '../../../common/Icons/MoreDetailsIcon';
import * as actionCreators from '../../../store/actions/index';
import { camelToTitleCase } from '../../../utils/mlp-utils';
import BatchEditDropDown from '../../SystemAdmin/BatchEditUsers/BatchEditDropDown';

class BatchEditGridParentRow extends Component {
    updateFeature = (data, roleId, selected) => {
        this.props.onUpdateParentFeature(data.parentIndex, roleId, selected);
    };

    removeParentRow = () => {
        this.props.onRemoveParentUser(this.props.parentIndex);
    };
    updateParentStatus = (status) => {
        this.props.onUpdateParentStatus(this.props.parentIndex, status);
    };

    //This method is being passed as props so there is no need to declare it
    //Keeping it here for history reasons
    // getUserDetails = (user) => {
    //     return [
    //         { label: this.props.translations.Phrases['CompanyName_Label'], value: user.CompanyName },
    //         { label: this.props.translations.Phrases['Roles_Label'], value: user.UserRoleDisplay },
    //         { label: this.props.translations.Phrases['Email_Label'], value: user.Email },
    //         { label: this.props.translations.Phrases['ExpirationDate_Label'], value: UTCtoLocalDateTime(user.ExpirationDate) }
    //     ];
    // };

    getUserStatusDisplay = (user) => {
        if (user.Status.some((x) => x.IsSelected)) {
            return (
                <td className='batch-edit-dropdown'>
                    <BatchEditDropDown disabled={!user.IsEnabled} options={user.Status} customAction={this.updateParentStatus} />
                </td>
            );
        }

        return (
            <td>
                <span>{camelToTitleCase(user.StatusName)}</span>
            </td>
        );
    };

    render() {
        const parentIndex = this.props.parentIndex;
        const user = this.props.user;
        const isDangerIcon = !user.IsEnabled;
        const data = { parentIndex: parentIndex };

        const RolesAndPermissions = user.RolesAndPermissions.map(
            function (r, index) {
                const name = 'ParentUsers.' + parentIndex + '.';
                return (
                    <td key={'roles-' + index} className='text-center'>
                        <CheckBox
                            embed
                            name={name}
                            checked={r.IsSelected}
                            disabled={!r.IsEnabled || !user.IsEnabled}
                            onChecked={this.updateFeature.bind(this, data, r.RoleId)}
                        />
                    </td>
                );
            }.bind(this)
        );

        const removeButton = (
            <span onClick={this.removeParentRow} className='btn btn-link'>
                <i className='text-danger fa fa-times-circle'></i>
            </span>
        );

        return (
            <tr>
                <td>
                    {user.FullName}
                    <MoreDetailsIcon isDangerIcon={isDangerIcon} items={this.props.getUserDetails(user)} />
                </td>
                {this.getUserStatusDisplay(user)}
                {RolesAndPermissions}
                <td className='text-right'>{removeButton}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateParentFeature: (parentIndex, roleId, isSelected) =>
            dispatch(actionCreators.updateParentFeature(parentIndex, roleId, isSelected)),
        onRemoveParentUser: (parentIndex) => dispatch(actionCreators.removeParentUser(parentIndex)),
        onUpdateParentStatus: (parentIndex, status) => dispatch(actionCreators.updateParentStatus(parentIndex, status))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchEditGridParentRow);
