import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated } from '../../utils/mlp-utils';

class UnexpectedProcessingError extends Component {
    static propTypes = {
        errorDetails: PropTypes.string,
        failedEmails: PropTypes.array
    };
    getFailedEmailList = () => {
        if (this.props.failedEmails === undefined || this.props.failedEmails.length < 1) {
            return null;
        }

        const emailList = this.props.failedEmails.map(function (email) {
            return <li>{email}</li>;
        });

        return <ul className='list-unstyled'>{emailList}</ul>;
    };

    render() {
        return (
            <div>
                <div className='col-sm-12'>
                    <h3>{this.props.title}</h3>
                    <br />
                    <h4>{this.props.translations.Phrases['Details_Label']}:</h4>
                    <p>{formatTranslated(this.props.errorDetails, this.props.translations.Phrases)}</p>
                    <br />
                    {this.getFailedEmailList()}
                </div>
                <div className='col-sm-12'>
                    <button type='button' className='button small blue' onClick={this.props.tryAgain}>
                        {this.props.translations.Phrases['TryAgain_Button']}
                    </button>
                </div>
                <div className='col-sm-12'>
                    <div className='back-link'>
                        <a href='/SystemAdmin'>{this.props.translations.Phrases['BackToSystemAdmin_Label']}</a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(UnexpectedProcessingError);
