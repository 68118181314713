import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';
import DetailsHeader from './DetailsHeader';

class SearchResultDetails extends Component {
    componentDidMount() {
        this.props.onInitTranslations('Imaging.Imaging.SearchResultDetails');
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    componentWillUnmount() {
        this.props.onClearImageDetailsState();
    }

    render() {
        return (
            <div id='page' className='wide-page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div id='imaging'>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {formatTranslated('Page_Header_Navigation_Level_1', this.props.translations.Phrases)}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <Link to='/Imaging' className='breadcrumb-parent'>
                                        <span className='blue-title'>
                                            {formatTranslated('Page_Header_Navigation_Level_2', this.props.translations.Phrases)}
                                        </span>
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <span>{formatTranslated('Page_Header_Navigation_Level_3', this.props.translations.Phrases)}</span>
                                </nav>
                                <h1 className='bold-title'>{formatTranslated('Page_Header', this.props.translations.Phrases)}</h1>
                                <div className='back-link'>
                                    <Link to={{ pathname: '/Imaging', state: { prevPath: this.props.location.pathname } }}>
                                        <span className='fa fa-long-arrow-left' aria-hidden='true'></span>
                                        {formatTranslated('Back_To_Image_Search', this.props.translations.Phrases)}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='imaging-details' className='w-100'>
                                    <DetailsHeader></DetailsHeader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onClearImageDetailsState: () => dispatch(actions.resetImageDetailsState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultDetails);
