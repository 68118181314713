import { Placement } from '@popperjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import TooltipWrapper from '../Wrappers/TooltipWrapper';
import classes from './Tooltip.module.css';

interface TooltipProps {
    label: string;
    children: React.ReactNode;
    placement?: Placement;
    offset?: number;
    delay?: number;
}

const Tooltip: React.FC<TooltipProps> = ({ label, placement = 'top', children, offset = 5, delay = 0 }) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [referenceElement, setReferenceElement] = useState<any>(null);
    const [popperElement, setPopperElement] = useState<any>(null);
    const [arrowElement, setArrowElement] = useState<any>(null);
    const delayHandler = useRef<NodeJS.Timeout>();
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: placement,
        modifiers: [
            { name: 'offset', options: { offset: [0, offset] } },
            { name: 'arrow', options: { element: arrowElement } }
        ]
    });

    useEffect(() => {
        if (update) update();
    }, [update]);

    const handleMouseEnter = () => {
        delayHandler.current = setTimeout(() => {
            setShowTooltip(true);
        }, delay);
    };

    const handleMouseLeave = () => {
        clearTimeout(delayHandler.current);
        setShowTooltip(false);
    };

    return (
        <>
            <span
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={classes['tooltip-wrapper']}
                ref={setReferenceElement}
            >
                {children}
            </span>
            {showTooltip && (
                <TooltipWrapper>
                    <div
                        className={`${classes.tooltip} ${classes['fade-in']}`}
                        ref={setPopperElement}
                        style={{ ...styles.popper, maxWidth: '30vw' }}
                        {...attributes.popper}
                    >
                        {label}
                        <div ref={setArrowElement} style={styles.arrow} className={classes.arrow} />
                    </div>
                </TooltipWrapper>
            )}
        </>
    );
};

export default Tooltip;
