import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditTeamMemberSlice } from '../../common/models/ReduxSlices';
import { GetTeamMemberDetails } from '../actions/teamManagement';

const initialState: EditTeamMemberSlice = {
    isLoading: true,
    error: undefined,
    data: null,
    editData: null,
    userDetailsIsValid: false
};

const editTeamMemberSlice = createSlice({
    name: 'editTeamMember',
    initialState,
    reducers: {
        setEditData(
            state,
            action: PayloadAction<{
                FirstName: string;
                LastName: string;
                Email: string;
                CompanyName: string;
                Phone: string;
                MobileNumber: string;
                UserRole: string;
                Roles: any;
                ExpirationDate: string;
            }>
        ) {
            state.editData = {
                ...state.data,
                Users: [
                    {
                        ...action.payload
                    }
                ]
            };
        },
        setUserDetailsIsValid(state, action: PayloadAction<boolean>) {
            state.userDetailsIsValid = action.payload;
        },
        resetEditTeamMember() {
            return { ...initialState };
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetTeamMemberDetails.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetTeamMemberDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = Object.fromEntries(Object.entries(action.payload).filter(([key, value]) => !/parentservices/i.test(key)));
            })
            .addCase(GetTeamMemberDetails.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { setEditData, resetEditTeamMember, setUserDetailsIsValid } = editTeamMemberSlice.actions;

export default editTeamMemberSlice.reducer;
