import produce from 'immer';
import { Component, React } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import { dashboard } from '../../constants/mlp-constants';
import { formatTranslated } from '../../utils/mlp-utils';
import WidgetSelectorModal from './WidgetSelectorModal';

class WidgetSelectorContent extends Component {
    state = {
        searchText: '',
        enableOk: true,
        data: []
    };

    cancelChoice = () => {
        var initialState = JSON.parse(JSON.stringify(this.props.data));
        this.setState({ data: initialState });
        this.props.closeModal();
    };

    confirmChoice = () => {
        this.saveWidgetSelection(this.state.data);
        this.props.onRequestConfirm(this.state.data);
    };

    componentDidUpdate = () => {
        if (this.state.data.length === 0 && this.props.data.length > 0) {
            const data = JSON.parse(JSON.stringify(this.props.data));
            this.setState({ data: data });
        }
    };

    saveWidgetSelection = (model) => {
        axios.post(dashboard.DASHBOARD_SAVE_WIDGETSELECTION_URL, model).catch((error) => {
            if (error.toString() === '') {
                toastr.error(formatTranslated('Widget_Selection_Session_TimeOut_Label', this.props.translations.Phrases));
            } else {
                toastr.error(formatTranslated('Widget_Selection_Save_Error_Label', this.props.translations.Phrases));
            }
        });
    };

    toggleWidget = (widget) => {
        let newData = produce(this.state.data, (draft) => {
            let wg = draft.find((w) => w.Widget.Id === widget.Widget.Id);
            if (wg.Visible === false) {
                wg.SortOrder = 0;
            }
            wg.Visible = !wg.Visible;
            //if widget is visible set sort order +1
            if (wg.Visible === true) {
                draft.forEach((w) => {
                    if (w.CountryCode === this.props.countryCode) {
                        w.SortOrder = w.SortOrder + 1;
                    }
                });
            }
        });
        this.setState({ data: newData });
    };

    toggleAll = (isSelected) => {
        let newData = produce(this.state.data, (draft) => {
            if (draft) {
                draft.forEach((wg) => {
                    if (wg.CountryCode === this.props.countryCode) {
                        wg.Visible = isSelected;
                    }
                });
            }
        });
        this.setState({ data: newData });
    };

    render() {
        return (
            <div className='client-number-chooser-content'>
                <WidgetSelectorModal
                    data={this.state.data}
                    isOpen={this.props.isOpen}
                    onRequestClose={this.cancelChoice}
                    onRequestConfirm={this.confirmChoice}
                    onToggleWidget={this.toggleWidget}
                    onToggleSelect={this.toggleAll}
                    countryCode={this.props.countryCode}
                ></WidgetSelectorModal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(WidgetSelectorContent);
