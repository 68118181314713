import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputBase from '../../../common/Inputbase/Inputbase';

class BatchEditDropDown extends Component {
    static defaultProps = {
        name: '',
        options: [],
        defaultOptionText: 'Select an option',
        valueField: 'Value',
        textField: 'Text',
        onlyCustomAction: false,
        readonly: false
    };
    static propTypes = {
        name: PropTypes.string,
        options: PropTypes.array,
        readonly: PropTypes.bool,
        textField: PropTypes.string,
        valueField: PropTypes.string,
        defaultOptionText: PropTypes.string,
        onlyCustomAction: PropTypes.bool
    };

    updateState = (event) => {
        if (this.props.customAction) {
            this.props.customAction(event.target.value);
        }
    };

    render() {
        let selectedValue = '';

        if (this.props && this.props.options && this.props.options.length > 0 && this.props.valueField) {
            let selectedOptions = this.props.options.find((o) => o.IsSelected);
            if (selectedOptions) {
                selectedValue = selectedOptions[this.props.valueField];
            }
        }
        const options = this.props.options.map((item) => {
            return (
                <option key={item[this.props.valueField]} value={item[this.props.valueField]}>
                    {item[this.props.textField]}
                </option>
            );
        });
        return (
            <InputBase {...this.props}>
                <select
                    value={selectedValue ? selectedValue : 0}
                    name={this.props.name}
                    className='form-control'
                    disabled={this.props.disabled}
                    readOnly={this.props.readonly}
                    onChange={this.updateState}
                >
                    {options}
                </select>
            </InputBase>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(BatchEditDropDown);
