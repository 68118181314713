import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridColumn as Column, Grid, GridCellProps, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridLoadingOverlay from '../../../portal/common/features/Grid/GridLoadingOverlay';
import Spinner from '../../../portal/common/features/Spinner';
import useGridColumnMinWidth from '../../../portal/common/hooks/useGridColumnMinWidth';
import useGridLanguage from '../../../portal/common/hooks/useGridLanguage';
import useGridPreferences from '../../../portal/common/hooks/useGridPreferences';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { ColumnSettings } from '../../../portal/common/models/GridPreferences';
import { ClientSelectionSlice, GridPreferencesSlice, LanguageSlice } from '../../../portal/common/models/ReduxSlices';
import { setIsApplyClicked } from '../../../portal/redux/reducers/clientSelectionReducer';
import FormattedGridCell from '../../common/components/FormattedGridCell';
import { gridSettingsConstants } from '../../common/constants/complete-entry-data-constants';
import '../../common/cssfixes/cssFixes.css';
import useDisableColumnsDrag from '../../common/hooks/useDisableColumnsDrag';
import useHeaderSelection from '../../common/hooks/useHeaderSelection';
import { CASSModel, CompleteEntryDataGridSettingsSlice } from '../../common/models/completeEntryDataModels';
import { GetCASSData, GetCASSIndex } from '../../redux/actions/completeEntryData';
import { SaveUserCADGridSetting } from '../../redux/actions/completeEntryDataGridPreferences';
import {
    updateColumnOrderGrid,
    updateColumnOrderSaveCompleted,
    updateCoulumnActivated
} from '../../redux/reducers/completeEntryDataGridPreferenceSlice';
import { setPageSelected, setPageSize } from '../../redux/reducers/completeEntryDataGridSettingsSlice';
import {
    resetSelection,
    updatePageSize,
    updateSelection,
    updateSelectionSelectAll,
    updateShouldSubmitSearch,
    updateStartRow
} from '../../redux/reducers/completeEntryDataSlice';
import { RootState } from '../../redux/store';
import CompleteEntryDataFooter from './CompleteEntryDataFooter';
import NoShipmentData from './NoShipmentData';

const CompleteEntryDataGrid = () => {
    const {
        allColumns,
        visibleColumns,
        columnsWidths,
        areColumnsLoading,
        isLoading: isGridConfigLoading,
        columnOrderSaveCompleted,
        coulumnActivated
    } = useSelector<RootState, GridPreferencesSlice>((state) => state.completeEntryDataGridPreferences);
    const {
        model,
        shouldSubmitSearch,
        isFiltering,
        isLoadingIndex,
        isLoading: isLoadingSearch,
        selectedEntries,
        error
    } = useSelector<RootState, CASSModel>((state) => state.completeEntryData);
    const { isLoadingGet } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const gridSettings = useSelector<RootState, CompleteEntryDataGridSettingsSlice>((state) => state.completeEntryDataGridSettings);
    const { applyClicked } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const { headerCheckbox, handleHeaderCheckbox } = useHeaderSelection({
        allEntries: model.Shipments || [],
        selectedEntries,
        dispatchSelection: (value) => dispatch(updateSelectionSelectAll(!value))
    });
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const gridLanguage = useGridLanguage(selectedLanguage);
    const gridRef = useRef(null);
    const SELECTED_FIELD = 'selected';
    const { setWidth } = useGridColumnMinWidth({ columns: visibleColumns });
    const tableHeaders = document.getElementsByTagName('th');
    const elements = [tableHeaders[0] as HTMLElement, tableHeaders[tableHeaders.length - 1]];
    const header = document.getElementsByClassName('k-grid-header')[0];
    header && model.Shipments && model.Shipments.length < 10 ? header?.classList.add('mr-0') : header?.classList.remove('mr-0');
    const { disableDrag } = useDisableColumnsDrag({ isLoading: isLoadingSearch, elements });
    const isFooterDisplayed = selectedEntries.length > 0;
    const translate = useTranslation();
    const dispatch = useDispatch();

    /* Get initial model */
    useEffect(() => {
        dispatch(GetCASSIndex());
    }, [dispatch]);

    /* Get data with initial model */
    useEffect(() => {
        if (shouldSubmitSearch && !isLoadingIndex && !isLoadingSearch) {
            dispatch(GetCASSData(model));
        }
    }, [shouldSubmitSearch, model, isLoadingIndex, isLoadingSearch, dispatch]);

    /* When column(s) is/are activated trigger new submitSearch to get data for the column(s) */
    useEffect(() => {
        if (columnOrderSaveCompleted && coulumnActivated) {
            dispatch(updateShouldSubmitSearch(true));
            dispatch(updateColumnOrderSaveCompleted(false));
            dispatch(updateCoulumnActivated(false));
        } else if (columnOrderSaveCompleted) {
            dispatch(updateColumnOrderSaveCompleted(false));
        }
    }, [columnOrderSaveCompleted, coulumnActivated, dispatch]);

    /* Trigger search when client chooser apply clicked */
    useEffect(() => {
        if (applyClicked) {
            dispatch(updateShouldSubmitSearch(true));
            dispatch(resetSelection());
        }
        dispatch(setIsApplyClicked(false));
    }, [applyClicked, dispatch]);

    // Reset pagination on filter
    useEffect(() => {
        dispatch(setPageSelected(1));
    }, [isFiltering, dispatch]);

    const { columns, handleColumnReorder } = useGridPreferences({
        allColumns,
        visibleColumns,
        columnsWidths,
        onColumnReorder: (value) => {
            dispatch(updateColumnOrderGrid(value));
        },
        saveColumnReorder: (value) => {
            saveGridSettings(value);
        }
    });

    const saveGridSettings = (value: ColumnSettings[]) => {
        dispatch(
            SaveUserCADGridSetting({
                GridColumns: value.filter((item: ColumnSettings) => {
                    if (item.Code !== 'selected' && item.Code !== 'Actions') {
                        return item;
                    }
                    return null;
                }),
                area: gridSettingsConstants['ca']
            })
        );
    };

    const handleCheckbox = useCallback(
        (event: CheckboxChangeEvent) => {
            if (!event.target.name) return;

            dispatch(updateSelection(event.target.name));
        },
        [dispatch]
    );

    const formattedCell = (props: GridCellProps) => (
        <FormattedGridCell {...props} selectedEntries={selectedEntries} checkboxChange={handleCheckbox} />
    );

    // Header with drag icon
    const customHeader = useCallback(
        (props: any) => {
            return props.field === 'selected' ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox value={headerCheckbox} disabled={false} onChange={handleHeaderCheckbox} />
                </div>
            ) : (
                <div className='d-flex justify-content-between grabbable' onClick={props.onClick}>
                    <div className='header-title-nowrap'>{props.title}</div>
                    {props.children}
                    <div className='text-lii-text-light'>
                        <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                </div>
            );
        },
        [headerCheckbox, handleHeaderCheckbox]
    );

    // Pagination Section Change
    const pageChange = (event: GridPageChangeEvent) => {
        dispatch(setPageSelected(event.page.skip / event.page.take + 1));
        dispatch(setPageSize(event.page.take));
        dispatch(updateStartRow((event.page.skip / event.page.take) * event.page.take));
        dispatch(updatePageSize(event.page.take ? event.page.take : 10));
    };

    const calculateColumnWidth = (columnCode: string, columnWidth: number) => {
        const width = setWidth(columnsWidths?.find((item) => item.Code === columnCode)?.Width);
        return columnWidth > width ? columnWidth : width;
    };

    return (
        <>
            {model.Shipments && model.Shipments.length > 0 && !isGridConfigLoading && columns && !error && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <Grid
                            ref={gridRef}
                            className='flex-fill'
                            data={model.Shipments ?? model.Shipments}
                            total={model.TotalHits}
                            pageable={model.TotalHits <= 10 ? false : gridSettings.pageable}
                            onPageChange={pageChange}
                            skip={(gridSettings.pageSelected - 1) * gridSettings.pageSize}
                            take={gridSettings.pageSize}
                            reorderable={!areColumnsLoading && !disableDrag ? true : false}
                            onColumnReorder={handleColumnReorder}
                        >
                            <Column field={SELECTED_FIELD} cell={formattedCell} headerCell={customHeader} width='50px' orderIndex={0} />
                            {columns?.map((column) => (
                                <Column
                                    key={column.Code}
                                    field={column.Code}
                                    title={translate(column.HeaderLabel).trim()}
                                    headerCell={customHeader}
                                    cell={formattedCell}
                                    width={column.IsVisible ? calculateColumnWidth(column.Code, column.Width) : 0}
                                    orderIndex={column.Order}
                                />
                            ))}
                            <Column
                                field='Actions'
                                title={' '}
                                headerCell={''}
                                cell={formattedCell}
                                sortable={false}
                                resizable={false}
                                locked
                                width='50%'
                                orderIndex={columns?.length}
                            />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            )}

            {isFooterDisplayed && (
                <CompleteEntryDataFooter selectedEntries={selectedEntries} amountSelected={selectedEntries.length + ''} />
            )}

            {/* Initial loading of the grid */}
            {((!model?.Shipments?.length && !columns) || isGridConfigLoading || isLoadingGet) && (
                <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                    <Spinner />
                </div>
            )}

            {/* Loading when grid has data */}
            {model.Shipments && model.Shipments.length > 0 && !isLoadingIndex && isLoadingSearch && columns && !isLoadingGet && (
                <GridLoadingOverlay />
            )}

            {/* No search results or data */}
            {!!model?.ReturnFields?.length &&
                !isLoadingIndex &&
                !isLoadingSearch &&
                model.Shipments &&
                model?.Shipments.length === 0 &&
                !isGridConfigLoading &&
                !error && (
                    <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                        <NoShipmentData />
                    </div>
                )}
        </>
    );
};

export default CompleteEntryDataGrid;
