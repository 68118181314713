import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../portal/common/features/Button';
import Checkbox from '../../../../portal/common/features/Checkbox';
import DropdownMenuButton from '../../../../portal/common/features/DropdownMenuButton';
import Tooltip from '../../../../portal/common/features/Tooltip/Tooltip';
import useDropdownToggle from '../../../../portal/common/hooks/useDropdownToggle';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import { RootState } from '../../../../portal/redux/store';
import { EditSubscribersSlice } from '../../../common/models/ReduxSlices';
import { setNotificationTypeCheckbox } from '../../../redux/reducers/editSubscriptiosSubscribersSlice';
import { RootState as SubscribersRootState } from '../../../redux/store';

interface NotificationTypesDropdownProps {
    subscriptionId: number;
    disabled?: boolean;
    applyChange: (isEmailChecked: boolean, isMobileChecked: boolean) => void;
}
const NotificationTypesDropdown: React.FC<NotificationTypesDropdownProps> = ({
    subscriptionId,
    disabled,
    applyChange
}) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { subscriptions } = useSelector<SubscribersRootState, EditSubscribersSlice>((state) => state.editSubscriptionsSubscriber)
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const translate = useTranslation();
    const dispatch = useDispatch();

    let emailCheckbox = subscriptions?.find(item => item.SubscriptionId === subscriptionId)?.NotificationTypeCheckboxes?.EmailCheckbox;
    let phoneCheckbox = subscriptions?.find(item => item.SubscriptionId === subscriptionId)?.NotificationTypeCheckboxes?.PhoneCheckbox;
    let subscription = subscriptions?.find(item => item.SubscriptionId === subscriptionId);
    let email = subscription?.Email ?? subscription?.ProfileEmail;
    let phone = subscription?.Phone ?? subscription?.ProfilePhone;

    const handleApply = () => {
        handleToggle();
        applyChange(emailCheckbox!, phoneCheckbox!);
    }
    
    return (
        <>
            <div className='table-milestone-dropdown'>
                <DropdownMenuButton
                    btnVariant='tertiary'
                    btnClassName='btn-sm dropdown-toggle'
                    label={'Email, text'}
                    childrenDivClassName='dropdown-menu'
                    isOpen={isOpen}
                    onClick={handleToggle}
                    ref={dropdownMenuRef}
                    disabled={disabled}
                >
                    <div className='mr-4 ml-4 mt-3'>
                        <p style={{ fontWeight: 'bold' }}>{translate('HowToBeNotified_Label')}</p>
                        <form className='checkboxes'>
                            <div className='custom-control custom-checkbox mb-1'>
                                {email && (
                                    <Checkbox
                                        id={'email'}
                                        name={'Email'}
                                        inputStyle={{ width: '1.25rem', height: '1.32813rem' }}
                                        divStyle={{ paddingBottom: '1vh' }}
                                        onChange={() => !user?.InternalUser && dispatch(setNotificationTypeCheckbox({
                                            subscriptionId: subscriptionId, 
                                            emailValue: !emailCheckbox, 
                                            phoneValue: phoneCheckbox!
                                        }))}
                                        isChecked={emailCheckbox}
                                    >
                                        {email}
                                    </Checkbox>
                                )}
                                {!email && <p>{translate('DisableAddEmail_Label')}</p>}
                                {phone && (
                                    <Checkbox
                                        id={'phone'}
                                        name={'Phone'}
                                        inputStyle={{ width: '1.25rem', height: '1.32813rem' }}
                                        divStyle={{ paddingBottom: '1vh' }}
                                        onChange={() => !user?.InternalUser && dispatch(setNotificationTypeCheckbox({
                                            subscriptionId: subscriptionId, 
                                            emailValue: emailCheckbox!, 
                                            phoneValue: !phoneCheckbox
                                        }))}
                                        isChecked={phoneCheckbox}
                                    >
                                        {phone}
                                    </Checkbox>
                                )}
                                {!phone && <p>{translate('DisableAddMobile_Label')}</p>}
                            </div>
                        </form>
                    </div>
                    <hr />
                    <div className='button-container d-flex pb-2 ml-4 mr-4'>
                        <Button
                            children={translate('Cancel_Label')}
                            type='button'
                            variant='tertiary-blue'
                            className='mr-2 w-50'
                            onClick={handleToggle}
                        />
                        <Button
                            children={
                                !user?.InternalUser ? (
                                    translate('Apply_Label')
                                ) : (
                                    <Tooltip label='Disabled for LII users.'>{translate('Apply_Label')}</Tooltip>
                                )
                            }
                            type='button'
                            variant='primary'
                            className='w-50'
                            disabled={!emailCheckbox && !phoneCheckbox && !user?.InternalUser}
                            onClick={() => handleApply()}
                        />
                    </div>
                </DropdownMenuButton>
            </div>
        </>
    );
};

export default NotificationTypesDropdown;
