import { createGenericGridSettingsSlice, genericGridSettingsInitialState } from '../../../portal/redux/reducers/genericGridSettingsSlice';

const clearancesGridSettingsSlice = createGenericGridSettingsSlice({
    name: 'clearancesGridSettings',
    initialState: genericGridSettingsInitialState,
    reducers: {}
});

export const {
    setPageSelected,
    setDataState,
    setTotalItems,
    setSelectedState,
    setHeaderCheckbox,
    setCurrentDataItems,
    setSort,
    resetDataState,
    resetSelectedState
} = clearancesGridSettingsSlice.actions;

export default clearancesGridSettingsSlice.reducer;
