import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    API_HYDRATION_PATH,
    Breakpoints,
    HeaderNavItems,
    LanguageCodes,
    SidebarNavItems,
    Translations
} from '../portal/common/constants/constants-portal';
import AlertsDrawer from '../portal/common/features/Alerts/AlertsDrawer';
import AlertsToasts from '../portal/common/features/Alerts/AlertsToasts';
import ErrorPage from '../portal/common/features/ErrorPage';
import HeaderNav from '../portal/common/features/HeaderNav/HeaderNav';
import Sidebar from '../portal/common/features/Sidebar/Sidebar';
import Spinner from '../portal/common/features/Spinner';
import useAlerts from '../portal/common/hooks/useAlerts';
import usePageLoad from '../portal/common/hooks/usePageLoad';
import { HydrationSlice, LanguageSlice, NoticesSlice, TranslationsSlice } from '../portal/common/models/ReduxSlices';
import { GetUserDetails } from '../portal/redux/actions/hydration';
import { GetNotices } from '../portal/redux/actions/notices';
import { ChangeLanguage, ChangeLanguageHydration, GetTranslations } from '../portal/redux/actions/translations';
import { setAlerts } from '../portal/redux/reducers/alertsSlice';
import { setLanguageSelected } from '../portal/redux/reducers/languageSlice';
import { resetDataState, setSort } from './redux/reducers/teamGridSettingsSlice';
import { RootState } from './redux/store';
import Team from './Team';
// INITIAL_VALUES:
// Don't show the Sidebar when the window is smaller than 1024px
const INITIAL_VALUES = {
    isSidebarOpen: window.innerWidth < Breakpoints.Mobile ? false : true
};

const App = () => {
    const { user, isLoading: userLoading, error: userError } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const {
        isLoading: translationsLoading,
        error: translationsError,
        translations
    } = useSelector<RootState, TranslationsSlice>((state) => state.translations);
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { isLoading: noticesLoading, error: noticesError } = useSelector<RootState, NoticesSlice>((state) => state.notices);
    const [isSidebarOpen, setIsSidebarOpen] = useState(INITIAL_VALUES.isSidebarOpen);
    const [showAlerts, setShowAlerts] = useState(false);
    const dispatch = useDispatch();
    const alerts = useAlerts();
    const pageLoading = usePageLoad();

    // Set the "activeMenuItem" inside the "localStorage" on initial load
    useEffect(() => {
        localStorage.setItem('activeMenuItem', HeaderNavItems[1].id);
    }, []);

    // Get user and notices on initial load
    useEffect(() => {
        dispatch(GetUserDetails());
    }, [dispatch]);

    // Set initial sort for the team grid
    useEffect(() => {
        dispatch(setSort([{ field: 'LastLogin', dir: 'desc' }]));
    }, [dispatch]);

    // When user is loaded get the translations related to the user's language code (runs only once)
    useEffect(() => {
        if (user?.LanguageCode) {
            // CHANGE THIS: Get the translations for the current page
            dispatch(GetTranslations({ languageCode: user?.LanguageCode, phraseGroup: Translations.PhraseGroups.Team }));
        }
    }, [dispatch, user?.LanguageCode]);

    // Update alerts based on the user's slice (alerts) and user's preffered language (notices)
    useEffect(() => {
        if (alerts) {
            dispatch(setAlerts(alerts));
        }
    }, [alerts, dispatch]);

    // Get the notices for the selected language (runs on every language change)
    useEffect(() => {
        if (translations?.LanguageCode) {
            dispatch(GetNotices());
        }
    }, [dispatch, translations?.LanguageCode]);

    // Toggle the Sidebar visibility when:
    // - Clicking the "close" button on the Sidebar
    // - Clicking the "hamburger" button on the Header navigation
    // - Clicking the overlay
    const toggleSidebar = useCallback(() => {
        setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
    }, []);

    // Toggle the Alerts Drawer visibility when:
    // - Clicking the "close" button icon on the Alert's drawer
    // - Clicking the overlay
    const toggleAlerts = useCallback(() => {
        setShowAlerts((showAlerts) => !showAlerts);
    }, []);

    // On language change do:
    // - Change language for the user
    // - Get new translations
    // - Get notices (look at parent App.tsx)
    // - Reset all filters
    const changeLanguage = useCallback(
        (languageIndex: number) => {
            // Check if the language is not already selected before changing it
            if (languageSelected?.code !== LanguageCodes[languageIndex].code) {
                // CHANGE THIS: Change language for the current page (Clearances, Team, Profile...)
                dispatch(
                    ChangeLanguage({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Team
                    })
                );
                dispatch(
                    ChangeLanguageHydration({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Hydration
                    })
                );
                dispatch(setLanguageSelected(languageIndex));
                dispatch(resetDataState());
            }
        },
        [dispatch, languageSelected?.code]
    );

    return (
        <>
            {/* Status: pending */}
            {(userLoading || pageLoading || translationsLoading) && (
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {!userLoading && !pageLoading && !translationsLoading && !userError && !translationsError && (
                <div className='d-flex'>
                    {/* Sidebar */}
                    <Sidebar
                        onToggleSidebar={toggleSidebar}
                        isSidebarOpen={isSidebarOpen}
                        setSidebarStatus={setIsSidebarOpen}
                        navigationItems={SidebarNavItems}
                        onLanguageChange={changeLanguage}
                    />
                    <div className='navbar-and-stage-wrapper d-flex flex-column p-0'>
                        {/* Header navigation */}
                        <header>
                            <HeaderNav onToggleSidebar={toggleSidebar} onToggleAlerts={toggleAlerts} />
                        </header>
                        {/* Alerts drawer */}
                        {showAlerts && <AlertsDrawer onToggleAlerts={toggleAlerts} />}
                        {/* Alerts toasts */}
                        {!noticesLoading && !noticesError && <AlertsToasts />}
                        {/* CHANGE THIS: Current page (Clearances, Team, Profile...) */}
                        <Team />
                    </div>
                </div>
            )}
            {/* Status: rejected */}
            {(userError || translationsError) && (
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <ErrorPage errorCode={userError || translationsError || noticesError} withTranslations={!!translations} />
                </div>
            )}
        </>
    );
};

export default App;
