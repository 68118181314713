import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';
import DetailsEntry from './DetailsEntry';
import DocumentCompareView from './DocumentCompareView';
import DocumentView from './DocumentView';
import DownloadAndPrint from './DownloadAndPrint';
import ImagingDropdown from './ImagingDropdown';

class DetailsHeader extends Component {
    render() {
        return (
            <>
                <div className='image-details-container'>
                    <div className='col-sm-6'>
                        <ImagingDropdown
                            large
                            transparent
                            options={this.props.viewModel}
                            valueField='groupId'
                            onItemChanged={this.props.onSelectEntry}
                        />
                    </div>
                </div>

                <div className='imaging-tabs'>
                    <div className='imaging-nav-container col-sm-12 no-gutter'>
                        <NavLink activeClassName='active-route' to='/Imaging/Imaging/SearchResultDetails/DetailsEntry'>
                            <div className='imaging-nav-item image-tab-item '>
                                <div>
                                    <span className='nav-route image-tab-item-link'>
                                        {formatTranslated('Entry_Details_Label', this.props.translations.Phrases)}
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink activeClassName='active-route' to='/Imaging/Imaging/SearchResultDetails/DocumentView'>
                            <div className='imaging-nav-item image-tab-item'>
                                <div>
                                    <span className='nav-route image-tab-item-link'>
                                        {formatTranslated('Documents_Label', this.props.translations.Phrases)}
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink activeClassName='active-route' to='/Imaging/Imaging/SearchResultDetails/DocumentCompareView'>
                            <div className='imaging-nav-item image-tab-item'>
                                <div>
                                    <span className='nav-route image-tab-item-link'>
                                        {formatTranslated('Compare_Label', this.props.translations.Phrases)}
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                        <NavLink activeClassName='active-route' to='/Imaging/Imaging/SearchResultDetails/DownloadAndPrint'>
                            <div className='imaging-nav-item image-tab-item'>
                                <div>
                                    <span className='nav-route image-tab-item-link'>
                                        {formatTranslated('Download_And_Print_Label', this.props.translations.Phrases)}
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <Route path='/Imaging/Imaging/SearchResultDetails/DetailsEntry' exact component={DetailsEntry} />
                    <Route path='/Imaging/Imaging/SearchResultDetails/DocumentView' exact component={DocumentView} />
                    <Route path='/Imaging/Imaging/SearchResultDetails/DocumentCompareView' exact component={DocumentCompareView} />
                    <Route path='/Imaging/Imaging/SearchResultDetails/DownloadAndPrint' exact component={DownloadAndPrint} />
                </div>
                <div className='row'>
                    <div className='col-sm-12'>{this.props.children}</div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        viewModel: state.imagingDetails.model.ViewModel
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectEntry: (newIndex, oldIndex, value) => dispatch(actions.selectEntry(newIndex, oldIndex, value))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsHeader);
