import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridCaption from '../../../portal/common/features/Grid/GridCaption';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { GridSettingsSlice, SearchFilterSlice, StatusFilterSlice } from '../../../portal/common/models/ReduxSlices';
import { StatusFilterOptions } from '../../common/constants/constants-team';
import { resetDataState, setPageSelected } from '../../redux/reducers/teamGridSettingsSlice';
import { setSearchFilter } from '../../redux/reducers/teamSearchFilterSlice';
import { setSelectedStatus } from '../../redux/reducers/teamStatusFilterSlice';
import { RootState } from '../../redux/store';

const TeamGridCaption = () => {
    const { selectedStatus } = useSelector<RootState, StatusFilterSlice>((state) => state.teamStatusFilter);
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.teamSearchFilter);
    const { dataState, totalItems } = useSelector<RootState, GridSettingsSlice>((state) => state.teamGridSettings);
    const [showClear, setShowClear] = useState(false);
    const dispatch = useDispatch();
    const translate = useTranslation();

    useEffect(() => {
        if (selectedStatus.id !== StatusFilterOptions[0].id || searchTerm.length > 0 || dataState.filter !== undefined) {
            setShowClear(true);
        } else {
            setShowClear(false);
        }
    }, [selectedStatus.id, searchTerm, dataState]);

    const clearFilters = () => {
        dispatch(setSelectedStatus(0));
        dispatch(setSearchFilter(''));
        dispatch(resetDataState({ take: 10, skip: 0, sort: [{ field: 'LastLogin', dir: 'desc' }] }));
        dispatch(setPageSelected(1));
    };

    const formatMessage = () => {
        switch (selectedStatus.id) {
            case 'ALL':
                return translate('AllMembers_Label', totalItems);
            case 'ACTIVE':
                return translate('ActiveMembers_Label', totalItems);
            case 'INACTIVE':
                return translate('InactiveMembers_Label', totalItems);
        }
    };

    return (
        <GridCaption clearLabel={translate('Clear_Label')} showClear={showClear} onClear={clearFilters} tableColumnsDropdown={false}>
            <span>{formatMessage()}</span>
        </GridCaption>
    );
};

export default TeamGridCaption;
