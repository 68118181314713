import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../common/models/CreateNewUser';
import { UserDetailsSlice } from '../../common/models/ReduxSlices';
import { GetCloneMemberDetails, InitNewTeamMember } from '../actions/teamManagement';

const initialState: UserDetailsSlice = {
    isLoading: false,
    error: undefined,
    data: null,
    newUsers: [],
    newUsersAmount: 0
};

const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        addTeamMember(state, action: PayloadAction<{ changedUsers: any; users: User[] }>) {
            if (state.data?.ChangedProperties) {
                state.data.ChangedProperties = action.payload.changedUsers;
            }
            if (state.data?.Users) {
                const newUsers = action.payload.users.map((item) => {
                    if (state.data?.Users?.length) {
                        return { ...state.data?.Users[0], ...item };
                    } else {
                        return { ...item };
                    }
                });
                //@ts-ignore
                state.data.Users = newUsers;
            }
        },
        addUser(state, action: PayloadAction<User>) {
            let updatedUsers = [...state.newUsers];
            updatedUsers.unshift(action.payload);
            state.newUsers = updatedUsers;
            state.newUsersAmount = state.newUsers.length;
        },
        removeUser(state, action: PayloadAction<number>) {
            let updatedUsers = [...state.newUsers];
            if (state.newUsers.length > 1) {
                updatedUsers.splice(action.payload, 1);
                state.newUsers = updatedUsers;
                state.newUsersAmount = state.newUsers.length;
            } else {
                state.newUsers = [];
                state.newUsersAmount = 0;
            }
        },
        resetUserDetailsData(state) {
            state.data = initialState.data;
            state.error = initialState.error;
            state.isLoading = initialState.isLoading;
            state.newUsers = initialState.newUsers;
        },
        resetNewUsersAmount(state) {
            state.newUsersAmount = initialState.newUsersAmount;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCloneMemberDetails.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetCloneMemberDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = Object.fromEntries(Object.entries(action.payload).filter(([key, value]) => !/parentservices/i.test(key)));
            })
            .addCase(GetCloneMemberDetails.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(InitNewTeamMember.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(InitNewTeamMember.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = Object.fromEntries(Object.entries(action.payload).filter(([key, value]) => !/parentservices/i.test(key)));
            })
            .addCase(InitNewTeamMember.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { addTeamMember, addUser, removeUser, resetUserDetailsData, resetNewUsersAmount } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
