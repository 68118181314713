import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { HydrationSlice } from '../models/ReduxSlices';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);

    if (user?.InternalUser) return <Redirect to='/Clearances' />;

    return <>{children}</>;
};

export default ProtectedRoute;
