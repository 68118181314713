import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { DownloadDocuments } from '../../../clearances/redux/actions/clearanceDocuments';
import RadioButton from '../../../portal/common/features/RadioButton';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { hashMap, hashMapCA, menuItems } from '../../common/constants/constants-documents';
import { DestructuredArrayForDownload } from '../../common/models/DownloadModal';
import { FileData } from '../../common/models/ImagingModel';
import { formS3Array } from '../../utils/download-modal-functions';
import DownloadModalDropdown from './DownloadModalDropdown';
import DownloadModalPreview from './DownloadModalPreview';
interface DownloadModalProps {
    selectedDocuments: string[] | undefined;
    closeModal: (value: null) => void;
    formededObject: { [key: string]: FileData[] };
    selectedCountry: string | undefined;
    destructuredArrayForDownload: DestructuredArrayForDownload;
}

export const DownloadModal: React.FC<DownloadModalProps> = ({
    selectedDocuments,
    closeModal,
    formededObject,
    selectedCountry,
    destructuredArrayForDownload
}) => {
    const [selectedOption, setSelectedOption] = useState('MultiplePDF');
    const [sortDirOne, setSortDirOne] = useState<string>('ASC');
    const [sortDirTwo, setSortDirTwo] = useState<string>('ASC');
    const [selectedMultipleGroupByDropdownItem, setSelectedMultipleGroupByDropdownItem] = useState<string>('None');
    const [selectedMultipleSortByDropdownItem, setSelectedMultipleSortByDropdownItem] = useState<string>('Document type');
    const [selectedSingleGroupByDropdownItem, setSelectedSingleGroupByDropdownItem] = useState<string>('Entry no.');
    const dispatch = useDispatch();
    const translate = useTranslation();

    const multiplePDFfilesData: { details: string[]; id: number; title: string }[] = [];
    const singlePDFfilesData: { details: string[]; id: number; title: string }[] = [];
    const [, ...menuItemsReduced] = menuItems;
    const date = new Date();
    const dateString = `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDay()}_`;

    const handleMultipleGroupByDropdownItemClick = (newSelection: string) => {
        setSelectedMultipleGroupByDropdownItem(newSelection);
        if (newSelection === selectedMultipleSortByDropdownItem) {
            const indexOfElement = menuItemsReduced.indexOf(newSelection);
            setSelectedMultipleSortByDropdownItem(menuItemsReduced[(indexOfElement + 1) % menuItemsReduced.length]);
        }
    };
    const handleMultipleSortByDropdownItemClick = (newSelection: string) => {
        setSelectedMultipleSortByDropdownItem(newSelection);
        if (newSelection === selectedMultipleGroupByDropdownItem) {
            const indexOfElement = menuItemsReduced.indexOf(newSelection);
            setSelectedMultipleGroupByDropdownItem(menuItemsReduced[(indexOfElement + 1) % menuItemsReduced.length]);
        }
    };
    const handleSingleGroupByDropdownItemClick = (newSelection: string) => {
        setSelectedSingleGroupByDropdownItem(newSelection);
    };
    const funResults = formS3Array(
        selectedOption === 'SingleMergedPDF',
        selectedMultipleGroupByDropdownItem === 'None',
        {
            groupParamOne:
                selectedCountry === 'us' ? hashMap[selectedMultipleGroupByDropdownItem] : hashMapCA[selectedMultipleGroupByDropdownItem],
            sortParamTwoUniversal:
                selectedCountry === 'us' ? hashMap[selectedMultipleSortByDropdownItem] : hashMapCA[selectedMultipleSortByDropdownItem],
            ascDescParamOne: sortDirTwo === 'ASC',
            sortParamOne:
                selectedCountry === 'us' ? hashMap[selectedSingleGroupByDropdownItem] : hashMapCA[selectedSingleGroupByDropdownItem],
            ascDescParamTwo: sortDirOne === 'ASC',
            ascDescParamThree: sortDirTwo === 'ASC'
        },
        destructuredArrayForDownload
    );
    const download = (ImagePackageId: number) => {
        const funResultsType = Array.isArray(funResults);
        if (funResultsType)
            dispatch(
                DownloadDocuments({
                    fileNames: {
                        allFiles: funResults
                    },
                    ImagePackageId
                })
            );
        else
            dispatch(
                DownloadDocuments({
                    fileNames: funResults,
                    ImagePackageId
                })
            );
    };

    const resetOnExit = () => {
        closeModal(null);
        setSelectedOption('MultiplePDF');
        setSortDirTwo('ASC');
        setSortDirTwo('ASC');
        setSelectedMultipleGroupByDropdownItem('None');
        setSelectedMultipleSortByDropdownItem('Document type');
        setSelectedSingleGroupByDropdownItem('Entry no.');
    };
    function formatDropdownItemLabel(input: string): string {
        return input
            .replaceAll('.', '')
            .split(' ')
            .map((x: string) => {
                return `${x[0].toUpperCase()}${x.substring(1)}`;
            })
            .join('')
            .concat('_Label');
    }

    return (
        <>
            <div className='modal' style={{ zIndex: '1300', display: 'block', position: 'fixed' }}>
                <div className='modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3 className='modal-title' id='staticBackdropLabel'>
                                {translate('DownloadModalHeader_Label')}
                            </h3>
                            <button onClick={resetOnExit} type='button' className='modal-close btn btn-tertiary btn-lg'>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <div className='modal-body p-0'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-12 col-lg-7 border-bottom border-lg-bottom-0 border-lg-right pb-2 pb-lg-5'>
                                        <div className='d-lg-flex px-3 pt-4 pb-3 pt-lg-4'>
                                            <RadioButton
                                                id='MultiplePDF'
                                                name='DownloadOption'
                                                labelClass='custom-control-label'
                                                onChange={() => {
                                                    setSelectedOption('MultiplePDF');
                                                }}
                                                isChecked={selectedOption === 'MultiplePDF'}
                                                divClass='custom-control custom-radio mb-2 mb-lg-0 mr-lg-4'
                                            >
                                                <h4 className={selectedOption === 'MultiplePDF' ? 'm-0' : 'm-0 font-weight-normal'}>
                                                    {translate('MultiplePDFDocs_Label')}
                                                </h4>
                                            </RadioButton>
                                            <RadioButton
                                                id='SingleMergedPDF'
                                                name='DownloadOption'
                                                labelClass='custom-control-label'
                                                onChange={() => {
                                                    setSelectedOption('SingleMergedPDF');
                                                }}
                                                isChecked={selectedOption === 'SingleMergedPDF'}
                                                divClass='custom-control custom-radio mb-2 mb-lg-0 mr-lg-4'
                                            >
                                                <h4 className={selectedOption === 'SingleMergedPDF' ? 'm-0' : 'm-0 font-weight-normal'}>
                                                    {translate('SingleMergedPDFDocument_Label')}
                                                </h4>
                                            </RadioButton>
                                        </div>
                                        <div className='position-relative'>
                                            <CSSTransition
                                                in={selectedOption === 'MultiplePDF'}
                                                timeout={300}
                                                classNames='fade-updown'
                                                unmountOnExit
                                            >
                                                <div id='MultiplePDFInputs' className='w-100'>
                                                    <div className='p-3 pt-1'>
                                                        <div className='font-weight-medium mb-2'>
                                                            {translate('GroupPDFDocumentsBy_Label')}
                                                        </div>
                                                        <DownloadModalDropdown
                                                            handleFunction={handleMultipleGroupByDropdownItemClick}
                                                            menuItems={menuItems}
                                                            selectedDropdownItem={translate(
                                                                formatDropdownItemLabel(selectedMultipleGroupByDropdownItem)
                                                            )}
                                                        />
                                                    </div>

                                                    {selectedMultipleGroupByDropdownItem !== 'None' && (
                                                        <div className='px-3 pt-0 pb-3 pt-lg-3'>
                                                            <div className='font-weight-medium mb-2'>
                                                                {translate('SortPagesWithinPDF_Label')}
                                                            </div>

                                                            <DownloadModalDropdown
                                                                handleFunction={handleMultipleSortByDropdownItemClick}
                                                                menuItems={menuItemsReduced}
                                                                selectedDropdownItem={translate(
                                                                    formatDropdownItemLabel(selectedMultipleSortByDropdownItem)
                                                                )}
                                                            />

                                                            <div className='d-flex'>
                                                                <RadioButton
                                                                    id='SortByAscending'
                                                                    name='SortByAscendingDescending'
                                                                    divClass='custom-control custom-radio mb-2 mr-3 mb-lg-0 mr-lg-4'
                                                                    labelClass='custom-control-label'
                                                                    className='custom-control-input'
                                                                    isChecked={sortDirTwo === 'ASC'}
                                                                    onChange={() => {
                                                                        setSortDirTwo('ASC');
                                                                    }}
                                                                >
                                                                    {translate('Ascending_Label')}
                                                                </RadioButton>
                                                                <RadioButton
                                                                    id='SortByDescending'
                                                                    name='SortByAscendingDescending'
                                                                    divClass='custom-control custom-radio'
                                                                    labelClass='custom-control-label'
                                                                    className='custom-control-input'
                                                                    isChecked={sortDirTwo === 'DESC'}
                                                                    onChange={() => {
                                                                        setSortDirTwo('DESC');
                                                                    }}
                                                                >
                                                                    {translate('Descending_Label')}
                                                                </RadioButton>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </CSSTransition>
                                            <CSSTransition
                                                in={selectedOption === 'SingleMergedPDF'}
                                                timeout={300}
                                                classNames='fade-updown'
                                                unmountOnExit
                                            >
                                                <div id='SingleMergedPDFInputs' className='w-100'>
                                                    <div className='p-3 pt-1'>
                                                        <div className='font-weight-medium mb-2'>{translate('FirstSortBy_Label')}</div>

                                                        <DownloadModalDropdown
                                                            handleFunction={handleSingleGroupByDropdownItemClick}
                                                            menuItems={menuItemsReduced.filter(
                                                                (x: string) => x !== selectedMultipleSortByDropdownItem
                                                            )}
                                                            selectedDropdownItem={translate(
                                                                formatDropdownItemLabel(selectedSingleGroupByDropdownItem)
                                                            )}
                                                        />

                                                        <div className='d-flex'>
                                                            <RadioButton
                                                                id='GroupByAscending'
                                                                name='GroupByAscendingDescending'
                                                                className='custom-control-input'
                                                                divClass='custom-control custom-radio mb-2 mr-3 mb-lg-0 mr-lg-4'
                                                                isChecked={sortDirOne === 'ASC'}
                                                                labelClass='custom-control-label'
                                                                onChange={() => {
                                                                    setSortDirOne('ASC');
                                                                }}
                                                            >
                                                                {translate('Ascending_Label')}
                                                            </RadioButton>
                                                            <RadioButton
                                                                id='GroupByDescending'
                                                                name='GroupByAscendingDescending'
                                                                className='custom-control-input'
                                                                divClass='custom-control custom-radio'
                                                                isChecked={sortDirOne === 'DESC'}
                                                                labelClass='custom-control-label'
                                                                onChange={() => {
                                                                    setSortDirOne('DESC');
                                                                }}
                                                            >
                                                                {translate('Descending_Label')}
                                                            </RadioButton>
                                                        </div>
                                                    </div>
                                                    <div className='px-3 pt-0 pb-3 pt-lg-3'>
                                                        <div className='font-weight-medium mb-2'>{translate('ThenSortBy_Label')}</div>
                                                        <DownloadModalDropdown
                                                            handleFunction={handleMultipleSortByDropdownItemClick}
                                                            menuItems={menuItemsReduced.filter(
                                                                (x: string) => x !== selectedSingleGroupByDropdownItem
                                                            )}
                                                            selectedDropdownItem={translate(
                                                                formatDropdownItemLabel(selectedMultipleSortByDropdownItem)
                                                            )}
                                                        />
                                                        <div className='d-flex'>
                                                            <RadioButton
                                                                id='SortByAscending2'
                                                                name='SortByAscendingDescending2'
                                                                className='custom-control-input'
                                                                divClass='custom-control custom-radio mb-2 mr-3 mb-lg-0 mr-lg-4'
                                                                labelClass='custom-control-label'
                                                                isChecked={sortDirTwo === 'ASC'}
                                                                onChange={() => {
                                                                    setSortDirTwo('ASC');
                                                                }}
                                                            >
                                                                {translate('Ascending_Label')}
                                                            </RadioButton>
                                                            <RadioButton
                                                                id='SortByDescending2'
                                                                name='SortByAscendingDescending2'
                                                                className='custom-control-input'
                                                                divClass='custom-control custom-radio'
                                                                labelClass='custom-control-label'
                                                                isChecked={sortDirTwo === 'DESC'}
                                                                onChange={() => {
                                                                    setSortDirTwo('DESC');
                                                                }}
                                                            >
                                                                {translate('Descending_Label')}
                                                            </RadioButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CSSTransition>
                                        </div>
                                    </div>
                                    <DownloadModalPreview
                                        multiplePDFfilesData={multiplePDFfilesData}
                                        selectedOption={selectedOption}
                                        singlePDFfilesData={singlePDFfilesData}
                                        documentS3Array={selectedDocuments}
                                        documentNameGenerated={dateString}
                                        groupedBy={selectedMultipleGroupByDropdownItem}
                                        sortedBy={selectedMultipleSortByDropdownItem}
                                        firstSortedBy={selectedSingleGroupByDropdownItem}
                                        ascOrder={sortDirOne}
                                        groupByAscOrder={sortDirTwo}
                                        downloadFunction={download}
                                        isGroupBySelected={
                                            selectedMultipleGroupByDropdownItem !== 'None' && selectedOption === 'MultiplePDF'
                                        }
                                        funResults={funResults}
                                        objectContainingFiles={formededObject}
                                        countrySelected={selectedCountry}
                                        formatOptions={formatDropdownItemLabel}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <DownloadFooterModal selectedOption={selectedOption} /> */}
                    </div>
                </div>
            </div>
        </>
    );
};
