import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusAlert from '../../../portal/common/features/StatusAlert';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../portal/common/models/ReduxSlices';
import { ContactsSlice } from '../../common/models/ReduxSlices';
import { clearNewContacts, resetAddNewContacts, resetEditContacts, resetRemoveContacts } from '../../redux/reducers/contactsSlice';
import { RootState } from '../../redux/store';

const ContactStatusAlert = () => {
    const { isLoading, contacts, addNewContacts, removeContacts, editedContact } = useSelector<RootState, ContactsSlice>(
        (state) => state.contacts
    );
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const translate = useTranslation();
    const dispatch = useDispatch();

    // Reset all action inside "contacts" slice on unmount
    useEffect(() => {
        return () => {
            dispatch(resetAddNewContacts());
            dispatch(resetRemoveContacts());
            dispatch(resetEditContacts());
        };
    }, [dispatch]);

    useEffect(() => {
        const newContDelay = setTimeout(() => {
            dispatch(clearNewContacts());
        }, 3000);
        return () => clearTimeout(newContDelay);
    }, [dispatch, contacts.length]);

    if (!isLoading && !addNewContacts.isLoading) {
        return (
            <>
                {/* Status alert for adding new contact(s) */}
                {addNewContacts.data && !addNewContacts.error && (
                    <StatusAlert
                        message={translate('ContactsAdded_Label', addNewContacts.newContactsAmount)}
                        type={'success'}
                        customTimeout={user?.PortalTimeoutShort}
                    />
                )}

                {/* Status alert for deleting contact(s) */}
                {removeContacts.data && removeContacts.data === 'SuccessfullRemoved' && !removeContacts.error && (
                    <StatusAlert
                        message={translate('ContactsDeleted_Label', removeContacts.removedContactsAmount)}
                        type={'success'}
                        customTimeout={user?.PortalTimeoutShort}
                    />
                )}
                {/* Status alert for editing contact(s) */}
                {editedContact.data && !editedContact.error && (
                    <StatusAlert message={translate('ContactsEdited_Label')} type={'success'} customTimeout={user?.PortalTimeoutShort} />
                )}
            </>
        );
    } else {
        return null;
    }
};

export default ContactStatusAlert;
