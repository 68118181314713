import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { LanguageSlice } from '../../portal/common/models/ReduxSlices';
import TermsFormFrench from '../common/components/TermsFormFrench';
import TermsFormUS from '../common/components/TermsFormUS';
import { RootState } from '../redux/store';

const TermsOfUse = () => {
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    return (
        <div id='page'>
            <HelmetProvider>
                <Helmet>
                    <title>{languageSelected?.country === 'CA' ? 'Conditions d’utilisation' : 'TERMS OF USE'}</title>
                </Helmet>
            </HelmetProvider>
            <div className='page-wrapper' style={{ padding: '5%', paddingTop: '2%' }}>
                <div className='page-top-row'>
                    <div className='page-header-section'>
                        <h1
                            style={{
                                fontWeight: '700',
                                color: '#004e9c',
                                margin: '20px 0 20px -3px',
                                textTransform: 'uppercase',
                                fontSize: '46px'
                            }}
                            className='bold-title'
                        >
                            {languageSelected?.country === 'CA' ? 'Conditions d’utilisation' : 'TERMS OF USE'}
                        </h1>
                    </div>
                </div>

                <div className='content-wrapper'>
                    <div className='row page-content'>{languageSelected?.country === 'CA' ? <TermsFormFrench /> : <TermsFormUS />}</div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;
