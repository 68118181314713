import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice } from '../../../../portal/common/models/ReduxSlices';
import {
    resetSelectedEntries,
    setSearchBarOptions,
    updateSearchFilters,
    updateSearchKeywords,
    updateSubmittedSearchKeywords
} from '../../../redux/reducers/imagingSlice';
import { RootState } from '../../../redux/store';
import { ImagingModelSlice } from '../../models/ReduxSlices';
import SearchChip from './SearchChip';

import './styles.css';

// const suggestedSearches = [
//     { query: 'Billing invoices', period: '30 days' },
//     { query: 'Customs release & Customs entry docs', period: '3 months' },
//     { query: 'Customs release & Customs entry docs', period: '24 hrs' }
// ];

// const recentSearches = [
//     { keywords: ['404590'], time: 'Today' },
//     { keywords: ['9830143390037', '9830143390037'], time: 'Yesterday' },
//     { keywords: ['300-40364571'], time: '13/12/22' },
//     { keywords: ['6437554603423', '6029915117638', '613220'], time: '12/10/22' }
// ];

// interface Props {}

const compareArrays = (a: string[], b: string[]) => a.length === b.length && a.every((element, index) => element === b[index]);

const DoucmentsSearchBar = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [onEnterKey, setOnEnterKey] = useState<boolean>(false);
    // const debouncedQuery = useDebounce(searchInput, 1500);
    const translate = useTranslation();

    const dispatch = useDispatch();

    const { searchKeywords: reduxSearchKeywords } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);

    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);

    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;

    // const shouldRenderDropdown = (suggestedSearches && suggestedSearches.length > 0) || (recentSearches && recentSearches.length > 0);
    const shouldRenderDropdown = false;

    const placeholderText =
        selectedCountryCodeInitial === 'us' ? translate('InputPlaceholderUS_Label') : translate('InputPlaceholderCA_Label');

    const handleChangeInput = (val: string) => {
        const cleanString = val.replace(/[^a-zA-Z0-9,_\s-/]/g, '');
        setSearchInput(cleanString);
    };

    const handleClearSearch = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        resetSearchAndFilters();
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const resetSearchAndFilters = () => {
        setSearchInput('');
        setSearchKeywords([]);
        inputRef.current && inputRef.current.focus();

        dispatch(updateSubmittedSearchKeywords([]));
        return dispatch(
            updateSearchFilters({
                value: [],
                country: selectedCountryCodeInitial || 'us'
            })
        );
    };

    const updateKeywords = (nextVal?: string) => {
        // trim leading/trailing spaces
        const trimmedSearchInput = nextVal ? nextVal.trim() : searchInput.trim();

        if (trimmedSearchInput === '') {
            setSearchInput('');
            return;
        }
        const newSearchKeywords = [...searchKeywords];

        if (trimmedSearchInput.includes(' ') || trimmedSearchInput.includes(',') || trimmedSearchInput.includes('	')) {
            const keywordArr = trimmedSearchInput.split(/[ s,	]/).filter((keyword) => keyword !== '');

            keywordArr.map((keyword) => {
                if (keyword === '') {
                    return;
                }
                newSearchKeywords.push(keyword);
            });
        } else {
            newSearchKeywords.push(trimmedSearchInput);
        }

        setSearchKeywords(newSearchKeywords);
        setSearchInput('');
    };

    const removeKeyword = (removed: string) => {
        setSearchKeywords(searchKeywords.filter((keyword) => keyword !== removed));
        if (searchKeywords.length <= 1) {
            resetSearchAndFilters();
        }
    };

    const handlePasteInput = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();

        // @ts-ignore
        let paste = (e.clipboardData || window.clipboardData).getData('text');
        updateKeywords(paste);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // revert last keyword to text on backspace
        if (searchInput === '' && e.key === 'Backspace') {
            if (searchKeywords.length !== 0) {
                const lastElement = searchKeywords.slice(-1);
                setSearchKeywords(searchKeywords.slice(0, -1));
                setSearchInput(lastElement[0]);
            }
        }
        if (e.key === 'Enter') {
            updateKeywords();
            setOnEnterKey(true);
        }
    };

    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        e.target.placeholder = '';

        if (shouldRenderDropdown && !searchInput && searchKeywords.length === 0) {
            const searchSuggestions = document.querySelector('.suggestions-dropdown');
            // searchSuggestions.classList.remove('d-none');
        }
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        e.target.placeholder = searchInput || searchKeywords.length > 0 ? '' : placeholderText;

        if (shouldRenderDropdown) {
            const searchSuggestions = document.querySelector('.suggestions-dropdown');
            // searchSuggestions.classList.add('d-none');
        }
        if (searchInput) updateKeywords();
    };

    const createKeywordString = (keywordArr: string[]) => {
        let keywordStr = keywordArr.reduce((str, keyword) => str + ', ' + keyword, '');
        keywordStr = keywordStr.substring(2);
        return keywordStr;
    };

    const searchButtonOnClick = (val?: string[]) => {
        const payload = {
            value: val?.length ? val : searchKeywords,
            country: selectedCountryCodeInitial || 'us'
        };
        dispatch(resetSelectedEntries());
        dispatch(setSearchBarOptions());
        dispatch(updateSearchFilters(payload));
        return dispatch(updateSubmittedSearchKeywords(searchKeywords));
    };
    // useEffect(() => {
    //     searchButtonOnClick([debouncedQuery]);
    // }, [debouncedQuery]);

    useEffect(() => {
        // only update after user adds a space or comma
        // handle copied sheet cells containing tab separators
        if (searchInput.includes(' ') || searchInput.includes(',') || searchInput.includes('	')) updateKeywords();
    }, [searchInput, dispatch]);

    useEffect(() => {
        dispatch(updateSearchKeywords(searchKeywords));
    }, [searchKeywords, dispatch]);

    useEffect(() => {
        if (onEnterKey) {
            searchButtonOnClick(searchKeywords);
            setOnEnterKey(false);
        }
    }, [onEnterKey]);

    // Map local state to redux????
    useEffect(() => {
        if (!compareArrays(searchKeywords, reduxSearchKeywords)) setSearchKeywords(reduxSearchKeywords);
    }, [reduxSearchKeywords]);

    // Clear search bar when client selection changes
    useEffect(() => {
        setSearchKeywords([]);
        dispatch(updateSearchKeywords([]));
        setSearchInput('');
    }, [initialClientSelection]);

    useEffect(() => {
        if (!searchKeywords.length && inputRef.current)
            inputRef.current.placeholder = searchInput || searchKeywords.length > 0 ? '' : placeholderText;
    }, [searchKeywords]);

    return (
        <div className={`input-group pb-3 pt-3 pl-3 pr-3 container-fluid border-bottom flex-nowrap bg-white`}>
            <div className={`simplified-search-bar simplified-search-bar-with-button documents_search_bar`}>
                <div className={'chips-input-wrapper'}>
                    <div className={'magnifying-glass-input-icon documents_search_bar_icon'}>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <div className='documents_search_chips chips'>
                        {searchKeywords.map((keyword, index) => (
                            <SearchChip
                                key={keyword}
                                index={index}
                                keyword={keyword}
                                remove={removeKeyword}
                                searchKeywords={searchKeywords}
                                setSearchKeywords={setSearchKeywords}
                            />
                        ))}
                        <input
                            type={'text'}
                            ref={inputRef}
                            className={'documents_search_input form-control preserve-borders'}
                            placeholder={placeholderText}
                            value={searchInput}
                            onChange={(e) => handleChangeInput(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onFocus={(e) => handleInputFocus(e)}
                            onBlur={(e) => handleInputBlur(e)}
                            onPaste={(e) => handlePasteInput(e)}
                        />
                    </div>

                    <div className={'justify-content-end'}>
                        <span
                            role='button'
                            onClick={handleClearSearch}
                            className={`form-clear-simplified-search form-clear ${searchKeywords.length ? '' : 'd-none'}`}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                    </div>
                </div>
                {/* {shouldRenderDropdown ? (
                    <div className={'suggestions-dropdown advanced-search-container d-none'}>
                        <div className={'d-flex flex-column justify-content-between inner-wrapper'}>
                            {suggestedSearches && suggestedSearches.length > 0 && (
                                <div
                                    className={`advanced-search-heading flex-column align-items-start ${
                                        !recentSearches || recentSearches.length === 0 ? 'border-0' : ''
                                    }`}
                                >
                                    <h4 className={'advanced-search-title mb-2'}>{`I'm searching for {suggestion}...`}</h4>
                                    <div className={'suggestions-section w-100 align-items-start d-flex mt-2'}>
                                        {suggestedSearches.map((suggested, index) => (
                                            <a
                                                className={
                                                    'dropdown-list-card dropdown-item d-flex flex-row align-items-center flex-grow-0 w-auto pr-4 pl-3 py-2 mr-2'
                                                }
                                                key={index}
                                                href={'/#'}
                                            >
                                                <FontAwesomeIcon icon={faSearch} className={'suggestions-icon'} />
                                                <div className={'flex-column align-items-start'}>
                                                    <h4 className={'font-weight-medium m-0'}>{suggested.query}</h4>
                                                    <p>from the last {suggested.period}</p>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {recentSearches && recentSearches.length > 0 && (
                                <div className={'recent-searches advanced-search-filters'}>
                                    <h4 className={'advanced-search-title'}>Recent searches</h4>
                                    <div className={'recent-container'}>
                                        {recentSearches.map((recentItem, index) => (
                                            <a
                                                className={'dropdown-item recent-item d-flex align-items-center justify-content-between'}
                                                key={index}
                                                href={'/#'}
                                            >
                                                <div
                                                    className={'d-flex flex-row flex-grow-0 align-items-center mr-3'}
                                                    style={{ minWidth: 0 }}
                                                >
                                                    <FontAwesomeIcon icon={faClock} className={'suggestions-icon mr-2'} />
                                                    <span className={'text-truncate'}>{createKeywordString(recentItem.keywords)}</span>
                                                </div>
                                                <span className={'text-right'}>{recentItem.time}</span>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null} */}
            </div>

            <div className='documents_search_bar_btn input-group-append'>
                <button type='button' className='btn btn-primary d-flex align-items-center' onClick={() => searchButtonOnClick()}>
                    <span>{translate('SearchSubmitButton_Label')}</span>
                </button>
            </div>
        </div>
    );
};

export default DoucmentsSearchBar;
