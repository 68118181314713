import React from 'react';
import { ButtonProps } from '../models/Button';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = 'primary',
            size = 'medium',
            type = 'button',
            className = '',
            disabled,
            'data-toggle': dataToggle,
            'data-display': dataDisplay,
            'data-target': dataTarget,
            'data-dismiss': dataDismiss,
            onClick,
            children,
            ...rest
        },
        ref
    ) => {
        // Set the default start values
        let btnVariant = 'primary';
        let btnSize = '';

        // Set button variant
        switch (variant.toLowerCase()) {
            case 'primary':
                btnVariant = 'primary';
                break;
            case 'secondary':
                btnVariant = 'secondary';
                break;
            case 'tertiary':
                btnVariant = 'tertiary';
                break;
            case 'tertiary-blue':
                btnVariant = 'tertiary-blue';
                break;
            case 'tertiary-white':
                btnVariant = 'tertiary-white';
                break;
            case 'ghost':
                btnVariant = 'ghost';
                break;
            case 'ghost-dark':
                btnVariant = 'ghost-dark';
                break;
            case 'danger':
                btnVariant = 'danger';
                break;
            case 'danger-outline':
                btnVariant = 'outline-danger';
                break;
            case 'danger-ghost':
                btnVariant = 'ghost-danger';
                break;
            case 'link':
                btnVariant = 'link';
                break;
            default:
                break;
        }

        // Set button size
        switch (size.toLowerCase()) {
            case 'small':
                btnSize = 'btn-sm';
                break;
            case 'medium':
                btnSize = '';
                break;
            case 'large':
                btnSize = 'btn-lg';
                break;
            default:
                break;
        }
        const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
            let button = e.target as HTMLButtonElement;
            button.blur();
        };

        return (
            <button
                type={type}
                className={`${variant !== 'link' ? 'btn' : 'font-weight-normal'} btn-${btnVariant} ${btnSize} ${className}`}
                onClick={onClick}
                onMouseUp={handleMouseUp}
                ref={ref}
                style={
                    variant === 'link'
                        ? { ...rest.style, zIndex: 0, backgroundColor: 'inherit', border: 'none' }
                        : { ...rest.style, zIndex: 0 }
                }
                data-toggle={dataToggle}
                data-display={dataDisplay}
                data-target={dataTarget}
                data-dismiss={dataDismiss}
                disabled={disabled}
                {...rest}
            >
                {children}
            </button>
        );
    }
);

export default Button;
