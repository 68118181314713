import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface SidebarNavItemProps {
    url: string;
    title: string;
    icon: IconDefinition;
    isActive: boolean;
    onClick: () => void;
}

const SidebarNavItem: React.FC<SidebarNavItemProps> = ({ url, icon, title, onClick, isActive }) => {
    return (
        <div className='list-group sidebar-list-group'>
            <Link
                className={`${
                    isActive ? 'menu-item--active active' : ''
                } list-group-item sidebar-list-group-item d-flex align-items-center`}
                to={url}
                onClick={onClick}
                style={isActive ? { zIndex: 2, color: '#fff' } : undefined}
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix={icon.prefix}
                    data-icon={icon.iconName}
                    className={`svg-inline--fa fa-${icon.iconName} fa-w-20`}
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 640 512'
                >
                    <path fill='currentColor' d={icon.icon[4].toString()}></path>
                </svg>
                <div className='sidbar-nav-label'>{title}</div>
            </Link>
        </div>
    );
};

export default SidebarNavItem;
