import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice, HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import {
    updateMainSearchFilter,
    updateSearchKeywords,
    updateSubmittedSearchKeywords
} from '../../../redux/reducers/completeEntryDataSlice';
import { RootState } from '../../../redux/store';
import { limitSearch } from '../../constants/complete-entry-data-constants';
import { CASSModel } from '../../models/completeEntryDataModels';
import SearchChip from './SearchChip';
import './styles.css';

// interface Props {}

const compareArrays = (a: string[], b: string[]) => a.length === b.length && a.every((element, index) => element === b[index]);

const CADSearchBar = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [onEnterKey, setOnEnterKey] = useState<boolean>(false);
    // const debouncedQuery = useDebounce(searchInput, 1500);
    const translate = useTranslation();

    const dispatch = useDispatch();

    const { searchKeywords: reduxSearchKeywords } = useSelector<RootState, CASSModel>((state) => state.completeEntryData);

    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);

    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    // const shouldRenderDropdown = (suggestedSearches && suggestedSearches.length > 0) || (recentSearches && recentSearches.length > 0);
    const shouldRenderDropdown = false;

    // const placeholderText =
    //     selectedCountryCodeInitial === 'us' ? translate('InputPlaceholderUS_Label') : translate('InputPlaceholderCA_Label');

    const placeholderText = translate('InputPlaceholder_Label');
    const remainingSpace = user?.PortalCompleteEntryDataSearchBarTextLimit
        ? user.PortalCompleteEntryDataSearchBarTextLimit - searchKeywords.reduce((acc, curr) => acc + curr.length, 0)
        : 0;

    const handleChangeInput = (val: string) => {
        const cleanString = val.replace(/[^a-zA-Z0-9,_\s-]/g, '');

        const truncatedVal = cleanString.slice(0, remainingSpace);

        setSearchInput(truncatedVal);
    };

    const handleClearSearch = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();

        resetSearchAndFilters();
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const resetSearchAndFilters = () => {
        setSearchInput('');
        setSearchKeywords([]);
        inputRef.current && inputRef.current.focus();

        dispatch(updateSubmittedSearchKeywords([]));
        return dispatch(updateMainSearchFilter([]));
    };

    const updateKeywords = (nextVal?: string) => {
        const trimmedSearchInput = nextVal ? nextVal.trim() : searchInput.trim();

        if (trimmedSearchInput === '') {
            setSearchInput('');
            return;
        }
        let newSearchKeywords = [...searchKeywords];

        if (trimmedSearchInput.includes(' ') || trimmedSearchInput.includes(',') || trimmedSearchInput.includes('	')) {
            const keywordArr = trimmedSearchInput.split(/[ s,	]/).filter((keyword) => keyword !== '');

            keywordArr.forEach((keyword) => {
                if (keyword !== '') {
                    newSearchKeywords.push(keyword);
                }
            });
        } else {
            newSearchKeywords.push(trimmedSearchInput);
        }

        if (newSearchKeywords.length > 10) {
            newSearchKeywords = newSearchKeywords.slice(0, limitSearch.LiMIT_SEARCHED_TERMS);
        }

        setSearchKeywords(newSearchKeywords);
        setSearchInput('');
    };

    const removeKeyword = (removed: string) => {
        setSearchKeywords(searchKeywords.filter((keyword) => keyword !== removed));
        if (searchKeywords.length <= 1) {
            resetSearchAndFilters();
        }
    };

    const handlePasteInput = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();

        // @ts-ignore
        let paste = (e.clipboardData || window?.clipboardData).getData('text');
        const cleanString = paste?.replace(/[^a-zA-Z0-9,_\s-]/g, '');

        const truncatedPaste = cleanString?.slice(0, user?.PortalCompleteEntryDataSearchBarTextLimit);

        updateKeywords(truncatedPaste);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // revert last keyword to text on backspace
        if (searchInput === '' && e.key === 'Backspace') {
            if (searchKeywords.length !== 0) {
                const lastElement = searchKeywords.slice(-1);
                setSearchKeywords(searchKeywords.slice(0, -1));
                setSearchInput(lastElement[0]);
            }
        }
        if (e.key === 'Enter') {
            updateKeywords();
            setOnEnterKey(true);
        }
    };

    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        e.target.placeholder = '';

        if (shouldRenderDropdown && !searchInput && searchKeywords.length === 0) {
            const searchSuggestions = document.querySelector('.suggestions-dropdown');
            // searchSuggestions.classList.remove('d-none');
        }
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        e.target.placeholder = searchInput || searchKeywords.length > 0 ? '' : placeholderText;

        if (shouldRenderDropdown) {
            const searchSuggestions = document.querySelector('.suggestions-dropdown');
            // searchSuggestions.classList.add('d-none');
        }
        if (searchInput) updateKeywords();
    };

    const createKeywordString = (keywordArr: string[]) => {
        let keywordStr = keywordArr.reduce((str, keyword) => str + ', ' + keyword, '');
        keywordStr = keywordStr.substring(2);
        return keywordStr;
    };

    const searchButtonOnClick = (val?: string[]) => {
        const payload = val?.length ? val : searchKeywords;

        dispatch(updateMainSearchFilter(payload));
        return dispatch(updateSubmittedSearchKeywords(searchKeywords));

        // dispatch(resetSelectedEntries());
        // dispatch(setSearchBarOptions());
        // dispatch(updateSearchFilters(payload));
    };

    useEffect(() => {
        // only update after user adds a space or comma
        // handle copied sheet cells containing tab separators
        if (searchInput.includes(' ') || searchInput.includes(',') || searchInput.includes('	')) updateKeywords();
    }, [searchInput, dispatch]);

    useEffect(() => {
        dispatch(updateSearchKeywords(searchKeywords));
    }, [searchKeywords, dispatch]);

    useEffect(() => {
        if (onEnterKey) {
            searchButtonOnClick(searchKeywords);
            setOnEnterKey(false);
        }
    }, [onEnterKey]);

    // Map local state to redux????
    useEffect(() => {
        if (!compareArrays(searchKeywords, reduxSearchKeywords)) setSearchKeywords(reduxSearchKeywords);
    }, [reduxSearchKeywords]);

    // Clear search bar when client selection changes
    useEffect(() => {
        setSearchKeywords([]);
        dispatch(updateSearchKeywords([]));
        setSearchInput('');
    }, [initialClientSelection]);

    useEffect(() => {
        if (!searchKeywords.length && inputRef.current)
            inputRef.current.placeholder = searchInput || searchKeywords.length > 0 ? '' : placeholderText;
    }, [searchKeywords]);

    return (
        <div className={`input-group pb-3 pt-3 pl-3 pr-3 container-fluid border-bottom flex-nowrap bg-white`}>
            <div className={`simplified-search-bar simplified-search-bar-with-button documents_search_bar`}>
                <div className={'chips-input-wrapper'}>
                    <div className={'magnifying-glass-input-icon documents_search_bar_icon'}>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <div className='documents_search_chips chips'>
                        {searchKeywords.map((keyword, index) => (
                            <SearchChip
                                key={keyword + index}
                                index={index}
                                keyword={keyword}
                                remove={removeKeyword}
                                searchKeywords={searchKeywords}
                                setSearchKeywords={setSearchKeywords}
                                remainingSpace={remainingSpace}
                            />
                        ))}
                        <input
                            type={'text'}
                            ref={inputRef}
                            className={'documents_search_input form-control preserve-borders'}
                            placeholder={searchKeywords.length ? '' : placeholderText}
                            value={searchInput}
                            onChange={(e) => handleChangeInput(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onFocus={(e) => handleInputFocus(e)}
                            onBlur={(e) => handleInputBlur(e)}
                            onPaste={(e) => handlePasteInput(e)}
                        />
                    </div>

                    <div className={'justify-content-end'}>
                        <span
                            role='button'
                            onClick={handleClearSearch}
                            className={`form-clear-simplified-search form-clear ${searchKeywords.length ? '' : 'd-none'}`}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                    </div>
                </div>
            </div>

            <div className='documents_search_bar_btn input-group-append'>
                <button
                    type='button'
                    className='btn btn-primary d-flex align-items-center'
                    onClick={() => {
                        searchButtonOnClick();
                    }}
                >
                    <span>{translate('SearchSubmitButton_Label')}</span>
                </button>
            </div>
        </div>
    );
};

export default CADSearchBar;
