import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from '../../../../../portal/common/features/Button';
import { SearchBoxProps } from '../../../../common/models/SearchBox';
import ClearSearchInputButton from './ClearSearchInputButton';

const SearchBoxDesktop: React.FC<SearchBoxProps> = ({
    inputValue,
    placeholder,
    advancedSearchPlaceholder,
    submitButtonText,
    showAdvancedSearch,
    showMobileSearch,
    enableAdvancedSearch,
    customStyle,
    onToggleMobileSearch,
    onChange,
    onKeyDown,
    onBlur,
    onClearInputValue,
    onToggleAdvancedSearch,
    onSubmit
}) => {
    return (
        <>
            {/* <div className='search-bar-wrapper align-self-center d-none d-md-block flex-md-grow-1'>
                <div className={`search-bar ${enableAdvancedSearch ? 'with-advanced-search' : ''}`}>
                    <div className='input-group form-group align-items-center position-relative'> */}
            <div className='input-group pb-3 container-fluid border-bottom' style={{ backgroundColor: 'white', flexWrap: 'nowrap' }}>
                <div className={'simplified-search-bar simplified-search-bar-with-button'}>
                    <div className='chips-input-wrapper' style={{ overflow: 'unset' }}>
                        <div className='magnifying-glass-input-icon'>
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <div className='chips'></div>
                        <input
                            type='text'
                            placeholder={placeholder}
                            value={inputValue}
                            className='form-control search-input text-truncate'
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            onBlur={onBlur}
                        />

                        <div className='input-group-append'>
                            <ClearSearchInputButton inputValue={inputValue} onClick={onClearInputValue} />
                            {enableAdvancedSearch && (
                                <div className='advanced-search-button dropdown'>
                                    {/* <Button variant='ghost-dark' size='small' className='settings-icon' onClick={onToggleAdvancedSearch}>
                                        <FontAwesomeIcon icon={faSlidersH} />
                                    </Button> */}
                                    {/* Advanced Search */}
                                    {/* {showAdvancedSearch && (
                                        <AdvancedSearch
                                            placeholder={advancedSearchPlaceholder}
                                            showMobileSearch={showMobileSearch}
                                            onToggleAdvancedSearch={onToggleAdvancedSearch}
                                            onToggleMobileSearch={onToggleMobileSearch}
                                        />
                                    )} */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='input-group-append'>
                    <Button variant='primary' onClick={onSubmit}>
                        {submitButtonText}
                    </Button>
                </div>
            </div>
            {/* Mobile search toggle */}
            {/* <div
                onClick={onToggleMobileSearch}
                className='mobile-search-toggle d-flex flex-column justify-content-center align-items-center px-3'
            >
                <FontAwesomeIcon icon={faSearch} />
            </div> */}
        </>
    );
};

export default SearchBoxDesktop;
