import React, { Component } from 'react';
import { imaging as Constants } from '../../../constants/mlp-constants';
import { formatTranslated, printPage } from '../../../utils/mlp-utils';

class DownloadSuccess extends Component {
    render() {
        let print = '';
        if (this.props.packageType === Constants.PACKAGE_TYPE_MERGE) {
            print = (
                <button type='button' className='btn btn-default' onClick={() => printPage(this.props.url)}>
                    {formatTranslated('Print_Requested_Images_Button', this.props.translations.Phrases)}
                </button>
            );
        }
        return (
            <div>
                <div>
                    <h3>{formatTranslated('Download_Completed_Label', this.props.translations.Phrases)}</h3>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        {formatTranslated('Download_Requested_Images_Label', this.props.translations.Phrases)}
                        <br />
                        <br />
                        <a href={this.props.url}>{formatTranslated('Download_Requested_Images_Button', this.props.translations.Phrases)}</a>
                        <br />
                        <br />
                        {print}
                    </div>
                </div>
            </div>
        );
    }
}

export default DownloadSuccess;
