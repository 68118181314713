import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supportMailCA, supportMailUS, supportNumberCA, supportNumberUS } from '../../../../constants/mlp-constants';
import CountryFlag from '../../../../portal/common/features/CountryFlag';

interface SupportCardProps {
    country: string | undefined;
    translate: (key?: string | null | undefined, ...subs: any) => string;
}

const SupportCard: React.FC<SupportCardProps> = ({ country, translate }) => {
    const title = country === 'us' ? translate('USCustomsBrokerage_Label') : translate('CACustomsBrokerage_Label');
    const tel = country === 'us' ? supportNumberUS : supportNumberCA;
    const email = country === 'us' ? supportMailUS : supportMailCA;

    return (
        <div>
            <div
                className='card cursor-pointer h-100 '
                style={{
                    padding: '5%',
                    paddingLeft: '10%',
                    paddingRight: '10%'
                }}
            >
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <small className='text-uppercase text-lii-text-light font-weight-bold'>{translate('Support_Label')}</small>
                        <h3 className='mb-0'>{title}</h3>
                    </div>
                    <CountryFlag className='mb-3' countryCode={country ?? 'us'} width={'35'} height={'25'} />
                </div>

                <div className='mt-2'>
                    <FontAwesomeIcon icon={faPhone} className='mr-2' />
                    <a href={`tel:${tel}`}>{tel}</a>
                </div>
                <div>
                    <FontAwesomeIcon icon={faEnvelope} className='mr-2' />
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
            </div>
        </div>
    );
};

export default SupportCard;
