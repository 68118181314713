import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslationHydration from '../../../../portal/common/hooks/useTranslationHydration';
import { TranslationsSlice } from '../../../../portal/common/models/ReduxSlices';
import CountryFlag from '../../../common/features/CountryFlag';
import { LanguageSlice } from '../../../common/models/ReduxSlices';
import { setLanguageSelected } from '../../../redux/reducers/languageSlice';
import { RootState } from '../../../redux/store';
import { LanguageCodes } from '../../constants/constants-portal';

interface SidebarNavLanguageSelectorProps {
    onLanguageChange: (payload: number) => void;
}

const SidebarNavLanguageSelector: React.FC<SidebarNavLanguageSelectorProps> = ({ onLanguageChange }) => {
    const translate = useTranslationHydration();
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { translations } = useSelector<RootState, TranslationsSlice>((state) => state.translations);
    const dispatch = useDispatch();

    // Set the initial language
    useEffect(() => {
        const preferredLanguageIndex = translations?.LanguageCode === 'fr' ? 1 : 0;
        dispatch(setLanguageSelected(preferredLanguageIndex));
    }, [translations?.LanguageCode, dispatch]);

    return (
        <div className='dropdown'>
            <button
                className='btn btn-tertiary-white dropdown-toggle btn-lg w-100 text-left d-flex align-items-center'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {languageSelected && (
                    <>
                        <CountryFlag countryCode={languageSelected.country} className='mr-3' />
                        {translate(languageSelected.label)}
                    </>
                )}
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                {languageSelected &&
                    LanguageCodes.map((lang, index) => {
                        const isActive = languageSelected.code === lang.code;

                        return (
                            <div
                                className={`dropdown-item d-flex justify-content-between align-items-stretch ${isActive ? 'active' : ''}`}
                                key={lang.code}
                                onClick={() => onLanguageChange(index)}
                                style={isActive ? { backgroundColor: 'inherit' } : undefined}
                            >
                                <div className='mr-3 d-flex align-items-center'>
                                    <CountryFlag countryCode={lang.country} width='16px' height='12px' className='mr-3' />
                                    {translate(lang.label)}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default SidebarNavLanguageSelector;
