import { createGenericStatusFilterSlice } from '../../../portal/redux/reducers/genericStatusFilterSlice';
import { StatusFilterOptions } from '../../common/constants/constants-team';

const teamStatusFilterSlice = createGenericStatusFilterSlice({
    name: 'teamStatusFilter',
    initialState: { statuses: StatusFilterOptions, selectedStatus: StatusFilterOptions[0] },
    reducers: {}
});

export const { setSelectedStatus } = teamStatusFilterSlice.actions;

export default teamStatusFilterSlice.reducer;
