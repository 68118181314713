import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { imageDetailsPath, storageModelName } from '../../../constants/mlp-constants';
import CustomDropDown from '../../../common/CustomDropDown/CustomDropDown';
import * as actions from '../../../store/actions/index';
import { removeModelFromStorage } from '../../../utils/modelStorage';
import { formatTranslated, pluck, sortBy, sortByFunc, sortByOrders } from '../../../utils/mlp-utils';

class DownloadAndPrint extends Component {
    detailPages = [
        imageDetailsPath.VIEWIMAGE_SEARCHSHIPMENTDETAIL,
        imageDetailsPath.DOCUMENTCOMPARE_VIEW,
        imageDetailsPath.DOCUMENT_VIEW,
        imageDetailsPath.DOWNLOAD_AND_PRINT
    ];

    state = {
        sortLevelOne: 'ASC',
        sortLevelTwo: 'DESC',
        groupFilesInZip: false,
        downloadZip: false,
        sortOrderDrowpdownOne: [],
        sortOrderDrowpdownTwo: []
    };

    componentDidMount() {
        // when comes from Details or Documents or Compare tabs
        if (this.props.translations.PhraseGroupCode === 'Imaging.Imaging.SearchResultDetails') {
            this.createOrderDropDowns();
        }
    }

    componentWillUnmount() {
        if (this.props.history.location.pathname !== '/Imaging' && !this.detailPages.includes(this.props.history.location.pathname)) {
            removeModelFromStorage(storageModelName.VIEWIMAGE);
        }
    }
    
    componentDidUpdate(prevProps) {
        // when comes from ViewImages page
        if (this.props.translations.PhraseGroupCode !== prevProps.translations.PhraseGroupCode) {
            this.createOrderDropDowns();
        }
    }

    createOrderDropDowns = () => {
        let sortLevelOne = this.props.levelOneSortOptions.find((e) => e.IsSelected) || this.props.levelOneSortOptions[0];
        let sortLevelTwo = this.props.levelTwoSortOptions.find((e) => e.IsSelected) || this.props.levelTwoSortOptions[0];

        const sortOrderOne = sortLevelOne.Direction !== 'ASC' && sortLevelOne.Direction !== 'DESC' ? 'ASC' : sortLevelOne.Direction;

        const sortOrderTwo = sortLevelTwo.Direction !== 'ASC' && sortLevelTwo.Direction !== 'DESC' ? 'DESC' : sortLevelTwo.Direction;

        let sortOrderDrowpdownOne = [
            {
                Text: formatTranslated('ASC', this.props.translations.Phrases),
                Order: 'ASC',
                IsSelected: sortOrderOne === 'ASC'
            },
            {
                Text: formatTranslated('DESC', this.props.translations.Phrases),
                Order: 'DESC',
                IsSelected: sortOrderOne === 'DESC'
            }
        ];
        let sortOrderDrowpdownTwo = [
            {
                Text: formatTranslated('ASC', this.props.translations.Phrases),
                Order: 'ASC',
                IsSelected: sortOrderTwo === 'ASC'
            },
            {
                Text: formatTranslated('DESC', this.props.translations.Phrases),
                Order: 'DESC',
                IsSelected: sortOrderTwo === 'DESC'
            }
        ];

        this.setState({
            sortLevelOne: sortOrderOne,
            sortLevelTwo: sortOrderTwo,
            sortOrderDrowpdownOne: sortOrderDrowpdownOne,
            sortOrderDrowpdownTwo: sortOrderDrowpdownTwo
        });
    };

    getDocumentGroupingOptionsForLevel = (documents, level) => {
        let metadata = documents[0].metadata;
        let result = metadata
            .filter((e) => e.isPackageSortable)
            .map((item) => {
                return {
                    Text: item.label,
                    Attribute: item.attribute,
                    IsSelected: item.packageSortLevel === level,
                    Level: item.packageSortLevel,
                    Direction: item.packageSortDirection,
                    IsHidden: false
                };
            });
        return result;
    };

    activeTab(type) {
        this.setState({ activeTab: type });
    }

    selectLevelOneSortOption = (newIndex, oldIndex) => {
        this.props.sortOptionSelect(newIndex, oldIndex, 1);
    };

    selectLevelTwoSortOption = (newIndex, oldIndex) => {
        this.props.sortOptionSelect(newIndex, oldIndex, 2);
    };

    toggleGroupCheckbox = () => {
        this.setState((prevState) => {
            return { groupFilesInZip: !prevState.groupFilesInZip };
        });
    };

    getNavClassMerge = () => {
        let elementClass = ['imaging-nav-item'];
        if (!this.state.downloadZip) {
            elementClass.push('selected');
        }
        return elementClass.join(' ');
    };

    getNavClassZip = () => {
        let elementClass = ['imaging-nav-item'];
        if (this.state.downloadZip) {
            elementClass.push('selected');
        }
        return elementClass.join(' ');
    };

    selectMergeOption = () => {
        this.setState({
            downloadZip: false
        });
    };

    selectZipOption = () => {
        this.setState({
            downloadZip: true,
            sortLevelOne: 'ASC'
        });
    };

    selectSortOrderDropdown = (newIndex, oldIndex, name) => {
        let newOrder = '';
        let sortOrderDrowpdown = null;
        if (name === 'sortLevelOne') {
            sortOrderDrowpdown = this.state.sortOrderDrowpdownOne;
        } else if (name === 'sortLevelTwo') {
            sortOrderDrowpdown = this.state.sortOrderDrowpdownTwo;
        } else {
            return;
        }

        if (newIndex !== oldIndex) {
            const value = sortOrderDrowpdown.map((x, index) => {
                if (index === oldIndex) {
                    x = { ...x, IsSelected: false };
                } else if (index === newIndex) {
                    x = { ...x, IsSelected: true };
                    newOrder = x.Order;
                }
                return x;
            });

            if (name === 'sortLevelOne') {
                this.setState({ sortLevelOne: newOrder, sortOrderDrowpdownOne: value });
            } else {
                this.setState({ sortLevelTwo: newOrder, sortOrderDrowpdownTwo: value });
            }
        }
    };

    getMergeSection = () => {
        return (
            <div className='row download-options'>
                <div className='col-lg-6 col-sm-12 download-gutter'>
                    <div className='d-flex'>
                        <div className='col-sm-7 no-gutter'>
                            <CustomDropDown
                                options={this.props.levelOneSortOptions}
                                disabled={this.state.downloadZip && !this.state.groupFilesInZip}
                                label={formatTranslated(
                                    this.state.downloadZip ? 'Group_The_Included_Files_By_Label' : 'Sort_By_Label',
                                    this.props.translations.Phrases
                                )}
                                textField='Text'
                                valueField='Attribute'
                                onlyCustomAction={false}
                                onItemChanged={this.selectLevelOneSortOption}
                            />
                        </div>
                        {!this.state.downloadZip ? (
                            <div className='col-sm-5 no-gutter'>
                                <CustomDropDown
                                    label='Order'
                                    options={this.state.sortOrderDrowpdownOne}
                                    name='sortLevelOne'
                                    textField='Text'
                                    valueField='Order'
                                    onlyCustomAction={false}
                                    onItemChanged={this.selectSortOrderDropdown}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className='col-lg-6 col-sm-12 download-gutter'>
                    <div className='d-flex'>
                        <div className='col-sm-7 no-gutter'>
                            <CustomDropDown
                                options={this.props.levelTwoSortOptions}
                                disabled={this.state.downloadZip && !this.state.groupFilesInZip}
                                label={formatTranslated(
                                    this.state.downloadZip ? 'Sort_Within_Grouping_By_Label' : 'Then_By_Label',
                                    this.props.translations.Phrases
                                )}
                                textField='Text'
                                valueField='Attribute'
                                onlyCustomAction={false}
                                onItemChanged={this.selectLevelTwoSortOption}
                            />
                        </div>
                        {this.state.groupFilesInZip || !this.state.downloadZip ? (
                            <div className='col-sm-5 no-gutter'>
                                <CustomDropDown
                                    label='Order'
                                    options={this.state.sortOrderDrowpdownTwo}
                                    name='sortLevelTwo'
                                    textField='Text'
                                    valueField='Order'
                                    onlyCustomAction={false}
                                    onItemChanged={this.selectSortOrderDropdown}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    getZipSection = () => {
        return (
            <div>
                <div className='row download-options'>
                    <div className='checkbox-container'>
                        <label>
                            <input type='checkbox' checked={!this.state.groupFilesInZip} onChange={this.toggleGroupCheckbox} />
                            {formatTranslated('Do_Not_Group_The_Files_Label', this.props.translations.Phrases)}
                        </label>
                    </div>
                </div>
                {this.getMergeSection()}
            </div>
        );
    };

    sortNotSet = () => {
        return (
            this.props.levelOneSortOptions === undefined ||
            this.props.levelOneSortOptions.length === 0 ||
            this.props.levelOneSortOptions === undefined ||
            this.props.levelOneSortOptions.length === 0
        );
    };

    printDocument = () => {
        this.props.onDownloadDocument(true, this.getSortedDocuments());
    };

    donwloadDocument = () => {
        const sortColumnOne = this.props.levelOneSortOptions.find((x) => x['IsSelected']).Attribute;
        this.props.onDownloadDocument(false, this.getSortedDocuments(), this.state.downloadZip, this.state.groupFilesInZip, sortColumnOne);
    };

    getSortedDocuments = () => {
        const sortDirOne = this.state.sortLevelOne;
        const sortDirTwo = this.state.sortLevelTwo;

        const sortColumnOne = this.props.levelOneSortOptions.find((e) => e.IsSelected).Attribute;
        const sortColumnTwo = this.props.levelTwoSortOptions.find((e) => e.IsSelected).Attribute;

        let sortOrders = [sortDirOne, sortDirTwo];
        let sortColumns = [sortColumnOne, sortColumnTwo];

        let sortOptions = sortBy(
            this.props.levelOneSortOptions.filter((x) => {
                return x.Level > 0;
            }),
            'Level'
        );
        sortOptions.forEach((y) => {
            if (sortColumns.indexOf(y.Attribute) < 0) {
                sortColumns.push(y.Attribute);
                sortOrders.push(y.Direction);
            }
        });

        let docsToSort = this.props.FlatDocumentModel.map((x) => {
            let result = {
                doc: x
            };

            sortColumns.forEach((y) => {
                let col = x.metadata.find((e) => e.attribute === y);
                if (col) {
                    // always use originalValue for sorting
                    result[y] = col.originalValue;

                    // we have to sort by local date in ISO format (originalValue) because it sorts as a string
                    if (col.dataType === 'DateTime') {
                        const localDate = moment.utc(col.originalValue).local().format('YYYY-MM-DD');
                        result[y + '_original'] = col.originalValue;
                        result[y] = localDate;
                    }
                } else {
                    result[y] = undefined;
                }
            });
            return result;
        });

        const sorted = sortByOrders(docsToSort, sortColumns, sortOrders);
        return pluck(sorted, 'doc');
    };

    getDocumentRows = (sortedDocuments) => {
        let sortColumnOne = this.props.levelOneSortOptions.find((e) => e.IsSelected);
        let sortColumnTwo = this.props.levelTwoSortOptions.find((e) => e.IsSelected);
        let isZipAndGroup = this.state.groupFilesInZip && this.state.downloadZip;
        let previousGroupValue = '';

        return (
            sortedDocuments &&
            sortedDocuments.map((doc) => {
                let sorted = [
                    doc.metadata && doc.metadata.find((e) => e.attribute === sortColumnOne.Attribute),
                    doc.metadata && doc.metadata.find((e) => e.attribute === sortColumnTwo.Attribute)
                ];

                const filteredMetadata = doc.metadata.filter((a) => {
                    return a.isPackageSortable && a.attribute !== sortColumnOne.Attribute && a.attribute !== sortColumnTwo.Attribute;
                });

                sortByFunc(filteredMetadata, (m) => {
                    return m.detailsScreenOrder > 0 ? m.detailsScreenOrder : 10 + m.gridOrder;
                }).forEach((a) => {
                    sorted.push(a);
                });

                const groupValue = sorted[0].value;

                let tds = sorted.map((attr, index) => {
                    let sortOrderIndex = attr.attribute.indexOf('SortOrder');
                    let value = attr.value;
                    if (sortOrderIndex > 0) {
                        let attrNoSort = attr.attribute.substring(0, sortOrderIndex);
                        value = doc.metadata.find((e) => e.attribute === attrNoSort).value;
                    }

                    let style = isZipAndGroup && previousGroupValue !== groupValue ? { borderTop: '1px solid black' } : null;

                    return (
                        <td style={style} key={doc.pid + attr.attribute + index}>
                            {value}
                        </td>
                    );
                });

                previousGroupValue = groupValue;

                return <tr key={doc.pid}>{tds}</tr>;
            })
        );
    };

    getDocumentsGrid = () => {
        let isZipAndGroup = this.state.groupFilesInZip && this.state.downloadZip;
        let sortColumnOne = this.props.levelOneSortOptions.find((e) => e.IsSelected);
        let sortColumnTwo = this.props.levelTwoSortOptions.find((e) => e.IsSelected);
        let sortedDocuments = this.getSortedDocuments();
        let docs = this.getDocumentRows(sortedDocuments);

        let groupHeaderLabel = isZipAndGroup ? 'Group_The_Included_Files_By_Label' : 'Sort_By_Label';
        let groupHeader = formatTranslated(groupHeaderLabel, this.props.translations.Phrases) + ': ' + sortColumnOne.Text;
        if (!isZipAndGroup) groupHeader += ' - ' + formatTranslated(this.state.sortLevelOne, this.props.translations.Phrases);

        let secondHeaderLabel = isZipAndGroup ? 'Sort_By_Label' : 'Then_By_Label';
        let headers = [
            groupHeader,
            formatTranslated(secondHeaderLabel, this.props.translations.Phrases) +
                ': ' +
                sortColumnTwo.Text +
                ' - ' +
                formatTranslated(this.state.sortLevelTwo, this.props.translations.Phrases)
        ];

        const filteredMetadata = sortedDocuments[0].metadata.filter((a) => {
            return a.isPackageSortable && a.attribute !== sortColumnOne.Attribute && a.attribute !== sortColumnTwo.Attribute;
        });

        sortByFunc(filteredMetadata, (m) => {
            return m.detailsScreenOrder > 0 ? m.detailsScreenOrder : 10 + m.gridOrder;
        }).forEach((a) => {
            headers.push(a.label);
        });

        return (
            <div>
                <p>{formatTranslated('Documents_Downloaded_In_Order_Label', this.props.translations.Phrases)}</p>
                <table className='table'>
                    <thead>
                        <tr>
                            {headers.map((h, i) => {
                                return <th key={i}>{h}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>{docs}</tbody>
                </table>
            </div>
        );
    };

    render() {
        if (this.sortNotSet()) {
            return <div></div>;
        }
        let docGrid = !this.state.downloadZip || this.state.groupFilesInZip ? this.getDocumentsGrid() : null;
        return (
            <div id='imaging'>
                <div className='content-wrapper'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='download-section-container'>
                                <div className='download-section'>
                                    <div className='imaging-nav-container row'>
                                        <div className={this.getNavClassMerge()} onClick={this.selectMergeOption}>
                                            <div>
                                                <span>{formatTranslated('Single_Merged_PDF_Label', this.props.translations.Phrases)}</span>
                                            </div>
                                        </div>
                                        <div className={this.getNavClassZip()} onClick={this.selectZipOption}>
                                            <div>
                                                <span>{formatTranslated('ZIP_File_Label', this.props.translations.Phrases)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container imaging-details-container'>
                                        <div className='entry-details-container'>
                                            {!this.state.downloadZip ? this.getMergeSection() : this.getZipSection()}
                                        </div>
                                    </div>
                                    <hr />
                                    {docGrid}
                                    <div className='text-right download-print-buttons'>
                                        {!this.state.downloadZip ? (
                                            <button className='button blue small' onClick={() => this.printDocument()}>
                                                {formatTranslated('Print_Label', this.props.translations.Phrases)}
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        <button className='button blue small' onClick={() => this.donwloadDocument()}>
                                            {formatTranslated('Download_Label', this.props.translations.Phrases)}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        levelOneSortOptions: state.imagingDetails.model.LevelOneSortOptions,
        levelTwoSortOptions: state.imagingDetails.model.LevelTwoSortOptions,
        sortedDocuments: state.imagingDetails.model.SortedDocuments,
        FlatDocumentModel: state.imagingDetails.model.FlatDocumentModel
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDownloadDocument: (print, sortedDocuments, downloadZip, groupFilesInZip, groupByColumn) =>
            dispatch(actions.downloadDocument(print, sortedDocuments, downloadZip, groupFilesInZip, groupByColumn)),
        sortOptionSelect: (newIndex, oldIndex, level) => dispatch(actions.sortOptionSelect(newIndex, oldIndex, level))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAndPrint);
