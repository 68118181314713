import { Component, React } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionCreators from '../../../store/actions/index';

class StepFinishedForm extends Component {
    componentDidMount = () => {
        this.props.onInitTranslations('ClientAdmin.Signup.StepFinished');
    };

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    render() {
        return (
            <div id='page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                            <h3 className='subheader'>{this.props.translations.Phrases['Page_Sub_Header']}</h3>
                        </div>
                        <div className='back-link'>
                            <Link to='/Dashboard' className='breadcrumb-parent'>
                                {this.props.translations.Phrases['BackToDashboard_Label']}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepFinishedForm);
