import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ContactsGrid from './screens/ContactsGrid';

const Contacts = () => {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path={['/Contacts', '//Contacts']} exact component={ContactsGrid} />
                </Switch>
            </div>
        </main>
    );
};
export default React.memo(Contacts);
