import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DocumentsPage from './screens/DocumentsPage';

const Documents = () => {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path='/Documents' exact component={DocumentsPage} />
                </Switch>
            </div>
        </main>
    );
};

export default React.memo(Documents);
