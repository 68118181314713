import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../../../portal/common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../portal/common/features/Breadcrumb/BreadcrumbItem';
import Spinner from '../../../portal/common/features/Spinner';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { setTeamMember } from '../../api/utils';
import ClientsAndPermissions from '../../common/features/ClientsAndPermissions';
import Header from '../../common/features/Header';
import TeamMemberFooter from '../../common/features/TeamMemberFooter';
import UserDetails from '../../common/features/UserDetails';
import { TeamMemberData } from '../../common/models/ResponseData';
import { GetCloneMemberDetails } from '../../redux/actions/teamManagement';
import { statusChangeCheck } from '../../redux/reducers/statusUserSlice';
import { setPageSelected } from '../../redux/reducers/teamGridSettingsSlice';
import { resetNewUsersAmount } from '../../redux/reducers/userDetailsSlice';

const CloneTeamMember = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { push } = useHistory();
    const translate = useTranslation();

    // On initial load get "clone" team member details and reset "newUsersAmount" from the "userDetails" slice
    useEffect(() => {
        let queryParams = new URLSearchParams(window.location.search);
        let userId = queryParams.get('userId');
        dispatch(resetNewUsersAmount());
        dispatch(GetCloneMemberDetails(userId));
    }, [dispatch]);

    const handleAddingUsers = (payload: { data: TeamMemberData; amount: number }) => {
        setIsLoading(true);
        setTeamMember(payload.data).then((res: any) => {
            setIsLoading(false);
            dispatch(statusChangeCheck());
            dispatch(setPageSelected(1));
            if (res.data === 'SuccessfulSubmit') {
                return push('/Team');
            }
        });
    };

    if (isLoading) {
        return (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                <Spinner />
            </div>
        );
    }

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb>
                <BreadcrumbItem url='/Team'>{translate('Team_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{translate('CloneUser_Label')}</BreadcrumbItem>
            </Breadcrumb>
            {/* Header */}
            <Header title={translate('HeaderCloneUser_Label')} />
            {/* Content */}
            <div className='container-fluid py-3'>
                {/* 1. User details */}
                <UserDetails />
                {/* 2. Clients and permissions */}
                <ClientsAndPermissions />
            </div>
            {/* Footer */}
            <TeamMemberFooter onAddUsers={handleAddingUsers} />
        </>
    );
};

export default CloneTeamMember;
