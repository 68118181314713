import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useRef, useState } from 'react';
import Button from '../../../../portal/common/features/Button';
import Checkbox from '../../../../portal/common/features/Checkbox';
import DropdownMenuButton from '../../../../portal/common/features/DropdownMenuButton';
import FormInputField from '../../../../portal/common/features/FormInputField';
import { inputHasValueValidator } from '../../../../portal/common/functions/validators';
import useDropdownToggle from '../../../../portal/common/hooks/useDropdownToggle';
import useMaskedInputValidation from '../../../../portal/common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../portal/common/hooks/useTranslation';

interface NotificationTypesDropdownProps {
    email: null | string;
    phone: null | string;
    emailChecked: boolean;
    disabled?: boolean;
    applyChange: (isEmailChecked: boolean, mobileNumber: null | string) => void;
}

const NotificationTypesDropdown: React.FC<NotificationTypesDropdownProps> = ({ applyChange, email, phone, emailChecked, disabled }) => {
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const [isEmailChecked, setIsEmailChecked] = useState<boolean>(emailChecked);
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid,
        handleReset: resetMobileNumber
    } = useMaskedInputValidation({
        required: false,
        initial: phone,
        validators: [inputHasValueValidator, (value: string) => isValidPhoneNumber(value, 'US')],
        inputMask: '(###) ###-####'
    });
    const translate = useTranslation();

    // On open set email is checked and reset mobile number on unmount
    useEffect(() => {
        if (isOpen) {
            setIsEmailChecked(emailChecked);
        }
        return () => {
            resetMobileNumber(phone || null);
        };
    }, [isOpen, emailChecked, phone, resetMobileNumber]);

    const handleCheckbox = (key: string, value: boolean) => {
        setIsEmailChecked(value);
    };

    return (
        <>
            <div className='table-milestone-dropdown'>
                <DropdownMenuButton
                    btnVariant='tertiary'
                    btnClassName='btn-sm dropdown-toggle'
                    label={translate('EmailText_Label')}
                    childrenDivClassName='dropdown-menu'
                    isOpen={isOpen}
                    onClick={handleToggle}
                    ref={dropdownMenuRef}
                    disabled={disabled}
                >
                    <div className='mr-4 ml-4 mt-3'>
                        <p style={{ fontWeight: 'bold' }}>{translate('SelectHowTobeNotified_Label')}</p>
                        <form className='checkboxes'>
                            <div className='custom-control custom-checkbox mb-1'>
                                <Checkbox
                                    id={'email'}
                                    name={'Email'}
                                    inputStyle={{ width: '1.25rem', height: '1.32813rem' }}
                                    divStyle={{ paddingBottom: '1vh' }}
                                    onChange={handleCheckbox}
                                    isChecked={isEmailChecked}
                                >
                                    {email}
                                </Checkbox>
                            </div>
                            <FormInputField
                                value={mobileNumber}
                                onChange={mobileNumberChangeHandler}
                                onBlur={mobileNumberBlurHandler}
                                isValid={mobileNumberIsValid}
                                hasError={mobileNumberHasError}
                                placeholder={translate('EnterMobileNumber_Label')}
                                label={''}
                                showLabel={false}
                            />
                            <div className='small'>{translate('StandardMessageAndDataRates_Label')}</div>
                            {mobileNumberHasError && <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>}
                        </form>
                    </div>
                    <hr />
                    <div className='button-container d-flex pb-2 ml-4 mr-4'>
                        <Button
                            children={translate('Cancel_Label')}
                            type='button'
                            variant='tertiary-blue'
                            className='mr-2 w-50'
                            onClick={handleToggle}
                        />
                        <Button
                            children={translate('Apply_Label')}
                            type='button'
                            variant='primary'
                            className='w-50'
                            disabled={mobileNumberIsValid || mobileNumber.length === 0 ? false : true}
                            onClick={() => [applyChange(isEmailChecked, mobileNumber), handleToggle()]}
                        />
                    </div>
                </DropdownMenuButton>
            </div>
        </>
    );
};

export default NotificationTypesDropdown;
