import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridColumn as Column, Grid, GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetDateRange } from '../../clearances/redux/reducers/dateRangeSlice';
import { cancelPortalRequests } from '../../portal/axios-portal';
import ErrorPage from '../../portal/common/features/ErrorPage';
import GridLoadingOverlay from '../../portal/common/features/Grid/GridLoadingOverlay';
import Spinner from '../../portal/common/features/Spinner';
import useGridColumnMinWidth from '../../portal/common/hooks/useGridColumnMinWidth';
import useGridLanguage from '../../portal/common/hooks/useGridLanguage';
import useGridPreferences from '../../portal/common/hooks/useGridPreferences';
import useTranslation from '../../portal/common/hooks/useTranslation';
import { ColumnSettings } from '../../portal/common/models/GridPreferences';
import { ClientSelectionSlice, GridPreferencesSlice, LanguageSlice, TranslationsSlice } from '../../portal/common/models/ReduxSlices';
import { ViewImagesSlice, addViewImagesData, removeViewImagesData } from '../../portal/redux/reducers/viewImagesSlice';
import DocumentDetailsModal from '../common/components/DocumentDetailsModal/DocumentDetailsModal';
import FormattedGridCell from '../common/components/FormattedGridCell';
import { ButtonArray } from '../common/constants/constants-documents';
import '../common/cssfixes/cssFixes.css';
import useDisableColumnsDrag from '../common/hooks/useDisableColumnsDrag';
import useHeaderSelection from '../common/hooks/useHeaderSelection';
import useScrollHandler from '../common/hooks/useScrollHandler';
import useSetupPayloadForImaging from '../common/hooks/useSetupPayloadForImaging';
import { gridSettingsConstants } from '../common/imaging-constants';
import { EllipsisSlice, ImagingGridSettingsSlice, ImagingModelSlice } from '../common/models/ReduxSlices';
import { GetImagingDocuments, GetImagingModel } from '../redux/actions/Imaging';
import { SaveUserImagingGridSetting } from '../redux/actions/imagingGridPreferences';
import { resetDocumentsDropdownFilters } from '../redux/reducers/imagingFiltersSlice';
import { updateColumnOrderGrid } from '../redux/reducers/imagingGridPreferenceSlice';
import { resetImagingGridSettings } from '../redux/reducers/imagingGridSettingsSlice';
import {
    resetDateRangeAndTypeFilters,
    resetMOTFilter,
    resetSearchResults,
    resetSelectedEntries,
    resetSubmitedSearchKeywords,
    resetTotalLoadedResults,
    setDocumentDetailModalView,
    updateClientSelectionPayload,
    updateScrollPosition,
    updateSelection,
    updateSelectionSelectAll,
    updateShouldInitModel,
    updateShouldScrollToLastPosition,
    updateShouldSubmitImageSearch
} from '../redux/reducers/imagingSlice';
import { RootState } from '../redux/store';
import { getArrayDestructured, modelPreviewObject, prepareFilesForDownload } from '../utils/download-modal-functions';
import DocumentsFooter from './DocumentsFooter';
import DocumentViewOverlay from './DocumentsView/DocumentsViewOverlay';
import DocumentCompareModal from './Modal/DocumentCompareModal';
import { DownloadModal } from './Modal/DownloadModal';
import NoDocumentsData from './NoDocumentsData';

interface DocumentsGridProps {
    setRowsInGrid: (value: number) => void;
}

const DocumentsGrid: React.FC<DocumentsGridProps> = ({ setRowsInGrid }) => {
    const { initialClientSelection, clientSelection, applyClicked } = useSelector<RootState, ClientSelectionSlice>(
        (state) => state.clientSelection
    );
    const { isLoading: isGridConfigLoading } = useSelector<RootState, GridPreferencesSlice>((state) => state.imagingGridPreferences);
    const { viewImages } = useSelector<RootState, ViewImagesSlice>((state) => state.viewImages);
    const { ellipsisData } = useSelector<RootState, EllipsisSlice>((state) => state.ellipsis);
    const {
        shouldInitModel,
        isLoading,
        error,
        noResults,
        shouldSubmitImageSearch,
        shouldScrollToLastPosition,
        scrollPosition,
        selectedEntries,
        submitedSearchKeywords,
        documentDetailsModalTopPosition,
        model,
        isCancelled,
        searchTypeCode
    } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const { translations } = useSelector<RootState, TranslationsSlice>((state) => state.translations);
    const gridSettings = useSelector<RootState, ImagingGridSettingsSlice>((state) => state.imagingGridSettings);
    const country = initialClientSelection?.Countries.find((c) => c.IsSelected);
    const { payloadToGetDocs, resetPayloadToGetDocs } = useSetupPayloadForImaging({
        model,
        translations,
        shouldSubmitImageSearch,
        selectedCountry: country && country?.Code,
        gridSettings: gridSettings,
        clientSelection: clientSelection,
        searchTypeCode: searchTypeCode
    });
    const gridRef = useRef(null);
    const imagingData = model && model.SearchResults ? model.SearchResults : [];

    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);

    const { allColumns, visibleColumns, columnsWidths, areColumnsLoading } = useSelector<RootState, GridPreferencesSlice>(
        (state) => state.imagingGridPreferences
    );
    const objectAllEntries = Object.assign({}, ...imagingData);
    const { headerCheckbox, handleHeaderCheckbox } = useHeaderSelection({
        allEntries: objectAllEntries,
        selectedEntries,
        dispatchSelection: (value) => dispatch(updateSelectionSelectAll(!value))
    });
    const { setWidth } = useGridColumnMinWidth({ columns: visibleColumns });
    const dispatch = useDispatch();
    const translate = useTranslation();

    const gridLanguage = useGridLanguage(selectedLanguage);
    const SELECTED_FIELD = 'selected';

    const [modalView, setModalView] = useState<null | string>(null);
    const [compareModalView, setCompareModalView] = useState<null | 'documentsGrid' | 'ellipsis' | 'documentDetailModal'>(null);
    const [downloadModalView, setDownloadModalView] = useState<null | string>(null);
    const [downloadModalData, setDownloadModalData] = useState<string[]>([]);
    const [currentRow, setCurrentRow] = useState<string | null>(null);

    /* Header fix and for preventing moving ellipsis on touchscreen */
    const tableHeaders = document.getElementsByTagName('th');
    const elements = [tableHeaders[0] as HTMLElement, tableHeaders[tableHeaders.length - 1]];
    if (tableHeaders.length) {
        const element = elements[elements.length - 1];
        element.classList.add('no-drag');
        var nested = element.querySelectorAll('span');
        nested.forEach(function (span) {
            span.classList.add('no-drag');
        });
    }
    const header = document.getElementsByClassName('k-grid-header')[0];
    header && imagingData.length < 10 ? header?.classList.add('mr-0') : header?.classList.remove('mr-0');
    const { disableDrag } = useDisableColumnsDrag({ isLoading, elements });

    /* Grid scroll handler */
    const { data, skip, resetSkip, pageChange, scrollHandler } = useScrollHandler({
        gridRef,
        isLoading,
        imagingData,
        scrollPosition,
        resultsLength: model.TotalResults ?? 0,
        loadedResultsLength: model.TotalLoadedResults ?? 0,
        shouldSubmitImageSearch,
        shouldScrollToLastPosition,
        saveUpdateScroll: (value) => updateScrollAndSubmitImageSearch(value)
    });

    const updateScrollAndSubmitImageSearch = (value: boolean) => {
        if (value) {
            dispatch(updateShouldScrollToLastPosition(true));
            dispatch(updateScrollPosition(data.length - 1));
            dispatch(updateShouldSubmitImageSearch(true));
        } else {
            dispatch(updateShouldScrollToLastPosition(false));
        }
    };

    /* Ordering columns */
    const { columns, handleColumnReorder } = useGridPreferences({
        allColumns,
        visibleColumns,
        columnsWidths,
        onColumnReorder: (value) => dispatch(updateColumnOrderGrid(value)),
        saveColumnReorder: (value) => saveGridSettings(value)
    });

    const saveGridSettings = (value: ColumnSettings[]) => {
        dispatch(
            SaveUserImagingGridSetting({
                GridColumns: value.filter((item: ColumnSettings) => {
                    if (item.Code !== 'selected' && item.Code !== 'Actions') {
                        return item;
                    }
                    return null;
                }),
                area: country?.Code === 'us' ? gridSettingsConstants['us'] : gridSettingsConstants['ca']
            })
        );
    };

    /* Retrieve imaging model and then imaging documents */
    useEffect(() => {
        if (shouldInitModel && !isLoading) {
            dispatch(GetImagingModel());
        }
        if (shouldSubmitImageSearch && translations?.Phrases && Object.entries(payloadToGetDocs).length) {
            // Cancel any previous active documents requests
            if (isLoading) cancelPortalRequests();

            if (applyClicked) dispatch(updateClientSelectionPayload(clientSelection));
            dispatch(GetImagingDocuments(payloadToGetDocs));
            dispatch(updateShouldSubmitImageSearch(false));
            resetPayloadToGetDocs();
        }
    }, [
        payloadToGetDocs,
        shouldInitModel,
        isLoading,
        shouldSubmitImageSearch,
        translations?.Phrases,
        applyClicked,
        clientSelection,
        resetPayloadToGetDocs,
        dispatch
    ]);

    /* Get more data if the grid has less entries than minimum to show scroll and less than total entries */
    useEffect(() => {
        if (
            model.SearchResults &&
            model.SearchResults.length &&
            model.TotalEntries &&
            model.SearchResults.length &&
            model?.SearchResults.length < model.TotalEntries &&
            model?.SearchResults.length < 11
        ) {
            dispatch(updateShouldSubmitImageSearch(true));
        }
    }, [model.SearchResults, model.TotalEntries, dispatch]);

    /* Resets after client chooser changes client */
    useEffect(() => {
        if (applyClicked) {
            // reset TotalLoadedResults to get new client numbers from 0
            resetSkip();
            dispatch(resetSelectedEntries());
            dispatch(resetSubmitedSearchKeywords());
            dispatch(updateScrollPosition(0));
            dispatch(resetSearchResults());
            dispatch(resetTotalLoadedResults());
            dispatch(resetDateRange());
            dispatch(resetDocumentsDropdownFilters());
            dispatch(resetMOTFilter());
            dispatch(resetDateRangeAndTypeFilters());
            dispatch(resetImagingGridSettings());
            dispatch(updateShouldInitModel(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyClicked, dispatch]);

    /* Initial page load resets */
    useEffect(() => {
        dispatch(resetDateRange());
        dispatch(resetSelectedEntries());
        dispatch(resetDocumentsDropdownFilters());
        dispatch(resetDateRangeAndTypeFilters());
        dispatch(resetImagingGridSettings());
        dispatch(updateShouldInitModel(true));
        dispatch(removeViewImagesData());
    }, [dispatch]);

    useEffect(() => {
        if (modalView) {
            dispatch(setDocumentDetailModalView(true));
        } else {
            dispatch(setDocumentDetailModalView(false));
        }
    }, [modalView, dispatch]);
    useEffect(() => {
        if (data.length) {
            setRowsInGrid(data.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (ellipsisData.entryNo) {
            setCurrentRow(ellipsisData.entryNo);
        } else if (modalView) {
            setCurrentRow(modalView);
        }
    }, [ellipsisData.entryNo, modalView]);

    /* Header with drag icon */
    const customHeader = useCallback(
        (props: any) => {
            return props.field === 'selected' ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox value={headerCheckbox} onChange={handleHeaderCheckbox} />
                </div>
            ) : (
                <div className='d-flex justify-content-between grabbable' onClick={props.onClick}>
                    <div className='k-column-title'>{props.title}</div>
                    {props.children}
                    <div className='text-lii-text-light'>
                        <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                </div>
            );
        },
        [headerCheckbox, handleHeaderCheckbox]
    );

    const handleCheckbox = useCallback(
        (event: CheckboxChangeEvent) => {
            // when user click on row, modal will be displayed, except of checkbox click
            setModalView(null);
            if (!event.target.name) return;

            dispatch(updateSelection(event.target.name));
        },
        [dispatch]
    );

    /* Grid Components */
    const formattedCell = (props: GridCellProps) => (
        <FormattedGridCell
            {...props}
            country={country ? country.Code : 'us'}
            selectedEntries={selectedEntries}
            modalView={modalView}
            checkboxChange={handleCheckbox}
            setModalView={setModalView}
            setEllipsisCompareView={() => setCompareModalView('ellipsis')}
        />
    );

    const calculateColumnWidth = (columnCode: string, columnWidth: number) => {
        const width = setWidth(columnsWidths?.find((item) => item.Code === columnCode)?.Width);
        return columnWidth > width ? columnWidth : width;
    };

    const viewButtonClick = () => {
        const formattedSelection = selectedEntries.map((entry) => {
            const documents = data?.find((val) => val.hasOwnProperty(entry))?.[entry];
            return {
                entry: entry || '',
                documents:
                    documents?.map((d) => ({
                        _metadataFormat: d._metadataFormat || '',
                        _fileS3Path: d._fileS3Path || '',
                        _imageDate: d._imageDate || '',
                        secondaryDescription: d?.USDOCUMENTTYPE ?? d?.LIDOCUMENTID,
                        version: d.LIVERSION
                    })) || []
            };
        });

        dispatch(addViewImagesData({ viewImages: formattedSelection, openedLocation: 'documentsGrid' }));
    };

    const downloadModalDataObject = () => {
        let entries: string[] = [];

        if (selectedEntries.length) {
            entries = selectedEntries;
        } else if (currentRow) {
            entries = [currentRow];
        }

        return {
            compareModalView,
            downloadModalView,
            selectedEntries: entries,
            data,
            country,
            modalView: entries[0] ?? '',
            downloadModalData
        };
    };

    const getSelectedDocumentsForDownloadModal = () => {
        if (downloadModalView === 'documentsGrid' && selectedEntries.length) {
            return prepareFilesForDownload(selectedEntries, imagingData);
        } else if (downloadModalView === 'documentsGrid' && !selectedEntries.length && currentRow) {
            return prepareFilesForDownload([currentRow], imagingData);
        } else {
            return downloadModalData;
        }
    };

    const isFooterDisplayed = !compareModalView && selectedEntries.length > 0 && viewImages?.length === 0;

    return (
        <>
            {!compareModalView && !viewImages.length && !isGridConfigLoading && columns && !noResults && !error && !isCancelled && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <Grid
                            ref={gridRef}
                            className='flex-fill'
                            style={{ height: '63vh', paddingBottom: isFooterDisplayed ? 67 : 0 }}
                            rowHeight={40}
                            data={data}
                            pageSize={100}
                            total={data.length}
                            skip={skip}
                            onScroll={scrollHandler}
                            onPageChange={pageChange}
                            fixedScroll={true}
                            dataItemKey={'id'}
                            reorderable={!areColumnsLoading && !disableDrag ? true : false}
                            onColumnReorder={handleColumnReorder}
                        >
                            <Column field={SELECTED_FIELD} cell={formattedCell} headerCell={customHeader} width='50px' orderIndex={0} />
                            {columns?.map((column) => (
                                <Column
                                    key={column.Code}
                                    field={column.Code}
                                    title={translate(column.HeaderLabel)}
                                    headerCell={customHeader}
                                    sortable={column.IsSortable}
                                    cell={formattedCell}
                                    width={column.IsVisible ? calculateColumnWidth(column.Code, column.Width) : 0}
                                    orderIndex={column.Order}
                                />
                            ))}
                            <Column
                                field='Actions'
                                title={' '}
                                headerCell={''}
                                cell={formattedCell}
                                sortable={false}
                                resizable={false}
                                locked
                                width='50%'
                                orderIndex={columns.length}
                            />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            )}

            {isFooterDisplayed && (
                <DocumentsFooter
                    buttonArray={ButtonArray}
                    amountEntries={selectedEntries.length + ''}
                    amountSelected={prepareFilesForDownload(selectedEntries, imagingData)?.length + ''}
                    downloadButtonParam={'documentsGrid'}
                    downloadButtonClick={setDownloadModalView}
                    viewButtonClick={viewButtonClick}
                    compareButtonClick={() => setCompareModalView('documentsGrid')}
                />
            )}

            {downloadModalView && (
                <DownloadModal
                    selectedDocuments={getSelectedDocumentsForDownloadModal()}
                    closeModal={setDownloadModalView}
                    formededObject={modelPreviewObject(downloadModalDataObject())}
                    selectedCountry={country?.Code}
                    destructuredArrayForDownload={downloadModalDataObject()}
                />
            )}

            {modalView && (
                <DocumentDetailsModal
                    modalView={modalView}
                    topPosition={documentDetailsModalTopPosition}
                    setModalView={setModalView}
                    downloadButtonClick={setDownloadModalView}
                    setDownloadModalData={setDownloadModalData}
                    setDocumentDetailsCompareView={() => setCompareModalView('documentDetailModal')}
                />
            )}
            {compareModalView && (
                <DocumentCompareModal
                    isClearances={false}
                    arrayOfFiles={getArrayDestructured(
                        {
                            compareModalView,
                            downloadModalView,
                            selectedEntries,
                            data,
                            country,
                            modalView,
                            downloadModalData
                        },
                        ellipsisData.entryNo!
                    )}
                    setCompareModalView={() => setCompareModalView(null)}
                    entriesCount={
                        compareModalView === 'ellipsis' || compareModalView === 'documentDetailModal' ? '1' : selectedEntries.length + ''
                    }
                    documentsCount={
                        compareModalView === 'ellipsis'
                            ? prepareFilesForDownload([ellipsisData.entryNo!], imagingData)?.length + ''
                            : compareModalView === 'documentDetailModal'
                            ? downloadModalData.length + ''
                            : prepareFilesForDownload(selectedEntries, imagingData)?.length + ''
                    }
                    downloadButtonClick={setDownloadModalView}
                />
            )}
            {/* Initial loading of the grid */}
            {((!model?.SearchResults?.length && !columns && !error) ||
                (!model?.SearchResults?.length && !columns && !error && isGridConfigLoading) ||
                isCancelled) && (
                <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                    <Spinner />
                </div>
            )}

            {/* No search results or data */}
            {noResults && !isLoading && !isGridConfigLoading && !error && (
                <NoDocumentsData
                    countrySelected={country?.Code}
                    numbersSearched={submitedSearchKeywords.map((k) => ({ Number: k }))}
                    submitedSearchKeywordsLength={submitedSearchKeywords.length}
                />
            )}

            {/* Loading when grid has data */}
            {isLoading && columns && <GridLoadingOverlay />}

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}

            <DocumentViewOverlay setDownloadModalView={setDownloadModalView} isClearances={false} />
        </>
    );
};

export default React.memo(DocumentsGrid);
