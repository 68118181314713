import useTranslation from '../../../portal/common/hooks/useTranslation';

interface ClientsAndPermissionsTableHeadingProps {
    adminRole: string | null | undefined;
    featureName: string;
    title: string;
    onToggleSelectAll: (options: { title: string; featureName: string }) => void;
}

const ClientsAndPermissionsTableHeading: React.FC<ClientsAndPermissionsTableHeadingProps> = ({
    adminRole,
    featureName,
    title,
    onToggleSelectAll
}) => {
    const translate = useTranslation();

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='text-center pb-1'>
                {/* {props.title} */}
                {adminRole === 'CentralAdmin' && featureName === 'My Livingston Portal' ? 'Clearances' : featureName}
            </div>
            <div
                className='table-column-select-all'
                onClick={() => {
                    onToggleSelectAll({ title, featureName });
                }}
            >
                {translate('SelectDeselectAll_Label')}
            </div>
        </div>
    );
};

export default ClientsAndPermissionsTableHeading;
