import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { SystemAdmin } from '../../../constants/mlp-constants';
import * as actionCreators from '../../../store/actions/index';
import SearchUserBox from '../SearchUser/SearchUserBox';

class SearchUserForm extends Component {
    componentDidMount() {
        this.props.onInitTranslations('SystemAdmin.ManageUser.Index');
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    formatResultBuilder = (i) => {
        const address = i.Address || '';
        const addressArray = address.split(',');
        let ContactAddress = <div></div>;
        if (addressArray.length > 1) {
            ContactAddress = (
                <div className='data-row'>
                    <div className='result-label'> Address: </div>
                    <div className='result-value'>
                        <div>{addressArray[0]}</div>
                        <div>
                            {addressArray[1]}, {addressArray[2]}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className='user-search-results'>
                <div>
                    <strong> {i.FullName} </strong>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Email:</div>
                    <div className='result-value'>{i.Email}</div>
                    <div className='result-label'>Phone:</div>
                    <div className='result-value'>{i.Phone}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Company:</div>
                    <div className='result-value'>{i.CompanyName}</div>
                    <div className='result-label'> ContactId: </div>
                    <div className='result-value'>{i.SalesforceId} </div>
                </div>
                {ContactAddress}
            </div>
        );
    };

    selectUser = (item) => {
        this.props.history.push({
            pathname: SystemAdmin.GET_USER_MANAGE_URL,
            search: '?userId=' + item.UserId.toString()
        });
    };

    render() {
        if (this.props.translations.PhraseGroupCode === 'SystemAdmin.ManageUser.Index') {
            const pageContent = (
                <div>
                    <SearchUserBox
                        url='/ManageUser/SearchUser'
                        formatResultBuilder={this.formatResultBuilder}
                        onSelectItem={this.selectUser}
                    />
                </div>
            );

            return (
                <div>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                    </Link>

                                    <i className='fa fa-caret-right'></i>
                                    <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                                </nav>
                                <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='search-user-form' className='w-100'>
                                    <div className='col-sm-10'>
                                        <h4>Search Users</h4>
                                        {pageContent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchUserForm));
