import { faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Badge from '../../../../portal/common/features/Badge';
import Button from '../../../../portal/common/features/Button';
import Tooltip from '../../../../portal/common/features/Tooltip/Tooltip';
import ModalWrapper from '../../../../portal/common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ContactsModals } from '../../../../portal/common/models/ContactsModals';
import { TeamMemberStatusLabels } from '../../../common/constants/constants-team';
import { EditTeamMemberSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

const UserDetailsNonEditable = () => {
    const initialModals: ContactsModals = {
        add: false,
        cancelAdd: false,
        remove: false,
        edit: false,
        confirm: false
    };
    const translate = useTranslation();
    const { data } = useSelector<RootState, EditTeamMemberSlice>((state) => state.editTeamMember);
    const [modal, setModal] = useState<ContactsModals>(initialModals);

    const toggleModal = useCallback(
        (prop: keyof ContactsModals | Array<keyof ContactsModals>, next?: boolean) => {
            if (typeof prop === 'string') {
                setModal({ ...modal, [prop]: next !== undefined ? next : !modal[prop] });
            } else {
                setModal(Object.assign({}, modal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [modal]
    );

    if (!data?.Users?.[0]) return <></>;

    return (
        <div className='container-fluid p-0'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                <div className='col order-md-3'>
                    <div className='form-group'>
                        <label>{translate('Status_Label')}</label>
                        <br />
                        <div className='d-flex align-items-center'>
                            {data?.Users?.[0]?.IsActive ? (
                                <Badge type='success'>{data?.Users?.[0]?.Status}</Badge>
                            ) : (
                                <Badge type='danger'>
                                    {translate(TeamMemberStatusLabels[data?.Users?.[0]?.Status as keyof typeof TeamMemberStatusLabels])}
                                    {(data?.Users?.[0].Status ?? '').includes('Pending') ? (
                                        <Tooltip label={translate('PendingUserTooltip_Label')}>
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                onClick={() => toggleModal('confirm', true)}
                                                className='ml-2'
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Tooltip>
                                    ) : null}
                                </Badge>
                            )}
                        </div>
                    </div>
                </div>

                <div className='col order-md-1'>
                    <div className='form-group'>
                        <label>{translate('FirstName_Label')}</label>
                        <p>{data?.Users?.[0]?.FirstName}</p>
                    </div>
                </div>

                <div className='col order-md-2'>
                    <div className='form-group'>
                        <label>{translate('LastName_Label')}</label>
                        <p>{data?.Users?.[0]?.LastName}</p>
                    </div>
                </div>

                <div className='col order-md-4'>
                    <div className='form-group'>
                        <label>{translate('Email_Label')}</label>
                        <p>{data?.Users?.[0]?.Email}</p>
                    </div>
                </div>

                <div className='col order-md-5'>
                    <div className='form-group'>
                        <label>{translate('PhoneNumber_Label')}</label>
                        <p>{data?.Users?.[0]?.Phone}</p>
                    </div>
                </div>

                <div className='col order-md-6'>
                    <div className='form-group'>
                        <label>{translate('MobileNumberWithoutOptional_Label')}</label>
                        <p>{data?.Users?.[0]?.MobileNumber}</p>
                    </div>
                </div>

                <div className='col order-md-7'>
                    <div className='form-group'>
                        <label>{translate('Company_Label')}</label>
                        <p>{data?.Users?.[0]?.CompanyName}</p>
                    </div>
                </div>

                <div className='col order-md-8'>
                    <div className='form-group'>
                        <label>{translate('UserRole_Label')}</label>
                        <p>{data?.Users?.[0]?.Roles?.find((role) => role.IsSelected)?.Text}</p>
                    </div>
                </div>

                <div className='col order-md-9'>
                    <div className='form-group'>
                        <label>{translate('ExpirationDateWithoutOptional_Label')}</label>
                        <p>
                            {data?.Users?.[0]?.ExpirationDate
                                ? moment(data?.Users?.[0]?.ExpirationDate).format('MM-DD-YYYY')
                                : translate('Never_Label')}
                        </p>
                    </div>
                </div>
            </div>
            {modal.confirm && (
                <ModalWrapper onEscape={() => toggleModal('confirm', false)}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                        <div className='modal-content'>
                            {/* Heading */}
                            <div className='modal-header '>
                                <h3 className='modal-title'>
                                    {translate(TeamMemberStatusLabels[data?.Users?.[0]?.Status as keyof typeof TeamMemberStatusLabels])}
                                </h3>
                                <button
                                    type='button'
                                    className='modal-close btn btn-tertiary btn-lg'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                    onClick={() => toggleModal('confirm', false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                            {/* Body */}
                            <div className='modal-body'>
                                <p className='note mb-3 notification-message'>{translate('PendingReason_Label')}</p>

                                {data && data.Users && data?.Users[0].PendingSteps
                                    ? data?.Users[0].PendingSteps?.map((item, index) => {
                                          return (
                                              <p key={index} className='note mb-0 notification-message'>
                                                  {translate(
                                                      item.PendingReason,
                                                      data && data.Users && data?.Users[0].Email,
                                                      moment(item.DateStarted).format('MM/DD/YYYY')
                                                  )}
                                              </p>
                                          );
                                      })
                                    : 'Other reason for pending status'}
                            </div>

                            {/* Footer */}
                            <div className='modal-footer'>
                                <Button variant='secondary' size='medium' onClick={() => toggleModal('confirm', false)}>
                                    {translate('Close_Label')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalWrapper>
            )}
        </div>
    );
};

export default UserDetailsNonEditable;
