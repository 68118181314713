import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, useEffect, useRef } from 'react';

import './styles.css';

interface Props {
    keyword: string;
    index: number;
    searchKeywords: string[];
    remove: (keyword: string) => void;
    setSearchKeywords: Dispatch<string[]>;
}

const SearchChip: React.FC<Props> = ({ keyword, searchKeywords, setSearchKeywords, remove, index }) => {
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.innerText = keyword;
        }
    }, [keyword]);

    const handleChangeValue = (e: React.FocusEvent<HTMLSpanElement, Element>) => {
        setSearchKeywords(searchKeywords.map((k, i) => (i === index ? e.currentTarget.innerText : k)));
    };

    return (
        <div className={'search-chip'}>
            <span
                id='search_chip_input_span'
                ref={spanRef}
                role='textbox'
                contentEditable='true'
                suppressContentEditableWarning={true}
                onBlur={handleChangeValue}
                onKeyDown={(e) => {
                    // Prevent special characters from being added
                    if (/[^a-zA-Z0-9,_\s-/]/.test(e.key) || e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                {keyword}
            </span>

            <span className='pl-2' onClick={() => remove(keyword)}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </div>
    );
};

export default SearchChip;
