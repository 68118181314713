import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { REACT_APP_ASSETS_URL } from '../../constants/constants-portal';
import { HydrationSlice } from '../../models/ReduxSlices';
import Button from '../Button';
import Tooltip from '../Tooltip/Tooltip';

interface EmptyStateProps {
    onAdd: () => void;
    descriptionLabel: string;
    addButtonLabel: React.ReactNode;
    disabled?: boolean;
}

const EmptyState = React.forwardRef<HTMLButtonElement, EmptyStateProps>(({ onAdd, descriptionLabel, addButtonLabel, disabled }, ref) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);

    return (
        <div className='container-fluid mt-4 mb-4 d-flex flex-column justify-content-center align-items-center flex-fill'>
            <img
                className='image-150px'
                src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/objects/object-inbox-zero.svg`}
                alt='Empty state'
            />
            <h3>{descriptionLabel}</h3>
            <Button variant='primary' size='medium' ref={ref} disabled={disabled} onClick={() => !user?.InternalUser && onAdd()}>
                <FontAwesomeIcon icon={faUserPlus} className='mr-1' />{' '}
                {!user?.InternalUser ? addButtonLabel : <Tooltip label='Disabled for LII users.'>{addButtonLabel}</Tooltip>}
            </Button>
        </div>
    );
});

export default EmptyState;
