import $ from 'jquery';
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import DatePickerModal from '../../common/DateTimePicker/DatePickerModal';
import DropDown from '../../common/Dropdown/Dropdown';
import SearchBox from '../../common/Searchbox/Searchbox';
import SubmitButton from '../../common/SubmitButton/SubmitButton';
import TextBox from '../../common/Textbox/Textbox';
import { teamManagement as Constants, userPermission } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import { setValueByPath } from '../../utils/mlp-utils';
import ClientServiceGrid from './ClientServiceGrid';
import StatusChangeLink from './StatusChangeLink';
import TeamManagementModal from './TeamManagementModal';

class TeamManagementDetailsForm extends Component {
    componentDidMount() {
        const value = this.props.location.search !== '' ? this.props.location.search : this.props.location.pathname;
        const translationUrl = this.findTranslationUrl(this.props.location.pathname);
        const userId = this.findUserId(value);

        if (translationUrl.includes(this.determineComponent(Constants.NEW_TEAM_MEMBER_LINK))) {
            this.props.onInitNewTeamMember();
        }
        if (translationUrl.includes(this.determineComponent(Constants.CLONE_TEAM_MEMBER_LINK))) {
            this.props.onInitCloneTeamMember(userId);
        }
        if (translationUrl.includes(this.determineComponent(Constants.TEAM_MEMBER_DETAILS_LINK))) {
            this.props.onInitTeamMemberDetails(userId);
        }
        this.props.onInitTranslations(translationUrl);
        this.props.onInitUserPermission(userPermission.CENTRALDASHBOARD_ACCESS);
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    findTranslationUrl = (path) => {
        path = path.includes('?') ? path.substring(0, path.indexOf('?')) : path;
        return path.substring(1, path.length).replaceAll('/', '.');
    };

    findUserId = (search) => {
        return search.substring(search.indexOf('=') + 1, search.length);
    };

    determineComponent = (link) => {
        return link
            .substring(1, link.length)
            .split('/')
            .find((x) => x.indexOf('TeamManagement') === -1);
    };

    componentWillUnmount() {
        this.props.onClearState();
        this.props.onResetTeamMemberDetails();
    }

    validateForm = () => {
        let validationUrl = '';
        const translationUrl = this.findTranslationUrl(this.props.location.pathname);

        if (
            translationUrl.includes(this.determineComponent(Constants.NEW_TEAM_MEMBER_LINK)) ||
            translationUrl.includes(this.determineComponent(Constants.CLONE_TEAM_MEMBER_LINK))
        ) {
            validationUrl = Constants.NEW_TEAM_MEMBER_VALIDATION_URL;
        }

        if (translationUrl.includes(this.determineComponent(Constants.TEAM_MEMBER_DETAILS_LINK))) {
            validationUrl = Constants.EDIT_TEAM_MEMBER_VALIDATION_URL;
        }
        axios
            .post(validationUrl, this.props.teamDetailsData)
            .then((response) => {
                let validationMessages = {};
                const json = JSON.parse(response.data);
                for (let i in json) {
                    setValueByPath(validationMessages, i, json[i]);
                }
                this.props.onSetValidationMessages(validationMessages);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        this.props.history.push('/BasicError');
                    }
                }
            });
    };

    addUser = () => {
        this.props.onAddNewUser();
    };

    removeUser = (index) => {
        this.props.onRemoveUser(index);
    };

    closeErrorModal = () => {
        this.props.setError(null);
    };

    statusDisplay = (data, teamMember, index, namePrefix, val) => {
        if (!data.IsMultiUserAllowed) {
            return (
                <div className='col-sm-3'>
                    <TextBox
                        value={teamMember.Status}
                        label={this.props.translations.Phrases['Status_Label']}
                        name={namePrefix + 'Status'}
                        valMsg={val.Users[index] ? this.props.translations.Phrases[[val.Users[index].Status]] : ''}
                        readonly={true}
                    />
                </div>
            );
        }
    };

    statusLink = (data, teamMember, index, canEdit) => {
        if (!data.IsMultiUserAllowed) {
            return (
                <div className='col-sm-3'>
                    <div className='input-container no-border status-link'>
                        <StatusChangeLink
                            cancelAction={this.props.onCancelUser}
                            reactivateAction={this.props.onReactivateUser}
                            teamMember={teamMember}
                            memberIndex={index}
                            canDelete={canEdit && !teamMember.IsLocked}
                        />
                    </div>
                </div>
            );
        }
    };

    updateUser = (user) => {
        const name = $("input[name*='Email']")[0].name;
        const nameArray = name.split('.');
        const index = nameArray[1];

        if (
            this.props.teamDetailsData.Users &&
            this.props.teamDetailsData.Users[index].Email === user.Email &&
            this.props.teamDetailsData.Users[index].SalesforceId === user.SalesforceId
        ) {
            return '';
        }

        this.props.onSelectUser(index, user);
        return '';
    };

    updateEmail = (index, email) => {
        this.props.onUpdateEmail(index, email);
    };

    createSubmitButtonUrl = () => {
        const translationUrl = this.findTranslationUrl(this.props.location.pathname);
        if (
            translationUrl.includes(this.determineComponent(Constants.NEW_TEAM_MEMBER_LINK)) ||
            translationUrl.includes(this.determineComponent(Constants.CLONE_TEAM_MEMBER_LINK))
        ) {
            return Constants.NEW_TEAM_MEMBER_URL;
        }
        if (translationUrl.includes(this.determineComponent(Constants.TEAM_MEMBER_DETAILS_LINK))) {
            return Constants.EDIT_TEAM_MEMBER_URL;
        }
    };

    dashboardBreadcrumb = () => {
        if (this.props.user.hasCentralPermission) {
            return (
                <Link to='/Clearances' className='breadcrumb-parent'>
                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                </Link>
            );
        } else {
            return (
                <Link to='/Dashboard' className='breadcrumb-parent'>
                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                </Link>
            );
        }
    };

    render() {
        const data = this.props.teamDetailsData;
        const val = this.props.validationMessages;
        const serviceList = data.ParentServices;
        const canEdit = data.UserRole === Constants.INTERNALCLIENTADMIN || data.UserRole === Constants.CLIENTADMIN;
        const submitButtonUrl = this.createSubmitButtonUrl();
        const clientGrids = serviceList.map((s, index) => {
            return (
                <ClientServiceGrid
                    key={index + s.Number}
                    parentService={s}
                    parentIndex={index}
                    readonly={!canEdit || data.Users[0].IsLocked || data.UserHasPendingChanges}
                />
            );
        });

        const submitButton = canEdit ? (
            <div className='col-sm-12 text-right'>
                {
                    <SubmitButton
                        label={this.props.translations.Phrases['Submit_Button']}
                        postActionUrl={submitButtonUrl}
                        postAction={this.props.onSetValidationMessagesSubmit}
                        model={data}
                        onSuccessfulSubmit={{
                            backToPath: '/Dashboard',
                            backToLabel: 'BackToDashboard_Label',
                            phraseGroup: 'TeamManagement.TeamManagement.SuccessfulSubmit'
                        }}
                    />
                }
            </div>
        ) : (
            ''
        );

        const userIsLockedMessage = <div className='error message'>{this.props.translations.Phrases['UserIsLocked_Label']}</div>;
        const userHasPendingChangesMessage = (
            <div className='warning message'>{this.props.translations.Phrases['UserHasPendingChanges_Label']}</div>
        );

        let userIsLocked = false;
        const users = data.Users.map((u, index) => {
            const namePrefix = 'Users.' + index + '.';
            userIsLocked = u.IsLocked;
            return (
                <div className='col-12' key={namePrefix}>
                    {data.IsMultiUserAllowed && data.Users.length > 1 ? (
                        <div className='text-right'>
                            <span className='btn btn-link' onClick={this.removeUser.bind(this, index)}>
                                {this.props.translations.Phrases['Remove_Label']}
                            </span>
                        </div>
                    ) : (
                        ''
                    )}
                    {userIsLocked ? userIsLockedMessage : ''}

                    <div className='row'>
                        <div className='col-sm-3'>
                            <TextBox
                                value={u.FirstName ? u.FirstName : ''}
                                label={this.props.translations.Phrases['FirstName_Label']}
                                name={namePrefix + 'FirstName'}
                                valMsg={val.Users[index] ? this.props.translations.Phrases[[val.Users[index].FirstName]] : ''}
                                readonly={!canEdit || userIsLocked}
                                onValueChanged={this.props.onModelChanged}
                                validate={this.validateForm}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <TextBox
                                value={u.LastName ? u.LastName : ''}
                                label={this.props.translations.Phrases['LastName_Label']}
                                name={namePrefix + 'LastName'}
                                valMsg={val.Users[index] ? this.props.translations.Phrases[val.Users[index].LastName] : ''}
                                readonly={!canEdit || userIsLocked}
                                onValueChanged={this.props.onModelChanged}
                                validate={this.validateForm}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <DropDown
                                name={namePrefix + 'Roles'}
                                options={u.Roles}
                                label={this.props.translations.Phrases['UserRole_Label']}
                                valMsg={val.Users[index] ? this.props.translations.Phrases[[val.Users[index].Roles]] : ''}
                                readonly={!canEdit || userIsLocked}
                                selectedValue={u.Roles[0].IsSelected ? data.Roles[0].Text : data.Roles[1].Text}
                                onItemChanged={this.props.onChangeUserRole}
                            />
                        </div>
                        {this.statusDisplay(data, u, index, namePrefix, val)}
                    </div>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <SearchBox
                                value={u.Email ? u.Email : ''}
                                label={this.props.translations.Phrases['Email_Label']}
                                formatResults={this.updateUser}
                                url={Constants.GET_SALESFORCE_CONTACTS_LINK}
                                name={namePrefix + 'Email'}
                                minChars={4}
                                valMsg={val.Users[index] ? this.props.translations.Phrases[[val.Users[index].Email]] : ''}
                                onValueChanged={this.props.onModelChanged}
                                updateProperty={this.updateEmail.bind(this, index)}
                                keepSearchText={true}
                                readonly={this.props.isEditMode || !canEdit || userIsLocked}
                                noCloseButton={true}
                                validate={this.validateForm}
                            ></SearchBox>
                        </div>
                        <div className='col-sm-3'>
                            <TextBox
                                value={u.Phone ? u.Phone : ''}
                                label={this.props.translations.Phrases['Phone_Label']}
                                name={namePrefix + 'Phone'}
                                valMsg={val.Users[index] ? this.props.translations.Phrases[val.Users[index].Phone] : ''}
                                readonly={!canEdit || userIsLocked}
                                placeholder={'(123) 456-7890 x123456'}
                                inputMask='(000) 000-0000 x000000'
                                onValueChanged={this.props.onModelChanged}
                                validate={this.validateForm}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <TextBox
                                value={u.CompanyName ? u.CompanyName : ''}
                                label={this.props.translations.Phrases['CompanyName_Label']}
                                name={namePrefix + 'CompanyName'}
                                valMsg={val.Users[index] ? this.props.translations.Phrases[val.Users[index].CompanyName] : ''}
                                readonly={!canEdit || userIsLocked}
                                onValueChanged={this.props.onModelChanged}
                                validate={this.validateForm}
                            />
                        </div>
                        <div className='col-sm-3'>
                            <DatePickerModal
                                startDate={new Date()}
                                name={namePrefix + 'ExpirationDate'}
                                readonly={!canEdit || userIsLocked}
                                linkText={this.props.translations.Phrases['SetAccessExpirationDate_Label']}
                                dateLabel={this.props.translations.Phrases['ExpirationDate_Label']}
                                date={u.ExpirationDate ? u.ExpirationDate : ''}
                                noBorder
                                onDateChanged={this.props.onModelChanged}
                                valMsg={val.Users[index] ? this.props.translations.Phrases[val.Users[index].ExpirationDate] : ''}
                            />
                        </div>
                        {this.statusLink(data, u, index, canEdit)}
                    </div>
                    <hr />
                </div>
            );
        });

        const addUserButton =
            data.IsMultiUserAllowed && data.Users.length < 20 ? (
                <div className='col-12'>
                    <span onClick={this.addUser} className='btn btn-link'>
                        {this.props.translations.Phrases['AddTeamMember_Label']}
                    </span>
                </div>
            ) : null;

        return (
            <div className='page-wrapper'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-top-row'>
                    <div className='page-header-section'>
                        <nav className='mlp-breadcrumb'>
                            {this.dashboardBreadcrumb()}
                            <i className='fa fa-caret-right'></i>
                            <Link to='/TeamManagement' className='breadcrumb-parent'>
                                {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                            </Link>
                            <i className='fa fa-caret-right'></i>
                            <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                        </nav>
                    </div>
                    <div className='header-align'>
                        <h1 className='float-left bold-title'>
                            <span>
                                {this.props.translations.Phrases['Page_Header']}
                                &nbsp;
                            </span>
                        </h1>
                    </div>
                </div>

                <div className='content-wrapper'>
                    <div className='row page-content' id='edit-team-member-form'>
                        <TeamManagementModal
                            isOpen={data.Error !== null}
                            onRequestClose={this.closeErrorModal}
                            yesOption={this.props.translations.Phrases['Close_Label']}
                            title={this.props.translations.Phrases['Error_Label']}
                        >
                            <p> {data.Error} </p>
                        </TeamManagementModal>
                        {users}
                        {addUserButton}

                        <div className='col-12'>
                            <h4 className='bold'>{this.props.translations.Phrases['ClientsAndPermissions_Label']}</h4>
                        </div>

                        {data.UserHasPendingChanges ? userHasPendingChangesMessage : ''}

                        <div className='validation-text'> {val.AllParentServices} </div>
                        <div className='col-12'>{clientGrids}</div>

                        {!userIsLocked ? submitButton : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        teamDetailsData: state.teamManagementDetails.model,
        validationMessages: state.teamManagementDetails.ValidationMessages,
        translations: state.translations.model,
        user: state.user.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onInitTeamMemberDetails: (userId) => dispatch(actions.teamMemberDetails(userId)),
        onInitCloneTeamMember: (userId) => dispatch(actions.cloneTeamMember(userId)),
        onInitNewTeamMember: () => dispatch(actions.initNewTeamMember()),
        onChangeUserRole: (newIndex, oldIndex, newValue, userRoleName) =>
            dispatch(actions.changeUserRole(newIndex, oldIndex, newValue, userRoleName)),
        onSelectUser: (user, index) => dispatch(actions.selectUser(user, index)),
        onAddNewUser: () => dispatch(actions.addNewUser()),
        onRemoveUser: (index) => dispatch(actions.removeUser(index)),
        onCancelUser: (userId) => dispatch(actions.cancelUserDetails(userId)),
        onReactivateUser: (userId) => dispatch(actions.reactivateUserDetails(userId)),
        onUpdateEmail: (index, email) => dispatch(actions.updateEmail(index, email)),
        onSetValidationMessages: (validationMessages) => dispatch(actions.setValidationMessages(validationMessages)),
        onSetValidationMessagesSubmit: (validationMessages) => dispatch(actions.setValidationMessagesSubmit(validationMessages)),
        onClearState: () => dispatch(actions.clearState()),
        setError: (value) => dispatch(actions.setTeamMemberDetailsError(value)),
        onModelChanged: (value, key) => dispatch(actions.modelChange(value, key)),
        onInitUserPermission: (permissionName) => dispatch(actions.initUserPermission(permissionName)),
        onResetTeamMemberDetails: ()=> dispatch(actions.resetTeamMemberDetails())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamManagementDetailsForm));
