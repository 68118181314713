import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import BatchEditDropDown from '../../SystemAdmin/BatchEditUsers/BatchEditDropDown';

class BatchEditGridHeader extends Component {
    toogleFeature = (role, event) => {
        const selected = !role.IsSelected;
        this.props.onToggleRole(role.RoleId, selected);
    };

    toggleStatuses = (status) => {
        this.props.onUpdateAllStatuses(status);
    };

    render() {
        const roles = this.props.vm.map((r, index) => {
            const title = r.IsSelected ? 'NONE' : 'ALL';
            const role = r;
            return (
                <th key={'headerRow-' + index}>
                    <div className='text-center'>{r.Alias}</div>
                    <div className='text-center'>
                        <button className='btn btn-link' onClick={this.toogleFeature.bind(this, role)}>
                            {title}
                        </button>
                    </div>
                </th>
            );
        });

        return (
            <thead>
                <tr>
                    <th>{this.props.translations.Phrases['BatchEditNameHeader_Label']}</th>
                    <th className='batch-edit-dropdown'>
                        <BatchEditDropDown options={this.props.statuses} customAction={this.toggleStatuses} />
                    </th>
                    {roles}
                    <th></th>
                </tr>
            </thead>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onToggleRole: (roleId, selected) => dispatch(actionCreators.toggleRole(roleId, selected)),
        onUpdateAllStatuses: (status) => dispatch(actionCreators.updateAllStatuses(status))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchEditGridHeader);
