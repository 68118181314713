import { PayloadAction } from '@reduxjs/toolkit';
import { SearchFilterSlice } from '../../../portal/common/models/ReduxSlices';
import { createGenericSearchFilterSlice } from '../../../portal/redux/reducers/genericSearchFilterSlice';

const initialState: SearchFilterSlice = {
    searchTerm: ''
};

const searchFilterSlice = createGenericSearchFilterSlice({
    name: 'searchFilter',
    initialState,
    reducers: {
        setSearchFilter(state, action: PayloadAction<string>) {
            state.searchTerm = action.payload;
        },
        clearSearchFilter(state) {
            state.searchTerm = '';
        }
    }
});

export const { setSearchFilter, clearSearchFilter } = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
