import { createSlice } from '@reduxjs/toolkit';
import { HydrationSlice } from '../../common/models/ReduxSlices';
import { GetUserDetails } from '../actions/hydration';
import { GetShipmentDataSource, SaveShipmentDataSource } from '../actions/shipmentDataSource';
import { ChangeLanguageHydration } from '../actions/translations';

const initialState: HydrationSlice = {
    user: null,
    isLoading: false,
    error: undefined,
    isTranslationsLoading: true,
    translationsError: undefined,
    isShipmentDataSourceLoading: false,
    isSaveDataSourceLoading: false,
    shipmentDataSource: ''
};

const hydrationSlice = createSlice({
    name: 'hydration',
    initialState,
    reducers: {
        resetShipmentDataSource(state) {
            state.shipmentDataSource = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserDetails.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetUserDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.Hydration;
            })
            .addCase(GetUserDetails.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(ChangeLanguageHydration.pending, (state, action) => {
                state.isTranslationsLoading = true;
                state.translationsError = undefined;
            })
            .addCase(ChangeLanguageHydration.fulfilled, (state, action) => {
                state.isTranslationsLoading = false;
                if (state.user?.Phrases) {
                    state.user.Phrases = action.payload.PhraseGroup.Phrases;
                }
            })
            .addCase(ChangeLanguageHydration.rejected, (state, action) => {
                state.isTranslationsLoading = false;
                if (typeof action.payload === 'number') {
                    state.translationsError = action.payload;
                } else {
                    state.translationsError = action.error.message;
                }
            })
            //Get Shipment Data Source
            .addCase(GetShipmentDataSource.pending, (state, action) => {
                state.isShipmentDataSourceLoading = true;
                state.error = undefined;
            })
            .addCase(GetShipmentDataSource.fulfilled, (state, action) => {
                state.isShipmentDataSourceLoading = false;
                state.shipmentDataSource = action.payload;
            })
            .addCase(GetShipmentDataSource.rejected, (state, action) => {
                state.isShipmentDataSourceLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            //Save Shipment Data Source
            .addCase(SaveShipmentDataSource.pending, (state, action) => {
                state.isSaveDataSourceLoading = true;
                state.error = undefined;
            })
            .addCase(SaveShipmentDataSource.fulfilled, (state, action) => {
                state.isSaveDataSourceLoading = false;
                state.shipmentDataSource = action.payload;
            })
            .addCase(SaveShipmentDataSource.rejected, (state, action) => {
                state.isSaveDataSourceLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { resetShipmentDataSource } = hydrationSlice.actions;

export default hydrationSlice.reducer;
