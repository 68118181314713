import DOMPurify from 'dompurify';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

class NoticeContentPreview extends Component {

    formatContent = (text) => {
        text = this.urlify(text);
        text = this.mailto(text);
        text = this.lineBreaks(text);
        return text;
    };

    urlify = (text) => {
        let urlRegex = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?/g;
        if (text) {
            return text.replace(urlRegex, (url) => {
                return '<a target="_blank" href="' + url + '">' + url + '</a>';
            });
        }
    };

    mailto = (text) => {
        let emailRegex = /[a-zA-Z_0-9.-]+@[a-zA-Z_0-9.-]+\.\w+/g;
        if (text) {
            return text.replace(emailRegex, (email) => {
                return '<a target="_top" href="mailto:' + email + '">' + email + '</a>';
            });
        }
    };

    lineBreaks = (text) => {
        if (text) {
            text = text.replace(/\n/g, '<br />');
        } else {
            text = '';
        }
        return text;
    };

    setDisplayData=()=>{
        const newData = {
            NoticesTitle:'',
            NoticesContent:'',
            StartDate:'',
            EndDate:'',
            StartTime:'',
            EndTime:'',
            StartTimeAmPm:'',
            EndTimeAmPm:'',
            ServiceProvider:'',
            NoticeType:'',
            Language:''
        };
        
        if(this.props.data){
            const {data} = this.props;
            const DATEFORMAT = this.props.translations.Phrases['Date_Format'];
            newData.NoticesTitle = data.Title;
            newData.NoticesContent = this.formatContent(data.Content);
            newData.StartDate = moment(data.EffectiveDatePartFrom).isValid() ? moment(data.EffectiveDatePartFrom).format(DATEFORMAT) : '';
            newData.EndDate = moment(data.EffectiveDatePartTo).isValid() ? moment(data.EffectiveDatePartTo).format(DATEFORMAT) : '';
            newData.StartTime = data.EffectiveTimePartFrom;
            newData.EndTime = data.EffectiveTimePartTo;
            newData.StartTimeAmPm = data.EffectiveTimePartFromAmPm;
            newData.EndTimeAmPm = data.EffectiveTimePartToAmPm;
            newData.ServiceProvider = data.ServiceProvider ?? 'Central';
            newData.NoticeType = data.NoticeType;
            newData.Language = data.Language;
        }

        return newData;
    }

    handleCloseModal=()=>{
        this.props.onCloseNoticePreview();
    }

    render() {
        const data = this.setDisplayData();

        return (
            <div className={this.props.isOpen ? 'modal modal-overlay d-block' : 'modal fade d-none'} role='dialog' aria-hidden='false'>
                <div className='modal-dialog notice-preview-modal'>
                    <div className='modal-content'>
                        <div className='notices-modal-header'>
                            <div className='row'>
                                <div className='col-10'>
                                    <h3>{this.props.translations.Phrases['Notices_Preview_Title_Label']}</h3>
                                </div>
                                <div className='col-2'>
                                    <i className='fa fa-close' onClick={this.handleCloseModal}></i>
                                </div>
                            </div>
                        </div>
                        <div className='modal-body left-align'>
                            <div className='row well'>
                                <div className='col-12 modal-title-section'>{data.NoticesTitle}</div>
                                <div
                                    className='col-12 modal-content-section'
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.NoticesContent) }}
                                ></div>
                            </div>
                            <div className='row'>
                                <div className='col-3 modal-date-section'>
                                    {this.props.translations.Phrases['ManageNotices_TableHeader_StartDate']}
                                </div>
                                <div className='col-3 modal-date-section'>
                                    {this.props.translations.Phrases['ManageNotices_TableHeader_EndDate']}
                                </div>
                                <div className='col-2 modal-date-section'>
                                    {this.props.translations.Phrases['ManageNotices_TableHeader_Application']}
                                </div>
                                <div className='col-2 modal-date-section'>
                                    {this.props.translations.Phrases['ManageNotices_TableHeader_NoticeType']}
                                </div>
                                <div className='col-2 modal-date-section'>
                                    {this.props.translations.Phrases['ManageNotices_TableHeader_Language']}
                                </div>
                                {/* Break */}
                                <div className='col-3'>
                                    {data.StartDate}&nbsp;{data.StartTime}&nbsp;{data.StartTimeAmPm}
                                </div>
                                <div className='col-3'>
                                    {data.EndDate}&nbsp;{data.EndTime}&nbsp;{data.EndTimeAmPm}
                                </div>
                                <div className='col-2'>{data.ServiceProvider}</div>
                                <div className='col-2'>{data.NoticeType}</div>
                                <div className='col-2'>{data.Language}</div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <div className='row'>
                                <div className='col-12'>
                                    <button type='button' className='button blue small' onClick={this.handleCloseModal}>
                                        {this.props.translations.Phrases['ManageNotices_Close']}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.notices.selectedNotice,
        isOpen: state.notices.isNoticePreviewOpen,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseNoticePreview: () => dispatch(actionCreators.closeNoticePreview())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeContentPreview);
