import React from 'react';
import useTranslation from '../../../portal/common/hooks/useTranslation';

interface AlertProps {
    type?: 'success' | 'warning' | 'danger' | 'info' | 'neutral';
    icon: React.ReactNode;
    title: string;
    children: React.ReactNode;
    date: string;
    infoLink?: {
        href: string;
        label: string;
    };
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const Alert: React.FC<AlertProps> = ({ type = 'info', icon, title, date, infoLink, children, onClick }) => {
    const translate = useTranslation();
    let alertType = 'info';

    switch (type) {
        case 'success':
            alertType = 'success';
            break;
        case 'warning':
            alertType = 'warning';
            break;
        case 'danger':
            alertType = 'danger';
            break;
        case 'info':
            alertType = 'info';
            break;
        case 'neutral':
            alertType = 'dark';
            break;
        default:
            break;
    }

    return (
        <div className={`d-flex flex-row align-items-md-center justify-content-between alert alert-${alertType} mb-0 mt-3`} role='alert'>
            <div className='text d-flex mb-3 mb-md-0' style={{ flex: '2 1 0' }}>
                <div className='alert-icon'>
                    <div className='alert-icon'>{icon}</div>
                </div>
                <div>
                    <span className='font-weight-bold'>{title} — </span>
                    {children}
                </div>
            </div>
            <div className='date ml-3' style={{ flex: '0.5 1 0' }}>
                {date}
            </div>
            {infoLink && (
                <a
                    className='text-link font-weight-normal mt-0 ml-3'
                    href={`#${infoLink.href}`}
                    onClick={onClick}
                    style={{ flex: '0.5 1 0', textAlign: 'right' }}
                >
                    {translate(infoLink.label)}
                </a>
            )}
        </div>
    );
};

export default Alert;
