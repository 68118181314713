import { createGenericGridSettingsSlice, genericGridSettingsInitialState } from '../../../portal/redux/reducers/genericGridSettingsSlice';
const contactsGridSettingsSlice = createGenericGridSettingsSlice({
    name: 'contactsGridSettings',
    initialState: genericGridSettingsInitialState,
    reducers: {}
});
export const {
    setPageSelected,
    setDataState,
    setTotalItems,
    setSelectedState,
    setHeaderCheckbox,
    setCurrentDataItems,
    setSort,
    resetDataState,
    resetSelectedState
} = contactsGridSettingsSlice.actions;
export default contactsGridSettingsSlice.reducer;