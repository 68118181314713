import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, useEffect, useRef } from 'react';

import './styles.css';

interface Props {
    keyword: string;
    index: number;
    searchKeywords: string[];
    remove: (keyword: string) => void;
    setSearchKeywords: Dispatch<string[]>;
    remainingSpace: number;
}

const cleanString = (str: string) => str?.replace(/[^a-zA-Z0-9,_\s-/]/g, '');

const SearchChip: React.FC<Props> = ({ keyword, searchKeywords, setSearchKeywords, remove, index, remainingSpace }) => {
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.innerText = keyword;
        }
    }, [keyword]);

    const handleChangeValue = (e: React.FocusEvent<HTMLSpanElement, Element> | React.KeyboardEvent<HTMLSpanElement>) => {
        setSearchKeywords(searchKeywords.map((k, i) => (i === index ? cleanString(e.currentTarget.innerText) : k)));
    };

    const startingLength = (spanRef.current && spanRef.current.innerText.length) || 0;

    return (
        <div className={'search-chip'}>
            <span
                id='search_chip_input_span'
                ref={spanRef}
                role='textbox'
                contentEditable='true'
                suppressContentEditableWarning={true}
                onBlur={handleChangeValue}
                onKeyDown={(e) => {
                    // Prevent special characters from being added & character limit
                    if (
                        /[^a-zA-Z0-9,_\s-/]/.test(e.key) ||
                        e.key === 'Enter' ||
                        (spanRef.current && spanRef.current?.innerText?.length >= remainingSpace + startingLength)
                    ) {
                        e.preventDefault();
                    }
                }}
                onPaste={(e) => {
                    // @ts-ignore
                    let paste = (e.clipboardData || window?.clipboardData).getData('text');

                    if (/[^a-zA-Z0-9,_\s-/]/.test(paste)) {
                        e.preventDefault();
                    }
                }}
            >
                {keyword}
            </span>

            <span className='pl-2' onClick={() => remove(keyword)}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </div>
    );
};

export default SearchChip;
