import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import useGTMEventTracking from '../../hooks/useGTMEventTracking';
import useTranslationHydration from '../../hooks/useTranslationHydration';

interface SidebarNavFooterHelpItemProps {
    icon: IconDefinition;
    url?: string;
    target?: string;
    title: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const SidebarNavFooterHelpItem: React.FC<SidebarNavFooterHelpItemProps> = ({ icon, url, target, title, onClick }) => {
    const liveChatRef = useRef(null);
    const resourceCenterRef = useRef(null);
    const translate = useTranslationHydration();
    useGTMEventTracking('Live_Chat_Main_CPEvt', { location: 'Need Help' }, liveChatRef);
    useGTMEventTracking('Resource_Center_Main_CPEvt', { location: 'Need Help' }, resourceCenterRef);
    return (
        <a
            className='help-items d-flex'
            ref={
                title === translate('LiveChat_Label') ? liveChatRef : title === translate('ResourceCenter_Label') ? resourceCenterRef : null
            }
            href={url}
            target={target}
            onClick={onClick}
        >
            <div className='d-flex align-items-center justify-content-center mr-3'>
                <FontAwesomeIcon icon={icon} className='globe' style={{ width: '1.25rem' }} />
            </div>
            <div className='list-group-item sidebar-list-group-item py-2'>{title}</div>
        </a>
    );
};

export default SidebarNavFooterHelpItem;
