import { faDownload, faFile, faFolder, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslationHydration from '../../../portal/common/hooks/useTranslationHydration';
import { ClientSelectionSlice } from '../../../portal/common/models/ReduxSlices';
import { setInitialEllipsisState } from '../../../portal/redux/reducers/ellipsisSlice';
import { hideMenuData } from '../../../portal/redux/reducers/viewImagesSlice';
import { componentStyle } from '../../common/constants/constants-documents';
import { FileData } from '../../common/models/ImagingModel';
import { fetchDocumentPresignedUrl } from '../../redux/actions/Imaging';
import { RootState } from '../../redux/store';
import { sortByDocumentsOnDates } from '../../utils/download-modal-functions';
import '../DocumentsView/styles.css';
import HeaderAndIframe from './PDFCompareComponents/HeaderAndIframe';

//This method pushes every entry number into seenValue so that it only contains uniqueEntryNumbers,
//in that way we see if we selected one or multiple entries from grid
export const getIsSingleEntry = (arr: FileData[], entryNumberIndex: string) => {
    const seenValues: string[] = [];
    arr.forEach((obj) => {
        const entryNo = obj[entryNumberIndex];

        if (!seenValues.find((v) => v === entryNo) && entryNo) seenValues.push(entryNo);
    });

    return seenValues.length <= 1;
};

interface DocumentCompareModalProps {
    entriesCount: string;
    documentsCount: string;
    setCompareModalView: () => void;
    arrayOfFiles: FileData[];
    downloadButtonClick: (value: string) => void;
    isClearances: boolean;
    entryNumberClearances?: string | undefined;
}

const DocumentCompareModal: React.FC<DocumentCompareModalProps> = ({
    entriesCount,
    documentsCount,
    setCompareModalView,
    arrayOfFiles,
    downloadButtonClick,
    isClearances,
    entryNumberClearances
}) => {
    const translateHydration = useTranslationHydration();
    sortByDocumentsOnDates(arrayOfFiles, '_folderDate');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 994);
    const [fetchedLinkOne, setFetchedLinkOne] = useState('');
    const [fetchedLinkTwo, setFetchedLinkTwo] = useState('');
    const [indexOfFirstDocument, setIndexOfFirstDocument] = useState<number>(0);
    const [indexOfSecondDocument, setIndexOfSecondDocument] = useState<number>(1);
    const [isLoading1, setIsLoading1] = useState<boolean>(false);
    const [isLoading2, setIsLoading2] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;
    const entryNumberIndex: string = country === 'us' ? '_entryNumber' : '_transactionNumber';
    const isSingleEntry = getIsSingleEntry(arrayOfFiles, entryNumberIndex);

    useEffect(() => {
        setIsLoading1(true);

        const fetchFirstDocument = fetchDocumentPresignedUrl(arrayOfFiles[indexOfFirstDocument]._fileS3Path ?? '');

        fetchFirstDocument
            .then((result) => {
                if (result) {
                    setFetchedLinkOne(result['result']);
                }
                setIsLoading1(false); // Update loading state after fetch
            })
            .catch((_err) => {
                setIsLoading1(false); // Update loading state in case of error
            });
    }, [indexOfFirstDocument]);

    useEffect(() => {
        setIsLoading2(true);

        const fetchSecondDocument = fetchDocumentPresignedUrl(arrayOfFiles[indexOfSecondDocument]._fileS3Path ?? '');

        fetchSecondDocument
            .then((result) => {
                if (result) {
                    setFetchedLinkTwo(result['result']);
                }
                setIsLoading2(false); // Update loading state after fetch
            })
            .catch((_err) => {
                setIsLoading2(false); // Update loading state in case of error
            });
    }, [indexOfSecondDocument]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        const mobileCheck = window.innerWidth < 994;
        setIsMobile(mobileCheck);
    };

    const containerStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginTop: isClearances ? '0px' : window.innerWidth <= 370 ? '-300px' : '-267px',
        zIndex: 1000,
        position: 'relative',
        backgroundColor: 'rgb(201, 221, 242)'
    };

    const headerAndIframeContainerStyle: CSSProperties = {
        display: 'flex',
        flex: 1,
        flexDirection: !(window.innerWidth < 994) ? 'row' : 'column'
    };

    return (
        <>
            <div style={containerStyle}>
                <div className='bg-white container-fluid py-2 px-3 py-sm-3 compare-documents-container'>
                    {!isSingleEntry ? <h2 className='compare-documents-title'>{translateHydration('CompareDocuments_Label')}</h2> : null}
                    {isSingleEntry ? (
                        <h2 className='compare-documents-title'>
                            {entryNumberClearances
                                ? translateHydration('EntryNo_Label', entryNumberClearances)
                                : translateHydration('EntryNo_Label', arrayOfFiles[0][entryNumberIndex])}
                        </h2>
                    ) : null}

                    {!isMobile && (
                        <span className='document-counts'>
                            {!isSingleEntry && (
                                <>
                                    <FontAwesomeIcon icon={faFolder} className='icon' />
                                    <b>{entriesCount}</b> {translateHydration('EntriesAnd_Label')}
                                </>
                            )}
                            <FontAwesomeIcon icon={faFile} className={`icon ${!isSingleEntry ? 'ml-2' : ''}`} />
                            <b>{documentsCount}</b> {translateHydration('DocumentsSelected_Label')}
                        </span>
                    )}
                    {
                        <div className='document-actions'>
                            {!isMobile && (
                                <button
                                    type='button'
                                    className='btn btn-tertiary mr-1 d-none d-md-inline-block download-btn'
                                    onClick={() => downloadButtonClick('download')}
                                >
                                    <span className='mr-1 d-none d-lg-inline-block'>
                                        {translateHydration('DownloadAllNoOfDocuments_Label', documentsCount)}
                                    </span>
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                            )}
                            <button
                                type='button'
                                className='`modal-close btn btn-tertiary-blue close-btn'
                                data-dismiss='modal'
                                aria-label='Close'
                                onClick={() => {
                                    setCompareModalView();
                                    dispatch(hideMenuData(false));
                                    dispatch(setInitialEllipsisState());
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                    }
                </div>
                <div style={headerAndIframeContainerStyle}>
                    <div style={{ ...componentStyle, backgroundColor: 'rgb(50, 80, 115)', overflow: 'unset' }}>
                        <HeaderAndIframe
                            isMobile={isMobile}
                            arrayOfFiles={arrayOfFiles}
                            fetchedLink={fetchedLinkOne}
                            setIndex={setIndexOfFirstDocument}
                            indexOfDocument={indexOfFirstDocument}
                            isLoading={isLoading1}
                            isClearances={isClearances}
                        />
                    </div>
                    <div style={{ ...componentStyle, backgroundColor: 'rgb(50, 80, 115)', overflow: 'unset' }}>
                        <HeaderAndIframe
                            isMobile={isMobile}
                            arrayOfFiles={arrayOfFiles}
                            fetchedLink={fetchedLinkTwo}
                            setIndex={setIndexOfSecondDocument}
                            indexOfDocument={indexOfSecondDocument}
                            isLoading={isLoading2}
                            isClearances={isClearances}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DocumentCompareModal;
