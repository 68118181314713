import $ from 'jquery';
import { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import * as actionCreators from '../../store/actions/index';
import DeleteNoticeModal from './DeleteNoticeModal';
import ManageNoticesArea from './ManageNoticesArea';
import NoticeContentPreview from './NoticeContentPreview';
import NoticesTableArea from './NoticesTableArea';

class ManageNotices extends Component {

    state={
        shouldOpenPreviewModal:false,
        shouldOpenDeleteModal:false
    }

    decideOnFormDisplay=()=>{
        const getRandomNum =()=>Math.ceil(Math.random()*1000);
        const displayData = [];
        let key = "";

        //Display preview modal 
        key = `NoticePreviewModal__${getRandomNum()}`;
        
        displayData.push(<NoticeContentPreview key={key} />);
        
        //Display delete modal 
        key = `NoticeDeleteModal__${getRandomNum()}`;
        this.props.isDeleteModalOpen && displayData.push(<DeleteNoticeModal key={key} />);

        //Display table area 
        key = `NoticeDisplayTableArea__${getRandomNum()}`;
        !this.props.manageNoticesAreaVisible && displayData.push(this.displayNoticeTable(key));

        //Display manage notice area 
        key = `NoticeManagementModal__${getRandomNum()}`;
        this.props.manageNoticesAreaVisible && displayData.push(this.displayNoticeManagementArea(key));

        return displayData;
    }

    displayNoticeTable(key){
                const tableArea = (this.props.notices.length > 0 && <NoticesTableArea />) || (
                                    <div className='no-notices'>
                                        {this.props.translations.Phrases['Notices_NoNoticesMessage']}
                                    </div>);

                return(<div key={key} className='d-block row page-content'>
                        {tableArea}
                        </div>);
        }
        
    displayNoticeManagementArea(key){
                  return (<div key={key} className='d-block row page-content'>
                            <ManageNoticesArea />
                            </div>);
    }

    componentDidMount() {
        this.props.onInitManageNotices();
        this.props.onInitTranslations('Notices.Notices.ManageNotices');
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    componentWillUnmount() {
        this.props.onResetNoticesState();
    }

    exportNotices = () => {
        if (this.props.notices.length > 0) {
            $('#overlay').show();
            axios
                .post('/Notices/ExportNotices', this.props.notices)
                .then((response) => {
                    ReactGA.event({
                        category: 'Outbound Link',
                        action: 'click',
                        label: 'Export Notices'
                    });
                    const filename = 'Notices.csv';
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style.cssText = 'display: none';
                    a.download = filename;
                    if (window.navigator.msSaveOrOpenBlob) {
                        //IE support
                        var fileData = [response.data];
                        var blobObject = new Blob(fileData);
                        $(a).on(() => {
                            window.navigator.msSaveOrOpenBlob(blobObject, filename);
                        });
                        a.click();
                    } else {
                        const blob = new Blob(['\ufeff', response.data], { type: 'octet/stream' });
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                    if (a.remove) {
                        a.remove();
                    }
                })
                .catch((error) => {
                    toastr.error(this.props.translations.Phrases['Download_Doc_Error_Label']);
                })
                .finally(() => {
                    $('#overlay').hide();
                });
        } else {
            toastr.warning(this.props.translations.Phrases['NoNotices_Msg']);
        }
    };

      render() {
        if (this.props.notices && this.props.translations.PhraseGroupCode === 'Notices.Notices.ManageNotices') {
            const pageTitle = this.props.translations.Phrases['Page_Title']
                ? this.props.translations.Phrases['Page_Title'].split(' ')
                : ['', ''];

            return (
                <div className='page-wrapper'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['MyLivingstonTitle_Label']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span>{this.props.translations.Phrases['ManageNotices_NavLevel2']}</span>
                            </nav>
                        </div>
                        <div>
                            <div className='header-align'>
                                <h1 className='float-left bold-title'>
                                    <span>
                                        {pageTitle[0]}
                                        &nbsp;
                                    </span>
                                    <span className='gold-title'>{pageTitle[1]}</span>
                                </h1>
                            </div>
                            <div className='header-align'>
                                <div className={this.props.manageNoticesAreaVisible ? 'd-none' : 'd-block float-right'}>
                                    <span className='button add-notice small' onClick={() => this.props.onNoticeAdd(this.props.notices)}>
                                        {this.props.translations.Phrases['AddNotice_Label']}
                                    </span>
                                    <span className='button export-notice small' onClick={() => this.exportNotices()}>
                                        {this.props.translations.Phrases['Export_Label']}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='manage-notice' className='content-wrapper'>
                        {this.decideOnFormDisplay()}
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        notices: state.notices.model.NoticesList,
        manageNoticesAreaVisible: state.notices.manageNoticesAreaVisible,
        translations: state.translations.model,
        isNoticePreviewOpen: state.notices.isNoticePreviewOpen,
        isDeleteModalOpen: state.notices.isDeleteModalOpen
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitManageNotices: () => dispatch(actionCreators.initManageNotices()),
        onNoticeAdd: (notices) => {
            let newNotice = {
                Id: '',
                LookupKey: '',
                ServiceProvider: '',
                NoticeType: null,
                Language: 'en',
                Title: '',
                Content: '',
                EffectiveDateFrom: '',
                EffectiveDateTo: '',
                EffectiveDatePartFrom: '',
                EffectiveDatePartTo: '',
                EffectiveTimePartFrom: '',
                EffectiveTimePartTo: '',
                EffectiveTimePartFromAmPm: 'PM',
                EffectiveTimePartToAmPm: 'PM',
                IsDeleted: false,
                CreatedBy: '',
                CreatedDate: '',
                ModifiedBy: '',
                ModifiedDate: ''
            };
            newNotice.Id = notices.length + 1;
            dispatch(actionCreators.addNotice(newNotice, 0));
        },
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onResetNoticesState: () => dispatch(actionCreators.resetNoticesState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageNotices);
