import React, { Component } from 'react';
import { sortBy } from '../../../utils/mlp-utils';

class SearchGridRowMoreDetails extends Component {
    static defaultProps = {
        visibilitySetting: 'isVisibleOnGridDetails',
        orderSetting: 'gridDetailsOrder',
        columnsPerRow: 4
    };

    getColumns = (metadata, columnsPerRow) => {
        return sortBy(
            metadata.filter((item) => item[this.props.visibilitySetting]),
            this.props.orderSetting
        ).map((item) => {
            return (
                <div className={'col-' + Math.floor(12 / columnsPerRow)} key={item.attribute}>
                    <div className='item-label'>{item.label}</div>
                    <div className='item'>{item.value}</div>
                </div>
            );
        });
    };

    getDisplayData = (entry, columnsPerRow) => {
        const columns = this.getColumns(entry['metadata'], columnsPerRow);

        let result = [];
        for (let i = 0; i < columns.length; i += columnsPerRow) {
            result.push(
                <div className='more-details row' key={i}>
                    {columns.slice(i, columnsPerRow + i)}
                </div>
            );
        }

        return result;
    };

    render() {
        return <div className='w-100'>{this.getDisplayData(this.props.entry, this.props.columnsPerRow)}</div>;
    }
}
export default SearchGridRowMoreDetails;
