import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-mlp';
import TextArea from '../../../common/Textarea/Textarea';
import Upload from '../../../common/Upload/Upload';
import { batchJobUrlPaths as batchConstants, Upload as upload } from '../../../constants/mlp-constants';
import * as actionCreators from '../../../store/actions/index';

class BatchUploadEditForm extends Component {
    state = {
        textareaValue: '',
        uploadSuccess: false,
        textAreaValidationMessage: ''
    };

    componentDidMount() {
        this.props.onInitTranslations('SystemAdmin.BatchUser.Edit');
    }

    uploadSuccess = (batchJobId) => {
        this.props.onGetBatchJobCreate(batchJobId);
    };

    constructSearchBtnClass = () => {
        let btnClass = ['button', 'small', 'blue', 'clear-margin-right'];
        if (!this.state.textareaValue) {
            btnClass.push('disabled');
        }
        return btnClass.join(' ');
    };

    searchBtnClickHandler = () => {
        $('#overlay').show();
        axios
            .post(batchConstants.SUBMIT_BATCH_EDIT_LIST, null, { params: { listData: this.state.textareaValue } })
            .then((response) => {
                if (response.data.isSuccess) {
                    this.uploadSuccess(response.data.batchJobId);
                } else {
                    this.setState({ textAreaValidationMessage: response.data.errorTranslationCode || response.data.error });
                }
            })
            .catch((error) => {
                this.setState({ textAreaValidationMessage: this.props.translations.Phrases['DateUploaSubmitFailedTryAgain_Label'] });
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
    onChangeText = (value) => {
        this.setState({ textareaValue: value });
    };

    render() {
        const uploadOptions = {
            paramName: 'file', // The name that will be used to transfer the file
            acceptedFiles: 'application/vnd.ms-excel,.csv',
            maxFilesize: 1000, // MB
            maxFiles: 1,
            dictFallbackMessage: this.props.translations.Phrases['ClickToSelectFile_Label'],
            dictInvalidFileType: this.props.translations.Phrases['InvalidFileType_Label']
        };

        return (
            <div>
                <div className='col-sm-12'>
                    <TextArea
                        label={this.props.translations.Phrases['ListOfUsersForEdit_Label']}
                        name='UploadEditList'
                        value={this.state.textareaValue}
                        valMsg={this.props.translations.Phrases[this.state.textAreaValidationMessage]}
                        onValueChanged={this.onChangeText}
                        rows='6'
                    />
                </div>

                <div className='col-sm-12 text-right'>
                    <button className={this.constructSearchBtnClass()} onClick={this.searchBtnClickHandler}>
                        <i className='fa fa-search'></i>
                        {this.props.translations.Phrases['SearchBtn_Label']}
                    </button>
                </div>

                <div className='col-sm-12'>
                    <h3>OR</h3>
                </div>

                <Upload
                    uploadSuccess={this.uploadSuccess}
                    options={uploadOptions}
                    id={'upload-batch-users'}
                    formAction={upload.UPLOAD_EDIT_FILE}
                    initialMessage={this.props.translations.Phrases['DropOrClickToUpload_Label']}
                    translations={this.props.translations}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onGetBatchJobCreate: (batchJobId) => dispatch(actionCreators.getBatchJobEdit(batchJobId))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BatchUploadEditForm);
