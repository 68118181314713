import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionsData } from '../../../portal/common/models/ResponseData';
import { SubscriptionsSlice } from '../../common/models/ReduxSlices';
import {
    GetSubscriptionsWithClientNumbers,
    GetUserWidthGridSetting,
    MultipleUnsubscribe,
    Unsubscribe,
    UpdateSubscription
} from '../actions/subscriptions';

const initialState: SubscriptionsSlice = {
    subscriptions: null,
    filteredSubscriptions: null,
    allColumns: [],
    visibleColumns: [],
    unsubscribe: {
        isLoading: false,
        error: undefined,
        removedSubscribersAmount: null,
        action: null,
        alertMessage: null,
        alertType: null
    },
    isLoading: true,
    error: undefined,

    subscriptionUpdate: {
        data: null,
        isLoading: false,
        error: undefined,
        action: null,
        alertMessage: null,
        alertType: null,
        customerNumber: null,
        requestedAction: null
    }
};

const clearanceNotificationsSlice = createSlice({
    name: 'clearanceNotifications',
    initialState,
    reducers: {
        setSubscriptionUpdateAction(state, action: PayloadAction<string>) {
            state.subscriptionUpdate.action = action.payload;
        },
        setCustomerNumber(state, action: PayloadAction<string | null>) {
            state.subscriptionUpdate.customerNumber = action.payload;
        },
        resetSubscriptionUpdate(state) {
            state.subscriptionUpdate = initialState.subscriptionUpdate;
        },
        resetUnsubscribe(state) {
            state.unsubscribe = initialState.unsubscribe;
        },
        setFilteredSubscriptions(state, action: PayloadAction<null | SubscriptionsData[]>) {
            state.filteredSubscriptions = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetSubscriptionsWithClientNumbers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetSubscriptionsWithClientNumbers.fulfilled, (state, action) => {
                state.subscriptions = action.payload.SubscriptionsPerClientNumbers;
                state.isLoading = false;
            })
            .addCase(GetSubscriptionsWithClientNumbers.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(GetUserWidthGridSetting.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetUserWidthGridSetting.fulfilled, (state, action) => {
                state.allColumns = action.payload.GridColumns;
                state.visibleColumns = action.payload.GridColumns;
                state.isLoading = false;
            })
            .addCase(GetUserWidthGridSetting.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })

            .addCase(Unsubscribe.pending, (state) => {
                state.subscriptionUpdate.isLoading = true;
            })
            .addCase(Unsubscribe.fulfilled, (state, action) => {
                state.subscriptionUpdate.requestedAction = action.payload.RequestedAction;
                state.subscriptionUpdate.data = action.payload;
                state.subscriptionUpdate.isLoading = false;
                if (action.payload.ResponseCode === 400 && action.payload.ResponseMessage === 'FAIL') {
                    state.subscriptionUpdate.alertMessage = 'Error';
                    state.subscriptionUpdate.alertType = 'warning';
                } else if (state.subscriptionUpdate.action === 'unsubscribe') {
                    state.subscriptionUpdate.alertMessage =
                        action.payload.ResponseMessage === 'PASS' && action.payload.RequestedAction === 'UNSUBSCRIBE'
                            ? 'UnsubscribedFromClient_Label'
                            : '';
                    state.subscriptionUpdate.alertType = 'success';
                }
            })
            .addCase(Unsubscribe.rejected, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.subscriptionUpdate.error = action.payload;
                } else {
                    state.subscriptionUpdate.error = action.error.message;
                }
            })
            .addCase(UpdateSubscription.pending, (state) => {
                state.subscriptionUpdate.isLoading = true;
            })
            .addCase(UpdateSubscription.fulfilled, (state, action) => {
                state.subscriptionUpdate.data = action.payload;
                state.subscriptionUpdate.isLoading = false;
                state.subscriptionUpdate.requestedAction = action.payload.RequestedAction;
                if (action.payload.ResponseCode === 400 && action.payload.ResponseMessage === 'FAIL') {
                    state.subscriptionUpdate.alertMessage = 'ClientNumberNoClearancesAvailable_Label';
                    state.subscriptionUpdate.alertType = 'warning';
                } else {
                    if (state.subscriptionUpdate.action === 'subscribe') {
                        state.subscriptionUpdate.alertMessage = 'SubscribedToClient_Label';
                    } else if (state.subscriptionUpdate.action === 'unsubscribe') {
                        state.subscriptionUpdate.alertMessage = 'UnsubscribedFromClient_Label';
                    } else if (state.subscriptionUpdate.action === 'onhold') {
                        state.subscriptionUpdate.alertMessage =
                            action.payload.ResponseMessage === 'PASS' && action.payload.RequestedAction === 'UNSUBSCRIBE'
                                ? 'UnsubscribedFromClient_Label'
                                : 'OnHoldNotificationsUpdated_Label';
                    } else if (state.subscriptionUpdate.action === 'notifTypes') {
                        state.subscriptionUpdate.alertMessage =
                            action.payload.ResponseMessage === 'PASS' && action.payload.RequestedAction === 'UNSUBSCRIBE'
                                ? 'UnsubscribedFromClient_Label'
                                : 'NotificationTypeUpdated_Label';
                    } else if (state.subscriptionUpdate.action === 'milestones') {
                        state.subscriptionUpdate.alertMessage =
                            action.payload.ResponseMessage === 'PASS' && action.payload.RequestedAction === 'UNSUBSCRIBE'
                                ? 'UnsubscribedFromClient_Label'
                                : 'MilestonesUpdated_Label';
                    }
                    state.subscriptionUpdate.alertType = 'success';
                }
            })
            .addCase(UpdateSubscription.rejected, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.subscriptionUpdate.error = action.payload;
                } else {
                    state.subscriptionUpdate.error = action.error.message;
                }
            })
            .addCase(MultipleUnsubscribe.pending, (state, action) => {
                state.unsubscribe.isLoading = true;
                state.subscriptionUpdate.isLoading = true;
                state.unsubscribe.removedSubscribersAmount = action.meta.arg.subscriptionIds.length;
            })
            .addCase(MultipleUnsubscribe.fulfilled, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                state.unsubscribe.isLoading = false;
            })
            .addCase(MultipleUnsubscribe.rejected, (state, action) => {
                state.unsubscribe.isLoading = false;
                state.subscriptionUpdate.isLoading = false;
                state.unsubscribe.error = action.error.message;
            });
    }
});

export const { setSubscriptionUpdateAction, setCustomerNumber, resetSubscriptionUpdate, resetUnsubscribe, setFilteredSubscriptions } =
    clearanceNotificationsSlice.actions;

export default clearanceNotificationsSlice.reducer;
