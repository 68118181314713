import { GridCellProps } from '@progress/kendo-react-grid';
import { useSelector } from 'react-redux';
import Badge from '../../../../portal/common/features/Badge';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import { createDateForTeamTable } from '../../../common/functions/parser';
import { TransformedTeamMember } from '../../../common/models/TeamMember';
import { RootState } from '../../../redux/store';
import { ActionButton } from './ActionButton';

interface FormattedGridCellProps extends GridCellProps {
    dataItem: TransformedTeamMember;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({ field = '', style, dataItem, className, ...rest }) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const translate = useTranslation();
    let cellContent;

    switch (field) {
        case 'Status':
            cellContent = dataItem.IsActive ? (
                <Badge type='success'>{dataItem.Status}</Badge>
            ) : (
                <Badge type='danger'>{dataItem.Status}</Badge>
            );
            break;
        case 'LastLogin':
            cellContent =
                dataItem.IsPending === true
                    ? translate('Never_Label')
                    : dataItem.LastLogin
                    ? createDateForTeamTable(dataItem.LastLogin)
                    : translate('Never_Label');
            break;
        case 'Expires':
            cellContent = dataItem.Expires ? createDateForTeamTable(dataItem.Expires) : translate('Never_Label');
            break;
        case 'Actions':
            cellContent =
                dataItem.IsPending === true || user?.Roles?.includes('TeamManagement_Edit') === false ? null : (
                    <ActionButton dataItem={dataItem} {...rest} />
                );
            break;
        default:
            cellContent = null;
    }

    return (
        <td
            className={className}
            style={{
                ...style,
                position: field === 'Actions' ? 'sticky' : 'static',
                backgroundColor: field === 'Actions' ? 'white' : 'transparent',
                cursor: 'pointer'
            }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
