import { getter } from '@progress/kendo-data-query';
import { Grid, GridCellProps, GridColumn as Column, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGridLanguage from '../../../../portal/common/hooks/useGridLanguage';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { Document } from '../../../../portal/common/models/ClearanceDocuments';
import { ClientSelectionSlice, LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
import { setEllipsisEntryNo } from '../../../../portal/redux/reducers/ellipsisSlice';
import { addViewImagesData } from '../../../../portal/redux/reducers/viewImagesSlice';
import { RootState } from '../../../redux/store';
import DocumentsFooter from '../../../screens/DocumentsFooter';
import { ButtonArray } from '../../constants/constants-documents';
import { GroupedDocuments } from '../../models/ImagingModel';
import { ImagingModelSlice } from '../../models/ReduxSlices';
import FormattedGridCell from './FormattedGridCell';

interface EntryDocumentsTableProps {
    entryNo: string;
    downloadButtonClick: (value: string) => void;
    setDownloadModalData: (value: string[]) => void;
    setModalView: (value: null | string) => void;
    setDocumentDetailsCompareView: () => void;
}

const EntryDocumentsTable: React.FC<EntryDocumentsTableProps> = ({
    entryNo,
    downloadButtonClick,
    setDownloadModalData,
    setModalView,
    setDocumentDetailsCompareView
}) => {
    const SELECTED_FIELD = 'selected';
    const DATA_ITEM_KEY: keyof Document = 'documentId';
    const idGetter = getter(DATA_ITEM_KEY);
    const isMobile = window.innerWidth <= 768;

    const { model } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);

    const gridLanguage = useGridLanguage(selectedLanguage);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const translate = useTranslation();
    const dispatch = useDispatch();

    const [group, setGroup] = useState<GroupedDocuments | null>(null);
    const [dataState, setDataState] = useState<({ [SELECTED_FIELD]: boolean } & Document)[]>();
    const [selectedState, setSelectedState] = useState<{ [key: string]: boolean }>({});
    const [headerCheckbox, setHeaderCheckbox] = useState<boolean>(false);

    const checkboxHandler = (id: string) => {
        setSelectedState((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const GridCell = useCallback(
        (props: GridCellProps) => {
            return <FormattedGridCell onCheckboxClick={checkboxHandler} entryNo={entryNo} country={country?.Code!} {...props} />;
        },
        [entryNo]
    );

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            const headerCheckboxHandler = () => {
                if (Object.keys(selectedState).filter((x) => selectedState[x] === false).length === 0) {
                    setSelectedState((prevState) =>
                        Object.keys(prevState).reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr]: false
                            }),
                            {}
                        )
                    );

                    setHeaderCheckbox(false);
                } else {
                    setSelectedState((prevState) =>
                        Object.keys(prevState).reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr]: prevState[curr] ? true : !prevState[curr]
                            }),
                            {}
                        )
                    );
                    setHeaderCheckbox(true);
                }
            };
            return <Checkbox value={headerCheckbox} onChange={headerCheckboxHandler} />;
        },
        [headerCheckbox, selectedState]
    );

    const viewButtonClick = () => {
        const entryNo = Object.keys(group!)[0];
        const images = group![entryNo];

        const selectedDocuments = Object.keys(selectedState)
            .filter((key) => selectedState[key] === true)
            .map((item) => {
                const selectedImage = images.find((image) => image._pid === item) || {};

                return {
                    _metadataFormat: selectedImage._metadataFormat ?? '',
                    _fileS3Path: selectedImage._fileS3Path ?? '',
                    _imageDate: selectedImage._imageDate ?? '',
                    secondaryDescription: selectedImage.USDOCUMENTTYPE ?? selectedImage.LIDOCUMENTID,
                    version: selectedImage.LIVERSION ?? ''
                };
            });

        const dataForViewModal = {
            entry: entryNo,
            documents: selectedDocuments
        };

        setModalView(null);
        dispatch(addViewImagesData({ viewImages: [dataForViewModal], openedLocation: 'entryDocumentsGrid' }));
    };

    useEffect(() => {
        const entryNoGroup = model.SearchResults.find((group) => group[entryNo]);
        if (entryNoGroup) {
            setGroup(entryNoGroup);
        }
    }, [entryNo, model.SearchResults]);

    useEffect(() => {
        if (group) {
            const newSelectedState: { [key: string]: boolean } = {};
            const dataForGrid = Object.values(group)[0].map((item) => ({
                documentId: item._pid!,
                metadataFormat: item._metadataFormat!,
                imageDate: item._imageDate!,
                documentType: item._metadataFormat_raw!,
                secondaryDescription: item.USDOCUMENTTYPE ?? item.LIDOCUMENTID,
                path: item._fileS3Path!,
                LIVERSION: item.LIVERSION,
                USCICONSOL: item.USCICONSOL
            }));
            dataForGrid?.forEach((item) => {
                newSelectedState[item.documentId!] = false;
            });
            if (dataForGrid && dataForGrid.length > 0) {
                setSelectedState(newSelectedState);
                setDataState(dataForGrid.map((item) => ({ ...item, [SELECTED_FIELD]: false })));
            }
        }
    }, [group]);

    useEffect(() => {
        if (Object.keys(selectedState).filter((x) => selectedState[x] === false).length === 0) {
            setHeaderCheckbox(true);
        } else {
            setHeaderCheckbox(false);
        }

        let selectedIds = Object.keys(selectedState).filter((key) => selectedState[key] === true);
        if (selectedIds.length > 0 && dataState) {
            setDownloadModalData(dataState.filter((obj) => selectedIds.includes(obj.documentId)).map((obj) => obj.path));
        }
    }, [selectedState, setSelectedState]);

    useEffect(() => {
        return () => {
            setGroup(null);
        };
    }, []);

    return (
        <div>
            {dataState && group && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <Grid
                            data={
                                dataState &&
                                dataState.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                                }))
                            }
                            style={{ maxHeight: '60vh' }}
                            className={country?.Code === 'ca' ? 'documents-table entry-documents-table flex-fill' : ''}
                            total={dataState ? dataState.length : 0}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                        >
                            <Column field={SELECTED_FIELD} cell={GridCell} headerCell={HeaderCell} />
                            <Column
                                field='DocumentType'
                                title={translate('DocumentType_Label')}
                                cell={GridCell}
                                width={country?.Code === 'ca' ? 240 : 330}
                            />
                            {country?.Code === 'ca' && <Column field='Version' title={translate('Version_Label')} cell={GridCell} />}
                            <Column
                                field='Date'
                                title={translate('ImageDate_Label')}
                                cell={GridCell}
                                width={country?.Code !== 'ca' ? '100' : ''}
                            />
                            <Column field='Download' title=' ' cell={GridCell} locked {...(isMobile && { width: '50' })} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            )}

            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 && (
                <DocumentsFooter
                    buttonArray={ButtonArray}
                    amountEntries={'1'}
                    amountSelected={Object.entries(selectedState).filter(([key, value]) => value === true).length + ''}
                    downloadButtonParam={'entryDocumentsGrid'}
                    downloadButtonClick={downloadButtonClick}
                    viewButtonClick={() => viewButtonClick()}
                    compareButtonClick={() => {
                        dispatch(setEllipsisEntryNo(entryNo));
                        setModalView(null);
                        setDocumentDetailsCompareView();
                    }}
                />
            )}
        </div>
    );
};

export default EntryDocumentsTable;
