import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format } from 'react-string-format';
import axios from '../../axios-mlp';

class validationErrorSummary extends Component {
    static propTypes = {
        filename: PropTypes.string.isRequired,
        invalidRowsCount: PropTypes.number.isRequired,
        totalRowCount: PropTypes.number.isRequired,
        annotatedFileId: PropTypes.number.isRequired
    };

    getAnnotatedFile = (objectId, fileName) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        let fnAnnotated = fileName.substr(0, fileName.length - 4) + '_annotated.csv';
        params.append('s3ObjectId', objectId);
        axios
            .get('/BatchUser/GetAnnotatedFile', { params: params })
            .then((response) => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.cssText = 'display: none';
                a.download = fnAnnotated;
                if (window.navigator.msSaveOrOpenBlob) {
                    //IE support
                    const fileData = [response.data];
                    const blobObject = new Blob(fileData);
                    $(a).on(() => {
                        window.navigator.msSaveOrOpenBlob(blobObject, fnAnnotated);
                    });
                    a.click();
                } else {
                    const blob = new Blob([response.data], { type: 'octet/stream' }),
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                if (a.remove) {
                    a.remove();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    this.props.history.push('/BasicError');
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };

    render() {
        return (
            <div>
                <div className='col-sm-12'>
                    <h3>
                        {format(
                            this.props.translations.Phrases['FileContainedInvalidData_Label'],
                            this.props.filename,
                            this.props.invalidRowsCount,
                            this.props.totalRowCount
                        )}
                    </h3>
                    <br />
                    <h4>{this.props.translations.Phrases['DownloadAnnotatedFileLong_Label']}</h4>
                    <br />
                    <button
                        className='btn btn-link action-link'
                        onClick={() => this.getAnnotatedFile(this.props.annotatedFileId, this.props.filename)}
                    >
                        {this.props.translations.Phrases['DownloadAnnotatedFile_Label']}
                    </button>
                </div>
                <div className='col-sm-12'>
                    <div className='back-link'>
                        <a href='/SystemAdmin'>{this.props.translations.Phrases['BackToSystemAdmin_Label']}</a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default withRouter(connect(mapStateToProps)(validationErrorSummary));
