import { getter } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import ErrorPage from '../../../../portal/common/features/ErrorPage';
import EmptyState from '../../../../portal/common/features/Grid/EmptyState';
import GridLoadingOverlay from '../../../../portal/common/features/Grid/GridLoadingOverlay';
import RowWithButton from '../../../../portal/common/features/Grid/RowWithButton';
import UnsubscribeFooter from '../../../../portal/common/features/Grid/UnsubscribeFooter';
import ConfrimationModal from '../../../../portal/common/features/Modal/ConfirmationModal';
import Spinner from '../../../../portal/common/features/Spinner';
import Tooltip from '../../../../portal/common/features/Tooltip/Tooltip';
import useGTMEventTracking from '../../../../portal/common/hooks/useGTMEventTracking';
import useGridLanguage from '../../../../portal/common/hooks/useGridLanguage';
import useGridSelection from '../../../../portal/common/hooks/useGridSelection';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { Subscription } from '../../../../portal/common/models/ClearanceSubscriptions';
import { GridSettingsSlice, HydrationSlice, LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
import { SubscribersModals } from '../../../../portal/common/models/SubscribersModals';
import { ClearanceSubscribersSlice, ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { GetClearanceSubscribers } from '../../../redux/actions/clearanceSubscribers';
import { resetSelectedState, setHeaderCheckbox, setSelectedState } from '../../../redux/reducers/clearanceSubscribersGridSettingsSlice';
import { RootState } from '../../../redux/store';
import FormattedGridCell from './FormattedGridCell';
import SubscriptionStatusAlerts from './SubscriptionStatusAlerts';
import AddSubscriberModal from './subscribe/AddSubscriberModal';
import UnsubscribeModal from './unsubscribe/UnsubscribeModal';

const initialModals: SubscribersModals = {
    add: false,
    cancelAdd: false,
    remove: false
};

const DATA_ITEM_KEY: keyof Subscription = 'SubscriptionId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const SubscribersGrid = () => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const { subscribers, isLoading, subscriptionUpdate, unsubscribe, subscribe, selfSubscription, selfSubscriptionUpdate, error } =
        useSelector<RootState, ClearanceSubscribersSlice>((state) => state.clearanceSubscribers);
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { selectedState, headerCheckbox } = useSelector<RootState, GridSettingsSlice>((state) => state.clearanceSubscribersGridSettings);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const [modal, setModal] = useState<SubscribersModals>(initialModals);
    const [dataState, setDataState] = useState<
        ({
            [SELECTED_FIELD]: boolean;
        } & Subscription)[]
    >();
    const [selectedSubscriberIds, setSelectedSubscriberIds] = useState<string[]>([]);
    const dispatch = useDispatch();
    const translate = useTranslation();
    const gridLanguage = useGridLanguage(selectedLanguage);
    const { handleHeaderSelectionChange, handleSelectionChange } = useGridSelection({
        DATA_ITEM_KEY,
        SELECTED_FIELD,
        idGetter,
        selectedState,
        onSelectionChange: (value) => dispatch(setSelectedState(value)),
        onHeaderSelectionChange: useCallback((value) => dispatch(setHeaderCheckbox(value)), [dispatch]),
        onReset: useCallback(() => dispatch(resetSelectedState()), [dispatch])
    });
    const addSubscribersClearancesInitialRef = useRef(null);

    useGTMEventTracking('Add_Subsription_Entry_CLR_CPEvt', { location: 'Clearances' }, addSubscribersClearancesInitialRef);
    // Set data state for the grid
    useEffect(() => {
        if (subscribers) {
            setDataState(subscribers.map((item) => ({ ...item, [SELECTED_FIELD]: false })));
        }
    }, [subscribers]);

    // Refresh the grid after each "subscriptionUpdate", "subscribe", "unsubscribe" and "selfSubscriptionUpdate"
    useEffect(() => {
        if (
            !subscriptionUpdate.isLoading &&
            !subscriptionUpdate.error &&
            !subscribe.isLoading &&
            !subscribe.error &&
            !unsubscribe.isLoading &&
            !unsubscribe.error &&
            !selfSubscriptionUpdate.isLoading &&
            !selfSubscriptionUpdate.error &&
            shipmentDetails?.CustomsInfoID &&
            shipmentDetails.ClientCaptionNumber
        ) {
            dispatch(
                GetClearanceSubscribers({
                    customsInfoId: shipmentDetails.CustomsInfoID,
                    clientCaptionNumber: shipmentDetails.ClientCaptionNumber
                })
            );
        }
    }, [
        subscriptionUpdate.isLoading,
        subscriptionUpdate.error,
        subscribe.isLoading,
        subscribe.error,
        unsubscribe.isLoading,
        unsubscribe.error,
        selfSubscriptionUpdate.isLoading,
        selfSubscriptionUpdate.error,
        shipmentDetails?.ClientCaptionNumber,
        shipmentDetails?.CustomsInfoID,
        dispatch
    ]);

    // Reset the selection on every grid reload
    useEffect(() => {
        if (!isLoading && !error) {
            dispatch(resetSelectedState());
        }
    }, [isLoading, error, dispatch]);

    // Array of selected SubscriptionIds
    useEffect(() => {
        setSelectedSubscriberIds(
            Object.entries(selectedState)
                .filter(([key, value]) => value === true)
                .map(([key, value]) => key)
        );
    }, [selectedState]);

    const toggleModal = useCallback(
        (prop: keyof SubscribersModals | Array<keyof SubscribersModals>, next?: boolean) => {
            if (user?.InternalUser) return;
            if (typeof prop === 'string') {
                setModal({ ...modal, [prop]: next !== undefined ? next : !modal[prop] });
            } else {
                setModal(Object.assign({}, modal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [modal, user]
    );

    const handleRemoveSubscriber = useCallback(
        (id?: null | number) => {
            if (id) setSelectedSubscriberIds([id.toString()]);
            toggleModal('remove');
        },
        [toggleModal]
    );

    const GridCell = useCallback(
        (props: GridCellProps) => {
            return (
                <FormattedGridCell
                    {...props}
                    onRemoveSubscriber={handleRemoveSubscriber}
                    isLocked={props.dataItem[SELECTED_FIELD] || false}
                />
            );
        },
        [handleRemoveSubscriber]
    );

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            return <Checkbox value={headerCheckbox} onChange={props.selectionChange} />;
        },
        [headerCheckbox]
    );
    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'addasubscriber_subscribers_entry_clearances',
                location: 'Clearances'
            }
        });
    };
    return (
        <>
            {/* Status: pending (only showing on initial load) */}
            {(isLoading ||
                subscribe.isLoading ||
                unsubscribe.isLoading ||
                selfSubscription.isLoading ||
                selfSubscriptionUpdate.isLoading) &&
                (!subscribers || subscribers.length === 0) && (
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                        <Spinner />
                    </div>
                )}

            {modal.add && <AddSubscriberModal toggleModal={toggleModal} />}

            {modal.remove && (
                <UnsubscribeModal
                    clearanceNo={shipmentDetails?.EntryNumber}
                    subscriberIds={selectedSubscriberIds}
                    toggleModal={toggleModal}
                />
            )}

            {modal.cancelAdd && (
                <ConfrimationModal
                    title={translate('CancelAddingSucribers_Label')}
                    description={translate('LostProgress_Label')}
                    primaryButtonLabel={translate('YesCancel_Label')}
                    secondaryButtonLabel={translate('NotificationsGoBack_Label')}
                    onPrimary={() => toggleModal(['add', 'cancelAdd'], false)}
                    onSecondary={() => {
                        toggleModal('cancelAdd', false);
                        const modalElement = document.querySelector('#SteppedProgressModal') as HTMLDivElement;
                        modalElement.focus();
                    }}
                />
            )}

            {/* Status: fulfilled */}
            {subscribers && subscribers.length > 0 && !error && (
                <>
                    <div onClick={() => !user?.InternalUser && (toggleModal('add', true), handleTagEvent())}>
                        <RowWithButton extraRowClasses={''} element={'.k-grid.subscriber-to-single-clearance'}>
                            {!user?.InternalUser ? (
                                translate('AddSubscribersButtonGrid_Label')
                            ) : (
                                <Tooltip label='Disabled for LII users.'>{translate('AddSubscribersButtonGrid_Label')}</Tooltip>
                            )}
                        </RowWithButton>
                    </div>

                    <LocalizationProvider language={gridLanguage.language}>
                        <IntlProvider locale={gridLanguage.locale}>
                            <Grid
                                data={
                                    dataState &&
                                    dataState.map((item) => ({
                                        ...item,
                                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                                    }))
                                }
                                className='subscriber-to-single-clearance h-100'
                                total={dataState ? dataState.length : 0}
                                onSelectionChange={handleSelectionChange}
                                onHeaderSelectionChange={handleHeaderSelectionChange}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: false,
                                    drag: false,
                                    cell: false,
                                    mode: 'multiple'
                                }}
                                style={{ zIndex: 0 }}
                            >
                                <Column field={SELECTED_FIELD} width='50px' cell={GridCell} headerCell={HeaderCell} />
                                <Column field='FullName' title={translate('Name_Label')} cell={GridCell} />
                                <Column field='Email' title={translate('Email_Label')} cell={GridCell} />
                                <Column field='RoleName' title={translate('Role_Label')} cell={GridCell} />
                                <Column field='Phone' title={translate('NotificationsMobileNumber_Label')} cell={GridCell} />
                                <Column field='Milestones' title={translate('MilestoneSubscriptions_Label')} cell={GridCell} />
                                <Column field='OnHoldNotifications' title={translate('OnHoldNotifications_Label')} cell={GridCell} />
                                {/* <Column field='Language' title={translate('Language_Label')} cell={GridCell} /> */}
                                {/* <Column field='Type' title={translate('Type_Label')} cell={GridCell} /> */}
                                <Column field='Actions' title='Actions' cell={GridCell} locked />
                            </Grid>
                        </IntlProvider>
                    </LocalizationProvider>
                </>
            )}

            {/* No subsribers for this clearance */}
            {!isLoading &&
                !subscribe.isLoading &&
                !unsubscribe.isLoading &&
                !subscriptionUpdate.isLoading &&
                !selfSubscription.isLoading &&
                !selfSubscriptionUpdate.isLoading &&
                subscribers &&
                subscribers.length === 0 && (
                    <EmptyState
                        descriptionLabel={translate('NoSubscribersCurrently_Label')}
                        addButtonLabel={translate('AddASubscriberButton_Label')}
                        onAdd={() => toggleModal('add', true)}
                        ref={addSubscribersClearancesInitialRef}
                    />
                )}

            {/* Grid loading overlay to prevent user from taking further actions */}
            {(isLoading ||
                subscriptionUpdate.isLoading ||
                subscribe.isLoading ||
                unsubscribe.isLoading ||
                selfSubscription.isLoading ||
                selfSubscriptionUpdate.isLoading) &&
                subscribers &&
                subscribers.length > 0 && <GridLoadingOverlay parentElement='.k-grid.subscriber-to-single-clearance' />}

            {/* Status alerts for subscriptions */}
            <SubscriptionStatusAlerts />

            {/* Unsubscribe footer */}
            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 &&
                !modal.remove &&
                !isLoading &&
                !subscribe.isLoading &&
                !unsubscribe.isLoading &&
                !subscriptionUpdate.isLoading &&
                !selfSubscription.isLoading &&
                !selfSubscriptionUpdate.isLoading && (
                    <UnsubscribeFooter
                        amountLabel={translate('NumberOfRemoveSelected_Label', selectedSubscriberIds.length)}
                        primaryButtonLabel={translate('RemoveSelected_Label')}
                        onPrimary={() => toggleModal('remove', true)}
                        selectedSubscribers={selectedSubscriberIds.length}
                    />
                )}

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}
        </>
    );
};

export default React.memo(SubscribersGrid);
