import { createGenericSearchFilterSlice } from '../../../portal/redux/reducers/genericSearchFilterSlice';

const subscribersSearchFilterSlice = createGenericSearchFilterSlice({
    name: 'subscribersSearchFilter',
    initialState: { searchTerm: '' },
    reducers: {}
});

export const { setSearchFilter, clearSearchFilter } = subscribersSearchFilterSlice.actions;

export default subscribersSearchFilterSlice.reducer;
