import React from 'react';

interface SpinnerProps {
    size?: 'standard' | 'small';
    style?: React.CSSProperties;
    color?: 'blue' | 'white';
    className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 'standard', color = 'blue', style, className = '' }) => {
    let spinnerSize = {
        standard: '',
        small: 'spinner-border-sm'
    }[size.toLowerCase()];

    let spinnerColor = {
        blue: 'text-primary',
        white: 'text-light'
    }[color.toLowerCase()];

    return (
        <div className={`spinner-border ${spinnerColor} ${spinnerSize} ${className}`} role='status' style={style}>
            <span className='sr-only'>Loading...</span>
        </div>
    );
};

export default Spinner;
