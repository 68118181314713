import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../../axios-mlp';
import { imaging as Constants } from '../../../constants/mlp-constants';
import * as actions from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';
import DownloadFailure from './DownloadFailure';
import DownloadSuccess from "./DownloadSuccess";
import Waiting from "./Waiting";

class DownloadLobby extends Component {
    state = { model: {}, checkerID: null };

    componentDidMount() {
        this.props.onInitTranslations('Imaging.Imaging.DownloadLobby');
        const packageId = this.props.location.search.substring(
            this.props.location.search.indexOf('=') + 1,
            this.props.location.search.length
        );
        if (!packageId) {
            this.setState({ model: JSON.parse(this.props.data) });
        }

        this.checkStatus(packageId);
    }

    checkStatus = (packageId) => {
        if (this.state.checkerID !== null) return;
        if (this.state.model.PackageId) {
            packageId = this.state.model.PackageId;
        }
        const checkerID = window.setInterval(() => {
            if (this.state.model && packageId) {
                this.getData(packageId, checkerID);
            }
        }, 5000);

        this.setState({ checkerID: checkerID });
    };

    getData = (packageId, checkerID) => {
        let config = {
            params: {
                packageId: packageId
            },
            headers: { 'Cache-Control': 'no-store' }
        };
        axios
            .get('/Imaging/DownloadLobby', config)
            .then((response) => {
                const data = JSON.parse(response.data);
                if (response.error) {
                    toastr.error(response.error);
                }
                if (
                    checkerID !== null &&
                    (data.ImagingPackageStatusTypeID === Constants.imagingPackageStatusType.Completed ||
                        data.ImagingPackageStatusTypeID === Constants.imagingPackageStatusType.Cancelled ||
                        data.ImagingPackageStatusTypeID === Constants.imagingPackageStatusType.FaultTerminated ||
                        data.ImagingPackageStatusTypeID === Constants.imagingPackageStatusType.OverLimit  ||
                        data.ImagingPackageStatusTypeID === Constants.imagingPackageStatusType.IncorrectPassword) 
                ) {
                    window.clearInterval(checkerID);
                    this.setState({ model: {...data} });
                }
            })
            .catch((error) => {
                toastr.error(formatTranslated('Unexpected_Error_Label', this.props.translations.Phrases));
                window.clearInterval(checkerID);
            });
    };

    chooseDownloadComponent = () => {
        const packageModel = this.state.model;
        if(packageModel.ImagingPackageStatusTypeID !== undefined){
            switch (packageModel.ImagingPackageStatusTypeID) {
                case Constants.imagingPackageStatusType.New:
                case Constants.imagingPackageStatusType.InProgess:
                case Constants.imagingPackageStatusType.Submitted:
                    return <Waiting translations={this.props.translations} />;
                case Constants.imagingPackageStatusType.Cancelled:
                case Constants.imagingPackageStatusType.FaultTerminated:
                case Constants.imagingPackageStatusType.OverLimit:
                case Constants.imagingPackageStatusType.IncorrectPassword:
                    return <DownloadFailure translations={this.props.translations} errorMsg={packageModel.ErrorMsg} />;
                case Constants.imagingPackageStatusType.Completed:
                    return (
                        <DownloadSuccess
                            translations={this.props.translations}
                            url={packageModel.ImageUrl}
                            packageType={packageModel.PackageTypeID}
                        />
                    );
                default:
                    return '';
            }   
        }
    };

    render() {
        return (
            <div id='page'>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <Link className='breadcrumb-parent' to='/Imaging'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </Link>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div id='download-lobby'>
                                <div className='row'>
                                    <div className='col-sm-12'>{this.chooseDownloadComponent()}</div>
                                </div>
                            </div>
                            <iframe title='printframe' className='d-none' src={''} frameBorder='0'></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadLobby);
