import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';

class GridPaging extends Component {
    render() {
        return (
            <div className='mlp-paging'>
                <span className='btn btn-link btn-xl' onClick={() => this.props.onLoadImages()}>
                    {formatTranslated('LoadMore_Label', this.props.translations.Phrases)}
                </span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadImages: () => dispatch(actions.loadImages())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridPaging);
