import { GridCellProps } from '@progress/kendo-react-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ActionButtonElipsis from '../../../../portal/common/features/Grid/ActionButtonElipsis';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ActionButtonOptions } from '../../../common/constants/constants-team';
import { StatusUserSlice } from '../../../common/models/ReduxSlices';
import { openStatusModal, setIsActive, setUserId } from '../../../redux/reducers/statusUserSlice';
import { setEditMember } from '../../../redux/reducers/userSlice';
import { RootState } from '../../../redux/store';

export const ActionButton: React.FC<GridCellProps> = ({ dataItem, ...rest }) => {
    const { isActive } = useSelector<RootState, StatusUserSlice>((state) => state.statusUser);
    const translate = useTranslation();
    const dispatch = useDispatch();
    const { push } = useHistory();

    // Check available action button options
    const availableActionButtonOptions = ActionButtonOptions.filter((option) => {
        if (isActive) {
            return option.id !== 'REACTIVATE_USER';
        } else {
            return option.id !== 'DEACTIVATE_USER';
        }
    });

    const handleToggleActionPanel = () => {
        dispatch(setUserId(dataItem?.UserId));
        dispatch(setIsActive(dataItem?.IsActive));
    };

    const handleAction = (optionId: typeof ActionButtonOptions[number]['id']) => {
        switch (optionId) {
            case 'EDIT_USER': {
                dispatch(setEditMember(dataItem));
                return push(`/Team/TeamMember?userId=${dataItem?.UserId}`);
            }
            case 'CLONE_USER': {
                return push(`/Team/CloneNewUser?userId=${dataItem?.UserId}`);
            }
            case 'DEACTIVATE_USER': {
                dispatch(openStatusModal());
                return push('/Team');
            }
            case 'REACTIVATE_USER': {
                dispatch(openStatusModal());
                return push('/Team');
            }
            default:
                break;
        }
    };

    return (
        <ActionButtonElipsis
            options={availableActionButtonOptions.map((option) => ({ ...option, label: translate(option.label) }))}
            dangerOption={'DEACTIVATE_USER'}
            onAction={(option) => handleAction(option as typeof ActionButtonOptions[number]['id'])}
            onToggleActionPanel={handleToggleActionPanel}
            dataItem={dataItem}
            {...rest}
        />
    );
};
