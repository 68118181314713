import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Tooltip from '../../../portal/common/features/Tooltip/Tooltip';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { Contact } from '../../common/models/Contact';
import { ActionButton } from './ActionButton';

interface DataItem extends Contact {
    selected?: boolean;
    isNew?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    isLocked: boolean;
    isAdmin: boolean;
    handleRemoveContact: (id?: null | number) => void;
    handleEditContact: (id?: null | number) => void;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({ field = '', isLocked, isAdmin, dataItem, selectionChange, ...rest }) => {
    const translate = useTranslation();
    const [isOverflown, setIsOverflown] = useState(false);
    const tdRef = useRef<HTMLTableCellElement>(null);
    const syntheticSpanRef = useRef<HTMLSpanElement>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //This function calculates the length of the string that can fit in td
    const displayCorrect = (str: string, desiredLength: number | undefined) => {
        if (!desiredLength) return str;

        let min = 0;
        let max = str.length;
        let bestFit = '';

        while (min <= max) {
            const mid = Math.floor((min + max) / 2);
            const testStr = str.substring(0, mid);

            syntheticSpanRef.current!.textContent = testStr;

            if (syntheticSpanRef.current!.offsetWidth < desiredLength) {
                bestFit = testStr;
                min = mid + 1;
            } else {
                max = mid - 1;
            }
        }

        return bestFit.substring(0, bestFit.length - 5) + '...';
    };

    let cellContent: ReactNode;

    switch (field) {
        case 'selected':
            cellContent = <Checkbox value={!!dataItem.selected} onChange={selectionChange} />;
            break;
        case 'FullName':
            cellContent = dataItem.FullName;
            break;
        case 'Email':
            cellContent = dataItem.Email;
            break;
        case 'MobileNumber':
            cellContent = dataItem.MobileNumber;
            break;
        case 'RoleLabel':
            cellContent = translate(dataItem.RoleLabel);
            break;
        case 'Language':
            cellContent = dataItem.Language !== 'fr' ? 'EN' : 'FR';
            break;
        case 'CarrierCodes':
            cellContent = dataItem.CarrierCodes;
            break;
        case 'CreatedByUsername':
            cellContent = dataItem.CreatedByUsername;
            break;
        case 'ModifiedByUsername':
            cellContent = dataItem.ModifiedByUsername;
            break;
        case 'TeamAdminUserFullName':
            const fieldValue = dataItem.TeamAdminUserFullName
                ? `${dataItem.TeamAdminUserFullName} - ${dataItem?.TeamAdminUserCompanyName}`
                : '';
            cellContent = fieldValue;
            break;
        case 'Actions':
            cellContent = <ActionButton dataItem={dataItem} {...rest} disabled={!isAdmin || dataItem?.selected! || dataItem?.isNew!} />;
            break;
        default:
            cellContent = null;
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (syntheticSpanRef.current && tdRef.current) {
            if (tdRef.current?.offsetWidth < syntheticSpanRef.current.offsetWidth) setIsOverflown(true);
        }
    }, [windowWidth]);

    const displayContent = isOverflown
        ? displayCorrect(typeof cellContent === 'string' ? cellContent : '', tdRef.current?.offsetWidth)
        : cellContent;

    return (
        <td
            ref={tdRef}
            className={`${rest.className}
                 ${dataItem?.selected ? 'k-state-selected' : ''} 
                 ${dataItem?.isNew ? 'new-row-highlight' : ''} 
                 ${field === 'Actions' ? 'contacts-grid-actions-cell' : ''}`}
            style={{
                ...rest.style,
                textAlign: field === 'selected' ? 'center' : 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}
        >
            {/*This is an invisible span used to calculate width of string in the td*/}
            <span ref={syntheticSpanRef} style={{ position: 'absolute', visibility: 'hidden' }}>
                {cellContent}
            </span>
            {['CreatedByUsername', 'ModifiedByUsername', 'TeamAdminUserFullName', 'Email'].includes(field) ? (
                <Tooltip label={cellContent as string}>
                    {(displayContent! as string).length > 30
                        ? (displayContent! as string).substring(0, 30) + '...'
                        : (displayContent! as string)}
                </Tooltip>
            ) : (
                displayContent
            )}
        </td>
    );
};

export default FormattedGridCell;
