import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoints } from '../../../../../portal/common/constants/constants-portal';
import SearchBox from '../../../../../portal/common/features/FilterBar/SearchBox/SearchBox';
import useMediaQuery from '../../../../../portal/common/hooks/useMediaQuery';
import useSearchTags from '../../../../../portal/common/hooks/useSearchTags';
import useTranslation from '../../../../../portal/common/hooks/useTranslation';
import { AdvancedSearchSettingsSlice } from '../../../../common/models/ReduxSlices';
import { clearAllTags, resetAdvancedSearchSettings, setTags, setTagsInput } from '../../../../redux/reducers/advancedSearchSettingsSlice';
import { resetDateRange, setDateRangeSelected } from '../../../../redux/reducers/dateRangeSlice';
import { resetSearchFilter, setSearchFilter } from '../../../../redux/reducers/searchSlice';
import { resetMOTFilter, resetShipmentGridSettings, setPageSelected } from '../../../../redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../../redux/reducers/shipmentStatusSlice';
import { RootState } from '../../../../redux/store';

const ClearancesSearchBox = () => {
    const advancedSearchSettings = useSelector<RootState, AdvancedSearchSettingsSlice>((state) => state.advancedSearchSettings);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
    const isDesktop = useMediaQuery(Breakpoints.Mobile);
    const { value, handleBlur, handleChange, handleKeyDown, handleReset } = useSearchTags({
        inputValue: advancedSearchSettings.tagsInput,
        onEnterKey: (payload) => handleSubmit(payload)
    });
    const translate = useTranslation();
    const dispatch = useDispatch();

    // Automatically hide mobile search when not on mobile resolution
    useEffect(() => {
        if (isDesktop) {
            setShowMobileSearch(false);
        }
    }, [isDesktop]);

    const toggleMobileSearchBox = () => {
        setShowMobileSearch((prevState) => !prevState);
    };

    // Reset all filters to defaults
    const resetAllFilters = () => {
        dispatch(resetDateRange());
        dispatch(resetStatus());
        dispatch(resetSearchFilter());
        dispatch(resetAdvancedSearchSettings());
        dispatch(resetShipmentGridSettings());
        dispatch(resetMOTFilter());
    };

    // Toggle the visibility of the advanced search
    const toggleAdvancedSearch = () => {
        setShowAdvancedSearch((prevState) => !prevState);
    };

    // Handle clearing the tags input field
    const handleClearInputValue = () => {
        handleReset();
        dispatch(clearAllTags());
        dispatch(resetSearchFilter());
    };

    const handleSubmit = (payload?: { filteredInput: string; filteredTags: string[] }) => {
        let searchTerm: { inputValue: string; tags: string[] };

        if (payload?.filteredInput) {
            searchTerm = { inputValue: payload.filteredInput, tags: payload.filteredTags };
        } else {
            searchTerm = { inputValue: value, tags: value.split(', ') };
        }

        // Reset all filters if tags array is empty and previous search was conducted
        if (!searchTerm.tags.length) {
            resetAllFilters();
        } else {
            // Set dateRange dropdown to "All time"
            dispatch(setDateRangeSelected(5));
            // Set status radio buttons filter to "All"
            dispatch(resetStatus());
            // Update the advancedSearchFilters slice
            dispatch(setSearchFilter(searchTerm.tags));
            dispatch(setTagsInput(searchTerm.inputValue));
            dispatch(setTags(searchTerm.tags));
            dispatch(resetMOTFilter());
        }
        // If mobile search is open close it
        if (showMobileSearch) {
            toggleMobileSearchBox();
        }
        // Set grid page to 1
        dispatch(setPageSelected(1));
    };

    return (
        <SearchBox
            inputValue={value}
            placeholder={translate('SearchPlaceholder_Label')}
            submitButtonText={translate('SearchClearancesTitle_Label')}
            advancedSearchPlaceholder={translate('AdvancedSearchPlaceholder_Label')}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onToggleMobileSearch={toggleMobileSearchBox}
            onToggleAdvancedSearch={toggleAdvancedSearch}
            onClearInputValue={handleClearInputValue}
            onSubmit={handleSubmit}
            showAdvancedSearch={showAdvancedSearch}
            showMobileSearch={showMobileSearch}
            enableAdvancedSearch
        />
    );
};

export default ClearancesSearchBox;
