import { ShipmentHeader } from '../../../clearances/common/models/ShipmentHeader';
import { Notifications } from '../models/ResponseData';

export const getCountryFromClientNumber = (clientNumber: string): 'ca' | 'us' => {
    return parseInt(clientNumber[0]) < 4 ? 'ca' : 'us';
};

export const getMilestoneCount = (notifObj: Notifications) => {
    let counter = 0;
    Object.entries(notifObj).forEach(([key, value]) => {
        if (!key.includes('OnHold') && value) counter++;
    });

    return counter;
};

export const checkAllFalsy = (obj: Partial<ShipmentHeader> | null): boolean => {
    if (obj !== null) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key as keyof ShipmentHeader]) {
                return true;
            }
        }
        return false;
    } else {
        return false;
    }
};
