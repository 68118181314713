import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_CLEARANCES_PATH } from '../../../portal/common/constants/constants-portal';
import { Subscription } from '../../../portal/common/models/ClearanceSubscriptions';
import { TeamMembersAndContacts } from '../../../portal/common/models/ComboBoxSubscribers';
import { Notifications, SubscriptionsWithClientNumbersData } from '../../../portal/common/models/ResponseData';
import { SubscribersData, SubscriptionUpdateData, UnsubscribeData } from '../../common/models/ResponseData';
import { SelectedSubscriber } from '../../common/models/Subscriber';
import {
    GET_SUBCRIBER_SUBSCRIPTIONS,
    GET_TEAM_MEMBERS_AND_CONTACTS,
    SET_SUBSCRIBERS_DATA,
    SUBSCRIBE_USERS,
    UNSUBSCRIBE_USERS,
    UPDATE_SUBSCRIBERS
} from './actionTypes';

export const GetUserSubscribersData = createAsyncThunk(
    SET_SUBSCRIBERS_DATA,
    async (
        GetClearanceNotificationsRequest: {
            ProfileEmail: string | undefined;
            ProfileMobileNumber: string | undefined;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<SubscribersData>(
                `${API_CLEARANCES_PATH}/clearance/global-subscribers`,
                GetClearanceNotificationsRequest
            );
            return response.data.Subscribers;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const GetSubscriberSubscriptions = createAsyncThunk(
    GET_SUBCRIBER_SUBSCRIPTIONS,
    async (
        GetClearanceNotificationsRequest: {
            ProfileEmail: string | undefined | null;
            ProfileMobileNumber: string | undefined | null;
            UserIds: string[] | undefined | null;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<SubscriptionsWithClientNumbersData>(
                'api/clearance/clearance/get-subscriber-subscriptions',
                GetClearanceNotificationsRequest
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const SubscribeUsers = createAsyncThunk(
    SUBSCRIBE_USERS,
    async (
        payload: {
            customerNumbers: string[];
            subscribers: Subscription[];
            notificationsUS: Notifications[];
            notificationsCA: Notifications[];
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(`${API_CLEARANCES_PATH}/clearance/create-global-subscribers`, {
                Name: '',
                UserId: '',
                CustomerNumbers: payload.customerNumbers,
                Subscribers: payload.subscribers,
                MilestoneNotifications_US: payload.notificationsUS,
                MilestoneNotifications_CA: payload.notificationsCA
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UnsubscribeUsers = createAsyncThunk(UNSUBSCRIBE_USERS, async (payload: SelectedSubscriber[] | null, { rejectWithValue }) => {
    try {
        const response = await axios.put<UnsubscribeData>(`${API_CLEARANCES_PATH}/clearance/unsubscribe-global-subscribers`, {
            Subscribers: payload,
            UserIds: payload?.map((s) => s.UserId)
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const UpdateSubscription = createAsyncThunk(
    UPDATE_SUBSCRIBERS,
    async (
        payload: {
            customsInfoId: number;
            requestModel: Subscription;
            milestones: null | Notifications[];
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<SubscriptionUpdateData>(
                `${API_CLEARANCES_PATH}/clearance/${payload.customsInfoId}/update-milestones-for-clearance-subscriber`,
                {
                    SubscriptionId: payload.requestModel.SubscriptionId,
                    CustomerNumber: payload.requestModel.CustomerNumber,
                    MilestoneNotifications: payload.milestones,
                    FullName: payload.requestModel.FullName,
                    Language: payload.requestModel.Language,
                    Email: payload.requestModel.Email,
                    Phone: payload.requestModel.Phone,
                    RoleName: payload.requestModel.RoleName
                }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const GetTeamMembersAndContacts = createAsyncThunk(GET_TEAM_MEMBERS_AND_CONTACTS, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<TeamMembersAndContacts>(`${API_CLEARANCES_PATH}/clearance/get-team-members-externalcontacts`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
