import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../portal/common/features/Spinner';
import useGridLanguage from '../../../../portal/common/hooks/useGridLanguage';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
import { CadDetailsSlice, ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { GetFullCADData } from '../../../redux/actions/cadDetails';
import { RootState } from '../../../redux/store';
import NoDataResponse from '../lineDetails/NoDataResponse';
import FormattedGridCell from './FormatedGridCell';

const CADDetailsGrid = () => {
    const { shipments, returnFields, error, isLoading } = useSelector<RootState, CadDetailsSlice>((state) => state.cadDetails);
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const gridLanguage = useGridLanguage(selectedLanguage);
    const translate = useTranslation();
    const dispatch = useDispatch();
    const gridRef = useRef(null);
    const formattedCell = (props: GridCellProps) => <FormattedGridCell {...props} selectedEntries={undefined} />;

    // Get Full CAD data
    useEffect(() => {
        dispatch(GetFullCADData({ entryNumber: shipmentDetails?.EntryNumber! }));
    }, [dispatch]);

    return (
        <>
            {returnFields && shipments && !error && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <Grid ref={gridRef} className='flex-fill' data={shipments} total={shipments.length}>
                            {returnFields.map((column) => (
                                <Column
                                    key={column}
                                    field={column}
                                    title={column === 'CvdRate' ? translate(column + 'CAD_Label') : translate(column + '_Label')}
                                    cell={formattedCell}
                                    width={200}
                                />
                            ))}
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            )}
            {isLoading && (
                <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                    <Spinner />
                </div>
            )}
            {(!returnFields?.length || !shipments?.length || error) && !isLoading && (
                <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                    <NoDataResponse labelToDisply='NoCADDataAvailable_Label' />
                </div>
            )}
        </>
    );
};

export default CADDetailsGrid;
