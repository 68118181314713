import { Component } from 'react';
import { connect } from 'react-redux';
import { global } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';

class ParentServiceHeader extends Component {
    state = {
        roleVal: ''
    };

    componentDidMount() {
        if (this.props.userRoles) {
            this.setState({ roleVal: this.props.userRoles.find((role) => role.IsSelected).Value });
        }
        if (this.props.pendingUser) {
            this.setState({ roleVal: this.props.pendingUser.Roles.find((role) => role.IsSelected).Value });
        }
    }

    allFeaturesSelected = (featureId) => {
        return this.props.parentService.ClientServices.map((s) => {
            return s.Features.find((f) => {
                return f.FeatureId === featureId;
            });
        }).every((f) => {
            return f.IsSelected;
        });
    };

    selectAllFeatures = (featureId) => {
        this.props.selectAllFeaturesCA(this.props.countryIndex, this.props.parentIndex, featureId, this.props.area);
    };

    render = () => {
        let featuresHeader = [];
        let selectAllHeader = [];

        this.props.parentService.AvailableFeatures.forEach((f) => {
            const selectAllText = this.allFeaturesSelected(f.FeatureId)
                ? this.props.translations.Phrases['SelectNone_Label']
                : this.props.translations.Phrases['SelectAll_Label'];

            if (!f.IsSelected) return;

            featuresHeader.push(
                this.state.roleVal === 'CentralAdmin' && f.Name === 'Clearances' ? null : (
                    <th key={'availableFeatures-' + f.FeatureId} className='text-center'>
                        {/* {f.Name} */}
                        {this.state.roleVal === 'CentralAdmin' && f.Name === 'My Livingston Portal' ? 'Clearances' : f.Name}
                    </th>
                )
            );

            if (this.props.isRoleDropdownChanged) return;

            let feature;

            const findFeature = (countryIndex) => {
                const clientServicesByCountry =
                    this.props.viewModel.ClientServicesByCountry[countryIndex] ??
                    this.props.onBehalfOfModel.ClientServicesByCountry[countryIndex];

                if (clientServicesByCountry) {
                    const parentServices = clientServicesByCountry.ParentServices;
                    if (parentServices?.length) {
                        const clientServices = parentServices.map((x) => x.ClientServices);
                        const features = clientServices.find((parentService) => {
                            if (parentService.length) {
                                return parentService[0].Number === this.props.parentService.Number;
                            }
                        });
                        if (features?.length) {
                            return features[0].Features.find((x) => x.FeatureId === f.FeatureId);
                        }
                    }
                }

                return undefined;
            };

            feature = findFeature(0) || findFeature(1);

            const isSelectAllAvailable = () => {
                // Edit Client Admin - if ClientStatuses is populated and feature is not selected -> disable 'Select All' option
                if (
                    this.props.viewModel.User.ClientStatuses &&
                    this.props.viewModel.User.ClientStatuses[this.props.parentService.Number] &&
                    feature &&
                    !feature.IsSelected &&
                    feature.FeatureCode !== global.MLP_FEATURE_CODE &&
                    feature.FeatureCode !== global.CLEARANCES_FEATURE_CODE
                ) {
                    return null;
                }

                // Add New User - if the client account is inactive and feature is not selected -> disable 'Select All' option
                if (
                    this.props.selectedClients &&
                    this.props.selectedClients.find((x) => x.number === this.props.parentService.Number)?.status &&
                    feature &&
                    !feature.IsSelected &&
                    feature.FeatureCode !== global.MLP_FEATURE_CODE &&
                    feature.FeatureCode !== global.CLEARANCES_FEATURE_CODE
                ) {
                    return null;
                }

                return this.selectAllFeatures.bind(this, f.FeatureId);
            };

            selectAllHeader.push(
                this.state.roleVal === 'CentralAdmin' && f.Name === 'Clearances' ? null : (
                    <td key={'selectAll-' + f.FeatureId} className='text-center'>
                        <span className='btn btn-link' onClick={isSelectAllAvailable()}>
                            {selectAllText}
                        </span>
                    </td>
                )
            );
        });

        const selectAllRow = (
            <tr>
                <td colSpan='5'></td>
                {this.props.readonly ? '' : selectAllHeader}
                <td></td>
            </tr>
        );

        return (
            <thead>
                <tr>
                    <th>{this.props.translations.Phrases['ServiceNumber_Label']}</th>
                    <th>{this.props.translations.Phrases['ServiceName_Label']}</th>
                    <th>Status</th>
                    <th>{this.props.translations.Phrases['ClientAdminName_Label']}</th>
                    <th>{this.props.translations.Phrases['ServiceCoordinator_Label']}</th>
                    {featuresHeader}
                    <th></th>
                </tr>
                {selectAllRow}
            </thead>
        );
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectAllFeaturesCA: (countryIndex, parentIndex, featureId, area) =>
            dispatch(actions.selectAllFeaturesCA(countryIndex, parentIndex, featureId, area))
    };
};
const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        viewModel: state.accessManagement.model,
        onBehalfOfModel: state.onBehalfOf.model
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParentServiceHeader);
