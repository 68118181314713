import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../portal/common/models/ReduxSlices';
import TeamSectionWrapper from '../../common/features/TeamSectionWrapper';
import { EditTeamMemberSlice } from '../../common/models/ReduxSlices';
import { RootState } from '../../redux/store';
import UserDetailsEditable from './UserDetails/UserDetailsEditable';
import UserDetailsNonEditable from './UserDetails/UserDetailsNonEditable';

const EditTeamMemberUserDetails = () => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { data: userDetailsData, isLoading: userDetailsLoading } = useSelector<RootState, EditTeamMemberSlice>(
        (state) => state.editTeamMember
    );
    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const isPending = useMemo(() => (userDetailsData?.Users?.[0]?.Status ?? '').includes('Pending'), [userDetailsData?.Users]);
    const translate = useTranslation();

    return (
        <TeamSectionWrapper title={translate('TeamMemberUserDetailsHeader_Label')} isLoading={userDetailsLoading}>
            {isEditable && !isPending ? <UserDetailsEditable /> : <UserDetailsNonEditable />}
        </TeamSectionWrapper>
    );
};

export default EditTeamMemberUserDetails;
