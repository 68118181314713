import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

interface ToastWrapperProps {
    type: 'error' | 'success' | 'warning' | 'notice-alert' | 'on-hold-alert';
    timeout: number;
    children: React.ReactNode;
}

const ToastWrapper: React.FC<ToastWrapperProps> = ({ type, children, timeout }) => {
    const [showAnimation, setShowAnimation] = useState<boolean>(false);
    const nodeRef = useRef(null);

    useEffect(() => {
        const enterDelay = setTimeout(() => setShowAnimation(true), 50);
        const hideDelay = setTimeout(() => setShowAnimation(false), timeout);
        return () => {
            clearTimeout(enterDelay);
            clearTimeout(hideDelay);
        };
    }, [timeout]);

    const parentContainer =
        document.querySelector(
            `#portalToasts .toasts-wrapper${type === 'notice-alert' || type === 'on-hold-alert' ? '.alert-toasts-wrapper' : ''}`
        ) ?? document.body;

    return ReactDOM.createPortal(
        <CSSTransition
            in={showAnimation}
            timeout={200}
            classNames={{
                appear: 'fade',
                appearActive: 'fade showing',
                appearDone: 'fade show',
                enter: 'fade',
                enterActive: 'fade showing',
                enterDone: 'fade show',
                exit: 'fade',
                exitActive: 'fade',
                exitDone: 'fade hide'
            }}
            unmountOnExit
            nodeRef={nodeRef}
        >
            <div
                className={`toast ${type}`}
                ref={nodeRef}
                role={type === 'notice-alert' || type === 'on-hold-alert' ? 'alert' : undefined}
                aria-live={type === 'notice-alert' || type === 'on-hold-alert' ? 'assertive' : undefined}
                aria-atomic={type === 'notice-alert' || type === 'on-hold-alert' ? 'true' : undefined}
            >
                {children}
            </div>
        </CSSTransition>,
        parentContainer
    );
};

export default ToastWrapper;
