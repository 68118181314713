import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HydrationSlice } from '../../../portal/common/models/ReduxSlices';
import { RootState } from '../../redux/store';
import { ClearanceDestinationFilter } from '../constants/constants-clearances';

/**
 * Hook for setting the destinations based on the user's Client Caption Numbers.
 * Destinations are determined by the following:
 * - USA: Client Caption Number >= 4
 * - Canada: Client Caption Number < 4
 * - All: USA + Canada
 * Numbers for countries:
 * - 0: All
 * - 1: Canada
 * - 2: USA
 * The destination object: {id: string, label: string}
 *
 */

const useDestinations = () => {
    const [result, setResult] = useState<null | { id: string; label: string }[]>(null);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);

    useEffect(() => {
        if (user?.ClientCaptionNumbers) {
            const allClientNumbers = user.ClientCaptionNumbers.map((item) => (Number(item.charAt(0)) >= 4 ? 2 : 1));
            let determinedDestination = allClientNumbers.every((item, index, arr) => item === arr[0]) === true ? allClientNumbers[0] : 0;
            // All countries = 0
            if (determinedDestination === 0) {
                setResult(ClearanceDestinationFilter);
            } else {
                // Canada = 1 or USA = 2
                setResult([ClearanceDestinationFilter[determinedDestination]]);
            }
        }
    }, [user?.ClientCaptionNumbers]);
    return result;
};

export default useDestinations;
