import React from 'react';
import ReactDOM from 'react-dom';

interface TooltipWrapperProps {
    children: React.ReactNode;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({ children }) => {
    const parentContainer = document.querySelector('#portalTooltip') ?? document.body;

    return ReactDOM.createPortal(<div>{children}</div>, parentContainer);
};

export default TooltipWrapper;
