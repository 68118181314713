import React from 'react';
import { formatTranslated } from '../../../utils/mlp-utils';

const Waiting = (props) => {
    return (
        <div>
            <div>
                <h3>{formatTranslated('Processing_Label', props.translations.Phrases)}</h3>
            </div>
            <div className='row'>
                <div className='col-sm-12'>{formatTranslated('Waiting_For_Download_Label', props.translations.Phrases)}</div>
            </div>
        </div>
    );
};

export default Waiting;
