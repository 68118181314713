import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../portal/common/features/ProtectedRoute';
import CloneTeamMember from './screens/CloneTeamMember/CloneTeamMember';
import CreateNewTeamMember from './screens/CreateNewTeamMember/CreateNewTeamMember';
import EditTeamMember from './screens/EditTeamMember/EditTeamMember';
import TeamGrid from './screens/TeamGrid/TeamGrid';

const Team = () => {
    return (
        <ProtectedRoute>
            <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
                <div className='d-flex flex-column flex-fill'>
                    <Switch>
                        <Route path={['/Team/TeamMember', '//Team//TeamMember']} exact component={EditTeamMember} />
                        <Route path={['/Team', '//Team']} exact component={TeamGrid} />
                        <Route path={['/Team/CreateNewUser', '//Team//CreateNewUser']} exact component={CreateNewTeamMember} />
                        <Route path={['/Team/CloneNewUser', '//Team//CloneNewUser']} exact component={CloneTeamMember} />
                    </Switch>
                </div>
            </main>
        </ProtectedRoute>
    );
};

export default React.memo(Team);
