import PropTypes from 'prop-types';
import { Component, React } from 'react';
import { connect } from 'react-redux';
import { formatTranslated } from '../../utils/mlp-utils';
import WidgetSelectorGrid from './WidgetSelectorGrid';

class WidgetSelectorModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool
    };

    static defaultProps = {
        isOpen: false,
        CancelButtonText: 'Cancel',
        OkButtonText: 'OK'
    };

    render() {
        const modalTitle = formatTranslated('Widget_Selector_Title_Label', this.props.translations.Phrases);
        const modalHeaderText = formatTranslated('Widget_Selector_Header_Text', this.props.translations.Phrases).split('.');
        let widgetData;

        if (this.props.data !== undefined && this.props.data !== [] && Array.isArray(this.props.data)) {
            widgetData = this.props.data.filter((item) => {
                return item.CountryCode === this.props.countryCode;
            });
        }
        return (
            <div className={this.props.isOpen ? 'modal modal-overlay d-block' : 'modal fade d-none'} role='dialog' aria-hidden='false'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='widget-modal-header'>
                            <h3 className='bold-title'>{modalTitle}</h3>
                            <p>
                                {modalHeaderText[0]}.<br />
                                {modalHeaderText[1]}.
                            </p>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-12'>
                                    <WidgetSelectorGrid
                                        data={widgetData}
                                        onToggleWidget={this.props.onToggleWidget}
                                        onToggleSelect={this.props.onToggleSelect}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <div className='col-sm-6 col-12'>
                                <button type='button' className='button grey large full-width' onClick={this.props.onRequestClose}>
                                    {this.props.CancelButtonText}
                                </button>
                            </div>
                            <div className='col-sm-6 col-12'>
                                <button type='button' className='button blue large full-width' onClick={this.props.onRequestConfirm}>
                                    {this.props.OkButtonText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(WidgetSelectorModal);
