import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface HeaderNavItemProps {
    url: string;
    title: string;
    icon: IconDefinition;
    isActive: boolean;
    target: React.HTMLAttributeAnchorTarget;
    onClick?: () => void;
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({ url, title, icon, isActive, target, onClick }) => {
    return (
        <Link
            className={`dropdown-item d-flex justify-content-between align-items-stretch ${isActive ? 'active' : ''}`}
            to={url}
            target={target}
            style={isActive ? { backgroundColor: 'inherit' } : undefined}
            onClick={onClick}
        >
            <div className='mr-3 d-flex align-items-center'>
                <FontAwesomeIcon icon={icon} className='mr-3' />
                {title}
            </div>
        </Link>
    );
};

export default HeaderNavItem;
