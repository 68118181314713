import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown from '../../../common/Dropdown/Dropdown';
import Textbox from '../../../common/Textbox/Textbox';
import * as actions from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';
import GridPaging from './GridPaging';
import SearchGrid from './SearchGrid';

class SearchGridContainer extends Component {
    modelNotSet = () => {
        return this.props.gridModel === null || this.props.gridModel === undefined || this.props.gridModel.length === 0;
    };

    render() {
        if (this.modelNotSet()) {
            return (
                <div className='imaging-search-grid-container text-center'>
                    <div id='grid-loader'>
                        <div className='spinner'></div>
                    </div>
                    <h3>{formatTranslated('NoResults_Label', this.props.translations.Phrases)}</h3>
                </div>
            );
        }

        let groupBy = null;

        if (this.props.groupByOptions.length > 1) {
            groupBy = (
                <div className='col-sm-6 justify-content-end'>
                    <div className='row'>
                        <div className='col-sm-5 group-by-section text-right'>
                            <span className='group-by-title'>{formatTranslated('GroupBy_Label', this.props.translations.Phrases)}</span>
                        </div>
                        <div className='col-sm-7 group-by-section'>
                            <Dropdown
                                name={'ImageGroupings'}
                                options={this.props.groupByOptions}
                                noHeader
                                textField='TranslatedName'
                                valueField='CalculatedValue'
                                onItemChanged={this.props.onSelectGroupByOption}
                            />
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.groupByOptions.length === 1) {
            groupBy = (
                <div className='col-sm-5 group-by-section'>
                    <span className='group-by-title'>{formatTranslated('GroupBy_Label', this.props.translations.Phrases)}</span>
                    <Textbox noHeader value={this.props.groupByOptions[0].TranslatedName} readonly={true} />
                </div>
            );
        }

        let paging = null;
        if (this.props.showPaging) {
            paging = <GridPaging />;
        }

        const entryNumber = formatTranslated(
            'EntryNumber_Label',
            this.props.translations.Phrases,
            this.props.gridModel.length.toLocaleString(),
            this.props.totalEntries.toLocaleString()
        );

        return (
            <div className='imaging-search-grid-container'>
                <div id='grid-loader'>
                    <div className='spinner'></div>
                </div>
                <div className='row search-grid-container-header justify-content-between'>
                    <div className='col-sm-6 search-title-section'>
                        <h3 className='result-title'>Search Results</h3>
                        <h4 className='result-count'>
                            {formatTranslated('Showing_Label', this.props.translations.Phrases)}{' '}
                            {this.props.totalLoadedResults.toLocaleString()}{' '}
                            {formatTranslated('ShowingSeparator_Label', this.props.translations.Phrases)}{' '}
                            {this.props.totalResults.toLocaleString()} {formatTranslated('Results_Label', this.props.translations.Phrases)}{' '}
                            {entryNumber}
                        </h4>
                    </div>
                    {groupBy}
                </div>

                <SearchGrid
                    maxSelectedDocuments={this.props.maxSelectedDocuments}
                    entries={this.props.gridModel}
                    selectedSearchTypeCode={this.props.selectedSearchTypeCode}
                />

                <div className='row mlp-table-footer'>
                    <div className='col-sm-12 text-center'>{paging}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectGroupByOption: (newIndex, oldIndex) => dispatch(actions.selectGroupByOption(newIndex, oldIndex))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchGridContainer);
