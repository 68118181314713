import { ClientSelectionData } from '../../../../portal/common/models/ResponseData';
import { ClientChooserRow } from './ClientChooser';

export const convertSelectedClients = (clients: ClientSelectionData['ClientServiceSelection']['ClientServices']): ClientChooserRow[] => {
    if (!clients.length) return [];

    const uniqueArr = formatClients(clients) as ClientSelectionData['ClientServiceSelection']['ClientServices'];

    const convertedArr: ClientChooserRow[] = uniqueArr.map((c1) => ({
        name: c1.Name || 'Null',
        country: c1.CountryCode,
        number: c1?.Number,
        fullNumber: c1?.FullNumber,
        options: clients
            .filter((c2) => c2.Number === c1.Number)
            .map((c3) => ({
                name: c3.Name || 'Null',
                selected: c3.IsSelected,
                caption: c3?.Caption,
                fullNumber: c3.FullNumber
            }))
    }));

    return convertedArr;
};

export const revertSelectedClients = (
    clients: ClientChooserRow[],
    reduxClients: ClientSelectionData['ClientServiceSelection']['ClientServices']
): ClientSelectionData['ClientServiceSelection']['ClientServices'] => {
    if (!clients.length) return [];

    const revertedArr: ClientSelectionData['ClientServiceSelection']['ClientServices'] = reduxClients.map((c1) => ({
        ...c1,
        IsSelected: clients.find((c2) => c2.number === c1.Number)?.options.find((c3) => c3.caption === c1.Caption)?.selected || false
    }));

    return revertedArr;
};

// Formats initial client services array that we get from API
export const formatClients = (
    clients: ClientSelectionData['ClientServiceSelection']['ClientServices']
): ClientSelectionData['ClientServiceSelection']['ClientServices'] => {
    const uniqueArr = [] as ClientSelectionData['ClientServiceSelection']['ClientServices'];

    clients
        // Remove any without data
        .filter((c) => c.Number && c.Caption && c.CountryCode && c.FullNumber)
        // Filter only unique
        .filter(function (item) {
            const i = uniqueArr.findIndex((x) => x.FullNumber === item.FullNumber || x.Number === item.Number);
            if (i <= -1) {
                uniqueArr.push(item);
            }
            return null;
        });

    return uniqueArr;
};
