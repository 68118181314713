import React from 'react';
import Spinner from '../../../portal/common/features/Spinner';
import { IFC } from '../models/IFC';

interface Props extends IFC {
    title: string;
    isLoading?: boolean;
}

const TeamSectionWrapper: React.FC<Props> = ({ children, title, isLoading }) => {
    return (
        <div className='row'>
            <div className='col'>
                <div className='card mb-3'>
                    <div className='card-header'>
                        <h4>{title}</h4>
                    </div>
                    <div className='card-body'>{isLoading ? <Spinner /> : children}</div>
                </div>
            </div>
        </div>
    );
};

export default TeamSectionWrapper;
