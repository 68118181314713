interface TeamHeaderProps {
    title: String;
}

const TeamHeader: React.FC<TeamHeaderProps> = ({ title }) => {
    return (
        <div className='header-bar bg-white border-bottom-light shadow-sm'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <h1>{title}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamHeader;
