import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, GridCellProps, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { User } from '../models/CreateNewUser';

interface AddNewUserTableProps {
    data: User[];
    onRemoveUser: (index: number) => void;
}

const AddNewUserTable: React.FC<AddNewUserTableProps> = ({ data: users, onRemoveUser }) => {
    const [gridData, setGridData] = useState<User[]>([]);
    const [newRowAdded, setNewRowAdded] = useState<boolean>(false);
    const translate = useTranslation();

    useEffect(() => {
        if (newRowAdded) {
            const element = $('.add-new-user-table').find('.k-master-row:nth-child(-n+1)');
            const x = Math.random();
            element.toggleClass('new-row-highlight', true);
            const delay = setTimeout(
                (e) => {
                    e.toggleClass('new-row-highlight', false);
                    setNewRowAdded(false);
                },
                5000,
                element,
                x
            );
            return () => {
                clearTimeout(delay);
            };
        }
    }, [newRowAdded]);

    useEffect(() => {
        if (users.length) {
            if (gridData < users) {
                setNewRowAdded(true);
            }
            setGridData(users);
        }
    }, [users, gridData]);

    const addRemoveButton = (props: GridCellProps) => {
        return (
            <td className={'text-center'}>
                <button type='button' className='btn btn-outline-danger btn-sm' onClick={() => onRemoveUser(props.dataIndex)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </td>
        );
    };

    const totalUsersAdded = () => {
        if (gridData.length === 0) {
            return translate('AddUsersNoUsersMessage_Label');
        } else {
            return translate('StatusAlertUsersAdded_Label', gridData.length);
        }
    };

    return (
        <div className='row'>
            <div className='col-12'>
                <h5 className='border-top mt-4 mb-3 pt-3'>{totalUsersAdded()}</h5>
                <Grid className='add-new-user-table' data={users}>
                    <GridNoRecords>{translate('AddUsersNoUsersMessage_Label')}</GridNoRecords>
                    <Column field='FirstName' title={translate('AddUsersColumnName_Label')} />
                    <Column field='Email' title={translate('AddUsersColumnEmail_Label')} />
                    <Column field='CompanyName' title={translate('AddUsersColumnCompany_Label')} />
                    <Column field='MobileNumber' title={translate('AddUsersColumnMobile_Label')} />
                    <Column field='UserRole' title={translate('AddUsersColumnUserRole_Label')} />
                    <Column field='RemoveButton' cell={addRemoveButton} locked />
                </Grid>
            </div>
        </div>
    );
};

export default React.memo(AddNewUserTable);
