import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { teamManagement as Constants, userStatus as StatusConstants } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import StatusChangeLink from './StatusChangeLink';

class MemberRow extends Component {
    statusChangeLink = (isIcon) => {
        if (this.props.canDelete) {
            return (
                <StatusChangeLink
                    teamMember={this.props.teamMember}
                    isIcon={isIcon}
                    memberIndex={this.props.memberIndex}
                    canDelete={this.props.canDelete}
                    cancelAction={this.props.onCancelUser}
                    reactivateAction={this.props.onReactivateUser}
                />
            );
        }
    };

    cloneButton = () => {
        if (this.props.canClone) {
            return (
                <Link to={Constants.CLONE_TEAM_MEMBER_LINK + '?teamMemberId=' + this.props.teamMember.UserId}>
                    <span className='btn btn-link'>{this.props.translations.Phrases['CloneTeamMember_Label']}</span>
                </Link>
            );
        }
    };

    teamMemberDetailsButton = () => {
        return (
            <Link to={Constants.TEAM_MEMBER_DETAILS_LINK + '?teamMemberId=' + this.props.teamMember.UserId}>
                <span className='btn btn-link'>
                    {this.props.teamMember.FirstName} {this.props.teamMember.LastName}
                </span>
            </Link>
        );
    };

    userExpirationDate = () => {
        if (this.props.teamMember.ExpirationDate === null) {
            return <td className='team-row-data visible-desktop'>{this.props.translations.Phrases['Never_Label']}</td>;
        } else {
            const d = new Date(Date.parse(this.props.teamMember.ExpirationDate));
            return <td className='team-row-data visible-desktop'>{d.toLocaleDateString()}</td>;
        }
    };

    userLastLogin = () => {
        if (this.props.teamMember.LastLogin === null) {
            return this.props.translations.Phrases['Never_Label'];
        } else {
            return new Date(Date.parse(this.props.teamMember.LastLogin)).toLocaleDateString();
        }
    };

    statusTranslate(status) {
        let translatedStatus;
        switch (status) {
            case StatusConstants.ACTIVE:
                translatedStatus = this.props.translations.Phrases['Active'];
                break;
            case StatusConstants.DEACTIVATED:
                translatedStatus = this.props.translations.Phrases['Deactivated'];
                break;
            case StatusConstants.HOLD:
                translatedStatus = this.props.translations.Phrases['Hold'];
                break;
            case StatusConstants.CANCELLED:
                translatedStatus = this.props.translations.Phrases['Cancelled'];
                break;
            case StatusConstants.EXPIRED:
                translatedStatus = this.props.translations.Phrases['Expired'];
                break;
            default:
                translatedStatus = '';
        }
        return translatedStatus;
    }

    render() {
        return (
            <tr className='team-row'>
                <td className='team-row-data'>{this.teamMemberDetailsButton()}</td>
                <td className='team-row-data'>{this.statusTranslate(this.props.teamMember.Status)}</td>
                <td className='team-row-data visible-desktop'>{this.userLastLogin()}</td>
                <td className='team-row-data'>{this.props.teamMember.Email}</td>
                <td className='team-row-data'>{this.props.teamMember.Phone}</td>
                {this.userExpirationDate()}
                {this.props.canClone || this.props.canDelete ? (
                    <td className='team-buttons visible-tablet'>
                        {this.cloneButton()}
                        <br />
                        {this.statusChangeLink()}
                    </td>
                ) : null}
                {this.props.canClone ? <td className='team-row-data visible-desktop'>{this.cloneButton()}</td> : null}
                {this.props.canDelete ? <td className='team-row-data visible-desktop'>{this.statusChangeLink()}</td> : null}
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCancelUser: (userId) => dispatch(actions.cancelUser(userId)),
        onReactivateUser: (userId) => dispatch(actions.reactivateUser(userId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberRow);
