import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../portal/common/features/Button';
import useGTMEventTracking from '../../portal/common/hooks/useGTMEventTracking';
import useTranslation from '../../portal/common/hooks/useTranslation';
import useTranslationHydration from '../../portal/common/hooks/useTranslationHydration';
import { HydrationSlice } from '../../portal/common/models/ReduxSlices';
import { hideMenuData } from '../../portal/redux/reducers/viewImagesSlice';
import { ImagingModelSlice } from '../common/models/ReduxSlices';
import { RootState } from '../redux/store';

interface DocumentsFooterProps {
    amountEntries?: string;
    amountSelected?: string;
    disablePrimaryButton?: boolean;
    buttonArray?: { buttonText: string; buttonFunction: () => void; buttonStyle: {} }[];
    downloadButtonParam: string;
    downloadButtonClick: (value: string) => void;
    viewButtonClick: () => void;
    compareButtonClick?: () => void;
}

const DocumentsFooter: React.FC<DocumentsFooterProps> = ({
    amountEntries,
    buttonArray,
    amountSelected,
    downloadButtonParam,
    downloadButtonClick,
    viewButtonClick,
    compareButtonClick
}) => {
    const parentContainer = document.querySelector('.navbar-and-stage-wrapper') ?? document.body;
    const { documentDetailModalView } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const translate = useTranslation();
    const translateHydration = useTranslationHydration();
    const dispatch = useDispatch();
    const downloadDocumentsRef = useRef(null);

    useGTMEventTracking(
        `${!documentDetailModalView ? 'Doc_DNLD_Grid_DOC_CPEvt' : 'Doc_DNLD_Entry_DOC_CPEvt'}`,
        { location: 'Documents' },
        downloadDocumentsRef
    );
    const compareDocumentsRef = useRef(null);
    useGTMEventTracking(
        `${!documentDetailModalView ? 'Doc_Compare_Grid_DOC_CPEvt' : 'Doc_Compare_Entry_DOC_CPEvt'}`,
        { location: 'Documents' },
        compareDocumentsRef
    );
    const viewDocumentsRef = useRef(null);
    useGTMEventTracking(
        `${!documentDetailModalView ? 'Doc_View_Grid_DOC_CPEvt' : 'Doc_View_Entry_DOC_CPEvt'}`,
        { location: 'Documents' },
        viewDocumentsRef
    );
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const showMaxDownloadLabel =
        user && user.PortalDocumentsMaxItemDownloadNumber && Number(amountSelected) >= user?.PortalDocumentsMaxItemDownloadNumber;

    const handleCompareClick = () => {
        if (compareButtonClick) compareButtonClick();
        dispatch(hideMenuData(true));
    };

    return ReactDOM.createPortal(
        <div
            id='FixedFooter'
            className='bg-white shadow-lg'
            style={{ zIndex: '1201', position: 'absolute', right: '0', left: '0', bottom: '0', width: '-webkit-fill-available' }}
        >
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex flex-column flex-md-row align-items-md-center align-items-stretch justify-content-md-end py-3'>
                            <div className='d-flex justify-content-start'>
                                <div className='helper-text'>
                                    {`${amountEntries} ${translate('Entries_Label')} `}
                                    {`${amountSelected} ${translate('DocumentsSelected_Label')}`}
                                </div>
                            </div>
                            {isSafari && (
                                <div className='d-flex justify-content-center' style={{ flex: 1, color: 'red' }}>
                                    <p style={{ visibility: showMaxDownloadLabel ? 'visible' : 'hidden' }}>
                                        {translate('MaxItemDownloadNumber_Label')}
                                    </p>

                                    <Button type='button' ref={viewDocumentsRef} onClick={() => viewButtonClick()} variant='secondary'>
                                        {translate('ViewDocumentsButtonFooter_Label')}
                                    </Button>

                                    {Number(amountSelected) >= 2 && (
                                        <Button
                                            data-toggle='modal'
                                            onClick={handleCompareClick}
                                            data-target='#DocumentCompareModal'
                                            ref={compareDocumentsRef}
                                            type='button'
                                            variant='secondary'
                                        >
                                            {translate('CompareDocumentsButtonFooter_Label')}
                                        </Button>
                                    )}

                                    <Button
                                        type='button'
                                        data-toggle='modal'
                                        onClick={() => downloadButtonClick(downloadButtonParam)}
                                        ref={downloadDocumentsRef}
                                        variant='primary'
                                    >
                                        {translate('DownloadButtonFooter_Label')} <FontAwesomeIcon icon={faFileDownload} />
                                    </Button>
                                </div>
                            )}
                            {!isSafari && (
                                <>
                                    <div
                                        className='d-flex justify-content-center'
                                        style={{ flex: 1, color: 'red', visibility: showMaxDownloadLabel ? 'visible' : 'hidden' }}
                                    >
                                        {translate('MaxItemDownloadNumber_Label')}
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row flex-shrink-3'>
                                        <Button
                                            className='mr-2'
                                            type='button'
                                            ref={viewDocumentsRef}
                                            onClick={() => viewButtonClick()}
                                            variant='secondary'
                                        >
                                            {translateHydration('ViewDocumentsButtonFooter_Label')}
                                        </Button>

                                        {Number(amountSelected) >= 2 && (
                                            <Button
                                                className='mr-2'
                                                onClick={handleCompareClick}
                                                data-toggle='modal'
                                                ref={compareDocumentsRef}
                                                data-target='#DocumentCompareModal'
                                                type='button'
                                                variant='secondary'
                                            >
                                                {translateHydration('CompareDocumentsButtonFooter_Label')}
                                            </Button>
                                        )}

                                        <Button
                                            type='button'
                                            data-toggle='modal'
                                            onClick={() => downloadButtonClick(downloadButtonParam)}
                                            ref={downloadDocumentsRef}
                                            variant='primary'
                                            disabled={!!showMaxDownloadLabel ?? false}
                                        >
                                            {translate('DownloadButtonFooter_Label')} <FontAwesomeIcon icon={faFileDownload} />
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        parentContainer
    );
};

export default DocumentsFooter;
