import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated } from '../../utils/mlp-utils';
        
class UploadSummaryGrid  extends Component {    
    render() {
        const rows = this.props.uploadSummary.map((item, index) => {
            return (
                <tr key={item.Metric+item.Value}>					    
                    <td className='row-data'>{formatTranslated(item.Metric,this.props.translations.Phrases)}</td>
                    <td className='row-data'>{item.Value}</td>
                  </tr>
            );
        });

        return (
            <div>					
                <div className='row'>
                    <div className='col-sm-12'>
                        <h3 className='pull-left bold table-title'>{this.props.translations.Phrases['UploadSummary_Label']}</h3>
                    </div>							
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <br />
                        <table className='table table-striped data-table'>
                            <thead>
                                <tr>
                                <th>{this.props.translations.Phrases['Metric_Label']}</th>
                                <th>{this.props.translations.Phrases['Value_Label']}</th>									                                    
                                  </tr>
                            </thead>
                            <tbody>
                                  {rows}
                              </tbody>
                        </table>
                    </div>					
                </div>					
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    }
}

export default connect(mapStateToProps)(UploadSummaryGrid);


