import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { CheckArraysFunction, DocumentStatusPayload, DocumentsTypePayload } from '../../common/constants/constants-documents';
import { ImagingModel, model } from '../../common/models/ImagingModel';
import { GetImagingDocuments, GetImagingModel } from '../actions/Imaging';

const initialState: ImagingModel = {
    model: {} as model,
    submitedSearchKeywords: [],
    searchKeywords: [],
    isLoading: false,
    isCancelled: false,
    error: undefined,
    shouldInitModel: true,
    shouldSubmitImageSearch: false,
    shouldScrollToLastPosition: false,
    scrollPosition: 0,
    documentDetailsModalTopPosition: 0,
    noResults: false,
    selectedEntries: [],
    isFiltering: false,
    dateTypesCaLabel: '',
    dateTypesUsLabel: '',
    dateRangeCaLabel: '',
    dateRangeUsLabel: '',
    customDateRangeUsStartLabel: '',
    customDateRangeUsEndLabel: '',
    customDateRangeCaStartLabel: '',
    customDateRangeCaEndLabel: '',
    applyDateRangeUsFilter: false,
    applyDateRangeCaFilter: false,
    applyDateTypeUsFilter: false,
    applyDateTypeCaFilter: false,
    searchTypeCode: '',
    startCaRangeDate: '',
    endCaRangeDate: '',
    documentDetailModalView: false
};

const imagingSlice = createSlice({
    name: 'imaging',
    initialState,
    reducers: {
        updateSubmittedSearchKeywords(state, action: PayloadAction<string[]>) {
            state.submitedSearchKeywords = action.payload;
        },
        updateSearchKeywords(state, action: PayloadAction<string[]>) {
            state.searchKeywords = action.payload;
        },
        updateImagingModel(state, action) {
            state.model = action.payload;
        },
        updateShouldInitModel(state, action: PayloadAction<boolean>) {
            state.shouldInitModel = action.payload;
        },
        setDocumentDetailModalView(state, action: PayloadAction<boolean>) {
            state.documentDetailModalView = action.payload;
        },
        updateShouldSubmitImageSearch(state, action: PayloadAction<boolean>) {
            state.shouldSubmitImageSearch = action.payload;
            state.noResults = false;
        },
        updateTotalLoadedResults(state, action: PayloadAction<number>) {
            state.model = {
                ...state.model,
                TotalLoadedResults: action.payload
            };
        },
        updateShouldScrollToLastPosition(state, action: PayloadAction<boolean>) {
            state.shouldScrollToLastPosition = action.payload;
        },
        updateScrollPosition(state, action: PayloadAction<number>) {
            state.scrollPosition = action.payload;
        },
        updateSelection(state, action: PayloadAction<string>) {
            if (state.model.SearchResults.length) {
                let entries = [...state.selectedEntries];
                const found = entries.find((item) => item === action.payload);
                // if item exists remove it
                if (found) {
                    entries = entries
                        .map((item) => {
                            return item !== action.payload ? item : '';
                        })
                        .filter((item) => {
                            return item.length;
                        });
                    state.selectedEntries = entries;
                    //if item doesn't exist add it
                } else {
                    entries.push(action.payload);
                    state.selectedEntries = entries;
                }
            }
        },
        updateSelectionSelectAll(state, action: PayloadAction<boolean>) {
            action.payload
                ? (state.selectedEntries = state.model.SearchResults.map((item) => {
                      return Object.keys(item).toString();
                  }))
                : (state.selectedEntries.length = 0);
        },
        updateClientSelectionPayload(state, action: any) {
            state.model = {
                ...state.model,
                ClientServiceSelection: action.payload
            };
        },
        updateDocumentDetailsModelTopPosition(state, action: PayloadAction<number>) {
            state.documentDetailsModalTopPosition = action.payload;
        },
        resetTotalLoadedResults(state) {
            state.model = {
                ...state.model,
                TotalLoadedResults: 0,
                TotalEntries: undefined,
                TotalResults: undefined
            };
        },
        resetSearchResults(state) {
            state.model = {
                ...state.model,
                SearchResults: []
            };
        },
        resetSelectedEntries(state) {
            state.selectedEntries.length = 0;
        },
        resetSubmitedSearchKeywords(state) {
            state.submitedSearchKeywords.length = 0;
        },
        updateStatusFilter(state, action: PayloadAction<DocumentStatusPayload>) {
            const selectedFilters = action.payload.country?.Code === 'us' ? 'USSTAT' : 'LISTAT';
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === selectedFilters && filter.SearchParameterType === 'TermsParam') {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    return {
                                        ...option,
                                        IsSelected: CheckArraysFunction(action.payload.optionsArray, option.Name)
                                    };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            state.shouldSubmitImageSearch = true;
            state.isFiltering = true;
            state.model.TotalLoadedResults = 0;
        },

        updateTypesFilter(state, action: PayloadAction<DocumentsTypePayload>) {
            const selectedFilters = action.payload.country?.Code === 'us' ? 'USSHP' : 'CASHP';
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === 'Doc Types' && filter.SearchTypeCodes[0] === selectedFilters) {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    return {
                                        ...option,
                                        IsSelected: action.payload.optionsArray.includes(option.Name)
                                    };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            state.shouldSubmitImageSearch = true;
            state.isFiltering = true;
            state.model.TotalLoadedResults = 0;
        },
        updateMotFilters(state, action: PayloadAction<string[]>) {
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === 'USMOT') {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    return { ...option, IsSelected: !!action.payload.find((item) => item === option.Name) };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            state.shouldSubmitImageSearch = true;
            state.isFiltering = true;
            state.model.TotalLoadedResults = 0;
        },
        updateSearchFilters(state, action: PayloadAction<{ value: string[]; country: 'ca' | 'us' }>) {
            if (!state.model?.Filters) return;

            const filterName = action.payload.country === 'us' ? 'ClearancesUSSearch' : 'ClearancesCASearch';
            const filterIndex = state.model.Filters.findIndex((f) => f.Name === filterName);

            if (!filterIndex) return;

            let filters = state.model.Filters;

            filters[filterIndex].Value = action.payload.value.join(';');
            filters[filterIndex].Attribute = 'allTypes';
            state.searchTypeCode === 'CACLVS'
                ? (filters[filterIndex].SearchTypeCodes = ['CACLVS'])
                : (filters[filterIndex].SearchTypeCodes = action.payload.country === 'ca' ? ['CASHP'] : ['USSHP']);

            let options = filters[filterIndex].Options;
            options = options.map((opt) => ({ ...opt, IsSelected: true }));

            filters[filterIndex].Options = options;

            state.model.Filters = filters;
            state.model.TotalLoadedResults = 0;
            state.model.SearchResults = [];
            state.isLoading = true;
            state.shouldSubmitImageSearch = true;
        },
        updateDateFilters(
            state,
            action: PayloadAction<{
                startDate: string | null;
                endDate: string | null;
                attribute: string | undefined;
                dateRangeLabel: string;
            }>
        ) {
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (
                            filter.Name === 'Date' &&
                            filter.ControlName === 'PrimaryDateFilter' &&
                            filter.Options.some((option) => option.Name === action.payload.attribute)
                        ) {
                            return {
                                ...filter,
                                RangeStart: action.payload.startDate,
                                RangeEnd: action.payload.endDate
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            if (action.payload.attribute === 'USARVD') {
                state.dateRangeUsLabel = action.payload.dateRangeLabel;
            }
            if (action.payload.attribute === 'LIETA') {
                state.dateRangeCaLabel = action.payload.dateRangeLabel;
                state.startCaRangeDate = moment(action.payload.startDate).format('YYYY-MM-DD');
                state.endCaRangeDate = moment(action.payload.endDate).format('YYYY-MM-DDTHH:mm:ss');
            }
            if (action.payload.attribute === 'LICONS') {
                state.dateRangeCaLabel = action.payload.dateRangeLabel;
                state.startCaRangeDate = moment(action.payload.startDate).format('YYYY-MM-DD');
                state.endCaRangeDate = moment(action.payload.endDate).format('YYYY-MM-DDTHH:mm:ss');
            }
            if (action.payload.attribute === 'USARVD' && action.payload.dateRangeLabel === 'DateRangeCustom_Label') {
                state.customDateRangeUsStartLabel = moment(action.payload.startDate).format('MM/DD/YYYY');
                state.customDateRangeUsEndLabel = moment(action.payload.endDate).format('MM/DD/YYYY');
            }
            if (action.payload.attribute === 'LIETA' && action.payload.dateRangeLabel === 'DateRangeCustom_Label') {
                state.customDateRangeCaStartLabel = moment(action.payload.startDate).format('MM/DD/YYYY');
                state.customDateRangeCaEndLabel = moment(action.payload.endDate).format('MM/DD/YYYY');
            }
            if (action.payload.attribute === 'LICONS' && action.payload.dateRangeLabel === 'DateRangeCustom_Label') {
                state.customDateRangeCaStartLabel = moment(action.payload.startDate).format('MM/DD/YYYY');
                state.customDateRangeCaEndLabel = moment(action.payload.endDate).format('MM/DD/YYYY');
            }

            state.model.TotalLoadedResults = 0;
        },
        updateDateType(
            state,
            action: PayloadAction<{ dateType: string; attribute: string; dateTypeLabel: string; searchTypeCode: string }>
        ) {
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (
                            filter.Name === 'Date' &&
                            filter.ControlName === 'PrimaryDateFilter' &&
                            filter.Options.some((option) => option.Name === action.payload.attribute)
                        ) {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    return { ...option, IsSelected: option.Name === action.payload.dateType };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            if (action.payload.attribute === 'USARVD' && action.payload.dateType !== 'LICONS') {
                state.dateTypesUsLabel = action.payload.dateTypeLabel;
                state.searchTypeCode = action.payload.searchTypeCode;
            }
            if (action.payload.attribute === 'LIETA' && action.payload.dateType !== 'LICONS') {
                state.dateTypesCaLabel = action.payload.dateTypeLabel;
                state.searchTypeCode = action.payload.searchTypeCode;
            }
            if (action.payload.dateType === 'LICONS') {
                state.dateTypesCaLabel = action.payload.dateTypeLabel;
                state.searchTypeCode = action.payload.searchTypeCode;
            }
            state.model.TotalLoadedResults = 0;
        },
        setApplyDateRangeFilter(
            state,
            action: PayloadAction<{ country: string | undefined; usLabel: string | undefined; caLabel: string | undefined }>
        ) {
            if (action.payload.country === 'us' && action.payload.usLabel) {
                state.applyDateRangeUsFilter = true;
            } else if (action.payload.country === 'ca' && action.payload.caLabel) {
                state.applyDateRangeCaFilter = true;
            }
        },
        setApplyDateTypeFilter(
            state,
            action: PayloadAction<{ country: string | undefined; usDataType: string | undefined; caDataType: string | undefined }>
        ) {
            if (action.payload.country === 'us' && action.payload.usDataType) {
                state.applyDateTypeUsFilter = true;
            } else if (action.payload.country === 'ca' && action.payload.caDataType) {
                state.applyDateTypeCaFilter = true;
            }
        },
        resetDataFilter(state, action: PayloadAction<{ applyDateRangeUsFilter: boolean; applyDateRangeCaFilter: boolean }>) {
            if (!action.payload.applyDateRangeUsFilter) {
                state.dateRangeUsLabel = initialState.dateRangeUsLabel;
                state.customDateRangeUsStartLabel = initialState.customDateRangeUsStartLabel;
                state.customDateRangeUsEndLabel = initialState.customDateRangeUsEndLabel;
            }
            if (!action.payload.applyDateRangeCaFilter) {
                state.dateRangeCaLabel = initialState.dateRangeCaLabel;
                state.customDateRangeCaStartLabel = initialState.customDateRangeCaStartLabel;
                state.customDateRangeCaEndLabel = initialState.customDateRangeCaEndLabel;
            }
        },
        resetTypeFilter(state, action: PayloadAction<{ applyDateRangeUsFilter: boolean; applyDateRangeCaFilter: boolean }>) {
            if (!action.payload.applyDateRangeUsFilter) {
                state.dateTypesUsLabel = initialState.dateTypesUsLabel;
            }
            if (!action.payload.applyDateRangeCaFilter) {
                state.dateTypesCaLabel = initialState.dateTypesCaLabel;
            }
        },
        resetStatusFilter(state, action: PayloadAction<string>) {
            const selectedFilters = action.payload === 'us' ? 'USSTAT' : 'LISTAT';
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === selectedFilters && filter.SearchParameterType === 'TermsParam') {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    return {
                                        ...option,
                                        IsSelected: false
                                    };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            state.shouldSubmitImageSearch = true;
            state.isFiltering = true;
            state.model.TotalLoadedResults = 0;
        },
        resetMOTFilter(state) {
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === 'USMOT' && filter.SearchParameterType === 'TermsParam') {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    return {
                                        ...option,
                                        IsSelected: false
                                    };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            state.isFiltering = true;
            state.model.TotalLoadedResults = 0;
        },
        resetDocTypeFilter(state) {
            const selectedFilters = 'Doc Types';
            state.model = {
                ...state.model,

                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === selectedFilters && filter.SearchParameterType === 'TermsParam') {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    return {
                                        ...option,
                                        IsSelected: false
                                    };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
            state.shouldSubmitImageSearch = true;
            state.isFiltering = true;
            state.model.TotalLoadedResults = 0;
        },
        resetDateFilter(state, action: PayloadAction<string>) {
            if (state.dateTypesCaLabel === 'ConsistDate_Label') {
                state.searchTypeCode = 'CASHP';
            }
            state.model = {
                ...state.model,
                SearchTypes:
                    state.dateTypesCaLabel === 'ConsistDate_Label'
                        ? state.model.SearchTypes.map((st) => {
                              if (st.Code === 'CASHP') return { ...st, IsSelected: true };
                              if (st.Code === 'CACLVS') return { ...st, IsSelected: false };

                              return st;
                          })
                        : state.model.SearchTypes,
                Filters: state.model.Filters.map((filter) => {
                    if (filter.Name === 'Date' && filter.ControlName === 'PrimaryDateFilter' && filter.Options.length === 3) {
                        const attributeValue =
                            action.payload === 'us' ? 'USARVD' : filter.SearchTypeCodes[0] === 'CACLVS' ? 'LICONS' : 'LIETA';
                        return {
                            ...filter,
                            Attribute: attributeValue,
                            RangeEnd: null,
                            RangeStart: null,
                            Options: filter.Options.map((option, i) => {
                                return {
                                    ...option,
                                    IsSelected:
                                        action.payload === 'us'
                                            ? option.Name === 'USARVD'
                                                ? true
                                                : false
                                            : option.SearchTypeCodes[0] === 'CASHP'
                                            ? option.Name === 'LIETA'
                                                ? true
                                                : false
                                            : option.Name === 'LICONS'
                                            ? true
                                            : false
                                };
                            })
                        };
                    } else {
                        return filter;
                    }
                })
            };
            state.shouldSubmitImageSearch = true;
            state.isFiltering = true;
            state.model.TotalLoadedResults = 0;
        },
        resetDateRangeAndTypeFilters(state) {
            state.isFiltering = initialState.isFiltering;
            state.dateTypesCaLabel = initialState.dateTypesCaLabel;
            state.dateTypesUsLabel = initialState.dateTypesUsLabel;
            state.dateRangeCaLabel = initialState.dateRangeCaLabel;
            state.dateRangeUsLabel = initialState.dateRangeUsLabel;
            state.customDateRangeUsStartLabel = initialState.customDateRangeUsStartLabel;
            state.customDateRangeUsEndLabel = initialState.customDateRangeUsEndLabel;
            state.customDateRangeCaStartLabel = initialState.customDateRangeCaStartLabel;
            state.customDateRangeCaEndLabel = initialState.customDateRangeCaEndLabel;
            state.applyDateRangeUsFilter = initialState.applyDateRangeUsFilter;
            state.applyDateRangeCaFilter = initialState.applyDateRangeCaFilter;
            state.applyDateTypeUsFilter = initialState.applyDateTypeUsFilter;
            state.applyDateTypeCaFilter = initialState.applyDateTypeCaFilter;
        },
        setSearchBarOptions(state) {
            state.model = {
                ...state.model,
                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === 'ClearancesUSSearch') {
                            return {
                                ...filter,
                                Options: filter.Options.map((opt) => {
                                    if (opt.Name === 'USCINV') {
                                        return {
                                            ...opt,
                                            Name: 'MULTIUSCINVCA.USCINV.value',
                                            Value: 'MULTIUSCINVCA.USCINV.value',
                                            DataType: 'Text'
                                        };
                                    } else if (opt.Name === 'USHBILL') {
                                        return {
                                            ...opt,
                                            Name: 'MULTIUSHBILLCA.USHBILL.value',
                                            Value: 'MULTIUSHBILLCA.USHBILL.value',
                                            DataType: 'Text'
                                        };
                                    } else if (opt.Name === 'USSHBILL') {
                                        return {
                                            ...opt,
                                            Name: 'MULTIUSSHBILLCA.USSHBILL.value',
                                            Value: 'MULTIUSSHBILLCA.USSHBILL.value',
                                            DataType: 'Text'
                                        };
                                    } else {
                                        return opt;
                                    }
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetImagingModel.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetImagingModel.fulfilled, (state, action) => {
                const payload = JSON.parse(action.payload);
                state.model = payload;
                state.isLoading = false;
                state.shouldInitModel = false;
                state.shouldSubmitImageSearch = true;
                state.model = {
                    ...state.model,
                    NumberOfResultsToLoad: state.model.NumberOfResultsToLoad
                };
            })
            .addCase(GetImagingModel.rejected, (state, action) => {
                state.isLoading = false;
                state.shouldInitModel = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(GetImagingDocuments.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetImagingDocuments.fulfilled, (state, action) => {
                const payload = action.payload;
                let keys = Object.keys(payload.GroupedDocuments);
                keys.forEach((x: string) => {
                    payload.GroupedDocuments[x].forEach((y) => {
                        if (y._imageDate === null) y._imageDate = new Date().toISOString();
                    });
                });
                const payloadArray = [...Object.entries(payload.GroupedDocuments)];
                const results = payloadArray.map((item) => {
                    return Object.assign({ [item[0]]: item[1] });
                });
                const data = !state.isFiltering
                    ? state.model.SearchResults && state.model.SearchResults.length
                        ? [...state.model.SearchResults, ...results]
                        : results
                    : results;
                state.model = {
                    ...state.model,
                    TotalLoadedResults: state.model.TotalLoadedResults + payload.TotalLoadedResults,
                    TotalResults: Number(payload.TotalHits),
                    TotalEntries: state.model.TotalLoadedResults === 0 ? Number(payload.TotalEntries) : state.model.TotalEntries,
                    SearchResults: results.length ? data : []
                };
                state.isLoading = false;
                state.shouldInitModel = false;
                state.shouldSubmitImageSearch = false;
                state.isFiltering = false;
                state.error = undefined;
                state.noResults = !!(
                    action.payload.TotalHits === '0' &&
                    action.payload.TotalEntries === '0' &&
                    action.payload.TotalLoadedResults === 0
                );
                state.isCancelled = false;
            })
            .addCase(GetImagingDocuments.rejected, (state, action) => {
                state.isLoading = false;

                if (action.payload === 'Request is canceled') {
                    state.isCancelled = true;
                }

                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error?.message;
                }
            });
    }
});

export const {
    updateSubmittedSearchKeywords,
    updateSearchKeywords,
    updateImagingModel,
    updateShouldInitModel,
    updateShouldSubmitImageSearch,
    updateTotalLoadedResults,
    updateShouldScrollToLastPosition,
    updateScrollPosition,
    updateDocumentDetailsModelTopPosition,
    updateSelection,
    updateClientSelectionPayload,
    resetSearchResults,
    resetStatusFilter,
    resetTotalLoadedResults,
    updateMotFilters,
    updateStatusFilter,
    updateTypesFilter,
    updateSearchFilters,
    resetDocTypeFilter,
    resetTypeFilter,
    resetDataFilter,
    resetDateFilter,
    resetSelectedEntries,
    setApplyDateTypeFilter,
    setApplyDateRangeFilter,
    updateDateType,
    updateDateFilters,
    updateSelectionSelectAll,
    resetDateRangeAndTypeFilters,
    setSearchBarOptions,
    resetSubmitedSearchKeywords,
    resetMOTFilter,
    setDocumentDetailModalView
} = imagingSlice.actions;
export default imagingSlice.reducer;
