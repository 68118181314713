import ReactDOM from 'react-dom';
import Spinner from '../Spinner';

interface GridLoadingOverlayProps {
    parentElement?: string;
}

const GridLoadingOverlay: React.FC<GridLoadingOverlayProps> = ({ parentElement = '.k-grid-container' }) => {
    const loadingPanel = (
        <div
            style={{
                display: 'flex',
                position: 'absolute',
                zIndex: 100,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255,255,255,0.8)',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Spinner />
        </div>
    );
    const parentContainer = document && (document.querySelector(parentElement) || document.body);

    return parentContainer ? ReactDOM.createPortal(loadingPanel, parentContainer) : loadingPanel;
};

export default GridLoadingOverlay;
