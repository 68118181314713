import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorPage from '../../../portal/common/features/ErrorPage';
import FilterBar from '../../../portal/common/features/FilterBar/FilterBar';
import StatusFilter from '../../../portal/common/features/FilterBar/StatusFilter';
import HeaderBar from '../../../portal/common/features/HeaderBar/HeaderBar';
import Spinner from '../../../portal/common/features/Spinner';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { SearchFilterSlice, StatusFilterSlice } from '../../../portal/common/models/ReduxSlices';
import { StatusFilterOptions, TeamMemberStatusLabels } from '../../common/constants/constants-team';
import { StatusUserSlice, UserTeamSlice } from '../../common/models/ReduxSlices';
import { TransformedTeamMember } from '../../common/models/TeamMember';
import { GetUserTeamData } from '../../redux/actions/teamManagement';
import { setPageSelected } from '../../redux/reducers/teamGridSettingsSlice';
import { setSelectedStatus } from '../../redux/reducers/teamStatusFilterSlice';
import { resetNewUsersAmount } from '../../redux/reducers/userDetailsSlice';
import { RootState } from '../../redux/store';
import EmptyState from '../../../portal/common/features/Grid/EmptyState';
import TeamSearchBox from './SearchBox/TeamSearchBox';
import TeamGridCaption from './TeamGridCaption';
import TeamsTable from './TeamsTable/TeamsTable';

const TeamGrid = () => {
    const { team, isLoading, error } = useSelector<RootState, UserTeamSlice>((state) => state.user);
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.teamSearchFilter);
    const { isStatusLoading, isStatusChange } = useSelector<RootState, StatusUserSlice>((state) => state.statusUser);
    const { selectedStatus } = useSelector<RootState, StatusFilterSlice>((state) => state.teamStatusFilter);
    const [transformedTeam, setTransformedTeam] = useState<TransformedTeamMember[] | null>(null);
    const [filteredTeam, setFilteredTeam] = useState<TransformedTeamMember[] | []>([]);
    const translate = useTranslation();
    const dispatch = useDispatch();
    const { push } = useHistory();

    // On page load get the team data and translations
    useEffect(() => {
        dispatch(GetUserTeamData());
    }, [dispatch, isStatusChange]);

    // Transform team members
    useEffect(() => {
        if (team?.TeamMembers && team.TeamMembers.length) {
            let transformedTeamMembers: TransformedTeamMember[] = [...team.TeamMembers].map((member) => {
                return {
                    ...member,
                    Name: `${member.FirstName} ${member.LastName}`,
                    Expires: member.ExpirationDate,
                    Status: member.Status
                        ? translate(TeamMemberStatusLabels[member.Status as keyof typeof TeamMemberStatusLabels])
                        : member.Status
                };
            });
            setTransformedTeam(transformedTeamMembers);
        }
    }, [team?.TeamMembers, translate]);

    // Filter team members
    useEffect(() => {
        if (transformedTeam) {
            // Filter the teamMembers by searchTerm
            let filteredTeamMembers = searchTeamMembers(transformedTeam, searchTerm);
            // Filter the TeamMembers by selected status
            filteredTeamMembers = filterTeamMembersByStatus(filteredTeamMembers, selectedStatus.id);
            setFilteredTeam(filteredTeamMembers);
        }
    }, [selectedStatus.id, searchTerm, transformedTeam]);

    // Reset "newUsersAmount" on unmount
    useEffect(() => {
        return () => {
            dispatch(resetNewUsersAmount());
        };
    }, [dispatch]);

    // Filter team members by Status (All, Active, Inactive)
    const filterTeamMembersByStatus = (teamMembers: TransformedTeamMember[], status: string) => {
        if (status.toUpperCase() === 'ACTIVE') {
            return teamMembers.filter((member) => member.IsActive);
        } else if (status.toUpperCase() === 'INACTIVE') {
            return teamMembers.filter((member) => !member.IsActive);
        } else {
            return teamMembers;
        }
    };

    // Filter team members by Search term (name or email)
    const searchTeamMembers = (teamMembers: TransformedTeamMember[], searchTerm: string) => {
        if (!searchTerm.length) {
            return teamMembers;
        }
        searchTerm = searchTerm.toLowerCase();
        return teamMembers.filter(
            (member) => member.Name.toLowerCase().includes(searchTerm) || (member.Email && member.Email.toLowerCase().includes(searchTerm))
        );
    };

    const handleStatusChange = (value: number) => {
        dispatch(setSelectedStatus(value));
        // Set the grid page to 1
        dispatch(setPageSelected(1));
    };

    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar>
                <h1>{translate('Team_Label')}</h1>
            </HeaderBar>
            {/* Filter bar */}
            <FilterBar>
                <div
                    className='filter-and-search d-flex flex-fill justify-content-between px-0 p-md-2'
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                >
                    {/* Status filter pills */}
                    <StatusFilter
                        id='status-filters'
                        options={StatusFilterOptions}
                        selectedStatus={selectedStatus}
                        onChange={handleStatusChange}
                    />
                    {/* Search box */}
                    <TeamSearchBox />
                </div>
            </FilterBar>
            {/* Status: pending (showing only on initial load) */}
            {(isLoading || isStatusLoading) && (!team || (team.TeamMembers && team.TeamMembers.length === 0)) && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {team && team.TeamMembers && team.TeamMembers.length > 0 && !error && (
                <>
                    <TeamGridCaption />
                    <TeamsTable teamMembers={filteredTeam} />
                </>
            )}
            {/* No team members */}
            {!isLoading && team && team.TeamMembers?.length === 0 && (
                <EmptyState
                    descriptionLabel={translate('NoTeamMembers_Label')}
                    addButtonLabel={translate('AddUser_Label')}
                    onAdd={() => push('/Team/CreateNewUser')}
                />
            )}
            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}
        </div>
    );
};

export default TeamGrid;
