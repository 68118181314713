import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { API_HYDRATION_PATH, Breakpoints, LanguageCodes, SidebarNavItems, Translations } from '../portal/common/constants/constants-portal';
import AlertsDrawer from '../portal/common/features/Alerts/AlertsDrawer';
import AlertsToasts from '../portal/common/features/Alerts/AlertsToasts';
import ErrorPage from '../portal/common/features/ErrorPage';
import HeaderNav from '../portal/common/features/HeaderNav/HeaderNav';
import Sidebar from '../portal/common/features/Sidebar/Sidebar';
import Spinner from '../portal/common/features/Spinner';
import useAlerts from '../portal/common/hooks/useAlerts';
import useLocalStorageItem from '../portal/common/hooks/useLocalStorageItem';
import usePageLoad from '../portal/common/hooks/usePageLoad';
import { ClientSelectionSlice, HydrationSlice, LanguageSlice, NoticesSlice, TranslationsSlice } from '../portal/common/models/ReduxSlices';
import { GetUserDetails } from '../portal/redux/actions/hydration';
import { GetNotices } from '../portal/redux/actions/notices';
import { GetShipmentDataSource } from '../portal/redux/actions/shipmentDataSource';
import { ChangeLanguage, ChangeLanguageHydration, GetTranslations } from '../portal/redux/actions/translations';
import { setAlerts } from '../portal/redux/reducers/alertsSlice';
import { resetChooser } from '../portal/redux/reducers/clientSelectionReducer';
import { setLanguageSelected } from '../portal/redux/reducers/languageSlice';
import { ViewImagesSlice } from '../portal/redux/reducers/viewImagesSlice';
import Clearances from './Clearances';
import { StatusFilter, clearancesGridSettingsConstants } from './common/constants/constants-clearances';
import { CadDetailsSlice } from './common/models/ReduxSlices';
import { GetUserClearancesGridSetting } from './redux/actions/clearancesGridPreferences';
import { resetAdvancedSearchSettings } from './redux/reducers/advancedSearchSettingsSlice';
import { setDefaultGridColumns } from './redux/reducers/clearancesGridPreferencesSlice';
import { resetDateRange, setDateRangeSelected } from './redux/reducers/dateRangeSlice';
import { resetSearchFilter } from './redux/reducers/searchSlice';
import { resetShipmentGridSettings, setColumnFilters } from './redux/reducers/shipmentGridSettingsSlice';
import { resetStatus, setStatusSelected } from './redux/reducers/shipmentStatusSlice';
import { RootState } from './redux/store';

// INITIAL_VALUES:
// Don't show the Sidebar when the window is smaller than 1024px
const INITIAL_VALUES = {
    isSidebarOpen: window.innerWidth < Breakpoints.Mobile ? false : true
};

const App = () => {
    const {
        user,
        isLoading: userLoading,
        error: userError,
        shipmentDataSource,
        isShipmentDataSourceLoading,
        isSaveDataSourceLoading
    } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const {
        isLoading: translationsLoading,
        error: translationsError,
        translations
    } = useSelector<RootState, TranslationsSlice>((state) => state.translations);
    const { viewImages, hideMenu } = useSelector<RootState, ViewImagesSlice>((state) => state.viewImages);
    const { languageSelected } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { isLoading: noticesLoading, error: noticesError } = useSelector<RootState, NoticesSlice>((state) => state.notices);
    const [isSidebarOpen, setIsSidebarOpen] = useState(INITIAL_VALUES.isSidebarOpen);
    const [showAlerts, setShowAlerts] = useState(false);
    const dispatch = useDispatch();
    const alerts = useAlerts();
    const pageLoading = usePageLoad();
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const history = useHistory();
    const location = useLocation();
    const { cadCustomsInfoId } = useSelector<RootState, CadDetailsSlice>((state) => state.cadDetails);
    const { value: preApplyStatus } = useLocalStorageItem('preApplyStatus');

    // Set the "activeMenuItem" inside the "localStorage" on initial load
    useEffect(() => {
        localStorage.setItem('activeMenuItem', SidebarNavItems.find((element) => element.id === 'clearances')!.id);
    }, []);

    // Set pre applied statuses
    useEffect(() => {
        if (preApplyStatus) {
            const index = Object.values(StatusFilter).findIndex((item) => item.id === preApplyStatus);
            dispatch(setStatusSelected([index]));

            dispatch(
                setColumnFilters({
                    ClearanceStatus: [StatusFilter[index].label.replace('_Label', '')]
                })
            );
            dispatch(setDateRangeSelected(5));
            localStorage.setItem('preApplyStatus', '');
        }
    }, [preApplyStatus, dispatch]);

    // Get user and notices on initial load
    useEffect(() => {
        dispatch(resetChooser());
        dispatch(GetUserDetails());
    }, [dispatch]);

    // Get shipment data source
    useEffect(() => {
        dispatch(GetShipmentDataSource('ca'));
    }, [dispatch]);

    // Redirect to CAD or CAD Details
    // useEffect(() => {
    //     // Check if url contains customsInfoId
    //     const match = location.pathname.match(/\/Clearances\/(\d+)/);

    //     if (shipmentDataSource === shipmentDataSourceConstants.us && cadCustomsInfoId) {
    //         dispatch(restartCadCustomsInfoId());
    //     }

    //     //  If url contains customsInfoId and shipment data source is CA, allow redirection to CAD Details
    //     if (match && !location.search.includes('documents') && shipmentDataSource !== shipmentDataSourceConstants.us) {
    //         dispatch(setCadCustomsInfoId(match[1]));
    //         history.push(`/Clearances/${match[1]}?origin=cad`);
    //     } else if (shipmentDataSource === shipmentDataSourceConstants.ca && !isSaveDataSourceLoading && !isShipmentDataSourceLoading) {
    //         history.push('/CompleteEntryData');
    //     }
    // }, [shipmentDataSource, history, isSaveDataSourceLoading, isShipmentDataSourceLoading, country?.Code]);

    // When user is loaded get the translations related to the user's language code (runs only once)
    useEffect(() => {
        if (user?.LanguageCode) {
            // CHANGE THIS: Get the translations for the current page
            dispatch(GetTranslations({ languageCode: user?.LanguageCode, phraseGroup: Translations.PhraseGroups.Clearances }));
        }
    }, [dispatch, user?.LanguageCode]);

    // Update alerts based on the user's slice (alerts) and user's preffered language (notices)
    useEffect(() => {
        if (alerts) {
            dispatch(setAlerts(alerts));
        }
    }, [alerts, dispatch]);

    // Get the notices for the selected language (runs on every language change)
    useEffect(() => {
        if (translations?.LanguageCode) {
            dispatch(GetNotices());
        }
    }, [dispatch, translations?.LanguageCode]);

    // Get user grid preferences
    useEffect(() => {
        if (!country?.Code) {
            dispatch(setDefaultGridColumns());
        } else {
            dispatch(
                GetUserClearancesGridSetting(
                    country.Code === 'us'
                        ? { area: clearancesGridSettingsConstants['us'] }
                        : { area: clearancesGridSettingsConstants['ca'] }
                )
            );
        }
    }, [dispatch, country?.Code]);

    useEffect(() => {
        if (viewImages.length > 0 || hideMenu) {
            setIsSidebarOpen(false);
        }
    }, [viewImages, hideMenu]);

    // Toggle the Sidebar visibility when:
    // - Clicking the "close" button on the Sidebar
    // - Clicking the "hamburger" button on the Header navigation
    // - Clicking the overlay
    const toggleSidebar = useCallback(() => {
        setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
    }, []);

    // Toggle the Alerts Drawer visibility when:
    // - Clicking the "close" button icon on the Alert's drawer
    // - Clicking the overlay
    const toggleAlerts = useCallback(() => {
        setShowAlerts((showAlerts) => !showAlerts);
    }, []);

    // On language change do:
    // - Change language for the user
    // - Get new translations
    // - Get notices (look at parent App.tsx)
    // - Reset all filters
    const changeLanguage = useCallback(
        (languageIndex: number) => {
            // Check if the language is not already selected before changing it
            if (languageSelected?.code !== LanguageCodes[languageIndex].code) {
                // CHANGE THIS: Change language for the current page (Clearances, Team, Profile...)
                dispatch(
                    ChangeLanguage({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Clearances
                    })
                );
                dispatch(
                    ChangeLanguageHydration({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Hydration
                    })
                );
                dispatch(setLanguageSelected(languageIndex));
                // CHANGE THIS: Clearances specific resets
                dispatch(resetDateRange());
                dispatch(resetStatus());
                dispatch(resetSearchFilter());
                dispatch(resetAdvancedSearchSettings());
                dispatch(resetShipmentGridSettings());
            }
        },
        [dispatch, languageSelected?.code]
    );

    return (
        <>
            {/* Status: pending */}
            {(userLoading || pageLoading || translationsLoading || !shipmentDataSource.length) && (
                // || (shipmentDataSource === shipmentDataSourceConstants.ca && !cadCustomsInfoId)
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {!userLoading && !pageLoading && !translationsLoading && !userError && !translationsError && (
                // (shipmentDataSource !== shipmentDataSourceConstants.ca || cadCustomsInfoId) &&
                <div className='d-flex'>
                    {/* Sidebar */}
                    <Sidebar
                        onToggleSidebar={toggleSidebar}
                        isSidebarOpen={isSidebarOpen}
                        setSidebarStatus={setIsSidebarOpen}
                        navigationItems={SidebarNavItems}
                        onLanguageChange={changeLanguage}
                    />
                    <div className='navbar-and-stage-wrapper d-flex flex-column p-0'>
                        {/* Header navigation */}
                        <header>
                            <HeaderNav onToggleSidebar={toggleSidebar} onToggleAlerts={toggleAlerts} />
                        </header>
                        {/* Alerts drawer */}
                        {showAlerts && <AlertsDrawer onToggleAlerts={toggleAlerts} />}
                        {/* Alerts toasts */}
                        {!noticesLoading && !noticesError && <AlertsToasts />}
                        {/* CHANGE THIS: Current page (Clearances, Team, Profile...) */}
                        <Clearances />
                    </div>
                </div>
            )}
            {/* Status: rejected */}
            {(userError || translationsError) && (
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <ErrorPage errorCode={userError || translationsError || noticesError} withTranslations={!!translations} />
                </div>
            )}
        </>
    );
};

export default App;
