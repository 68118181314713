import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DatePickerModal from '../../common/DateTimePicker/DatePickerModal';
import Dropdown from '../../common/Dropdown/Dropdown';
import SearchBox from '../../common/Searchbox/Searchbox';
import SubmitButton from '../../common/SubmitButton/SubmitButton';
import TagControl from '../../common/TagControl/TagControl';
import TextBox from '../../common/Textbox/Textbox';
import { accessManagement, userStatus as UserConstants } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import { formatTranslated } from '../../utils/mlp-utils';
import StatusChangeLink from '../TeamManagement/StatusChangeLink';

class EditClientAdminHeader extends Component {
    state = {
        searchBoxValue: '',
        userId: 0
    };
    searchBoxRef = React.createRef();

    static propTypes = {
        isEditMode: PropTypes.bool,
        viewModel: PropTypes.object,
        handleUpdate: PropTypes.func,
        validateForm: PropTypes.func,
        cancelUser: PropTypes.func,
        reactivateUser: PropTypes.func,
        validationMessages: PropTypes.object,
        isRoleDropdownChanged: PropTypes.bool,
        updateRoleDropdown: PropTypes.func,
        modelCopy: PropTypes.object
    };

    componentDidUpdate(prevProps) {
        if (this.props.translations.Phrases !== prevProps.translations.Phrases) {
            $(ReactDOM.findDOMNode(this.searchBoxRef.current)).tooltip({
                title: formatTranslated('NumberDelimiter', this.props.translations.Phrases),
                placement: 'top'
            });
        }
    }

    canEdit = (status, isReadOnly) => {
        return status === UserConstants.ACTIVE && this.props.isEditMode && !isReadOnly;
    };

    canTransfer = (status) => {
        return (
            status === UserConstants.ACTIVE ||
            status === UserConstants.EXPIRED ||
            status === UserConstants.CANCELLED ||
            status === UserConstants.DEACTIVATED
        );
    };

    transferBtnClass = (isDisabled) => {
        const btnClass = ['button', 'small', 'blue'];
        if (isDisabled) {
            btnClass.push('disabled');
        }
        return btnClass.join(' ');
    };

    transferRights = (userId) => {
        this.props.history.push({
            pathname: accessManagement.SEARCH_USERS_URL,
            state: {
                userId: userId
            }
        });
    };

    statusDisplay = () => {
        if (!this.props.viewModel.IsMultiUserAllowed) {
            return (
                <div className='col-sm-3'>
                    <TextBox
                        value={this.props.viewModel.User.Status ? this.props.viewModel.User.Status : ''}
                        label={this.props.translations.Phrases['Status_Label']}
                        name={'User.Status'}
                        valMsg={''}
                        readonly={true}
                        onValueChanged={this.props.handleUpdate}
                        validate={this.props.validateForm}
                    />
                </div>
            );
        }
    };

    statusLink = (teamMember, canEdit) => {
        return (
            <div className='col-sm-3'>
                <div className='input-container no-border status-link'>
                    <StatusChangeLink
                        cancelAction={this.cancelUser}
                        reactivateAction={this.reactivateUser}
                        teamMember={teamMember}
                        canDelete={!teamMember.IsLocked && canEdit}
                        hideCancel={true}
                    />
                </div>
            </div>
        );
    };

    cancelUser = (userId) => {
        this.props.cancelUser(userId);
    };

    reactivateUser = (userId) => {
        this.props.reactivateUser(userId);
    };

    formatServiceResults = (i) => {
        const address = i.Address || '';
        const addressArray = address.split(',');
        let companyAddress = <div />;
        if (addressArray.length > 1) {
            companyAddress = (
                <div className='data-row'>
                    <div className='result-label'>Address:</div>
                    <div className='result-value'>
                        <div>{addressArray[0]}</div>
                        <div>
                            {addressArray[1]}, {addressArray[2]}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='search-results'>
                <div>
                    <strong>{i.Number}</strong>
                </div>
                <div className='data-row'>
                    <div className='result-label-large'> Coordinator: </div>
                    <div className='result-value-large'>{i.ServiceCoordinator} </div>
                    <input className='pull-right' type='checkbox' checked={i.IsSelected} />
                </div>
                <div className='data-row'>
                    <div className='result-label'> Company: </div>
                    <div className='result-value'> {i.Name} </div>
                </div>
                <div className='data-row'>
                    <div className='result-label'> Account: </div>
                    <div className='result-value'>{i.AccountName} </div>
                </div>
                <div className='data-row'>
                    <div className='result-label'> Status: </div>
                    <div className='result-value'>{i.StatusCodeName} </div>
                </div>
                <div className='data-row'>
                    <div className='result-label-large'> Corp. Code: </div>
                    <div className='result-value-large'>{i.CorporateCodeName} </div>
                </div>
                {companyAddress}
            </div>
        );
    };

    addClientService = (item) => {
        item.IsSelected = true;
        this.props.onAddClientService(item, this.props.model.User.Roles[0].Value);
        this.props.setSelectedClientStatus({ number: item.Number, status: item.StatusCodeName });
    };

    onChangeUserExpirationDate = (date) => {
        this.props.handleUpdate(date, 'ExpirationDate');
    };

    onChangeUserRole = () => {
        this.props.updateRoleDropdown(!this.props.isRoleDropdownChanged);
    };

    searchBoxValueChanged = (val) => {
        this.setState({
            searchBoxValue: val
        });
    };

    render() {
        const vm = this.props.viewModel;
        const val = this.props.validationMessages ? this.props.validationMessages : [];
        let parentNames = [];
        const userIsLockedMessage = <div className='error message'>{this.props.translations.Phrases['UserIsLocked_Label']}</div>;
        const canEdit = this.canEdit(this.props.viewModel.User.Status, this.props.viewModel.IsReadOnly);
        const canTransfer = this.canTransfer(this.props.viewModel.User.Status);
        if (this.props.viewModel.ClientServicesByCountry) {
            this.props.viewModel.ClientServicesByCountry.forEach((cs) => {
                cs.ParentServices.forEach((p) => {
                    if (parentNames.indexOf(p.Number) < 0) {
                        parentNames.push(p.Number);
                    }
                });
            });
        }

        let changeUserRoleButton = null;
        changeUserRoleButton = (
            <div>
                <SubmitButton
                    model={this.props.modelCopy}
                    label={'Change User role'}
                    disabled={false}
                    postActionUrl='AccessManagement/ClientAdminEdit'
                    postAction={this.props.onSetValidationMessages}
                    onSuccessfulSubmit={{
                        backToPath: '/Dashboard',
                        backToLabel: 'BackToDashboard_Label',
                        phraseGroup: 'AccessManagement.AccessManagement.EditSuccessful'
                    }}
                />
                <p className='validation-text'>This action cannot be undone</p>
            </div>
        );

        let clientSearchBox = null;
        let transferButton = null;
        if (!this.props.viewModel.User.InternalUser) {
            transferButton = (
                <div className='col-sm-3'>
                    <button
                        disabled={
                            this.props.viewModel.User.IsLocked ||
                            !canTransfer ||
                            this.props.viewModel.UserHasPendingChanges ||
                            this.props.isRoleDropdownChanged
                        }
                        className={this.transferBtnClass(
                            this.props.isRoleDropdownChanged ||
                                this.props.viewModel.User.IsLocked ||
                                !canTransfer ||
                                this.props.viewModel.UserHasPendingChanges
                        )}
                        onClick={this.transferRights.bind(this, this.props.viewModel.User.UserId)}
                    >
                        {this.props.translations.Phrases['TransferRights_Label']}
                    </button>
                </div>
            );
            const selectedRole = () => {
                if (this.props.modelCopy && this.props.modelCopy.User && this.props.modelCopy.Roles) {
                    const role = this.props.modelCopy.User.Roles.find((f) => {
                        return f.IsSelected === true;
                    });

                    return role;
                } else if (this.props.viewModel && this.props.viewModel.User && this.props.viewModel.Roles) {
                    const role = this.props.viewModel.User.Roles.find((f) => {
                        return f.IsSelected === true;
                    });

                    return role;
                }
            };
            const selectedRoleValue = selectedRole ? selectedRole.Value : selectedRole;
            const readOnly = !canEdit || this.props.viewModel.User.IsLocked;
            let disabled = this.props.viewModel.UserHasPendingChanges || this.props.isRoleDropdownChanged ? true : false;
            clientSearchBox = (
                <div ref={this.searchBoxRef} className='col-sm-6'>
                    <SearchBox
                        label={this.props.translations.Phrases['RequestedClientNumbers_Label']}
                        placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                        name='ClientServicesByCountry'
                        value={this.state.searchBoxValue}
                        url={`/ClientAdmin/GetEDMClientServices?country=ca&role=${selectedRoleValue}`}
                        formatResults={this.formatServiceResults}
                        minChars={5}
                        titleField='Number'
                        descriptionField='Name'
                        clearWhenSearching={false}
                        readonly={readOnly}
                        multiSelect='multiselect'
                        deselect='deselect'
                        onSelectItem={this.addClientService}
                        disabled={disabled}
                        onValueChanged={this.searchBoxValueChanged}
                    >
                        <TagControl
                            tags={parentNames.sort((a, b) => {
                                return a - b;
                            })}
                        />
                    </SearchBox>
                </div>
            );
        }
        const readOnlyEmail = !canEdit || this.props.isEditMode || this.props.viewModel.User.IsLocked;
        const readOnly = !canEdit || this.props.viewModel.User.IsLocked;
        let disabled = this.props.viewModel.UserHasPendingChanges || this.props.isRoleDropdownChanged ? true : false;
        let userDetails = (
            <div>
                <div className='row'>
                    <div className='col-sm-3'>
                        <TextBox
                            value={this.props.viewModel.User.FirstName ? this.props.viewModel.User.FirstName : ''}
                            label={this.props.translations.Phrases['FirstName_Label']}
                            name={'User.FirstName'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.FirstName] : ''}
                            readonly={readOnly}
                            disabled={disabled}
                            onValueChanged={this.props.handleUpdate}
                            validate={this.props.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={this.props.viewModel.User.LastName ? this.props.viewModel.User.LastName : ''}
                            label={this.props.translations.Phrases['LastName_Label']}
                            name={'User.LastName'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.LastName] : ''}
                            readonly={readOnly}
                            disabled={disabled}
                            onValueChanged={this.props.handleUpdate}
                            validate={this.props.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={this.props.viewModel.User.LastLogin ? this.props.viewModel.User.LastLogin.substring(0, 10) : ''}
                            label={this.props.translations.Phrases['LastLogin_Label']}
                            name={'User.LastLogin'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.LastLogin] : ''}
                            readonly={readOnlyEmail}
                            disabled={disabled}
                            onValueChanged={this.props.handleUpdate}
                            validate={this.props.validateForm}
                        />
                    </div>
                    {this.statusDisplay()}
                </div>
                <div className='row'>
                    <div className='col-sm-3'>
                        <TextBox
                            value={this.props.viewModel.User.Email ? this.props.viewModel.User.Email : ''}
                            label={this.props.translations.Phrases['Email_Label']}
                            name={'User.Email'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.Email] : ''}
                            readonly={readOnlyEmail}
                            disabled={disabled}
                            onValueChanged={this.props.handleUpdate}
                            validate={this.props.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={this.props.viewModel.User.Phone ? this.props.viewModel.User.Phone : ''}
                            label={this.props.translations.Phrases['Phone_Label']}
                            name={'User.Phone'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.Phone] : ''}
                            readonly={readOnly}
                            disabled={this.props.isRoleDropdownChanged}
                            placeholder={'(123) 456-7890 x123456'}
                            inputMask='(000) 000-0000 x000000'
                            onValueChanged={this.props.handleUpdate}
                            validate={this.props.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <Dropdown
                            name={'User.Roles'}
                            options={
                                (this.props.modelCopy && this.props.modelCopy.User?.Roles) ||
                                (this.props.viewModel && this.props.viewModel.User?.Roles) ||
                                []
                            }
                            label={this.props.translations.Phrases['UserRole_Label']}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.Roles] : ''}
                            readonly={
                                !this.props.viewModel.User.Roles?.some((role) => role.Value === 'PaymentAdmin' && role.IsSelected) &&
                                readOnly
                            }
                            onItemChanged={() => this.onChangeUserRole()}
                        />
                        {this.props.isRoleDropdownChanged && changeUserRoleButton}
                    </div>
                    {!this.props.isRoleDropdownChanged &&
                        this.statusLink(
                            this.props.viewModel.User,
                            !this.props.viewModel.UserHasPendingChanges && this.props.isEditMode && !this.props.viewModel.IsReadOnly
                        )}
                </div>
                {!this.props.isRoleDropdownChanged && (
                    <div className='row'>
                        {clientSearchBox}
                        <div className='col-sm-3'>
                            <DatePickerModal
                                linkText={this.props.translations.Phrases['SetAccessExpirationDate_Label']}
                                dateLabel={this.props.translations.Phrases['ExpirationDate_Label']}
                                date={this.props.viewModel.User.ExpirationDate}
                                readonly={readOnly}
                                disabled={disabled}
                                name='User.ExpirationDate'
                                noBorder
                                valMsg={val.User ? this.props.translations.Phrases[val.User.ExpirationDate] : ''}
                                onDateChanged={this.props.handleUpdate}
                                validate={this.props.validateForm}
                            />
                        </div>

                        {transferButton}
                    </div>
                )}
            </div>
        );

        return (
            <div className='row'>
                <div className='col-sm-12'>{vm.User.IsLocked ? userIsLockedMessage : ''}</div>
                <div className='col-sm-12'>{userDetails}</div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        model: state.accessManagement.model,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAddClientService: (item, model) => dispatch(actions.addClientServiceClientAdmin(item, model))
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditClientAdminHeader));
