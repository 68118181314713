import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeDocumentsFilter } from '../../common/constants/constants-documents';
import { DateRangeSlice } from '../../common/models/ReduxSlices';

const initialState: DateRangeSlice = {
    dateRangeSelected: {
        id: '',
        label: 'DateRangeThirtyDays_Label',
        startDate: null,
        endDate: null
    },
    customDateRange: []
};

const dateRangeSlice = createSlice({
    name: 'dateRangeSelected',
    initialState,
    reducers: {
        setDateRangeSelected(state, action: PayloadAction<number>) {
            const dateRangeSelectedIndex = action.payload;
            state.dateRangeSelected = DateRangeDocumentsFilter[dateRangeSelectedIndex];
        },
        setCustomDateRange(state, action: PayloadAction<{ type: string; dateUs: string; dateCa: string }[]>) {
            state.customDateRange = action.payload;
        },

        resetDateRange() {
            return { ...initialState };
        }
    }
});

export const { setDateRangeSelected, setCustomDateRange, resetDateRange } = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
