import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusAlert from '../../../portal/common/features/StatusAlert';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../portal/common/models/ReduxSlices';
import { setStatusMessage } from '../../redux/reducers/statusUserSlice';
import { RootState } from '../../redux/store';
import { StatusUserSlice, UserDetailsSlice } from '../models/ReduxSlices';

interface StatusAlertsProps {
    name: string;
}

const StatusAlerts: React.FC<StatusAlertsProps> = ({ name }) => {
    const { isActive } = useSelector<RootState, StatusUserSlice>((state) => state.statusUser);
    const { newUsersAmount } = useSelector<RootState, UserDetailsSlice>((state) => state.userDetails);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const dispatch = useDispatch();
    const translate = useTranslation();

    const timeout = user?.PortalTimeoutShort || 5000;

    useEffect(() => {
        if (name === 'userStatus') {
            const alertDelay = setTimeout(() => dispatch(setStatusMessage(false)), timeout + 200);
            return () => {
                clearTimeout(alertDelay);
            };
        }
    }, [dispatch, name, timeout]);

    return (
        <>
            {name === 'userStatus' && (
                <StatusAlert message={!isActive ? translate('UserReactivate_Label') : translate('UserDeactivate_Label')} type='success' />
            )}
            {name === 'newUser' && <StatusAlert message={translate('StatusAlertUsersAdded_Label', newUsersAmount)} type='success' />}
        </>
    );
};

export default StatusAlerts;
