import React, { Component } from 'react';
import { connect } from 'react-redux';
import Upload from '../../../common/Upload/Upload';
import { Upload as upload } from '../../../constants/mlp-constants';
import * as actionCreators from '../../../store/actions/index';

class BatchUploadCreateForm extends Component {
    uploadSuccess = (batchJobId) => {
        this.props.onGetBatchJobCreate(batchJobId);
    };

    render() {
        const uploadOptions = {
            paramName: 'file', // The name that will be used to transfer the file
            acceptedFiles: 'application/vnd.ms-excel,.csv',
            maxFilesize: 1000, // MB
            maxFiles: 1,
            dictFallbackMessage: this.props.translations.Phrases['ClickToSelectFile_Label'],
            dictInvalidFileType: this.props.translations.Phrases['InvalidFileType_Label']
        };

        const component = (
            <Upload
                uploadSuccess={this.uploadSuccess}
                options={uploadOptions}
                id={'upload-batch-users'}
                formAction={upload.UPLOAD_CREATE_FILE}
                initialMessage={this.props.translations.Phrases['DropOrClickToUpload_Label']}
                translations={this.props.translations}
            />
        );

        return <div className='w-100'>{component}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetBatchJobCreate: (batchJobId) => dispatch(actionCreators.getBatchJobCreate(batchJobId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchUploadCreateForm);
