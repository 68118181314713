import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../../../portal/common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../portal/common/features/Breadcrumb/BreadcrumbItem';
import useTranslationHydration from '../../../../portal/common/hooks/useTranslationHydration';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

const BreadcrumbClearances = () => {
    const translate = useTranslationHydration();
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);

    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const origin = searchParams.get('origin');

    if (origin === 'documents') {
        return (
            <Breadcrumb>
                <BreadcrumbItem url='/Documents'>{translate('Documents_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{shipmentDetails?.EntryNumber}</BreadcrumbItem>
            </Breadcrumb>
        );
    } else if (origin === 'cad') {
        return (
            <Breadcrumb>
                <BreadcrumbItem url='/CompleteEntryData'>{translate('CAD_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{shipmentDetails?.EntryNumber}</BreadcrumbItem>
            </Breadcrumb>
        );
    } else {
        return (
            <Breadcrumb>
                <BreadcrumbItem url='/Clearances'>{translate('Clearances_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{shipmentDetails?.EntryNumber}</BreadcrumbItem>
            </Breadcrumb>
        );
    }
};

export default BreadcrumbClearances;
