import { createSlice } from '@reduxjs/toolkit';
import { ClientSelectionSlice } from '../../common/models/ReduxSlices';
import { ClientSelectionData } from '../../common/models/ResponseData';
import { GetClientSelectionSearch, GetClientSelectionSettings, UpdateClientSelectionSettings } from '../actions/clientSelection';

const initialState: ClientSelectionSlice = {
    clientSelection: null,
    initialClientSelection: null, // Used for unrestricted
    applyClicked: null,
    isLoading: true,
    isLoadingSearch: false,
    isLoadingUpdate: false,
    isLoadingGet: false,
    error: undefined
};

const clientSelectionSlice = createSlice({
    name: 'clientSelection',
    initialState,
    reducers: {
        resetChooser: (state) => {
            state.clientSelection = null;
            state.initialClientSelection = null;
            state.applyClicked = null;
            state.isLoading = true;
            state.isLoadingSearch = false;
            state.isLoadingUpdate = false;
            state.isLoadingGet = false;
            state.error = undefined;
        },
        resetClientSelectionState: (state) => {
            state.clientSelection = state.initialClientSelection;
        },
        setIsApplyClicked: (state, action) => {
            state.applyClicked = action.payload;
        },
        setClientSelectionCountry: (state, action) => {
            if (!state.clientSelection) return;

            state.clientSelection.Countries = state.clientSelection?.Countries.map((c) => ({
                ...c,
                IsSelected: c.Code === action.payload
            }));
        },
        setClientSelectionServices: (state, action) => {
            if (!state.clientSelection) return;

            state.clientSelection.ClientServices = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Get client selection
            .addCase(GetClientSelectionSettings.pending, (state, action) => {
                state.isLoadingGet = true;
                state.error = undefined;
            })
            .addCase(GetClientSelectionSettings.fulfilled, (state, action) => {
                state.isLoadingGet = false;

                state.clientSelection = action.payload.ClientServiceSelection;
                state.initialClientSelection = action.payload.ClientServiceSelection;
            })
            .addCase(GetClientSelectionSettings.rejected, (state, action) => {
                state.isLoadingGet = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })

            // Search clients as unrestricted
            .addCase(GetClientSelectionSearch.pending, (state, action) => {
                state.isLoadingSearch = true;
                state.error = undefined;
            })
            .addCase(GetClientSelectionSearch.fulfilled, (state, action) => {
                if (!state.clientSelection?.ClientServices) return;

                const copyServices = action.payload.copyServices as ClientSelectionData['ClientServiceSelection']['ClientServices'];

                const oldSelected = copyServices.filter((c: any) => c.IsSelected);

                const newClients = action.payload.res;

                const uniqueArr = [] as ClientSelectionData['ClientServiceSelection']['ClientServices'];
                [...oldSelected, ...newClients].forEach(function (item) {
                    var i = uniqueArr.findIndex((x) => x.FullNumber === item.FullNumber);
                    if (i <= -1) {
                        uniqueArr.push(item);
                    }
                });

                state.isLoadingSearch = false;
                state.clientSelection.ClientServices = uniqueArr;
            })
            .addCase(GetClientSelectionSearch.rejected, (state, action) => {
                state.isLoadingSearch = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })

            // Update client selection
            .addCase(UpdateClientSelectionSettings.pending, (state, action) => {
                state.isLoadingUpdate = true;
                state.error = undefined;
            })
            .addCase(UpdateClientSelectionSettings.fulfilled, (state, action) => {
                state.isLoadingUpdate = false;
                state.applyClicked = false;
            })
            .addCase(UpdateClientSelectionSettings.rejected, (state, action) => {
                state.isLoadingUpdate = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { resetChooser, resetClientSelectionState, setClientSelectionCountry, setClientSelectionServices, setIsApplyClicked } =
    clientSelectionSlice.actions;

export default clientSelectionSlice.reducer;
