import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from '../../portal/redux/reducers/alertsSlice';
import clientSelectionReducer from '../../portal/redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../portal/redux/reducers/hydrationSlice';
import languageReducer from '../../portal/redux/reducers/languageSlice';
import noticesReducer from '../../portal/redux/reducers/noticesSlice';
import portalSolutionsReducers from '../../portal/redux/reducers/portalSolutionsSlice';
import toastReducer from '../../portal/redux/reducers/toastSlice';
import translationsReducer from '../../portal/redux/reducers/translationsSlice';
import editSubscriptiosSubscribersSlice from './reducers/editSubscriptiosSubscribersSlice';
import searchFilterReducer from './reducers/searchFilterSlice';
import statusFilterReducer from './reducers/statusFilterSlice';
import subscribersGridSettingsReducer from './reducers/subscribersGridSettingsSlice';
import subscribersSearchFilterSlice from './reducers/subscribersSearchFilterSlice';
import subscribersReducer from './reducers/subscribersSlice';
import subscribersStatusFilterReducer from './reducers/subscribersStatusFilterSlice';

const store = configureStore({
    reducer: {
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        notices: noticesReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,

        // page specific slices
        statusFilter: statusFilterReducer,
        searchFilter: searchFilterReducer,
        subscribersStatusFilter: subscribersStatusFilterReducer,
        subscribersSearchFilter: subscribersSearchFilterSlice,
        subscribersGridSettings: subscribersGridSettingsReducer,
        subscribers: subscribersReducer,
        editSubscriptionsSubscriber: editSubscriptiosSubscribersSlice
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
