import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewSubscriberLanguages, NewSubscriberRoles } from '../../../portal/common/constants/constants-portal';
import { EditSubscribersSlice } from '../../common/models/ReduxSlices';
import { MultipleUnsubscribe, UpdateGlobalSubscription, UpdateGlobalSubscriptions } from '../actions/editSubscribers';
import { GetSubscriberSubscriptions } from '../actions/subscribersManagement';

const initialState: EditSubscribersSlice = {
    editSubscriber: {
        isEditable: false,
        editEmail: null,
        editPhone: null,
        fullName: null,
        role: '',
        language: '-',
        userId: ''
    },
    subscriptions: null,
    customerNumbers: [],
    unsubscribe: {
        isLoading: false,
        error: undefined,
        removedSubscribersAmount: null,
        action: null,
        alertMessage: null,
        alertType: null
    },
    isLoading: true,
    error: undefined,
    subscriptionUpdate: {
        data: null,
        isLoading: false,
        error: undefined,
        action: null,
        alertMessage: null,
        alertType: null,
        customerNumber: null,
        requestedAction: null
    }
};

const editSubscriptionsSubscribersSlice = createSlice({
    name: 'editSubscriptionsSubscribers',
    initialState,
    reducers: {
        setEditSubscriptionsInitialData(
            state,
            action: PayloadAction<{
                isEditable: boolean;
                editEmail: string | null;
                editPhone: string | null;
                fullName: string | null;
                role: typeof NewSubscriberRoles[number]['value'];
                language: typeof NewSubscriberLanguages[number]['value'];
            }>
        ) {
            state.editSubscriber = {
                ...state.editSubscriber,
                isEditable: action?.payload?.isEditable ?? false,
                editEmail: action?.payload?.editEmail ?? null,
                editPhone: action?.payload?.editPhone ?? null,
                fullName: action?.payload?.fullName,
                role: action?.payload?.role,
                language: action?.payload?.language
            };
        },
        setSubscriptionUpdateAction(state, action: PayloadAction<string>) {
            state.subscriptionUpdate.action = action.payload;
        },
        setCustomerNumber(state, action: PayloadAction<string | null>) {
            state.subscriptionUpdate.customerNumber = action.payload;
        },
        setCustomerNumbers(state, action: PayloadAction<string[]>) {
            state.customerNumbers = action.payload;
        },
        setNotificationTypeCheckbox(state, action: PayloadAction<{ subscriptionId: number; emailValue: boolean; phoneValue: boolean }>) {
            state.subscriptions = state.subscriptions?.map((item) =>
                item.SubscriptionId === action.payload.subscriptionId
                    ? {
                          ...item,
                          NotificationTypeCheckboxes: { EmailCheckbox: action.payload.emailValue, PhoneCheckbox: action.payload.phoneValue }
                      }
                    : item
            );
        },
        resetSubscriptionUpdate(state) {
            state.subscriptionUpdate = initialState.subscriptionUpdate;
        },
        resetUnsubscribe(state) {
            state.unsubscribe = initialState.unsubscribe;
        },
        resetEditSubscriptions(state) {
            state.editSubscriber = initialState.editSubscriber;
        },
        resetEditSubscriptionsGrid(state) {
            state.subscriptions = initialState.subscriptions;
        }
    },

    extraReducers: (builder) => {
        builder
            // GET SUBSCRIBER SUBSCRIPTIONS
            .addCase(GetSubscriberSubscriptions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetSubscriberSubscriptions.fulfilled, (state, action) => {
                state.subscriptions = action.payload.SubscriptionsPerClientNumbers;
                state.isLoading = false;
            })
            .addCase(GetSubscriberSubscriptions.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // UPDATE GLOBAL-CLIENT SUBSCRIPTION
            .addCase(UpdateGlobalSubscription.pending, (state) => {
                state.subscriptionUpdate.isLoading = true;
            })
            .addCase(UpdateGlobalSubscription.fulfilled, (state, action) => {
                state.subscriptionUpdate.data = action.payload;
                state.subscriptionUpdate.isLoading = false;
                state.subscriptionUpdate.requestedAction = action.payload.RequestedAction;
                if (
                    (action.payload.ResponseCode === 400 || action.payload.ResponseCode === 500) &&
                    action.payload.ResponseMessage === 'FAIL'
                ) {
                    state.subscriptionUpdate.alertMessage = 'ClientNumberNoClearancesAvailable_Label';
                    state.subscriptionUpdate.alertType = 'warning';
                } else {
                    if (state.subscriptionUpdate.action === 'subscribe') {
                        state.subscriptionUpdate.alertMessage = 'SubscribedToClient_Label';
                    } else if (state.subscriptionUpdate.action === 'unsubscribe') {
                        state.subscriptionUpdate.alertMessage = 'UnsubscribedFromClient_Label';
                    } else if (state.subscriptionUpdate.action === 'onhold') {
                        state.subscriptionUpdate.alertMessage =
                            action.payload.ResponseMessage === 'PASS' && action.payload.RequestedAction === 'UNSUBSCRIBE'
                                ? 'UnsubscribedFromClient_Label'
                                : 'OnHoldNotificationsUpdated_Label';
                    } else if (state.subscriptionUpdate.action === 'notifTypes') {
                        state.subscriptionUpdate.alertMessage =
                            action.payload.ResponseMessage === 'PASS' && action.payload.RequestedAction === 'UNSUBSCRIBE'
                                ? 'UnsubscribedFromClient_Label'
                                : 'NotificationTypeUpdated_Label';
                    } else if (state.subscriptionUpdate.action === 'milestones') {
                        state.subscriptionUpdate.alertMessage =
                            action.payload.ResponseMessage === 'PASS' && action.payload.RequestedAction === 'UNSUBSCRIBE'
                                ? 'UnsubscribedFromClient_Label'
                                : 'MilestonesUpdated_Label';
                    }
                    state.subscriptionUpdate.alertType = 'success';
                }
            })
            .addCase(UpdateGlobalSubscription.rejected, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.subscriptionUpdate.error = action.payload;
                } else {
                    state.subscriptionUpdate.error = action.error.message;
                }
            })
            // UPDATE GLOBAL-CLIENT SUBSCRIPTIONS
            .addCase(UpdateGlobalSubscriptions.pending, (state) => {
                state.subscriptionUpdate.isLoading = true;
            })
            .addCase(UpdateGlobalSubscriptions.fulfilled, (state, action) => {
                state.subscriptionUpdate.data = action.payload;
                state.subscriptionUpdate.isLoading = false;
                state.subscriptionUpdate.requestedAction = action.payload.RequestedAction;
                state.subscriptionUpdate.action = 'multi-select'
            })
            .addCase(UpdateGlobalSubscriptions.rejected, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.subscriptionUpdate.error = action.payload;
                } else {
                    state.subscriptionUpdate.error = action.error.message;
                }
            })
            // MULTIPLE UNSUBSCRIBE
            .addCase(MultipleUnsubscribe.pending, (state, action) => {
                state.unsubscribe.isLoading = true;
                state.subscriptionUpdate.isLoading = true;
                state.unsubscribe.removedSubscribersAmount = action.meta.arg.subscriptionIds.length;
            })
            .addCase(MultipleUnsubscribe.fulfilled, (state) => {
                state.subscriptionUpdate.isLoading = false;
                state.unsubscribe.isLoading = false;
            })
            .addCase(MultipleUnsubscribe.rejected, (state, action) => {
                state.unsubscribe.isLoading = false;
                state.subscriptionUpdate.isLoading = false;
                state.unsubscribe.error = action.error.message;
            });
    }
});

export const {
    setEditSubscriptionsInitialData,
    setSubscriptionUpdateAction,
    setCustomerNumber,
    setCustomerNumbers,
    setNotificationTypeCheckbox,
    resetSubscriptionUpdate,
    resetUnsubscribe,
    resetEditSubscriptions,
    resetEditSubscriptionsGrid
} = editSubscriptionsSubscribersSlice.actions;

export default editSubscriptionsSubscribersSlice.reducer;
