import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useTranslationHydration from '../hooks/useTranslationHydration';
import { HydrationSlice } from '../models/ReduxSlices';
import Badge from './Badge';
import useToast from './Toast/useToast';
import ToastWrapper from './Wrappers/ToastWrapper';

export interface StatusAlertProps {
    message: string; // translated string or just label
    isLabel?: boolean; // is "message" translated string or just label
    type: 'success' | 'warning' | 'error';
    isVisible?: (arg: boolean) => void;
    customTimeout?: number;
}

const StatusAlert: React.FC<StatusAlertProps> = ({ isVisible, type, message, isLabel = false, customTimeout }) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { clear } = useToast();
    const translate = useTranslationHydration();

    const timeout = customTimeout || user?.PortalTimeoutShort || 3000;

    useEffect(() => {
        const alertDelay = setTimeout(() => {
            clear(); // Clear all

            if (isVisible) {
                isVisible(false);
            }
        }, timeout + 200);

        return () => {
            clear();
            clearTimeout(alertDelay);
        };
    }, [timeout, isVisible, clear, customTimeout, isLabel, type]);

    const faIcon = {
        success: faCheckCircle,
        warning: faExclamationCircle,
        error: faTimesCircle
    };

    const alertMsg = isLabel ? translate(message) : message;

    return (
        <ToastWrapper type={type} timeout={timeout}>
            <div className='toast-body'>
                <div className='toast-icon'>
                    <Badge type={type === 'error' ? 'danger' : type} variant='circular' size='small'>
                        <FontAwesomeIcon icon={faIcon[type]} />
                    </Badge>
                </div>
                <div className='toast-text'>{alertMsg}</div>
            </div>
        </ToastWrapper>
    );
};

export default StatusAlert;
