import { PayloadAction } from '@reduxjs/toolkit';
import { ColumnSettings } from '../../../portal/common/models/GridPreferences';
import {
    createGenericGridPreferencesSlice,
    genericGridPreferencesInitialState
} from '../../../portal/redux/reducers/genericGridPreferencesSlice';
import { defaultGridColumns } from '../../common/constants/constants-clearances';
import { GetUserClearancesGridSetting, SaveUserClearancesGridSetting } from '../actions/clearancesGridPreferences';

const clearancesGridPreferencesSlice = createGenericGridPreferencesSlice({
    name: 'clearancesGridPreferences',
    initialState: genericGridPreferencesInitialState,
    reducers: {
        updateColumnOrder(state, action: PayloadAction<ColumnSettings[]>) {
            state.allColumns = action.payload.map((item, i) => {
                return { ...item, Order: i + 1 };
            });
            state.visibleColumns = state.allColumns?.filter((item: ColumnSettings) => {
                if (item.IsVisible) {
                    return item;
                }
                return null;
            });
        },
        updateColumnOrderGrid(state, action: PayloadAction<ColumnSettings[]>) {
            state.allColumns = [...action.payload].filter((item: ColumnSettings) => {
                if (item.Code !== 'selected' && item.Code !== 'Actions') {
                    return item;
                }
                return null;
            });
            state.visibleColumns = state.allColumns?.filter((item: ColumnSettings) => {
                if (item.IsVisible) {
                    return item;
                }
                return null;
            });
        },
        setDefaultGridColumns(state) {
            state.allColumns = defaultGridColumns;
            state.visibleColumns = defaultGridColumns.filter((column) => column.IsVisible === true);
            state.columnsWidths = defaultGridColumns.map((column) => {
                return { Code: column.Code, Width: column.Width };
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserClearancesGridSetting.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetUserClearancesGridSetting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allColumns = action.payload;
                state.visibleColumns = action.payload.filter((column) => column.IsVisible === true);
                state.columnsWidths = action.payload.map((column) => {
                    return { Code: column.Code, Width: column.Width };
                });
            })
            .addCase(GetUserClearancesGridSetting.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(SaveUserClearancesGridSetting.pending, (state, action) => {
                state.areColumnsLoading = true;
                state.error = undefined;
            })
            .addCase(SaveUserClearancesGridSetting.fulfilled, (state, action) => {
                state.areColumnsLoading = false;
                state.hasOwnColumnsOrder = action.payload;
            })
            .addCase(SaveUserClearancesGridSetting.rejected, (state, action) => {
                state.areColumnsLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { updateColumnOrder, updateColumnOrderGrid, resetGridPreferences, setDefaultGridColumns } =
    clearancesGridPreferencesSlice.actions;

export default clearancesGridPreferencesSlice.reducer;
