import { createGenericGridSettingsSlice, genericGridSettingsInitialState } from '../../../portal/redux/reducers/genericGridSettingsSlice';

const teamGridSettingsSlice = createGenericGridSettingsSlice({
    name: 'teamGridSettings',
    initialState: genericGridSettingsInitialState,
    reducers: {}
});

export const {
    setPageSelected,
    setDataState,
    setTotalItems,
    setSelectedState,
    setHeaderCheckbox,
    setCurrentDataItems,
    setSort,
    resetDataState,
    resetSelectedState
} = teamGridSettingsSlice.actions;

export default teamGridSettingsSlice.reducer;
