import { configureStore } from '@reduxjs/toolkit';
import dateRangeReducer from '../../documents/redux/reducers/dateRangeSlice';
import imagingReducer from '../../documents/redux/reducers/imagingSlice';
import alertsReducer from '../../portal/redux/reducers/alertsSlice';
import clientSelectionReducer from '../../portal/redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../portal/redux/reducers/hydrationSlice';
import languageReducer from '../../portal/redux/reducers/languageSlice';
import noticesReducer from '../../portal/redux/reducers/noticesSlice';
import portalSolutionsReducers from '../../portal/redux/reducers/portalSolutionsSlice';
import toastReducer from '../../portal/redux/reducers/toastSlice';
import translationsReducer from '../../portal/redux/reducers/translationsSlice';
//import completeEntryDataReducer from './reducers/completeEntryDataSlice';
import ellipsisReducer from '../../portal/redux/reducers/ellipsisSlice';
import completeEntryDataGridPreferencesReducer from './reducers/completeEntryDataGridPreferenceSlice';
import completeEntryDataGridSettingsReducer from './reducers/completeEntryDataGridSettingsSlice';
import completeEntryDataReducer from './reducers/completeEntryDataSlice';
import dateFilterReducer from './reducers/dateFilterSlice';
import locationFilterReducer from './reducers/locationFilterSlice';
import motFilterReducer from './reducers/motFilterSlice';

const store = configureStore({
    reducer: {
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        notices: noticesReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,
        dateFilter: dateFilterReducer,
        ellipsis: ellipsisReducer,

        // page specific slices
        imaging: imagingReducer,
        dateRange: dateRangeReducer,
        completeEntryDataGridPreferences: completeEntryDataGridPreferencesReducer,
        completeEntryDataGridSettings: completeEntryDataGridSettingsReducer,
        completeEntryData: completeEntryDataReducer,
        locationFilter: locationFilterReducer,
        motFilter: motFilterReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
