import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breakpoints } from "../../../portal/common/constants/constants-portal";
import SearchBox from "../../../portal/common/features/FilterBar/SearchBox/SearchBox";
import useMediaQuery from "../../../portal/common/hooks/useMediaQuery";
import useSearchInput from "../../../portal/common/hooks/useSearchInput";
import useTranslation from "../../../portal/common/hooks/useTranslation";
import { SearchFilterSlice } from "../../../portal/common/models/ReduxSlices";
import { setPageSelected } from "../../redux/reducers/contactsGridSettingsSlice";
import { clearSearchFilter, setSearchFilter } from "../../redux/reducers/contactsSearchFilterSlice";
import { RootState } from "../../redux/store";

const ContactsSearchBox = () => {
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.contactsSearchFilter);
    const { value, handleBlur, handleChange, handleKeyDown, handleReset } = useSearchInput({
        inputValue: searchTerm,
        onEnterKey: () => handleSubmit()
    });
    const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
    const isDesktop = useMediaQuery(Breakpoints.Mobile);
    const translate = useTranslation();
    const dispatch = useDispatch();

    // Automatically hide mobile search when not on mobile resolution
    useEffect(() => {
        if (isDesktop) {
            setShowMobileSearch(false);
        }
    }, [isDesktop]);

    const toggleMobileSearchBox = () => {
        setShowMobileSearch((prevState) => !prevState);
    };

    const handleClearInputValue = () => {
        handleReset();
        dispatch(clearSearchFilter());
    };

    const handleSubmit = () => {
        // Update the searchFilter redux slice
        dispatch(setSearchFilter(value.trim()));
        // Set the grid page to 1
        dispatch(setPageSelected(1));
        // If mobile search is open close it
        if (showMobileSearch) {
            toggleMobileSearchBox();
        }
    }

    return (
        <SearchBox
            inputValue={value}
            placeholder={translate('SearchPlaceholder_Label')}
            submitButtonText={translate('Search_Label')}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onToggleMobileSearch={toggleMobileSearchBox}
            onClearInputValue={handleClearInputValue}
            onSubmit={handleSubmit}
            showMobileSearch={showMobileSearch}
            customStyle={'mr-2 ml-2 mt-2'}
        />
    )
}

export default ContactsSearchBox;