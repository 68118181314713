import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../portal/common/models/ReduxSlices';
import FixedFooterWhite from '../../common/features/FixedFooterWhite';
import { isMLPFeatureSelected } from '../../common/functions/utils';
import { ClientsAndPermissionsSlice, EditTeamMemberSlice } from '../../common/models/ReduxSlices';
import { TeamMemberData } from '../../common/models/ResponseData';
import { RootState } from '../../redux/store';

interface EditTeamMemberFooterProps {
    onSaveUser: (payload: { data: TeamMemberData; editData: TeamMemberData }) => void;
}

const EditTeamMemberFooter: React.FC<EditTeamMemberFooterProps> = ({ onSaveUser }) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const {
        data: userDetailsData,
        editData,
        userDetailsIsValid,
        isLoading: userDetailsLoading,
        error: userDetailsError
    } = useSelector<RootState, EditTeamMemberSlice>((state) => state.editTeamMember);
    const {
        data: clientsAndPermissionsData,
        isPending,
        isLoading: clientsAndPermissionsLoading,
        error: clientsAndPermissionsError
    } = useSelector<RootState, ClientsAndPermissionsSlice>((state) => state.clientsAndPermissions);
    const [message, setMessage] = useState<string>('');
    const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const mlpFeatureSelectedIsValid = useMemo(
        () => isMLPFeatureSelected(clientsAndPermissionsData?.ParentServices),
        [clientsAndPermissionsData?.ParentServices]
    );
    const translate = useTranslation();

    useEffect(() => {
        if (!formIsSubmitted) {
            return;
        }
        if (!mlpFeatureSelectedIsValid) {
            setMessage(
                userDetailsData?.AdminRole === 'CentralAdmin'
                    ? translate('ClearancesFeatureMustBeSelected_Label')
                    : translate('MLPFeatureMustBeSelected_Label')
            );
        } else if (!userDetailsIsValid) {
            setMessage(translate('InvalidUserDetails_Label'));
        } else {
            setMessage('');
        }
    }, [formIsSubmitted, mlpFeatureSelectedIsValid, translate, userDetailsData?.AdminRole, userDetailsIsValid]);

    const handleSavingUsers = () => {
        setFormIsSubmitted(true);
        if (userDetailsData && editData && mlpFeatureSelectedIsValid && userDetailsIsValid) {
            onSaveUser({
                data: { ...userDetailsData, ...clientsAndPermissionsData },
                editData: { ...editData, ...clientsAndPermissionsData }
            });
        }
    };

    return (
        <>
            {isEditable &&
                !isPending &&
                !userDetailsLoading &&
                !userDetailsError &&
                !clientsAndPermissionsLoading &&
                !clientsAndPermissionsError && (
                    <FixedFooterWhite
                        primaryLabel={translate('TeamMemberSaveChanges_Label')}
                        primaryOnClick={handleSavingUsers}
                        message={message}
                    />
                )}
        </>
    );
};

export default EditTeamMemberFooter;
