import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShipmentDestinationSlice } from '../../common/models/ReduxSlices';
import { DestinationCountry } from '../../common/models/ShipmentDestination';

const initialState: ShipmentDestinationSlice = {
    destinations: [],
    destinationSelected: null
};

const shipmentDestinationSlice = createSlice({
    name: 'destinationSelected',
    initialState,
    reducers: {
        setDestinations(state, action: PayloadAction<DestinationCountry[]>) {
            state.destinations = action.payload;
            state.destinationSelected = action.payload[0];
        },
        setDestinationSelected(state, action: PayloadAction<{ id: string; label: string }>) {
            state.destinationSelected = action.payload;
        },
        resetDestinations(state) {
            state.destinationSelected = state.destinations![0];
        }
    }
});

export const { setDestinations, setDestinationSelected, resetDestinations } = shipmentDestinationSlice.actions;

export default shipmentDestinationSlice.reducer;
