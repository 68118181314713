import { useSelector } from 'react-redux';
import Breadcrumb from '../../../portal/common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../portal/common/features/Breadcrumb/BreadcrumbItem';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { EditTeamMemberSlice } from '../../common/models/ReduxSlices';
import { RootState } from '../../redux/store';

const EditTeamMemberBreadcrumb = () => {
    const { data: userDetailsData } = useSelector<RootState, EditTeamMemberSlice>((state) => state.editTeamMember);
    const translate = useTranslation();

    return (
        <Breadcrumb>
            <BreadcrumbItem url='/Team'>{translate('Team_Label')}</BreadcrumbItem>
            <BreadcrumbItem>{userDetailsData?.Users?.[0].FullName || translate('Loading_Label')}</BreadcrumbItem>
        </Breadcrumb>
    );
};

export default EditTeamMemberBreadcrumb;
