import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_PROFILE_PATH } from '../../../portal/common/constants/constants-portal';
import { RequestAnUpdateModel } from '../../common/models/RequestAnUpdateModel';
import { GET_CASE_NUMBER } from './actionTypes';

export const GetCaseNumber = createAsyncThunk(GET_CASE_NUMBER, async (requestModel: RequestAnUpdateModel, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${API_PROFILE_PATH}/getCaseNumberFromSFDC`, {
            RequestType: requestModel.RequestType,
            Description: requestModel.Description,
            FirstName: requestModel.FirstName,
            LastName: requestModel.LastName,
            Email: requestModel.Email,
            PhoneNumber: requestModel.PhoneNumber,
            ClientType: requestModel.ClientType
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
