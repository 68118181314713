import React from 'react';
import { useSelector } from 'react-redux';
import { AgencyList } from '../../../../portal/common/constants/constants-portal';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ShipmentMilestones } from '../../../common/constants/constants-clearances';
import { parseDateTimeET } from '../../../common/functions/parser';
import { ShipmentDetailsSlice } from '../../../common/models/ReduxSlices';
import { PGA } from '../../../common/models/ShipmentDetails';
import { RootState } from '../../../redux/store';

interface CustomsInfoProps {
    pga: PGA;
}

const CustomsInfo: React.FC<CustomsInfoProps> = ({ pga }) => {
    const { shipmentDetails } = useSelector<RootState, ShipmentDetailsSlice>((state) => state.shipmentDetails);
    const shipmentMilestones = shipmentDetails?.Destination === 'USA' ? ShipmentMilestones.USA : ShipmentMilestones.Canada;
    const translate = useTranslation();
    const title = translate(AgencyList[pga.Header as keyof typeof AgencyList].customsInfoLabel);
    const anchorId = AgencyList[pga.Header as keyof typeof AgencyList].label;

    // Filter the PGAs (remove the Key that has the "Documents recieved" value for the label from the Events)
    const filterPGAEvents = () => {
        if (pga.Events) {
            return pga.Events.filter((item) => item.Key && !item.Key.toLowerCase().includes(shipmentMilestones[1].eventKey.toLowerCase()));
        }
    };

    const filteredPGA = filterPGAEvents();

    return (
        <>
            {filteredPGA && filteredPGA.length > 0 && (
                <div className='card mb-3' id={`${anchorId}`}>
                    <div className='card-header'>
                        <h4>{title}</h4>
                    </div>
                    <div className='show'>
                        <div className='card-body'>
                            <dl className='row mb-0'>
                                {filteredPGA.map((item, index) => {
                                    let customsInfoLabel: string | undefined;
                                    // Find the appropriate labels for the "Customs info" card
                                    for (const key in shipmentMilestones) {
                                        if (
                                            item.Key &&
                                            item.Key.toLowerCase().includes(
                                                shipmentMilestones[key as unknown as keyof typeof shipmentMilestones].eventKey.toLowerCase()
                                            )
                                        ) {
                                            customsInfoLabel = shipmentMilestones[key as unknown as keyof typeof shipmentMilestones].label;
                                        }
                                    }

                                    return (
                                        <React.Fragment key={index}>
                                            <dt className='col-md-4 col-lg-5'>
                                                {`${customsInfoLabel ? translate(customsInfoLabel) : translate(item.Key)}`}:
                                            </dt>
                                            <dd className='col-md-8 col-lg-7'>{parseDateTimeET(item.Date)}</dd>
                                        </React.Fragment>
                                    );
                                })}
                            </dl>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomsInfo;
