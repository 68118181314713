import { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

class NoticesTableRow extends Component {

    handleEditNotice=()=>{
        this.props.onEditNotice(this.props.data, this.props.index);
    }

    handleOpenNoticePreview=()=>{
        this.props.onOpenNoticePreview(this.props.data);
    }

    handleDeleteNotice=()=>{
        this.props.onOpenDeleteNoticeModal(this.props.data, this.props.index);
    }

    renderNoticeOptions(){
        const noticeOptions = (
            <div>
                <i
                    id='edit-notice'
                    className='fa fa-edit fa-lg'
                    onClick={this.handleEditNotice}
                ></i>
                <i id='preview-notice' className='fa fa-eye fa-lg' onClick={this.handleOpenNoticePreview}></i>
                <i
                    id='delete-notice'
                    className='fa fa-trash fa-lg'
                    onClick={this.handleDeleteNotice}
                ></i>
            </div>
        );
        return noticeOptions;
    }

    decideNoticeClassName(){
        //Format Dates for active notices check for green highlight
        const todayDate = new Date();
        const startDate = new Date(this.props.data.EffectiveDateFrom);
        const endDate = new Date(this.props.data.EffectiveDateTo);
        let isActive = '';

        //Check active notices to be highlighted
        if (startDate <= todayDate && endDate >= todayDate) {
            isActive = 'active-notice';
        }

        return isActive;
    }

    render() {

        return (
            <tr className={this.decideNoticeClassName()}>
                <td>
                    {this.props.data.EffectiveDatePartFrom}&nbsp;
                    {this.props.data.EffectiveTimePartFrom}&nbsp;
                    {this.props.data.EffectiveTimePartFromAmPm}
                </td>
                <td>
                    {this.props.data.EffectiveDatePartTo}&nbsp;
                    {this.props.data.EffectiveTimePartTo}&nbsp;
                    {this.props.data.EffectiveTimePartToAmPm}
                </td>
                <td className='td-max-width' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                    {this.props.data.Title}
                </td>
                <td>{this.props.data.ServiceProvider}</td>
                <td>{this.props.data.NoticeType}</td>
                <td>{this.props.data.Language}</td>
                <td className='right-align'>{this.renderNoticeOptions()}</td>
            </tr>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenDeleteNoticeModal: (notice, index) => dispatch(actionCreators.openDeleteNoticeModal(notice, index)),
        onOpenNoticePreview: (notice) => dispatch(actionCreators.openNoticePreview(notice)),
        onEditNotice: (notice, index) => dispatch(actionCreators.editNotice(notice, index))
    };
};

export default connect(null, mapDispatchToProps)(NoticesTableRow);
