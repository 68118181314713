import { Component, default as React } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import * as actionCreators from '../../store/actions/index';

class BRPReportsForm extends Component {
    componentDidMount = () => {
        this.props.onInitBRPReports();
    };

    componentDidUpdate = () => {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
        if (this.props.status === 200) {
            this.props.onInitTranslations('Reports.Reports.Index');
        }
    };

    render() {
        const bodyBRP =
            this.props.status === 403 ? (
                <Redirect to='/Error' />
            ) : (
                <div id='imaging'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <a href='/Dashboard' className='breadcrumb-parent'>
                                        {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                    </a>
                                    <i className='fa fa-caret-right'></i>
                                    <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_2']}</span>
                                </nav>
                                <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='brp-details'>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h4>
                                                    <a
                                                        href='https://qliksense.liiaws.net/ping/single/?appid=7a415689-c1aa-47c8-a184-282e5b675b19&sheet=pPAnJyw&theme=liv&opt=currsel&select=clearall'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        Top Party Report
                                                    </a>
                                                </h4>
                                                <ul>
                                                    <li>Top Shippers</li>
                                                    <li>Top Carriers</li>
                                                    <li>Top Consignees</li>
                                                </ul>
                                            </div>
                                            <div className='col-md-6'>
                                                <h4>
                                                    <a
                                                        href='https://qliksense.liiaws.net/ping/single/?appid=7a415689-c1aa-47c8-a184-282e5b675b19&sheet=QYpd&theme=liv&opt=currsel&select=clearall'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        Top Ports Report
                                                    </a>
                                                </h4>
                                                <ul>
                                                    <li>Top Port Pairs: Load to Delivery</li>
                                                    <li>Top Ports of Entry</li>
                                                    <li>Top Ports of Loading</li>
                                                    <li>Top Ports of Delivery</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='col-md-6'>
                                            <h4>
                                                <a
                                                    href='https://qliksense.liiaws.net/ping/single/?appid=7a415689-c1aa-47c8-a184-282e5b675b19&sheet=eae55138-c35b-4da4-8b60-2267fdc8e1fe&theme=liv&opt=currsel&select=clearall'
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    Equipment Usage Report
                                                </a>
                                            </h4>
                                            <ul>
                                                <li># of Containers by Equipment Type</li>
                                            </ul>
                                        </div>
                                        <div className='col-md-6'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        return <div>{bodyBRP}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        status: state.brp.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onInitBRPReports: () => dispatch(actionCreators.initBRPReports())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BRPReportsForm);
