import { faBoxOpen, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../portal/common/features/Button';
import ClientChooser from '../../portal/common/features/ClientChooser/ClientChooser';
import ColumnSelectionDropdown from '../../portal/common/features/Grid/ColumnSelectionDropdown';
import GridHeader from '../../portal/common/features/GridHeader';
import HeaderBar from '../../portal/common/features/HeaderBar/HeaderBar';
import useClientDimensions from '../../portal/common/hooks/useClientDimensions';
import useTranslation from '../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice, GridPreferencesSlice } from '../../portal/common/models/ReduxSlices';
import DoucmentsSearchBar from '../common/components/Search/DocumentsSearchBar';
import useCalculateTopPositionWithScroll from '../common/hooks/useCalculateTopPositionWithScroll';
import { gridSettingsConstants } from '../common/imaging-constants';
import { ColumnSettings } from '../common/models/GridPreferences';
import { ImagingFiltersSlice, ImagingModelSlice } from '../common/models/ReduxSlices';
import { SaveUserImagingGridSetting } from '../redux/actions/imagingGridPreferences';
import { resetDateRange } from '../redux/reducers/dateRangeSlice';
import { resetDocumentsDropdownFilters } from '../redux/reducers/imagingFiltersSlice';
import { updateColumnOrder } from '../redux/reducers/imagingGridPreferenceSlice';
import { resetImagingGridSettings } from '../redux/reducers/imagingGridSettingsSlice';
import {
    resetDateFilter,
    resetDateRangeAndTypeFilters,
    resetDocTypeFilter,
    resetMOTFilter,
    resetSelectedEntries,
    resetStatusFilter,
    updateDocumentDetailsModelTopPosition
} from '../redux/reducers/imagingSlice';
import { RootState } from '../redux/store';
import hasFilterBarFixedWidth from '../utils/hasFilterBarFixedWidth';
import { numberWithCommas } from '../utils/imaging-utils';
import DocumentsGrid from './DocumentsGrid';
import DateDocumentsFilter from './Filters/DateFilter/DateDocumentsFilter';
import DropdownFilters from './Filters/DropdownFilters';

const DocumentsPage = () => {
    const translate = useTranslation();
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const scrollableDivItems = scrollableDivRef.current && Array.from(scrollableDivRef.current['children']);
    //const lastChild = scrollableDivRef && scrollableDivRef.current && Array.from(scrollableDivRef.current?.children).reverse()[0];
    const direction = {
        RIGHT: scrollableDivItems ? scrollableDivItems.length - 1 : 0,
        LEFT: 0
    };
    const [rowsInGrid, setRowsInGrid] = useState<number>(0);
    const [scrollRight, setScrollRight] = useState(true);
    const resize = useClientDimensions();
    // Fix filter bar width once it grows to a certain length
    const [filterBarHasFixedWidth, setFilterBarHasFixedWidth] = useState<boolean>(); //, setFilterBarHasFixedWidth
    const [filtersHaveSelected, setFiltersHaveSelected] = useState(false);
    const { documentTypeSelectedIndexes, documentStatusSelectedIndexes, documentMotSelectedIndexes } = useSelector<
        RootState,
        ImagingFiltersSlice
    >((state) => state.imagingFilters);
    const { applyDateRangeUsFilter, applyDateRangeCaFilter } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((c) => c.IsSelected);

    const { model, isLoading: modelLoading } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);
    const dispatch = useDispatch();

    const isDateFilterApplied = country?.Code === 'us' ? applyDateRangeUsFilter : applyDateRangeCaFilter;
    const isMotFilterApplied = country?.Code === 'us' ? documentMotSelectedIndexes.length : false;

    const { allColumns, visibleColumns, areColumnsLoading } = useSelector<RootState, GridPreferencesSlice>(
        (state) => state.imagingGridPreferences
    );
    const gridHeaderRef = useRef<HTMLDivElement>(null);
    const containerWithScroll = document.getElementsByClassName('navbar-and-stage-wrapper d-flex flex-column p-0')[0];

    useCalculateTopPositionWithScroll({
        targetElementRef: gridHeaderRef,
        scrollElement: containerWithScroll as HTMLElement,
        returnTopPosition: (value) => dispatch(updateDocumentDetailsModelTopPosition(value))
    });

    useEffect(() => {
        setTimeout(() => {
            setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
            isDateFilterApplied || isMotFilterApplied || documentTypeSelectedIndexes.length || documentStatusSelectedIndexes.length
                ? setFiltersHaveSelected(true)
                : setFiltersHaveSelected(false);
        }, 0);
    }, [
        isDateFilterApplied,
        isMotFilterApplied,
        documentTypeSelectedIndexes.length,
        documentStatusSelectedIndexes.length,
        resize,
        filtersHaveSelected
    ]);

    const clearAllFilters = () => {
        setFilterBarHasFixedWidth(hasFilterBarFixedWidth({ scrollableDivRef, filtersHaveSelected }));
        dispatch(resetSelectedEntries());
        dispatch(resetDateRange());
        dispatch(resetDocumentsDropdownFilters());
        dispatch(resetDateRangeAndTypeFilters());
        dispatch(resetImagingGridSettings());
        dispatch(resetStatusFilter(country?.Code ?? 'us'));
        dispatch(resetMOTFilter());
        dispatch(resetDateFilter(country?.Code ?? 'us'));
        dispatch(resetDocTypeFilter());
    };

    const applyDropdownChanges = (payload: ColumnSettings[]) => {
        dispatch(updateColumnOrder(payload));
        dispatch(
            SaveUserImagingGridSetting({
                GridColumns: payload
                    .map((item, i) => {
                        return { ...item, Order: i + 1 };
                    })
                    .filter((item: ColumnSettings) => {
                        if (item.Code !== 'selected' && item.Code !== 'Actions') {
                            return item;
                        }
                        return null;
                    }),
                area: country?.Code
                    ? country?.Code === 'us'
                        ? gridSettingsConstants['us']
                        : gridSettingsConstants['ca']
                    : gridSettingsConstants['us']
            })
        );
    };

    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar>
                <div className={'d-flex align-items-center'}>
                    <span className='badge badge-circular badge-info mr-3'>
                        <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <h1 className={'m-0 mr-2'}>{translate('ShippingDocumentsTitle_Label')}</h1>
                </div>
                <ClientChooser />
            </HeaderBar>
            <DoucmentsSearchBar />

            <nav className='bg-white border-bottom' id='FilterBarWithDropdowns'>
                <div className='d-flex justify-content-start'>
                    <div
                        className={`filters-and-nav-arrows-wrapper ${filterBarHasFixedWidth ? 'fixed-width' : ''} ${
                            filtersHaveSelected ? 'clear-all-showing' : ''
                        }`}
                    >
                        <div className='scrollable-filter-area d-flex flex-nowrap align-items-center py-3 pr-3' ref={scrollableDivRef}>
                            <div className='font-weight-medium pl-3 pr-2'>{translate('Filters_Label')}</div>
                            <DateDocumentsFilter />
                            <DropdownFilters />
                        </div>
                        <Button
                            className='btn btn-tertiary btn-sm btn-scroll-left shadow'
                            onClick={() => {
                                scrollableDivItems && scrollableDivItems[direction.LEFT].scrollIntoView(false);
                                setScrollRight(true);
                            }}
                            style={{
                                display:
                                    filterBarHasFixedWidth &&
                                    scrollableDivRef.current &&
                                    scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                    !scrollRight
                                        ? 'block'
                                        : 'none'
                            }}
                            variant={'link'}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                        <Button
                            className='btn btn-tertiary btn-sm btn-scroll-right shadow'
                            onClick={() => {
                                scrollableDivItems && scrollableDivItems[direction.RIGHT].scrollIntoView(false);
                                setScrollRight(false);
                            }}
                            style={{
                                display:
                                    filterBarHasFixedWidth &&
                                    scrollableDivRef.current &&
                                    scrollableDivRef.current.offsetWidth <= scrollableDivRef.current.scrollWidth &&
                                    scrollRight
                                        ? 'block'
                                        : 'none'
                            }}
                            variant={'link'}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>

                    {filtersHaveSelected ? (
                        <div
                            className={`clear-all-filters d-flex flex-row align-items-center ${
                                filterBarHasFixedWidth
                                    ? 'fixed-width text-center border-left shadow-sm justify-content-center'
                                    : 'justify-content-start'
                            }`}
                        >
                            <Button
                                className={`no-bold-underline ${filterBarHasFixedWidth ? '' : 'text-decoration-none'}`}
                                variant={'link'}
                                onClick={() => clearAllFilters()}
                            >
                                {translate('ClearAll_Label')}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </nav>
            <GridHeader
                ref={gridHeaderRef}
                children={`${translate(
                    'ShowingDocuments_Label',
                    !modelLoading && model?.TotalLoadedResults ? numberWithCommas(model?.TotalLoadedResults) : 0,
                    !modelLoading && model?.TotalResults ? numberWithCommas(model?.TotalResults) : 0
                )} ${translate(
                    'ShowingEntries_Label',
                    !modelLoading && (model?.SearchResults ?? rowsInGrid)
                        ? numberWithCommas(model?.SearchResults ? model?.SearchResults.length : 0)
                        : 0,
                    !modelLoading && model?.TotalEntries ? numberWithCommas(model?.TotalEntries) : 0
                )}`}
                columnsDropdown={
                    !!model?.SearchResults?.length && (
                        <ColumnSelectionDropdown
                            allColumns={allColumns ?? allColumns}
                            visibleColumns={visibleColumns ?? visibleColumns}
                            areColumnsLoading={areColumnsLoading}
                            applyDropdownChanges={applyDropdownChanges}
                            descriptiveText={translate('TableColumnsButton_Label')}
                        />
                    )
                }
            />
            <DocumentsGrid setRowsInGrid={setRowsInGrid} />
        </div>
    );
};

export default React.memo(DocumentsPage);
