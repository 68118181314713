import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_PROFILE_PATH } from '../../../portal/common/constants/constants-portal';
import { UserProfileData } from '../../common/models/ResponseData';
import { GET_PROFILE_DATA } from './actionTypes';

export const GetProfileData = createAsyncThunk(GET_PROFILE_DATA, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<UserProfileData>(`${API_PROFILE_PATH}/getUserDetails`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
