import ReactDOM from 'react-dom';
import Button from '../Button';
import Spinner from '../Spinner';

interface RowWithButtonProps {
    extraRowClasses?: string;
    disabled?: boolean;
    isLoading?: boolean;
    children: React.ReactNode;
    element: string;
}

const RowWithButton: React.FC<RowWithButtonProps> = ({ extraRowClasses = '', children, element, disabled, isLoading }) => {
    const parentContainer = document && (document.querySelector(element) || document.body);

    return (
        <>
            {parentContainer &&
                ReactDOM.createPortal(
                    <div className={`row-with-button ${extraRowClasses}`}>
                        <Button disabled={disabled} variant='tertiary-blue' size='small'>
                            {isLoading && <Spinner size='small' className='mr-2' />}
                            {children}
                        </Button>
                    </div>,
                    parentContainer
                )}
        </>
    );
};

export default RowWithButton;
