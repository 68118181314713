import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useDispatch } from 'react-redux';
import { DownloadDocuments } from '../../../../clearances/redux/actions/clearanceDocuments';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { Document } from '../../../../portal/common/models/ClearanceDocuments';
import { DocumentsTypeFilterCA, DocumentsTypeFilterUS } from '../../constants/constants-documents';

interface DataItem extends Document {
    selected?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    entryNo: string;
    country: 'us' | 'ca';
    onCheckboxClick: (param: string) => void;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    style,
    className,
    entryNo,
    country,
    onCheckboxClick
}) => {
    const docTypeFiltersHashMap = country === 'us' ? [...DocumentsTypeFilterUS] : [...DocumentsTypeFilterCA];
    const translate = useTranslation();
    const dispatch = useDispatch();
    let cellContent;

    const downloadButton = (document: Document) => {
        return (
            <button
                className='btn btn-tertiary btn-sm'
                type='button'
                onClick={() => dispatch(DownloadDocuments({ fileNames: { [entryNo]: [document.path] } }))}
                disabled={dataItem.selected}
            >
                <FontAwesomeIcon icon={faDownload} />
            </button>
        );
    };

    const formatImageDate = (imageDate: string) => {
        const date = new Date(imageDate);

        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear().toString();

        return <span data-toggle={'tooltip'}>{`${month}/${day}/${year}`}</span>;
    };

    const docTypeStyling = (docType: string, USCICONSOL: string | undefined, secondaryDescription?: string) => {
        return (
            <span className={'text-nowrap text-primary'} style={{ display: 'flex' }} data-toggle={'tooltip'}>
                <span>
                    <FontAwesomeIcon icon={faFile} className={'mr-2'} />
                    {translate(docTypeFiltersHashMap.find((type) => type.id === docType.trim().toUpperCase())?.label)}
                </span>
                <span style={{ whiteSpace: 'pre-wrap' }}>
                    {secondaryDescription?.toUpperCase() === docType.toUpperCase() || !secondaryDescription
                        ? null
                        : ' - ' + secondaryDescription}
                </span>
                <span style={{ whiteSpace: 'pre-wrap' }}>{country === 'us' && USCICONSOL ? ' - ' + USCICONSOL : null}</span>
            </span>
        );
    };

    switch (field) {
        case 'DocumentType':
            cellContent = docTypeStyling(dataItem.metadataFormat!, dataItem.USCICONSOL, dataItem.secondaryDescription?.replace('-', ''));
            break;
        case 'Version':
            if (country === 'ca') {
                cellContent = !dataItem.LIVERSION ? '-' : dataItem.LIVERSION;
            }
            break;
        case 'Date':
            cellContent = formatImageDate(dataItem.imageDate!);
            break;
        case 'Download':
            cellContent = downloadButton(dataItem);
            break;
        case 'selected':
            cellContent = <Checkbox value={dataItem.selected} onChange={() => onCheckboxClick(dataItem.documentId!)} />;
            break;
        default:
            cellContent = null;
            break;
    }

    return (
        <td
            className={className}
            style={{
                ...style,
                backgroundColor: dataItem?.selected ? '#DDE9F9' : '',
                cursor: 'default'
            }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
