import { faBan, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Badge from '../../../../portal/common/features/Badge';
import FormDatePicker from '../../../../portal/common/features/FormDatePicker';
import FormInputField from '../../../../portal/common/features/FormInputField';
import Select from '../../../../portal/common/features/Select';
import { dateFormatValidator, dateIsAfterValidator, inputHasValueValidator } from '../../../../portal/common/functions/validators';
import useInputValidation from '../../../../portal/common/hooks/useInputValidation';
import useMaskedInputValidation from '../../../../portal/common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import { TeamMemberStatusLabels } from '../../../common/constants/constants-team';
import { EditTeamMemberSlice } from '../../../common/models/ReduxSlices';
import { CancelStatusTeamMember, ReactivateStatusTeamMember } from '../../../redux/actions/teamManagement';
import { setEditData, setUserDetailsIsValid } from '../../../redux/reducers/editTeamMemberSlice';
import { statusChangeCheck } from '../../../redux/reducers/statusUserSlice';
import { RootState } from '../../../redux/store';

const UserDetailsEditable = () => {
    const { data } = useSelector<RootState, EditTeamMemberSlice>((state) => state.editTeamMember);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);

    const CHANGE_TEAM_MEMBER_STATUS = data?.Users?.[0]?.IsActive ? CancelStatusTeamMember : ReactivateStatusTeamMember;
    const email = data?.Users?.[0]?.Email || '';
    const dispatch = useDispatch();
    const translate = useTranslation();
    const history = useHistory();
    const [selectedRole, setSelectedRole] = useState<string>('');

    const roles = useMemo(() => {
        return data?.Roles?.map((item) => {
            if (item?.Text === selectedRole) {
                return { ...item, IsSelected: true };
            } else {
                return { ...item, IsSelected: false };
            }
        });
    }, [data?.Roles, selectedRole]);
    const allRoles = useMemo(() => {
        if (data?.Roles) {
            return data?.Roles?.map((item) => {
                return { value: item?.Text!, label: '' };
            });
        } else {
            return [{ value: '', label: '' }];
        }
    }, [data?.Roles]);

    const {
        value: firstName,
        handleChange: firstNameChangeHandler,
        handleBlur: firstNameBlurHandler,
        isValid: firstNameIsValid,
        hasError: firstNameHasError
    } = useInputValidation({ initial: data?.Users?.[0]?.FirstName, validators: [inputHasValueValidator] });
    const {
        value: lastName,
        handleChange: lastNameChangeHandler,
        handleBlur: lastNameBlurHandler,
        isValid: lastNameIsValid,
        hasError: lastNameHasError
    } = useInputValidation({ initial: data?.Users?.[0]?.LastName, validators: [inputHasValueValidator] });
    const {
        value: company,
        handleChange: companyChangeHandler,
        handleBlur: companyBlurHandler,
        isValid: companyIsValid,
        hasError: companyHasError
    } = useInputValidation({ initial: data?.Users?.[0]?.CompanyName, validators: [inputHasValueValidator] });
    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex
    } = useMaskedInputValidation({
        initial: data?.Users?.[0]?.Phone,
        validators: [inputHasValueValidator, (value: string) => isValidPhoneNumber(value, 'US')],
        inputMask: '(###) ###-#### x######'
    });
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        initial: data?.Users?.[0]?.MobileNumber,
        validators: [(value: string) => isValidPhoneNumber(value, 'US')],
        required: false,
        inputMask: '(###) ###-####'
    });
    const {
        value: date,
        handleChange: dateChangeHandler,
        handleBlur: dateBlurHandler,
        handlePicker: datePickerHandler,
        hasError: dateHasError,
        isValid: dateIsValid
    } = useInputValidation({
        initial: data?.Users?.[0]?.ExpirationDate ? moment(data?.Users?.[0]?.ExpirationDate).format('MM-DD-YYYY') : '',
        validators: [dateFormatValidator, dateIsAfterValidator],
        required: false
    });

    useEffect(() => {
        const id = setTimeout(() => {
            dispatch(
                setEditData({
                    FirstName: firstName.trim(),
                    LastName: lastName.trim(),
                    Email: email.trim(),
                    CompanyName: company.trim(),
                    Phone: phoneNumber.trim(),
                    MobileNumber: mobileNumber.trim(),
                    UserRole: selectedRole,
                    Roles: roles,
                    ExpirationDate: date.trim()
                })
            );
        }, 500);

        return () => clearTimeout(id);
    }, [firstName, lastName, email, company, phoneNumber, mobileNumber, date, selectedRole, dispatch, roles]);

    // Select the initial role for the user
    useEffect(() => {
        const currentRole = data?.Users?.[0]?.Roles?.find((item) => item?.IsSelected);

        if (currentRole?.Text) {
            setSelectedRole(currentRole.Text);
        }
    }, [data?.Users]);

    // Update the validation of the "User details"
    useEffect(() => {
        // If all fields are valid then output "true", else output "false". Had to use "hasError" instead "isValid" because in some cases the phone number and the mobile number are coming as invalid from the database.
        if (firstNameIsValid && lastNameIsValid && companyIsValid && !phoneNumberHasError && !mobileNumberHasError && !dateHasError) {
            dispatch(setUserDetailsIsValid(true));
        } else {
            dispatch(setUserDetailsIsValid(false));
        }
    }, [dispatch, companyIsValid, firstNameIsValid, lastNameIsValid, dateHasError, mobileNumberHasError, phoneNumberHasError]);

    const changeTeamMemberStatus = async (userId: number) => {
        try {
            await dispatch(CHANGE_TEAM_MEMBER_STATUS(userId));
        } catch (e) {
            console.log(e);
        }
    };

    const userId = parseInt(history.location.search.split('=')[1]);

    return (
        <div className='container-fluid p-0'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                <div className='col order-md-1'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('FirstName_Label')}
                            value={firstName}
                            onChange={firstNameChangeHandler}
                            onBlur={firstNameBlurHandler}
                            isValid={firstNameIsValid}
                            hasError={firstNameHasError}
                        />
                        {firstNameHasError && <div className='error-message'>{translate('RequiredFirstName_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-2'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('LastName_Label')}
                            value={lastName}
                            onChange={lastNameChangeHandler}
                            onBlur={lastNameBlurHandler}
                            isValid={lastNameIsValid}
                            hasError={lastNameHasError}
                        />
                        {lastNameHasError && <div className='error-message'>{translate('RequiredLastName_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-3'>
                    <div className='form-group'>
                        <label>{translate('Status_Label')}</label>
                        <br />
                        <div className='d-flex align-items-center'>
                            {data?.Users?.[0]?.IsActive ? (
                                <>
                                    <Badge type='success'>
                                        {translate(TeamMemberStatusLabels[data?.Users?.[0]?.Status as keyof typeof TeamMemberStatusLabels])}
                                    </Badge>
                                    {data?.Users?.[0]?.IsActive &&
                                        user?.Roles !== undefined &&
                                        (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) && (
                                            <button
                                                type='button'
                                                className='btn btn-outline-danger btn-sm ml-2'
                                                onClick={() => {
                                                    changeTeamMemberStatus(userId).then(() => {
                                                        dispatch(statusChangeCheck());
                                                        return history.push('/Team');
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faBan} className='mr-1' /> {translate('DeactivateUser_Label')}
                                            </button>
                                        )}
                                </>
                            ) : (
                                <>
                                    <Badge type='danger'>
                                        {translate(TeamMemberStatusLabels[data?.Users?.[0]?.Status as keyof typeof TeamMemberStatusLabels])}
                                    </Badge>
                                    {!data?.Users?.[0]?.IsActive &&
                                        user?.Roles !== undefined &&
                                        (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) && (
                                            <button
                                                type='button'
                                                className='btn btn-outline-success btn-sm ml-2'
                                                onClick={() => {
                                                    changeTeamMemberStatus(userId).then(() => {
                                                        dispatch(statusChangeCheck());
                                                        return history.push('/Team');
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faSyncAlt} className='mr-1' /> {translate('ReactivateUser_Label')}
                                            </button>
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col order-md-4'>
                    <div className='form-group'>
                        <label>{translate('Email_Label')}</label>
                        <p>{email}</p>
                    </div>
                </div>

                <div className='col order-md-5'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('PhoneNumber_Label')}
                            value={phoneNumber}
                            onChange={phoneNumberChangeHandler}
                            onBlur={phoneNumberBlurHandler}
                            isValid={phoneNumberIsValid}
                            hasError={phoneNumberHasError}
                        />
                        {phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                            <div className='error-message'>{translate('RequiredPhoneNumber_Label')}</div>
                        )}
                        {phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                            <div className='error-message'>{translate('InvalidPhoneNumber_Label')}</div>
                        )}
                    </div>
                </div>

                <div className='col order-md-6'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('MobileNumber_Label')}
                            value={mobileNumber}
                            onChange={mobileNumberChangeHandler}
                            onBlur={mobileNumberBlurHandler}
                            isValid={mobileNumberIsValid}
                            hasError={mobileNumberHasError}
                        />
                        {mobileNumberHasError && <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>}
                    </div>
                </div>

                <div className='col order-md-7'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('Company_Label')}
                            value={company}
                            onChange={companyChangeHandler}
                            onBlur={companyBlurHandler}
                            isValid={companyIsValid}
                            hasError={companyHasError}
                        />
                        {companyHasError && <div className='error-message'>{translate('RequiredCompany_Label')}</div>}
                    </div>
                </div>

                <div className='col order-md-8'>
                    <Select label={translate('UserRole_Label')} options={allRoles} value={selectedRole} onChange={setSelectedRole} />
                </div>

                <div className='col order-md-9'>
                    <div className='form-group'>
                        <FormDatePicker
                            label={translate('ExpirationDate_Label')}
                            noBorder
                            noHeader
                            value={date}
                            onChange={dateChangeHandler}
                            onDateChange={datePickerHandler}
                            onBlur={dateBlurHandler}
                            dateFormat='MM-DD-YYYY'
                            hasError={dateHasError}
                            isValid={dateIsValid}
                        />
                        {dateHasError && <div className='error-message'>{translate('InvalidDate_Label')}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetailsEditable;
