import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

export const gridSettingsConstants = {
    us: 'Shipping_Documents_US',
    ca: 'Shipments_CASS'
};

// Empty data labels
export const other = ['CheckTypos_Label', 'ClearSearchStartOver_Label'];
export const caBullet = [
    'ImporterBn15_Label',
    'TransactionNumber_Label',
    'CargoControlNumber_Label',
    'BrokerReference_Label',
    'PurchaserName_Label',
    'VendorName_Label',
    'InvoiceNumber_Label',
    'PoNumber_Label',
    'Keyword_Label',
    'CommodityDescription_Label',
    'HsClassification_Label'
];

export const locationFilterType = [
    { label: 'LocationPortOfEntry_Label', attribute: 'PortCd', placeholder: 'LocationSearchPortOfEntry_Label' },
    { label: 'LocationPortOfUnlading_Label', attribute: 'PortOfUnlading', placeholder: 'LocationSearchPortOfUnlading_Label' },
    { label: 'LocationPortOfExit_Label', attribute: 'PortOfExitCd', placeholder: 'LocationSearchPortOfExit_Label' }
];
export const CASSActionButtonOptions = [
    { id: 'VIEW_DETAILS', label: 'ViewClearanceDetails_Label', icon: null },
    { id: 'GRID_COLUMN', label: 'GridColumnData_Label', icon: null, rightIcon: faFileDownload },
    { id: 'FULL_CAD', label: 'FullCADData_Label', icon: null, rightIcon: faFileDownload },
    { id: 'DOCUMENTS', label: 'Documents_Label', icon: null, rightIcon: faFileDownload }
] as const;

export const MOTHashMap = {
    0: 'Air',
    1: 'Truck',
    2: 'Ocean',
    3: 'Rail',
    4: 'Hand carry',
    5: 'Pipeline',
    6: 'Other'
};

export const limitSearch = {
    LiMIT_SEARCHED_TERMS: 10
};
