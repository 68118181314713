import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../common/Modal/Modal';
import { global as Constants } from '../../constants/mlp-constants';
import FeatureSelector from './FeatureSelector';

class FeatureSelectorModal extends Component {
    static propTypes = {
        onTeamMembersModalClose: PropTypes.func
    };

    state = {
        isFeaturesModalOpen: false
    };

    closeFeaturesModal = () => {
        this.setState({ isFeaturesModalOpen: false });
    };

    openFeaturesModal = () => {
        this.setState({ isFeaturesModalOpen: true });
    };

    render() {
        const withoutMlpSelector = this.props.availableFeatures.filter((el) => {
            return el.FeatureCode !== Constants.MLP_FEATURE_CODE;
        });

        const featureSelectors = withoutMlpSelector.map((feature, index) => {
            return (
                <div className='row feature-selector-modal' key={feature.Name + this.props.countryIndex + this.props.parentIndex + index}>
                    <FeatureSelector
                        feature={feature}
                        parentIndex={this.props.parentIndex}
                        countryIndex={this.props.countryIndex}
                        area={this.props.area}
                    />
                    <hr />
                </div>
            );
        });

        return (
            <span>
                <Modal isOpen={this.state.isFeaturesModalOpen} onRequestClose={this.closeFeaturesModal} title='Add Products'>
                    {featureSelectors}
                </Modal>
                <span className='btn btn-link' onClick={this.openFeaturesModal}>
                    {this.props.translations.Phrases['AddProducts_Label']}
                </span>
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(FeatureSelectorModal);
