import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import {
    GridColumnMenuCheckboxFilter,
    GridColumnMenuFilter,
    GridColumnMenuFilterUIProps,
    GridColumnMenuProps,
    GridColumnMenuSort
} from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { TeamMemberStatusLabels } from '../../../common/constants/constants-team';
import { UserTeamSlice } from '../../../common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

export const ColumnMenuSearchText: React.FC<GridColumnMenuProps> = (props) => {
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} filterUI={CustomTextFilterUI} expanded={true} />
        </div>
    );
};

export const ColumnMenuCheckboxFilter: React.FC<GridColumnMenuProps> = (props) => {
    const { team } = useSelector<RootState, UserTeamSlice>((state) => state.user);
    const translate = useTranslation();
    let data;

    switch (props.column.field) {
        case 'Status':
            data =
                team &&
                team.TeamMembers &&
                team?.TeamMembers.map((item) => {
                    return {
                        Status: item.Status ? translate(TeamMemberStatusLabels[item.Status as keyof typeof TeamMemberStatusLabels]) : null,
                        value: item.Status
                    };
                });
            break;
        default:
            break;
    }

    return (
        <div>
            <GridColumnMenuSort {...props} />
            {data && <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} searchBox={() => null} uniqueData={true} />}
        </div>
    );
};

const CustomTextFilterUI: React.FC<GridColumnMenuFilterUIProps> = (props) => {
    const [inputValue, setInputValue] = useState(props.firstFilterProps.value || '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { firstFilterProps } = props;
        const value = event.target.value;

        firstFilterProps.onChange({
            value,
            operator: 'contains',
            syntheticEvent: event
        });

        setInputValue(value);
    };

    return (
        <div>
            <input className='form-control' id='columnfilter' name='columnSearch' type='text' value={inputValue} onChange={handleChange} />
        </div>
    );
};

export const ColumnMenuDateFilter: React.FC<GridColumnMenuProps> = (props) => {
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} filterUI={CustomDateFilterUI} expanded={true} />
        </div>
    );
};

export const CustomDateFilterUI: React.FC<GridColumnMenuFilterUIProps> = (props) => {
    const { firstFilterProps, secondFilterProps } = props;
    const startDate =
        firstFilterProps.operator === 'gte' || firstFilterProps.operator === 'contains' ? firstFilterProps.value : secondFilterProps.value;
    const endDate = firstFilterProps.operator === 'lte' ? firstFilterProps.value : secondFilterProps.value;

    const handleStartDateChange = (event: DatePickerChangeEvent) => {
        const { firstFilterProps, secondFilterProps } = props;
        const value = event.value?.toISOString();

        if (firstFilterProps.operator === 'lte') {
            secondFilterProps.onChange({
                value,
                operator: 'gte',
                syntheticEvent: event.syntheticEvent
            });
        } else {
            firstFilterProps.onChange({
                value,
                operator: 'gte',
                syntheticEvent: event.syntheticEvent
            });
        }
    };

    const handleEndDateChange = (event: DatePickerChangeEvent) => {
        const { firstFilterProps, secondFilterProps } = props;
        const selectedDate = event.value;
        let convertedDate;
        if (selectedDate) {
            convertedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59, 59);
        }
        const value = convertedDate?.toISOString();

        if (firstFilterProps.operator === 'lte') {
            firstFilterProps.onChange({
                value,
                operator: 'lte',
                syntheticEvent: event.syntheticEvent
            });
        } else {
            secondFilterProps.onChange({
                value,
                operator: 'lte',
                syntheticEvent: event.syntheticEvent
            });
        }
    };

    return (
        <div>
            <div className='k-filtercell' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='m-1 p-1'>
                    <label>Start date</label>
                    <DatePicker
                        name='startDate'
                        onChange={handleStartDateChange}
                        value={startDate ? new Date(startDate) : undefined}
                        max={endDate ? new Date(endDate) : undefined}
                    />
                </div>
                <div className='m-1 p-1'>
                    <label>End date</label>
                    <DatePicker
                        name='endDate'
                        onChange={handleEndDateChange}
                        value={endDate ? new Date(endDate) : undefined}
                        min={startDate ? new Date(startDate) : undefined}
                    />
                </div>
            </div>
        </div>
    );
};
