import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderNavItems } from '../../portal/common/constants/constants-portal';
import ClientChooser from '../../portal/common/features/ClientChooser/ClientChooser';
import ErrorPage from '../../portal/common/features/ErrorPage';
import FilterBar from '../../portal/common/features/FilterBar/FilterBar';
import HeaderBar from '../../portal/common/features/HeaderBar/HeaderBar';
import ConfirmationModal from '../../portal/common/features/Modal/ConfirmationModal';
import Spinner from '../../portal/common/features/Spinner';
import { useNonInitialEffect } from '../../portal/common/hooks/useNonInitialEffect';
import useTranslation from '../../portal/common/hooks/useTranslation';
import { ContactsModals } from '../../portal/common/models/ContactsModals';
import { ClientSelectionSlice, HydrationSlice, SearchFilterSlice } from '../../portal/common/models/ReduxSlices';
import { Contact } from '../common/models/Contact';
import { ContactsSlice } from '../common/models/ReduxSlices';
import { GetContactsData, GetListOfAdmins, UpdateContact } from '../redux/actions/contactsManagement';
import { resetEditContacts } from '../redux/reducers/contactsSlice';
import { RootState } from '../redux/store';
import AddContactsModal from './components/AddContact/AddContactsModal';
import ContactsGridCaption from './components/ContactsGridCaption';
import ContactsSearchBox from './components/ContactsSearchBox';
import ContactStatusAlert from './components/ContactStatusAlert';
import ContactsTable from './ContactsTable';
import EmptyStateContacts from './EmptyStateContacts';

const initialModals: ContactsModals = {
    add: false,
    cancelAdd: false,
    remove: false,
    edit: false,
    confirm: false
};

const ContactsGrid = () => {
    const { contacts, addNewContacts, removeContacts, editContact, editedContact, isLoading, error } = useSelector<
        RootState,
        ContactsSlice
    >((state) => state.contacts);
    const { searchTerm } = useSelector<RootState, SearchFilterSlice>((state) => state.contactsSearchFilter);
    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
    const [modal, setModal] = useState<ContactsModals>(initialModals);
    const translate = useTranslation();
    const initialLoad = useRef(true);
    const dispatch = useDispatch();

    // Set the "activeMenuItem" inside the "localStorage" on initial load
    useEffect(() => {
        localStorage.setItem('activeMenuItem', HeaderNavItems[2].id);
    }, []);

    // On page load get the contacts data and translations
    useEffect(() => {
        dispatch(GetContactsData());
    }, [dispatch]);

    // Filter contacts
    useEffect(() => {
        if (contacts) {
            // Filter contacts by search term
            let filteredData = searchContacts(contacts, searchTerm);
            setFilteredContacts(filteredData);
        }
    }, [contacts, searchTerm]);

    // Refresh the grid after each "addNewContacts", "removeContacts", "editContact"
    useEffect(() => {
        if (
            !addNewContacts.isLoading &&
            !addNewContacts.error &&
            !removeContacts.isLoading &&
            !removeContacts.error &&
            !editedContact.isLoading &&
            !editedContact.error &&
            !initialLoad.current &&
            (user?.InternalUser ? initialClientSelection : true)
        ) {
            dispatch(GetContactsData());
        }
        initialLoad.current = false;
    }, [
        addNewContacts.error,
        addNewContacts.isLoading,
        removeContacts.error,
        removeContacts.isLoading,
        editedContact.isLoading,
        editedContact.error,
        initialClientSelection,
        dispatch
    ]);

    const toggleModal = useCallback(
        (prop: keyof ContactsModals | Array<keyof ContactsModals>, next?: boolean) => {
            if (typeof prop === 'string') {
                setModal({ ...modal, [prop]: next !== undefined ? next : !modal[prop] });
            } else {
                setModal(Object.assign({}, modal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [modal]
    );

    useEffect(() => {
        dispatch(resetEditContacts());
    }, [dispatch]);

    const handleEditSubmit = () => {
        dispatch(
            UpdateContact({
                contactToBeUpdated: editContact
            })
        );
    };

    // Filter contacts by Search term (name, email and phone)
    const searchContacts = (contacts: Contact[], searchTerm: string) => {
        if (!searchTerm.length) {
            return contacts;
        }

        searchTerm = searchTerm.toLowerCase();
        return contacts.filter(
            (item) =>
                item.FullName.toLowerCase().includes(searchTerm) ||
                item.Email.toLowerCase().includes(searchTerm) ||
                item.MobileNumber?.toLowerCase().includes(searchTerm)
        );
    };

    useNonInitialEffect(() => {
        // Fetch list of admins for LII users
        if (user?.InternalUser) {
            dispatch(GetListOfAdmins());
        }
    }, [initialClientSelection]);

    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar>
                <div className='d-flex align-items-center'>
                    <span className='badge badge-circular badge-info mr-3 badge-icon-secondary'>
                        <FontAwesomeIcon icon={faAddressCard} />
                    </span>
                    <h1>{translate('Contacts_Label')}</h1>
                </div>

                {user?.InternalUser && <ClientChooser />}
            </HeaderBar>

            {/* Filter bar */}
            <FilterBar>
                <ContactsSearchBox />
            </FilterBar>

            {/* Status: pending (showing only on initial load) */}
            {(isLoading || addNewContacts.isLoading || removeContacts.isLoading || editedContact.isLoading) && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {contacts &&
                contacts.length > 0 &&
                !error &&
                !isLoading &&
                !addNewContacts.isLoading &&
                !editedContact.isLoading &&
                !removeContacts.isLoading && (
                    <>
                        <ContactsGridCaption />
                        <ContactsTable filteredContacts={filteredContacts} toggleModal={toggleModal} modal={modal} />
                    </>
                )}
            {/* No contacts */}
            {!error && !isLoading && !addNewContacts.isLoading && contacts && contacts?.length === 0 && (
                <EmptyStateContacts
                    disabled={!user?.Roles!.includes('TeamManagement_Create') && !user?.Roles!.includes('ExternalContact_Create')}
                    descriptionLabel={translate('NoContacts_Label')}
                    addButtonLabel={translate('AddContact_Label')}
                    onAdd={() => toggleModal('add', true)}
                />
            )}
            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}

            {modal.add && <AddContactsModal toggleModal={toggleModal} modal={modal} />}
            {modal.cancelAdd && (
                <ConfirmationModal
                    title={`${modal.edit ? translate('CancelUpdateContact_Label') : translate('CancelAddingContact_Label')}   `}
                    description={translate('LostProgress_Label')}
                    primaryButtonLabel={translate('YesCancel_Label')}
                    secondaryButtonLabel={translate('NotificationsGoBack_Label')}
                    onPrimary={() => toggleModal(['add', 'cancelAdd', 'edit'], false)}
                    onSecondary={() => {
                        toggleModal('cancelAdd', false);
                        const modalElement = document.querySelector('#AddContactsModal') as HTMLDivElement;
                        modalElement.focus();
                    }}
                />
            )}
            {modal.confirm && (
                <ConfirmationModal
                    title={translate('EditThisContact_Label')}
                    description={translate('EditContactMessage_Label')}
                    primaryButtonLabel={translate('Cancel_Label')}
                    secondaryButtonLabel={translate('ConfirmEditContact_Label')}
                    onPrimary={() => toggleModal('confirm', false)}
                    onSecondary={() => {
                        toggleModal(['edit', 'confirm'], false);
                        handleEditSubmit();
                    }}
                />
            )}

            {/* Status alert for contacts */}
            <ContactStatusAlert />
        </div>
    );
};

export default ContactsGrid;
