import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import {
    UpdateClearanceSubscriptionRequest,
    UpdateClearanceSubscriptionResponse,
    UpdateClearanceSubscriptionsRequest
} from '../../common/models/EditSubscriber';
import { SubscribeData } from '../../common/models/ResponseData';
import { UNSUBSCRIBE_MULTIPLE, UPDATE_GLOBAL_SUBSCRIPTION, UPDATE_GLOBAL_SUBSCRIPTIONS } from './actionTypes';

export const UpdateGlobalSubscription = createAsyncThunk(
    UPDATE_GLOBAL_SUBSCRIPTION,
    async (payload: { requestModel: UpdateClearanceSubscriptionRequest }, { rejectWithValue }) => {
        try {
            const response = await axios.post<UpdateClearanceSubscriptionResponse>(
                'api/clearance/clearance/update-global-subscription',
                payload.requestModel
            );

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UpdateGlobalSubscriptions = createAsyncThunk(
    UPDATE_GLOBAL_SUBSCRIPTIONS,
    async (payload: { requestModel: UpdateClearanceSubscriptionsRequest }, { rejectWithValue }) => {
        try {
            const response = await axios.post<UpdateClearanceSubscriptionResponse>(
                'api/clearance/clearance/update-global-subscriptions',
                payload.requestModel
            );

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const MultipleUnsubscribe = createAsyncThunk(
    UNSUBSCRIBE_MULTIPLE,
    async (payload: { subscriptionIds: number[] }, { rejectWithValue }) => {
        try {
            const response = await axios.put<SubscribeData>('api/clearance/clearance/unsubscribe-subscriptions', {
                subscriptionIds: payload.subscriptionIds
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
