import React, { Component } from 'react';
import { formatTranslated } from '../../../utils/mlp-utils';

class DownloadFailure extends Component {
    render() {
        return (
            <div>
                <div>
                    <h3>{formatTranslated('Download_Failed_Label', this.props.translations.Phrases)}</h3>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        {formatTranslated('Requested_Images_Not_Available_Label', this.props.translations.Phrases)}
                        <br />
                        <br />
                        {this.props.errorMsg}
                    </div>
                </div>
            </div>
        );
    }
}

export default DownloadFailure;
