import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterDropdown from '../../../portal/common/features/FilterDropdown';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice } from '../../../portal/common/models/ReduxSlices';
import {
    DocumentsStatusFilterUniversal,
    DocumentsTypeFilterCA,
    DocumentsTypeFilterUS,
    MOTFilter,
    MOTMap
} from '../../common/constants/constants-documents';
import { ImagingFiltersSlice, ImagingModelSlice } from '../../common/models/ReduxSlices';
import {
    setDocumentMotSelectedIndexes,
    setDocumentMotSelectedValues,
    setDocumentStatusIndexes,
    setDocumentStatusObject,
    setDocumentStatusSelectedValues,
    setDocumentTypeObject,
    setDocumentTypeSelectedValues,
    setDocumentTypesSelected
} from '../../redux/reducers/imagingFiltersSlice';
import {
    resetSelectedEntries,
    setSearchBarOptions,
    updateMotFilters,
    updateSearchFilters,
    updateStatusFilter,
    updateSubmittedSearchKeywords,
    updateTypesFilter
} from '../../redux/reducers/imagingSlice';
import { RootState } from '../../redux/store';

const DropdownFilters = () => {
    const dispatch = useDispatch();
    const translate = useTranslation();
    const { documentTypeSelectedIndexes, documentStatusSelectedIndexes, documentMotSelectedIndexes } = useSelector<
        RootState,
        ImagingFiltersSlice
    >((state) => state.imagingFilters);

    const { initialClientSelection } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);
    const { searchKeywords } = useSelector<RootState, ImagingModelSlice>((state) => state.imaging);

    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const docTypeFiltersHashMap = country?.Code === 'us' ? [...DocumentsTypeFilterUS] : [...DocumentsTypeFilterCA];

    useEffect(() => {
        dispatch(setDocumentTypesSelected([]));
        dispatch(setDocumentTypeObject({}));
        dispatch(setDocumentTypeSelectedValues([]));
    }, [initialClientSelection, dispatch]);

    const universalTranslated = DocumentsStatusFilterUniversal.map((x: any) => {
        return { ...x, label: translate(x.label) };
    }).sort((a, b) => a.label.localeCompare(b.label));

    const handleApply = (id: string, selectedArray: number[]) => {
        const payload = {
            value: searchKeywords,
            country: country?.Code || 'us'
        };
        dispatch(resetSelectedEntries());
        dispatch(updateSearchFilters(payload));
        dispatch(setSearchBarOptions());
        dispatch(updateSubmittedSearchKeywords(searchKeywords));

        switch (id) {
            case 'Document Types':
                dispatch(setDocumentTypesSelected(selectedArray));
                const arrayOfDocumentTypesNames: string[] = selectedArray.map((i: number) =>
                    docTypeFiltersHashMap[i].label.replaceAll('_Label', '')
                );
                const selectedDocumentTypeFiltersObject: { [key: string]: number } = {};
                for (let i = 0; i < arrayOfDocumentTypesNames.length; i++) {
                    selectedDocumentTypeFiltersObject[arrayOfDocumentTypesNames[i]] = i;
                }
                dispatch(setDocumentTypeObject(selectedDocumentTypeFiltersObject));
                dispatch(setDocumentTypeSelectedValues(selectedArray.map((i: number) => docTypeFiltersHashMap[i].id)));
                dispatch(
                    updateTypesFilter({
                        optionsArray: selectedArray.map((i: number) => docTypeFiltersHashMap[i].id),
                        country
                    })
                );
                break;
            case 'Document Status':
                dispatch(setDocumentStatusIndexes(selectedArray));
                const arrayOfDocumentStatusNames: string[] = selectedArray.map((i: number) => universalTranslated[i].id);
                const selectedStatusFiltersObject: { [key: string]: number } = {};
                for (let i = 0; i < arrayOfDocumentStatusNames.length; i++) {
                    selectedStatusFiltersObject[arrayOfDocumentStatusNames[i]] = i;
                }

                dispatch(setDocumentStatusObject(selectedStatusFiltersObject));
                dispatch(setDocumentStatusSelectedValues(selectedArray.map((i: number) => universalTranslated[i].id)));
                dispatch(updateStatusFilter({ optionsArray: selectedArray.map((i: number) => universalTranslated[i].value), country }));
                break;
            case 'MOT':
                dispatch(setDocumentMotSelectedIndexes(selectedArray));
                const selectedMOT = selectedArray
                    .map((i) => {
                        return Object.values(MOTMap)[i].allMots;
                    })
                    .flat();

                dispatch(setDocumentMotSelectedValues(selectedArray.map((i: number) => MOTFilter[i].id)));
                dispatch(updateMotFilters(selectedMOT));
                break;
        }
    };
    return (
        <>
            <FilterDropdown
                selectAllEnabled={false}
                id={'Document Types'}
                name={translate('DocumentType_Label')}
                options={docTypeFiltersHashMap}
                optionsLabel={translate('FilterByDocumentsTypes_Label')}
                selection={documentTypeSelectedIndexes}
                handleSelection={handleApply}
            />
            <FilterDropdown
                selectAllEnabled={false}
                id={'Document Status'}
                name={translate('DocumentStatus_Label')}
                options={universalTranslated}
                optionsLabel={translate('FilterByDocumentsStatus_Label')}
                selection={documentStatusSelectedIndexes}
                handleSelection={handleApply}
                conditionallyTranslate={true}
            />
            {country?.Code === 'us' && (
                <FilterDropdown
                    selectAllEnabled={false}
                    id={'MOT'}
                    name={translate('ModeOfTransport_Label')}
                    options={MOTFilter}
                    optionsLabel={translate('FilterByTransport_Label')}
                    selection={documentMotSelectedIndexes}
                    handleSelection={handleApply}
                />
            )}
        </>
    );
};

export default DropdownFilters;
