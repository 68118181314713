import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_SHIPMENT_DATA_SOURCE, SET_SHIPMENT_DATA_SOURCE } from '../../../completeEntryData/redux/actions/actionTypes';
import axios from '../../axios-portal';
import { API_CLEARANCES_PATH } from '../../common/constants/constants-portal';

export const GetShipmentDataSource = createAsyncThunk(GET_SHIPMENT_DATA_SOURCE, async (payload: string, { rejectWithValue }) => {
    try {
        const response = await axios.get<string>(`${API_CLEARANCES_PATH}/clearance/get-shipment-data-source/${payload}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const SaveShipmentDataSource = createAsyncThunk(
    SET_SHIPMENT_DATA_SOURCE,
    async (
        payload: {
            PreferenceValue: string;
            Country: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<string>(`${API_CLEARANCES_PATH}/clearance/save-shipment-data-source`, payload);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
