import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import SubmitButton from '../../common/SubmitButton/SubmitButton';
import TextArea from '../../common/Textarea/Textarea';
import {
    accessManagement as constants,
    ClientServicesByCountry as clientServices,
    userStatus as statusConstants
} from '../../constants/mlp-constants';
import * as actionCreators from '../../store/actions/index';
import { formatTranslated, setValueByPath } from '../../utils/mlp-utils';
import ClientServicesByCountry from '../ClientAdmin/ClientServicesByCountry';
import TeamMembersModal from '../TeamManagement/TeamManagementModal';
import EditClientAdminHeader from './EditClientAdminHeader';

class EditClientAdminForm extends Component {
    state = {
        modelCopy: {},
        isRoleDropdownChanged: false,
        selectedClientsStatuses: []
    };

    componentDidMount = () => {
        this.props.onInitTranslations('AccessManagement.AccessManagement.Index');
        if (this.props.location.state) {
            this.props.onGetUserDetail(this.props.location.state.userId);
        } else if (this.props.location.search) {
            this.props.onGetUserDetail(this.props.location.search.split('=')[1]);
        }
    };

    componentDidUpdate = (prevProps) => {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }

        if (
            this.props.viewModel &&
            this.props.viewModel.User &&
            this.props.viewModel !== prevProps.viewModel &&
            JSON.stringify(this.state.modelCopy) === '{}'
        ) {
            this.setState({ modelCopy: this.props.viewModel });

            if (this.props.viewModel.ClientServicesByCountry.length) {
                const usNumbers = this.props.viewModel.ClientServicesByCountry[0].ParentServices.flatMap((item) =>
                    item.ClientServices.map((x) => x.Number)
                );

                const caNumbers = this.props.viewModel.ClientServicesByCountry[1].ParentServices.flatMap((item) =>
                    item.ClientServices.map((x) => x.Number)
                );

                const combinedNumbers = usNumbers.concat(caNumbers);

                this.props.onGetClientStatus(combinedNumbers);
            }
        }
    };

    updateRoleDropdown = (value) => {
        this.setState({ isRoleDropdownChanged: value });

        let tempModel = JSON.parse(JSON.stringify(this.state.modelCopy));

        if (tempModel && tempModel.User && tempModel.User.Roles) {
            tempModel.User = {
                ...this.props.viewModel.User
            };

            const oldExpirationDate = this.state.modelCopy.User.ExpirationDate;
            tempModel.User.ExpirationDate = oldExpirationDate;

            // Deep clone Roles to avoid read-only property issues
            const roles = tempModel.User.Roles.map((role) => ({ ...role }));

            const selectedRole = roles.find((x) => x.IsSelected === true);
            if (selectedRole) {
                selectedRole.IsSelected = false;
            }

            const roleName = value ? 'ClientAdmin' : 'PaymentAdmin';
            const newSelectedRole = roles.find((x) => x.Value === roleName);
            if (newSelectedRole) {
                newSelectedRole.IsSelected = true;
            }

            tempModel.User.Roles = roles;

            this.setState({ modelCopy: tempModel });
        }
    };

    setSelectedClientStatus = (value) => {
        if (!this.state.selectedClientsStatuses.includes(value)) {
            this.setState((prevState) => ({
                selectedClientsStatuses: [...prevState.selectedClientsStatuses, value]
            }));
        }
    };

    allClientServicesHaveAdmins = () => {
        return this.props.viewModel.ClientServicesByCountry.every((csc) => {
            return csc.ParentServices.every((ps) => {
                return ps.ClientServices.every((cs) => {
                    return cs.ClientAdminId;
                });
            });
        });
    };

    isGridReadonly = (user) => {
        return user.Status !== statusConstants.ACTIVE || user.IsLocked;
    };

    clientServicesExist = () => {
        return this.props.viewModel.ClientServicesByCountry.some((x) => {
            return x.ParentServices.length > 0;
        });
    };

    componentWillUnmount = () => {
        this.props.onResetStateAccessManagement();
    };

    validateForm = () => {
        axios
            .post(constants.CLIENT_ADMIN_VALIDATE_URL, this.props.viewModel)
            .then((response) => {
                let validationMessages = {};
                const json = JSON.parse(response.data);
                for (let i in json) {
                    setValueByPath(validationMessages, i, json[i]);
                }
                this.props.onSetValidationMessages(validationMessages);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        this.props.history.push('/BasicError');
                    }
                }
            });
    };

    render() {
        let submitSection = null;
        const isGridReadonly =
            this.isGridReadonly(this.props.viewModel.User) || this.props.viewModel.UserHasPendingChanges || this.props.viewModel.IsReadOnly;
        const allClientServicesHaveAdmins = this.allClientServicesHaveAdmins();
        const clientServicesExist = this.clientServicesExist();
        const clientStatusesLoaded = !!this.props.viewModel.User.ClientStatuses;
        let parentsByCountry = null;

        if (clientServicesExist && clientStatusesLoaded) {
            parentsByCountry = this.props.viewModel.ClientServicesByCountry.map((cs, index) => {
                return (
                    <div key={index + cs.CountryCode}>
                        <hr />
                        <ClientServicesByCountry
                            pendingUser={this.props.viewModel.User}
                            country={cs}
                            countryIndex={index}
                            readonly={isGridReadonly}
                            area={clientServices.ACCESS_MANAGEMENT}
                            isRoleDropdownChanged={this.state.isRoleDropdownChanged}
                            selectedClients={this.state.selectedClientsStatuses}
                        />
                    </div>
                );
            });
        } else {
            parentsByCountry = (
                <div>
                    <br />
                    <br />
                    <h3 className='text-center'>{this.props.translations.Phrases['NoClientServicesFound_Label']}</h3>
                    <br />
                    <br />
                </div>
            );
        }

        if (!clientServicesExist && !this.props.viewModel.User.InternalUser) {
            submitSection = (
                <div className='col-sm-12 text-right'>
                    <Link to={constants.SEARCH_USERS_URL} className='btn-link pull-right button small'>
                        {this.props.translations.Phrases['Cancel_Button']}
                    </Link>
                </div>
            );
        } else if (allClientServicesHaveAdmins && !this.props.viewModel.User.InternalUser) {
            submitSection = (
                <div className='col-sm-12 text-right'>
                    <span className='validation-text'>{this.props.translations.Phrases['AllClientsHaveAdmins_Label']}</span>
                    <Link to={constants.SEARCH_USERS_URL} className='btn-link pull-right button small'>
                        {this.props.translations.Phrases['Cancel_Button']}
                    </Link>
                </div>
            );
        } else {
            submitSection = (
                <div className='col-sm-12 text-right'>
                    <span className='validation-text'>
                        {/* {this.props.translations.Phrases[this.props.validationMessages.AllClientServices]} */}
                        {this.props.viewModel.User.Roles.find((role) => role.IsSelected).Value === 'CentralAdmin' &&
                        this.props.validationMessages.ShipmentStatusMsg
                            ? this.props.translations.Phrases[this.props.validationMessages.ShipmentStatusMsg]
                            : this.props.translations.Phrases[this.props.validationMessages.AllClientServices]}
                    </span>
                    <span className='validation-text'>
                        {this.props.translations.Phrases[this.props.validationMessages.WorkflowProcess]}
                    </span>
                    <span className='validation-text'>
                        {formatTranslated(this.props.validationMessages.ExistingClientServices, this.props.translations.Phrases)}
                    </span>
                    <span className='validation-text'>
                        {this.props.validationMessages.User
                            ? this.props.translations.Phrases[this.props.validationMessages.User.SalesforceId]
                            : ''}
                    </span>
                    {isGridReadonly ? null : (
                        <SubmitButton
                            model={this.props.viewModel}
                            label={this.props.translations.Phrases['Submit_Button']}
                            disabled={this.state.isRoleDropdownChanged}
                            postActionUrl='AccessManagement/ClientAdminEdit'
                            postAction={this.props.onSetValidationMessages}
                            onSuccessfulSubmit={{
                                backToPath: '/Dashboard',
                                backToLabel: 'BackToDashboard_Label',
                                phraseGroup: 'AccessManagement.AccessManagement.EditSuccessful'
                            }}
                        />
                    )}
                    <Link to={constants.SEARCH_USERS_URL} className='btn-link pull-right button small'>
                        {this.props.translations.Phrases['Cancel_Button']}
                    </Link>
                </div>
            );
        }

        const userHasPendingChangesMessage = (
            <div className='warning message'>{this.props.translations.Phrases['UserHasPendingChanges_Label']}</div>
        );

        const noteField =
            (allClientServicesHaveAdmins && !this.props.viewModel.User.InternalUser) || !clientServicesExist ? (
                ''
            ) : (
                <div className='row'>
                    <div className='col-sm-12'>
                        <TextArea
                            label={this.props.translations.Phrases['Note_Label']}
                            readonly={isGridReadonly}
                            name='Note'
                            value={this.props.viewModel.Note}
                            onValueChanged={this.props.onHandleUpdate}
                        />
                    </div>
                </div>
            );

        return (
            <div id='page' className='wide-page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <Link to='/AccessManagement/AccessManagement/SearchUsers' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div id='edit-client-admin-form' className='w-100'>
                                {this.props.viewModel.UserHasPendingChanges ? userHasPendingChangesMessage : ''}

                                <TeamMembersModal
                                    teamMembers={this.props.viewModel.teamMembers}
                                    isOpen={this.props.viewModel.teamMembersModalVisible}
                                />
                                <EditClientAdminHeader
                                    isEditMode={!this.props.viewModel.IsReadOnly}
                                    viewModel={this.props.viewModel}
                                    validateForm={this.validateForm}
                                    handleUpdate={this.props.onHandleUpdate}
                                    cancelUser={this.props.onCancelUser}
                                    reactivateUser={this.props.onReactivateUser}
                                    validationMessages={this.props.validationMessages}
                                    isRoleDropdownChanged={this.state.isRoleDropdownChanged}
                                    updateRoleDropdown={this.updateRoleDropdown}
                                    modelCopy={this.state.modelCopy}
                                    onSetValidationMessages={this.props.onSetValidationMessages}
                                    setSelectedClientStatus={this.setSelectedClientStatus}
                                />

                                <div>{parentsByCountry}</div>
                                <br />
                                {noteField}
                                <div className='row'>{submitSection}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        viewModel: state.accessManagement.model,
        validationMessages: state.accessManagement.validationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onGetUserDetail: (userId) => dispatch(actionCreators.getUserDetail(userId)),
        onGetClientStatus: (numbers) => dispatch(actionCreators.getClientStatus(numbers)),
        onSetValidationMessages: (validationMessages) => dispatch(actionCreators.setValidationMessagesClientAdmin(validationMessages)),
        onHandleUpdate: (value, key) => dispatch(actionCreators.handleUpdate(value, key)),
        onCancelUser: (userId) => dispatch(actionCreators.cancelUserClientAdmin(userId)),
        onReactivateUser: (userId) => dispatch(actionCreators.reactivateUserClientAdmin(userId)),
        onResetStateAccessManagement: (userId) => dispatch(actionCreators.resetStateAccessManagement())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditClientAdminForm));
