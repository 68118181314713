import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import { formatTranslated, pluck, sortBy } from '../../../utils/mlp-utils';
import SearchGridRow from './SearchGridRow';

class SearchGrid extends Component {
    componentDidMount() {
        //TODO: Check using of stick_in_parent function (from sticky-kit) in React project
        // $('.fixed-header-container').stick_in_parent();
    }

    selectAll = (e) => {
        this.props.onSelectDocs(e.target.checked, -1, -1);
    };

    anySelected = () => {
        return this.props.entries.some((entry) => entry.isSelected === true);
    };

    render() {
        const allSelected = this.props.entries.every((entry) => entry.isSelected === true);
        const headers = pluck(
            sortBy(
                this.props.entries[0]['metadata'].filter((attribute) => attribute.isVisibleOnGrid === true),
                'gridOrder'
            ),
            'label'
        );
        const width = 85 / headers.length;
        const rows = this.props.entries.map((entry, i) => {
            return <SearchGridRow key={entry.groupId + this.props.selectedSearchTypeCode} groupIndex={i} entry={entry} />;
        });

        const gridActionButtons = this.anySelected() ? (
            <div className='col-lg-9 col-md-12 col-sm-12 action-link-section'>
                <button
                    className='button action-button white'
                    onClick={() =>
                        this.props.onViewDetails({ index: -1, pageRoute: '/DocumentView' }, this.props.entries, this.props.history)
                    }
                >
                    <span>{formatTranslated('View_Label', this.props.translations.Phrases)}</span>
                </button>
                <button
                    className='button action-button white'
                    onClick={() =>
                        this.props.onViewDetails({ index: -1, pageRoute: '/DocumentCompareView' }, this.props.entries, this.props.history)
                    }
                >
                    <span>{formatTranslated('Compare_Label', this.props.translations.Phrases)}</span>
                </button>
                <button
                    className='button action-button blue'
                    onClick={() =>
                        this.props.onViewDetails({ index: -1, pageRoute: '/DownloadAndPrint' }, this.props.entries, this.props.history)
                    }
                >
                    <span>
                        <i className='fa fa-print'></i>
                        {formatTranslated('PrintSelected_Label', this.props.translations.Phrases)}
                    </span>
                </button>
                <button
                    className='button action-button blue'
                    onClick={() =>
                        this.props.onViewDetails({ index: -1, pageRoute: '/DownloadAndPrint' }, this.props.entries, this.props.history)
                    }
                >
                    <span>
                        <i className='fa fa-arrow-circle-o-down'></i>
                        {formatTranslated('DownloadSelected_Label', this.props.translations.Phrases)}
                    </span>
                </button>
            </div>
        ) : (
            ''
        );

        let selectedMessage = null;
        const noOfselectedDocs = pluck(this.props.entries, 'documents')
            .flat()
            .filter((x) => x.isSelected === true).length;
        let selectedLimitReached = false;

        if (noOfselectedDocs && noOfselectedDocs < this.props.maxSelectedDocuments) {
            selectedMessage = (
                <h3>{formatTranslated('NumberOfSelectedDocuments_Label', this.props.translations.Phrases, noOfselectedDocs)}</h3>
            );
        } else if (noOfselectedDocs >= this.props.maxSelectedDocuments) {
            selectedLimitReached = true;
            selectedMessage = (
                <h3 className='text-danger'>
                    {formatTranslated('SelectedDocumentsLimit_Label', this.props.translations.Phrases, this.props.maxSelectedDocuments)}
                </h3>
            );
        }

        const outerHeader = this.anySelected() ? (
            <div className='row mlp-table-outer-header'>
                <div className='col-lg-3 col-md-12 col-sm-12 selected-message-section'>{selectedMessage}</div>
                {gridActionButtons}
            </div>
        ) : (
            ''
        );

        const headerRows = headers.map((item, i) => {
            return (
                <div className='mlp-table-header' key={i} style={{ width: width + '%' }}>
                    <span>{item}</span>
                </div>
            );
        });

        return (
            <div className='row'>
                <div className='fixed-header-container col-sm-12'>
                    {outerHeader}
                    <div className='row mlp-table-header-container'>
                        <div className='mlp-table-header'>
                            <span>
                                <input
                                    type='checkbox'
                                    disabled={selectedLimitReached && !allSelected}
                                    onChange={(e) => this.selectAll(e)}
                                    checked={allSelected}
                                />
                            </span>
                        </div>
                        {headerRows}
                        <div className='mlp-table-header'>
                            <span></span>
                        </div>
                        <div className='mlp-table-header'>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className='mlp-table-body-container col-sm-12'>{rows}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectDocs: (checked, groupIndex, docIndex) => dispatch(actions.selectImageDocs(checked, groupIndex, docIndex)),
        onViewDetails: (data, entries, history) => dispatch(actions.viewDetails(data, entries, history))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchGrid));
