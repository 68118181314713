import { getter, process } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridCellProps, GridDataStateChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateColumnOrder } from '../../../../clearances/redux/reducers/clearancesGridPreferencesSlice';
import { MILESTONES_CA, MILESTONES_US, TabNavigationIds } from '../../../../portal/common/constants/constants-portal';
import BasicSearchBox from '../../../../portal/common/features/BasicSearchBox';
import Button from '../../../../portal/common/features/Button';
import ClientChooser from '../../../../portal/common/features/ClientChooser/ClientChooser';
import ErrorPage from '../../../../portal/common/features/ErrorPage';
import FilterDropdown from '../../../../portal/common/features/FilterDropdown';
import GridLoadingOverlay from '../../../../portal/common/features/Grid/GridLoadingOverlay';
import UnsubscribeFooter from '../../../../portal/common/features/Grid/UnsubscribeFooter';
import HeaderBar from '../../../../portal/common/features/HeaderBar/HeaderBar';
import Spinner from '../../../../portal/common/features/Spinner';
import StatusAlert from '../../../../portal/common/features/StatusAlert';
import TabNav from '../../../../portal/common/features/Tabs/TabNav';
import TabNavItem from '../../../../portal/common/features/Tabs/TabNavItem';
import { UserProfileImage } from '../../../../portal/common/features/UserProfileImage';
import ModalWrapper from '../../../../portal/common/features/Wrappers/ModalWrapper';
import useBasicSearch from '../../../../portal/common/hooks/useBasicSearch';
import useClearanceNotificationsFilter from '../../../../portal/common/hooks/useClearanceNotificationsFilter';
import useGridLanguage from '../../../../portal/common/hooks/useGridLanguage';
import useGridPreferences from '../../../../portal/common/hooks/useGridPreferences';
import useGridSelection from '../../../../portal/common/hooks/useGridSelection';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { ClientSelectionSlice, GridSettingsSlice, HydrationSlice, LanguageSlice } from '../../../../portal/common/models/ReduxSlices';
import { SubscribersModals } from '../../../../portal/common/models/SubscribersModals';
import { GetUserWidthGridSetting } from '../../../../profile/redux/actions/subscriptions';
import { subscriptionTypeConstants } from '../../../common/constants/constants-subscribers';
import { GridSubscriptions } from '../../../common/models/EditSubscriber';
import { EditSubscribersSlice, SubscribersSlice } from '../../../common/models/ReduxSlices';
import { MultipleUnsubscribe } from '../../../redux/actions/editSubscribers';
import { GetSubscriberSubscriptions } from '../../../redux/actions/subscribersManagement';
import {
    resetEditSubscriptions,
    resetEditSubscriptionsGrid,
    resetSubscriptionUpdate,
    resetUnsubscribe,
    setEditSubscriptionsInitialData
} from '../../../redux/reducers/editSubscriptiosSubscribersSlice';
import {
    resetSelectedState,
    setCurrentDataItems,
    setDataState,
    setHeaderCheckbox,
    setSelectedState,
    setTotalItems
} from '../../../redux/reducers/subscribersGridSettingsSlice';
import { RootState } from '../../../redux/store';
import UnsubscribeModal from '../RemoveSubscribersModal/UnsubscribeModal';
import FormattedGridCell from './FormattedGridCell';
import { MultiSelectModal } from './MultiSelectModal';

const initialModals: SubscribersModals = {
    remove: false
};

const SELECTED_FIELD = 'selected';
const DATA_ITEM_KEY = 'RowId';
const idGetter = getter(DATA_ITEM_KEY);

const EditSubscribers = () => {
    const dispatch = useDispatch();
    const { languageSelected: selectedLanguage } = useSelector<RootState, LanguageSlice>((state) => state.language);
    const { dataState, currentDataItems, selectedState, headerCheckbox, totalItems } = useSelector<RootState, GridSettingsSlice>(
        (state) => state.subscribersGridSettings
    );

    const { subscriptions, customerNumbers, isLoading, editSubscriber, subscriptionUpdate, unsubscribe, error } = useSelector<
        RootState,
        EditSubscribersSlice
    >((state) => state.editSubscriptionsSubscriber);
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const { applyClicked } = useSelector<RootState, ClientSelectionSlice>((state) => state.clientSelection);

    const { handleHeaderSelectionChange, handleSelectionChange } = useGridSelection({
        DATA_ITEM_KEY,
        SELECTED_FIELD,
        idGetter,
        selectedState,
        currentDataItems,
        onSelectionChange: (value) => dispatch(setSelectedState(value)),
        onHeaderSelectionChange: useCallback((value) => dispatch(setHeaderCheckbox(value)), [dispatch]),
        onReset: useCallback(() => dispatch(resetSelectedState()), [dispatch])
    });
    const [unsubscribeModal, setUnsubscribeModal] = useState<SubscribersModals>(initialModals);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState<any[]>([]);
    const [loadingUpdateGrid, setLoadingUpdatedGrid] = useState<boolean>(false);
    const [notificationsUS, setNotificationsUS] = useState<any>(MILESTONES_US);
    const [notificationsCA, setNotificationsCA] = useState<any>(MILESTONES_CA);
    const [gridSubscriptions, setGridSubscriptions] = useState<GridSubscriptions[]>([]);
    const [modal, setModal] = useState(false);
    const gridLanguage = useGridLanguage(selectedLanguage);
    const translate = useTranslation();
    const location = useLocation<EditSubscribersSlice['editSubscriber']>();
    const gridRef = useRef<any>();
    const inputRef = React.createRef<HTMLInputElement>();
    const [subscriptionType, setSubscriptionType] = useState<{ value: string; label: string }[]>([]);
    const { searchParam, showClear, handleSearch, handleInputChange, handleKeyDown, handleOnClearInput } = useBasicSearch({ inputRef });
    const { filteredSubscriptions, count } = useClearanceNotificationsFilter({ searchParam, subscriptionType, gridSubscriptions });
    const [dropdownSelection, setDropdownSelection] = useState<number[]>([]);
    const { allColumns, visibleColumns } = useSelector<RootState, SubscribersSlice>((state) => state.subscribers);

    const { columns } = useGridPreferences({
        allColumns,
        visibleColumns,
        onColumnReorder: (value) => dispatch(updateColumnOrder(value)),
        //@ts-ignore
        saveColumnReorder: (value) => dispatch(SaveUserClearancesGridSetting({ GridColumns: value }))
    });

    useEffect(() => {
        dispatch(GetUserWidthGridSetting());
    }, [dispatch]);

    useEffect(() => {
        if (location && location.state) {
            dispatch(
                setEditSubscriptionsInitialData({
                    isEditable: location?.state?.isEditable,
                    editEmail: location?.state.editEmail,
                    editPhone: location?.state.editPhone,
                    fullName: location?.state.fullName,
                    role: location?.state.role,
                    language: location?.state.language
                })
            );
        } else return;
    }, [dispatch, location]);

    useEffect(() => {
        if (
            !subscriptionUpdate.isLoading &&
            !subscriptionUpdate.error &&
            !unsubscribe.error &&
            !unsubscribe.isLoading &&
            (editSubscriber.editEmail || editSubscriber.editPhone) &&
            applyClicked !== false
        ) {
            dispatch(
                GetSubscriberSubscriptions({
                    ProfileEmail: editSubscriber.editEmail ? editSubscriber.editEmail : null,
                    ProfileMobileNumber: editSubscriber.editPhone ? editSubscriber.editPhone : null,
                    UserIds: [location.state.userId || '']
                })
            );
        }
    }, [
        dispatch,
        editSubscriber.editEmail,
        editSubscriber.editPhone,
        subscriptionUpdate,
        unsubscribe,
        applyClicked,
        location.state.userId
    ]);

    // Set data state for the grid
    useEffect(() => {
        if (subscriptions) {
            setGridSubscriptions(
                subscriptions.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: false,
                    isNew:
                        Object.keys(selectedState).length !== 0 ? customerNumbers?.some((custom) => custom === item.CustomerNumber) : false
                }))
            );
        }
    }, [customerNumbers, subscriptions, selectedState]);

    // Update the total items that are responsible to show/hide pagination
    useEffect(() => {
        if (!gridRef.current) return;
        dispatch(setTotalItems(gridRef.current.props.data.total));
        dispatch(setCurrentDataItems(gridRef.current.props.data.data));
    }, [dataState, gridSubscriptions, filteredSubscriptions, dispatch]);

    // Array of selected SubscriptionIds
    useEffect(() => {
        setSelectedSubscriptions(
            Object.keys(selectedState)
                .filter((id) => selectedState[id])
                .map((id) => ({ ...gridSubscriptions.find((sub: any) => sub[DATA_ITEM_KEY] === parseInt(id)) }))
        );
    }, [gridSubscriptions, selectedState]);

    // Reset the selection on every grid reload
    useEffect(() => {
        if (!isLoading && !error) {
            dispatch(resetSelectedState());
        }
    }, [isLoading, error, dispatch]);

    useEffect(() => {
        dispatch(resetSubscriptionUpdate());
        dispatch(resetUnsubscribe());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetEditSubscriptions());
            dispatch(resetEditSubscriptionsGrid());
        };
    }, [dispatch]);

    const GridCell = useCallback(
        (props: GridCellProps) => {
            return <FormattedGridCell {...props} isLocked={editSubscriber.isEditable ? props.dataItem[SELECTED_FIELD] || false : true} />;
        },
        [editSubscriber.isEditable]
    );

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Checkbox value={headerCheckbox} onChange={props.selectionChange} disabled={!editSubscriber.isEditable} />
                </div>
            );
        },
        [editSubscriber.isEditable, headerCheckbox]
    );

    const toggleModal = useCallback(
        (prop: keyof SubscribersModals | Array<keyof SubscribersModals>, next?: boolean) => {
            if (user?.InternalUser) return;
            if (typeof prop === 'string') {
                setUnsubscribeModal({ ...unsubscribeModal, [prop]: next !== undefined ? next : !unsubscribeModal[prop] });
            } else {
                setUnsubscribeModal(Object.assign({}, unsubscribeModal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [unsubscribeModal, user]
    );

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        dispatch(setDataState(e.dataState));
    };

    const handleMultiUnsubscribe = (subscriptionIds: number[]) => {
        if (gridSubscriptions) {
            setGridSubscriptions(
                gridSubscriptions.map((item: any) => ({
                    ...item,
                    isNew: false
                }))
            );
        }

        if (subscriptionUpdate.data) {
            dispatch(resetSubscriptionUpdate());
        }

        if (selectedSubscriptions != null) {
            toggleModal('remove');
            dispatch(MultipleUnsubscribe({ subscriptionIds }));
        }
    };

    const handleApply = (id: string, selectedArray: number[]) => {
        const subsType = selectedArray.map((item: any) => {
            return subscriptionTypeConstants[item];
        });
        setSubscriptionType(subsType);
        setDropdownSelection(selectedArray);
    };

    const clearFilters = () => {
        setDropdownSelection([]);
        setSubscriptionType([]);
    };

    return (
        <div className='d-flex flex-column flex-fill h-100'>
            <HeaderBar>
                <div className='profile-name d-flex align-items-center'>
                    <div className='avatar icon mr-3'>
                        <UserProfileImage />
                    </div>
                    <h1>{editSubscriber.fullName ? editSubscriber.fullName : 'N/A'}</h1>
                </div>

                <ClientChooser />
            </HeaderBar>
            <BasicSearchBox
                ref={inputRef}
                inputPlaceHolder={translate('SearchClientNoEntryNo_Label')}
                buttonVariant={'primary'}
                buttonText={translate('Search_Label')}
                handleSearch={handleSearch}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                onClearInputValue={handleOnClearInput}
                showClear={showClear}
            />
            <div className='scrollable-filter-area d-flex flex-nowrap align-items-center border-bottom bg-white py-3 pr-3'>
                <div className='font-weight-medium pl-3 pr-2'>{translate('Filters_Label')}</div>
                <FilterDropdown
                    id={'SubscriptionType'}
                    name={translate('SubscriptionType_Label')}
                    options={subscriptionTypeConstants}
                    optionsLabel={translate('FilterBySubscriptionType_Label')}
                    selection={dropdownSelection}
                    handleSelection={handleApply}
                />
                {subscriptionType.length ? (
                    <div className='clear-all-filters d-flex flex-row align-items-center'>
                        <Button className='no-bold-underline' variant={'link'} onClick={clearFilters}>
                            {translate('Clear_Label')}
                        </Button>
                    </div>
                ) : null}
            </div>
            <div className='align-items-center border-bottom bg-white py-3 pr-3 pl-3'>
                <span className='font-weight-bold'>{count}</span>
                {` ${translate('Subscriptions_Label')}`}
            </div>
            <TabNav className='border-none' tabs={TabNavigationIds.Subscribers}>
                <TabNavItem id={TabNavigationIds.Subscribers[0]}>{translate('ClearanceNotifications_Label')}</TabNavItem>
            </TabNav>

            {/* Status: pending (only showing on initial load) */}
            {isLoading && !subscriptions && !subscriptionUpdate.isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {subscriptions && !error && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <Grid
                            className='profile-subscription-tables flex-fill h-100'
                            data={process(
                                (filteredSubscriptions ? filteredSubscriptions : gridSubscriptions && gridSubscriptions).map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                                })),
                                dataState
                            )}
                            ref={gridRef}
                            total={subscriptions ? subscriptions.length : 0}
                            pageable={
                                totalItems < 10
                                    ? false
                                    : {
                                          buttonCount: 4,
                                          info: true,
                                          type: 'numeric',
                                          pageSizes: [5, 10, 20],
                                          previousNext: true
                                      }
                            }
                            sortable={true}
                            style={{ zIndex: 0 }}
                            selectedField={SELECTED_FIELD}
                            onHeaderSelectionChange={handleHeaderSelectionChange}
                            onSelectionChange={handleSelectionChange}
                            onDataStateChange={dataStateChange}
                            selectable={{
                                enabled: false,
                                drag: false,
                                cell: false,
                                mode: 'multiple'
                            }}
                            {...dataState}
                        >
                            {columns?.map((column) => {
                                return (
                                    <Column
                                        key={column.Code}
                                        field={column.Code}
                                        title={translate(column.HeaderLabel)}
                                        cell={GridCell}
                                        headerCell={column.Code === 'selected' ? HeaderCell : null}
                                        sortable={column.IsSortable}
                                        width={column.Width}
                                        orderIndex={column.Order}
                                    />
                                );
                            })}
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            )}

            {modal && (
                <ModalWrapper>
                    <MultiSelectModal
                        email={editSubscriber.editEmail}
                        phone={editSubscriber.editPhone}
                        selectedClientsNumber={selectedSubscriptions?.length}
                        setModal={setModal}
                        notificationsCA={notificationsCA}
                        notificationsUS={notificationsUS}
                        setNotificationsCA={setNotificationsCA}
                        setNotificationsUS={setNotificationsUS}
                        selectedSubscriptions={selectedSubscriptions}
                        setLoadingUpdatedGrid={setLoadingUpdatedGrid}
                        setGridSubscriptions={setGridSubscriptions}
                        gridSubscriptions={gridSubscriptions}
                    />
                </ModalWrapper>
            )}
            {/* Grid loading overlay to prevent user from taking further actions */}
            {(isLoading || subscriptionUpdate.isLoading || unsubscribe.isLoading) && subscriptions && subscriptions.length > 0 && (
                <GridLoadingOverlay parentElement='.k-grid.profile-subscription-tables' />
            )}

            {!isLoading && !unsubscribe.isLoading && unsubscribe.removedSubscribersAmount && (
                <StatusAlert message={translate('UnsubscribedFromClients_Label', unsubscribe.removedSubscribersAmount)} type={'success'} />
            )}

            {/* Alert toast when update process is finished  */}
            {!isLoading &&
                !subscriptionUpdate.isLoading &&
                subscriptionUpdate.data &&
                subscriptionUpdate.alertMessage &&
                subscriptionUpdate.alertType &&
                subscriptionUpdate.action !== 'multi-select' && (
                    <StatusAlert
                        message={
                            subscriptionUpdate.requestedAction && subscriptionUpdate.requestedAction.toLowerCase() === 'unsubscribe'
                                ? translate(subscriptionUpdate.alertMessage, subscriptionUpdate.customerNumber)
                                : translate(subscriptionUpdate.alertMessage)
                        }
                        type={subscriptionUpdate.alertType}
                    />
                )}

            {!subscriptionUpdate.isLoading &&
                loadingUpdateGrid &&
                subscriptionUpdate.data?.ResponseCode === 200 &&
                subscriptionUpdate.data.ResponseMessage === 'PASS' &&
                subscriptionUpdate.data.SubscriptionStatusCode === 'SUBSCRIBED-UPDATED' && (
                    <StatusAlert message={translate('ClearanceNotificationsUpdated_Label')} type='success' />
                )}

            {/* Unsubscribe footer */}
            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 &&
                !isLoading &&
                !unsubscribe.isLoading &&
                !subscriptionUpdate.isLoading && (
                    <UnsubscribeFooter
                        amountLabel={translate('NumberOfRemoveSelected_Label', selectedSubscriptions.length)}
                        primaryButtonLabel={translate('RemoveSelected_Label')}
                        onPrimary={() => toggleModal('remove')}
                        secondaryButtonLabel={translate('ManageNotifications_Label')}
                        onSecondary={() =>
                            !user?.InternalUser
                                ? setModal(true)
                                : () => {
                                      return;
                                  }
                        }
                        selectedSubscribers={selectedSubscriptions.length}
                    />
                )}

            {unsubscribeModal.remove && (
                <UnsubscribeModal
                    subscribers={selectedSubscriptions}
                    isEditPage={true}
                    toggleModal={toggleModal}
                    handleSubmit={() => handleMultiUnsubscribe(selectedSubscriptions.map((item) => item.SubscriptionId))}
                />
            )}

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}
        </div>
    );
};

export default EditSubscribers;
