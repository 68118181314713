import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown from '../../common/Dropdown/Dropdown';
import TextBox from '../../common/Textbox/Textbox';
import * as actions from '../../store/actions/index';
import ClientServiceGrid from '../TeamManagement/ClientServiceGrid';

class NewClientAdminForm extends Component {
    state = {
        vm: {
            PendingClientAdmin: {},
            OriginalClientAdmin: {}
        }
    };

    componentDidMount() {
        this.props.onInitTranslations('AccessManagement.AccessManagement.SelectNewClientAdmin');

        this.setState({ vm: this.props.ViewModel });
    }

    onFirstNameChange = (firstname) => {
        this.setState((state) => {
            return {
                vm: {
                    ...state.vm,
                    PendingClientAdmin: {
                        ...state.vm.PendingClientAdmin,
                        FirstName: firstname
                    }
                }
            };
        });
    };

    onLastNameChange = (lastname) => {
        this.setState((state) => {
            return {
                vm: {
                    ...state.vm,
                    PendingClientAdmin: {
                        ...state.vm.PendingClientAdmin,
                        LastName: lastname
                    }
                }
            };
        });
    };

    onPhoneChange = (phone) => {
        this.setState((state) => {
            return {
                vm: {
                    ...state.vm,
                    PendingClientAdmin: {
                        ...state.vm.PendingClientAdmin,
                        Phone: phone
                    }
                }
            };
        });
    };

    onCompanyNameChange = (companyName) => {
        this.setState((state) => {
            return {
                vm: {
                    ...state.vm,
                    PendingClientAdmin: {
                        ...state.vm.PendingClientAdmin,
                        CompanyName: companyName
                    }
                }
            };
        });
    };

    render() {
        const u = this.state.vm.PendingClientAdmin;
        const val = this.props.ValidationMessages;

        const clientGrids = (this.state.vm.ParentServices ? this.state.vm.ParentServices : []).map((s, index) => {
            return <ClientServiceGrid key={index + s.Number} parentService={s} parentIndex={index} readonly={true} />;
        });

        const userDetails = (
            <div>
                <div className='row'>
                    <div className='col-sm-4'>
                        <TextBox
                            value={u.FirstName ? u.FirstName : ''}
                            label={this.props.translations.Phrases['FirstName_Label']}
                            name='PendingClientAdmin.FirstName'
                            valMsg={val.PendingClientAdmin ? this.props.translations.Phrases[val.PendingClientAdmin.FirstName] : ''}
                            readonly={false}
                            onValueChanged={this.onFirstNameChange}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <TextBox
                            value={u.LastName ? u.LastName : ''}
                            label={this.props.translations.Phrases['LastName_Label']}
                            name='PendingClientAdmin.LastName'
                            valMsg={val.PendingClientAdmin ? this.props.translations.Phrases[val.PendingClientAdmin.LastName] : ''}
                            readonly={false}
                            onValueChanged={this.onLastNameChange}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <Dropdown
                            name='PendingClientAdmin.UserRole'
                            options={this.state.vm.OriginalClientAdmin.Roles}
                            label={this.props.translations.Phrases['UserRole_Label']}
                            valMsg={''}
                            readonly={true}
                            onlyCustomAction={true}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={u.Status ? u.Status : ''}
                            label={this.props.translations.Phrases['Status_Label']}
                            name='PendingClientAdmin.Status'
                            readonly={true}
                            onlyCustomAction={true}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-4'>
                        <TextBox
                            value={u.Email ? u.Email : ''}
                            label={this.props.translations.Phrases['Email_Label']}
                            name='PendingClientAdmin.Email'
                            valMsg={''}
                            readonly={true}
                            onlyCustomAction={true}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <TextBox
                            value={u.Phone ? u.Phone : ''}
                            label={this.props.translations.Phrases['Phone_Label']}
                            name='PendingClientAdmin.Phone'
                            valMsg={val.PendingClientAdmin ? this.props.translations.Phrases[val.PendingClientAdmin.Phone] : ''}
                            readonly={false}
                            placeholder={'(123) 456-7890 x123456'}
                            inputMask='(000) 000-0000 x000000'
                            onValueChanged={this.onPhoneChange}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <TextBox
                            value={u.CompanyName ? u.CompanyName : ''}
                            label={this.props.translations.Phrases['CompanyName_Label']}
                            name={'PendingClientAdmin.CompanyName'}
                            valMsg={val.PendingClientAdmin ? this.props.translations.Phrases[val.PendingClientAdmin.CompanyName] : ''}
                            onValueChanged={this.onCompanyNameChange}
                        />
                    </div>
                </div>
            </div>
        );

        const submitButton = (
            <button className='button blue small pull-left' onClick={() => this.props.onConfirmNewClientAdmin(this.state.vm)}>
                {this.props.translations.Phrases['Submit_Button']}
            </button>
        );

        return (
            <div>
                {userDetails}

                <div className='row'>
                    <div className='col-sm-6'>
                        <h4 className='bold'>{this.props.translations.Phrases['ClientsAndPermissions_Label']}</h4>
                    </div>
                </div>
                <div>{clientGrids}</div>
                {submitButton}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        vm: state.userSearch.model,
        translations: state.translations.model,
        ValidationMessages: state.userSearch.validationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onConfirmNewClientAdmin: (vm) => dispatch(actions.confirmNewClientAdmin(vm))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewClientAdminForm);
