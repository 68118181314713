import { SortDescriptor } from '@progress/kendo-data-query';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_CLEARANCES_PATH } from '../../../portal/common/constants/constants-portal';
import { ShipmentMilestones } from '../../comon/constants/overview-dashboard-constants';
import { ShipmentListData } from '../../comon/models/overviewDashboard';

const transformData = (data: ShipmentListData) => {
    const shipmentList = data.Clearances.map((obj) => {
        return {
            ...obj,
            ModeOfTransport: { id: obj.ModeOfTransport },
            BillOfLading: {
                MasterBill: obj.BillOfLading?.length && obj.BillOfLading[0].MasterBill ? obj.BillOfLading[0].MasterBill : undefined
            },
            Milestone:
                obj.Destination && obj.Stage?.length
                    ? ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                          obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['Canada']
                      ].label ||
                      ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                          obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['USA']
                      ].label
                    : undefined
        };
    });
    return { totalInProgress: data.TotalInProgress, totalOnHold: data.TotalOnHold, totalCompleted: data.TotalCompleted, shipmentList };
};

export const GetShipmentList = createAsyncThunk(
    'GET_SHIPMENT_LIST',
    async (
        payload: {
            Status: null | string;
            Country: string | null | undefined;
            ClientCaptionNumbers: string[] | undefined;
            PageSize: number;
            OrderBy?: SortDescriptor[] | null;
            StartRow: number;
            //ColumnFilters: ColumnFilters;
            DateRange: {
                dateRangeSelected: string;
                customDateRange:
                    | {
                          type: string;
                          date: string;
                      }[]
                    | undefined;
            };
            AdvancedSearchFilters: {
                Tags: string[];
            };
            ClearancesCreatedOnDate: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<ShipmentListData>(`${API_CLEARANCES_PATH}/clearanceList`, payload);
            return transformData(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
