import { REACT_APP_ASSETS_URL } from '../../../../portal/common/constants/constants-portal';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
interface HeaderDetailsProps {
    labelToDisply: string;
}
const NoSearchResults: React.FC<HeaderDetailsProps> = ({ labelToDisply }) => {
    const translate = useTranslation();
    const translatedLabel = translate(labelToDisply);
    return (
        <>
            <img
                className='image-150px '
                src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/scenes/scene-error-404-page-not-found.svg`}
                alt={labelToDisply}
            />
            <h3 className='mb-2'>{translatedLabel}</h3>
            <p className='w-300px text-center'>{translate('ChechkBackLater_Label')}</p>
        </>
    );
};

export default NoSearchResults;
