import { SortDescriptor } from '@progress/kendo-data-query';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_CLEARANCES_PATH, API_SEARCH_SHIPMENT_PATH } from '../../../portal/common/constants/constants-portal';
import { ShipmentMilestones } from '../../common/constants/constants-clearances';
import { DocumentsIndex } from '../../common/models/Documents';
import { ShipmentListData } from '../../common/models/ResponseData';
import { ColumnFilters } from '../../common/models/ShipmentGridSettings';
import { GET_DOCUMENTS_INDEX, GET_SHIPMENT_LIST } from './actionTypes';

const transformData = (data: ShipmentListData) => {
    const shipmentList = data.Clearances.map((obj) => {
        return {
            ...obj,
            ModeOfTransport: { id: obj.ModeOfTransport },
            BillOfLading: {
                MasterBill: obj.BillOfLading?.length && obj.BillOfLading[0].MasterBill ? obj.BillOfLading[0].MasterBill : undefined
            },
            Milestone:
                obj.Destination && obj.Stage?.length
                    ? ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                          obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['Canada']
                      ].label ||
                      ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                          obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['USA']
                      ].label
                    : undefined
        };
    });
    return { totalInProgress: data.TotalInProgress, totalOnHold: data.TotalOnHold, totalCompleted: data.TotalCompleted, shipmentList };
};

export const GetShipmentList = createAsyncThunk(
    GET_SHIPMENT_LIST,
    async (
        payload: {
            Status: null | string;
            Country: string | null | undefined;
            ClientCaptionNumbers: string[] | undefined;
            PageSize: number;
            OrderBy: SortDescriptor[] | null;
            StartRow: number;
            ColumnFilters: ColumnFilters;
            DateRange: {
                dateRangeSelected: string;
                customDateRange:
                    | {
                          type: string;
                          date: string;
                      }[]
                    | undefined;
            };
            AdvancedSearchFilters: {
                Tags: string[];
            };
            ClearancesCreatedOnDate: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<ShipmentListData>(`${API_CLEARANCES_PATH}/clearanceList`, payload);
            return transformData(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const GetDocumentsIndex = createAsyncThunk(GET_DOCUMENTS_INDEX, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<DocumentsIndex>(`${API_SEARCH_SHIPMENT_PATH}/Index`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
