import {
    GridColumnMenuCheckboxFilter,
    GridColumnMenuFilter,
    GridColumnMenuFilterUIProps,
    GridColumnMenuProps,
    GridColumnMenuSort
} from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { SubscribersSlice } from '../../../../subscribers/common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

export const ColumnMenuSearchText: React.FC<GridColumnMenuProps> = (props) => {
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} filterUI={CustomTextFilterUI} expanded={true} />
        </div>
    );
};

export const ColumnMenuCheckboxFilter: React.FC<GridColumnMenuProps> = (props) => {
    const { subscribers } = useSelector<RootState, SubscribersSlice>((state) => state.subscribers);
    const translate = useTranslation();
    let data;

    switch (props.column.field) {
        case 'Type':
            data =
                subscribers &&
                subscribers.map((item) => {
                    return {
                        Type: item?.IsExternal ? translate('StatusExternal_Label') : translate('StatusPortalUser_Label'),
                        value: item?.IsExternal ? 'External' : 'Portal user'
                    };
                });

            break;
        case 'Language':
            data =
                subscribers &&
                subscribers?.map((item) => {
                    return {
                        Language: item?.Language?.toLocaleLowerCase()?.includes('fr') ? 'Fr' : 'En',
                        value: item?.Language
                    };
                });
            break;
        case 'Role':
            data =
                subscribers &&
                subscribers
                    ?.map((item) => {
                        return {
                            Role: item?.RoleName,
                            value: item?.RoleName
                        };
                    })
                    .filter((item) => item.Role);
            break;
        default:
            break;
    }

    return (
        <div>
            <GridColumnMenuSort {...props} />
            {data && <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} searchBox={() => null} uniqueData={true} />}
        </div>
    );
};

const CustomTextFilterUI: React.FC<GridColumnMenuFilterUIProps> = (props) => {
    const [inputValue, setInputValue] = useState(props.firstFilterProps.value || '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { firstFilterProps } = props;
        // Allow only numbers to be entered for "MobileNumberOnlyNumbers" field
        const value = firstFilterProps.field === 'MobileNumberOnlyNumbers' ? event.target.value.replace(/\D/g, '') : event.target.value;

        firstFilterProps.onChange({
            value,
            operator: 'contains',
            syntheticEvent: event
        });

        setInputValue(value);
    };

    return (
        <div>
            <input className='form-control' id='columnfilter' name='columnSearch' type='text' value={inputValue} onChange={handleChange} />
        </div>
    );
};
