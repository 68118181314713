import { Component, React } from 'react';
import { connect } from 'react-redux';
import CheckIcon from '../../common/Icons/CheckIcon';

class WidgetSelectorGridRow extends Component {
    selectWidget = (wg) => {
        this.props.onWidgetSelect(wg);
    };

    render() {
        const wg = this.props.data;
        const displayText = wg.Widget.Name;
        return (
            <div className='widget-selector-row'>
                <div className='row grid-row'>
                    <div className='col-sm-1' onClick={() => this.selectWidget(wg)}>
                        <span>
                            <input type='checkbox' checked={wg.Visible} onChange={() => this.selectWidget(wg)} className='checkbox-none' />
                            <CheckIcon isChecked={wg.Visible} />
                        </span>
                    </div>
                    <div className='col-sm-10'>
                        <span className='row-display-text pull-left'>{displayText}</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(WidgetSelectorGridRow);
