import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clearToast, sendToast } from '../../../redux/reducers/toastSlice';
import { StatusAlertProps } from '../StatusAlert';

const useToast = () => {
    const dispatch = useDispatch();

    const send = (props: StatusAlertProps) => {
        dispatch(sendToast(props));
    };

    const clear = useCallback(() => {
        dispatch(clearToast());
    }, [dispatch]);

    return { send, clear };
};

export default useToast;
