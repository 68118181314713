import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from '../../../portal/common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { CancelStatusTeamMember, ReactivateStatusTeamMember } from '../../redux/actions/teamManagement';
import { closeStatusModal, setStatusIsLoading, setStatusMessage, statusChangeCheck } from '../../redux/reducers/statusUserSlice';
import { RootState } from '../../redux/store';
import { StatusUserSlice } from '../models/ReduxSlices';

const ChangeUserStatusModal: React.FC = () => {
    const { statusUserId, isActive } = useSelector<RootState, StatusUserSlice>((state) => state.statusUser);
    const dispatch = useDispatch();
    const translate = useTranslation();

    const USER_CHANGE_STATUS_FUNCTION = isActive ? CancelStatusTeamMember : ReactivateStatusTeamMember;

    const deactivateUser = async (userId: number) => {
        try {
            await dispatch(closeStatusModal());
            await dispatch(setStatusIsLoading(true));
            await dispatch(USER_CHANGE_STATUS_FUNCTION(userId));
        } catch (err) {
            dispatch(closeStatusModal());
            dispatch(setStatusIsLoading(false));
        }
    };

    return (
        <ModalWrapper onEscape={() => dispatch(closeStatusModal())}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content' style={{ padding: '15px', maxWidth: '500px' }}>
                    <div className='modal-header' style={{ borderBottom: 'none', paddingBottom: '0' }}>
                        <h3 className='modal-title' style={{ fontSize: '20px' }}>
                            {isActive ? translate('DeactivateUserModal_Label') : translate('ReactivateUserModal_Label')}
                        </h3>
                    </div>
                    <div className='modal-body' style={{ paddingTop: '12px', paddingBottom: '40px' }}>
                        {isActive ? translate('DeactivateModalMessage_Label') : translate('ReactivateModalMessage_Label')}
                    </div>
                    <div className='modal-footer'>
                        <button
                            type='button'
                            className='btn btn-tertiary-blue btn-lg'
                            onClick={() => {
                                dispatch(closeStatusModal());
                            }}
                        >
                            {translate('Close_Label')}
                        </button>
                        <button
                            type='button'
                            className='btn btn-danger btn-lg'
                            onClick={(e) => {
                                deactivateUser(statusUserId).then(() => {
                                    dispatch(setStatusMessage(true));
                                    dispatch(statusChangeCheck());
                                    dispatch(setStatusIsLoading(false));
                                });
                            }}
                        >
                            {isActive ? translate('ConfirmUserDeactivation_Label') : translate('ConfirmUserReactivation_Label')}
                        </button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ChangeUserStatusModal;
