import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '../../../portal/common/hooks/useTranslation';
import { RootState } from '../../redux/store';
import { isMLPFeatureSelected } from '../functions/utils';
import { ClientsAndPermissionsSlice, UserDetailsSlice } from '../models/ReduxSlices';
import { TeamMemberData } from '../models/ResponseData';
import FixedFooterWhite from './FixedFooterWhite';

interface TeamMemberFooterProps {
    onAddUsers: (payload: { data: TeamMemberData; amount: number }) => void;
}

const TeamMemberFooter: React.FC<TeamMemberFooterProps> = ({ onAddUsers }) => {
    const {
        data: userDetailsData,
        newUsers,
        isLoading: userDetailsLoading,
        error: userDetailsError
    } = useSelector<RootState, UserDetailsSlice>((state) => state.userDetails);
    const {
        data: clientsAndPermissionsData,
        isLoading: clientsAndPermissionsLoading,
        error: clientsAndPermissionsError
    } = useSelector<RootState, ClientsAndPermissionsSlice>((state) => state.clientsAndPermissions);
    const [message, setMessage] = useState<string>('');
    const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);
    const mlpFeatureSelectedIsValid = useMemo(
        () => isMLPFeatureSelected(clientsAndPermissionsData?.ParentServices),
        [clientsAndPermissionsData?.ParentServices]
    );
    const translate = useTranslation();

    useEffect(() => {
        if (!formIsSubmitted) {
            return;
        }
        if (newUsers.length === 0) {
            setMessage(translate('AddAtLeastOneUser_Label'));
        } else if (!mlpFeatureSelectedIsValid) {
            setMessage(
                userDetailsData?.AdminRole === 'CentralAdmin'
                    ? translate('ClearancesFeatureMustBeSelected_Label')
                    : translate('MLPFeatureMustBeSelected_Label')
            );
        } else {
            setMessage('');
        }
    }, [mlpFeatureSelectedIsValid, translate, userDetailsData?.AdminRole, newUsers.length, formIsSubmitted]);

    const handleAddingUsers = () => {
        setFormIsSubmitted(true);
        if (userDetailsData && newUsers.length > 0 && mlpFeatureSelectedIsValid) {
            onAddUsers({ data: { ...userDetailsData, ...clientsAndPermissionsData }, amount: newUsers.length });
        }
    };

    return (
        <>
            {!userDetailsLoading && !userDetailsError && !clientsAndPermissionsLoading && !clientsAndPermissionsError && (
                <FixedFooterWhite
                    feedbackText={
                        newUsers.length === 0 ? translate('NoUsersCreated_Label') : translate('CountUsersCreated_Label', newUsers.length)
                    }
                    primaryLabel={newUsers.length > 0 ? translate('SaveUsers_Label') : translate('AddUsers_Label')}
                    primaryIcon={faUserPlus}
                    primaryOnClick={handleAddingUsers}
                    message={message}
                />
            )}
        </>
    );
};

export default TeamMemberFooter;
