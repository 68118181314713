import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditSubscribers from './screens/SubscribersGrid/EditSubscribers/EditSubscribers';
import SubscribersGrid from './screens/SubscribersGrid/SubscribersGrid';
const Subscribers = () => {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path={['/Subscribers', '//Subscribers']} exact component={SubscribersGrid} />
                    <Route path='/Subscribers/:rowId' exact component={EditSubscribers} />
                </Switch>
            </div>
        </main>
    );
};

export default React.memo(Subscribers);
