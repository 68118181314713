import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Button from '../../../../portal/common/features/Button';
import Tooltip from '../../../../portal/common/features/Tooltip/Tooltip';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { HydrationSlice } from '../../../../portal/common/models/ReduxSlices';
import { RootState } from '../../../redux/store';

interface ActionButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ onClick, disabled }) => {
    const { user } = useSelector<RootState, HydrationSlice>((state) => state.hydration);
    const translate = useTranslation();

    return (
        <Button onClick={onClick} variant='danger-ghost' size='small' disabled={disabled || false}>
            <FontAwesomeIcon icon={faTimesCircle} className='mr-2' />
            {!user?.InternalUser ? (
                translate('Remove_Label')
            ) : (
                <Tooltip label='Disabled for LII users.'>{translate('Remove_Label')}</Tooltip>
            )}
        </Button>
    );
};

export default ActionButton;
