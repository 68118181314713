import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_PROFILE_PATH } from '../../../portal/common/constants/constants-portal';
import { GET_USERS_CASE_TICKETS_DETAILS } from './actionTypes';

export const GetUsersTickets = createAsyncThunk(GET_USERS_CASE_TICKETS_DETAILS, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${API_PROFILE_PATH}/getUsersCaseTicketsDetails`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
