import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'react-string-format';
import BatchCancelModal from '../SystemAdmin/BatchCancelModal';
import ClientAdminsCreatedGrid from '../SystemAdmin/ClientAdminsCreatedGrid';
import UploadSummaryGrid from '../SystemAdmin/UploadSummaryGrid';

class SuccessConfirmationSummary extends Component {
    render() {
        return (
            <div>
                <div className='col-sm-12'>
                    <h3>
                        File <span className='bold'>{this.props.batchJob.OriginalFileName}</span> was processed successfully.
                    </h3>
                    <br />
                    <UploadSummaryGrid uploadSummary={this.props.batchJob.Summary.UploadSummary} />
                    <br />
                    <ClientAdminsCreatedGrid clientAdminCreatedSummary={this.props.batchJob.Summary.ClientAdminsCreatedSummary} />
                </div>
                <div className='col-sm-12'>
                    <h4>{format(this.props.translations.Phrases['ConfirmBatchCreate_Label'], this.props.batchJob.Summary.TotalRows)}</h4>
                </div>
                <div className='col-sm-12'>
                    <div className='pull-right'>
                        <button type='button' className='button small blue' onClick={this.props.onRequestConfirm}>
                            {this.props.translations.Phrases['Confirm_Button']}
                        </button>
                        <BatchCancelModal
                            action=''
                            okButtonText={this.props.translations.Phrases['YesCancel_Label']}
                            cancelButtonText={this.props.translations.Phrases['No_Label']}
                            title={this.props.translations.Phrases['CancelBatchCreate_Label']}
                            btnText={this.props.translations.Phrases['Cancel_Button']}
                            batchJobId={this.props.batchJob.BatchJobId}
                            cancelAction={this.props.onRequestCancel}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(SuccessConfirmationSummary);
