import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CadDetailsSlice } from '../../common/models/ReduxSlices';
import { GetFullCADData } from '../actions/cadDetails';

const initialState: CadDetailsSlice = {
    isLoading: false,
    error: undefined,
    returnFields: undefined,
    shipments: undefined,
    cadCustomsInfoId: undefined
};

const cadDetailsSlice = createSlice({
    name: 'cadDetails',
    initialState,
    reducers: {
        setCadCustomsInfoId(state, action: PayloadAction<string>) {
            state.cadCustomsInfoId = action.payload;
        },
        restartCadCustomsInfoId(state) {
            state.cadCustomsInfoId = initialState.cadCustomsInfoId;
        }
    },
    extraReducers: (builder) => {
        builder
            // GET FULL CAD DATA
            .addCase(GetFullCADData.pending, (state) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetFullCADData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.returnFields = action.payload.ReturnFields;
                if (action.payload.Shipments.length) {
                    state.shipments = action.payload.Shipments;
                }
            })
            .addCase(GetFullCADData.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { setCadCustomsInfoId, restartCadCustomsInfoId } = cadDetailsSlice.actions;

export default cadDetailsSlice.reducer;
