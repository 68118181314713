import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridCaption from '../../../../portal/common/features/Grid/GridCaption';
import useTranslation from '../../../../portal/common/hooks/useTranslation';
import { GridPreferencesSlice } from '../../../../portal/common/models/ReduxSlices';
import { DateRangeSlice, ShipmentListSlice, ShipmentStatusSlice } from '../../../common/models/ReduxSlices';
import { resetAdvancedSearchSettings } from '../../../redux/reducers/advancedSearchSettingsSlice';
import { resetDateRange } from '../../../redux/reducers/dateRangeSlice';
import { resetSearchFilter } from '../../../redux/reducers/searchSlice';
import { resetShipmentGridSettings } from '../../../redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../redux/reducers/shipmentStatusSlice';
import { RootState } from '../../../redux/store';

const ShipmentsGridCaption = () => {
    const { statusSelected } = useSelector<RootState, ShipmentStatusSlice>((state) => state.shipmentStatus);
    const { totalSelected } = useSelector<RootState, ShipmentListSlice>((state) => state.shipmentsWithTotals);
    const { dateRangeSelected: selectedDateRange, customDateRange } = useSelector<RootState, DateRangeSlice>((state) => state.dateRange);
    const [showClear, setShowClear] = useState(false);
    const dispatch = useDispatch();
    const translate = useTranslation();
    const { shipmentList, isLoading, error } = useSelector<RootState, ShipmentListSlice>((state) => state.shipmentsWithTotals);
    const { allColumns, visibleColumns, areColumnsLoading } = useSelector<RootState, GridPreferencesSlice>(
        (state) => state.clearancesGridPreferences
    );

    const constructMessage = () => {
        if (selectedDateRange.id === 'CUSTOM' && customDateRange) {
            if (customDateRange?.length === 2) {
                return ` ${translate(
                    'ResultsBetween_Label',
                    customDateRange[0].date.split('-').join('/'),
                    customDateRange[1].date.split('-').join('/')
                )}`;
            } else if (customDateRange[0].type === 'startdate') {
                return ` ${translate('ResultsFrom_Label', customDateRange[0].date.split('-').join('/'))}`;
            } else if (customDateRange[0].type === 'enddate') {
                return ` ${translate('ResultsUntil_Label', customDateRange[0].date.split('-').join('/'))}`;
            }
        } else {
            const status = statusSelected
                .map((item) => {
                    return translate(item.label);
                })
                .toString()
                .replaceAll(',', ', ');
            const dateRangeArr = selectedDateRange.id.split('_');
            const isNum = !isNaN(Number(dateRangeArr[1])) ? true : false;
            const dateRange = isNum ? dateRangeArr[0] : selectedDateRange.id;
            const label = createLabel(`${isNum ? '_In_The_' : '_'}${dateRange}`);
            const translateResult = status + ' ' + translate(label);
            return `${translateResult ? (isNum ? translateResult.replace('{0}', dateRangeArr[1]) : translateResult) : label}`;
        }
    };

    const createLabel = (word: string) => {
        const arrayWords = word.split('_');
        let result = '';
        arrayWords.forEach(function (item, index) {
            const str = arrayWords[index].toLowerCase();
            const label = str.charAt(0).toUpperCase() + str.slice(1);
            result += label;
        });

        return `${result}_Label`;
    };

    const clearFilters = () => {
        dispatch(resetDateRange());
        dispatch(resetStatus());
        dispatch(resetSearchFilter());
        dispatch(resetAdvancedSearchSettings());
        dispatch(resetShipmentGridSettings());
        setShowClear(false);
    };

    return (
        <GridCaption
            gridDataState={{ shipmentList, isLoading, error }}
            clearLabel={translate('Clear_Label')}
            showClear={showClear}
            onClear={clearFilters}
            tableColumnsDropdown={true}
            allColumns={allColumns}
            visibleColumns={visibleColumns}
            areColumnsLoading={areColumnsLoading}
        >
            <>
                <span className='font-weight-bold'>{totalSelected} &nbsp;</span>
                {` ${constructMessage()}`}
            </>
        </GridCaption>
    );
};

export default ShipmentsGridCaption;
