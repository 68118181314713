import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from '../../portal/redux/reducers/alertsSlice';
import clientSelectionReducer from '../../portal/redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../portal/redux/reducers/hydrationSlice';
import languageReducer from '../../portal/redux/reducers/languageSlice';
import noticesReducer from '../../portal/redux/reducers/noticesSlice';
import portalSolutionsReducers from '../../portal/redux/reducers/portalSolutionsSlice';
import toastReducer from '../../portal/redux/reducers/toastSlice';
import translationsReducer from '../../portal/redux/reducers/translationsSlice';
import contactsGridSettingsReducer from './reducers/contactsGridSettingsSlice';
import contactsSearchFilterReducer from './reducers/contactsSearchFilterSlice';
import contactsReducer from './reducers/contactsSlice';

const store = configureStore({
    reducer: {
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        notices: noticesReducer,
        toast: toastReducer,
        solutionsMenuItems: portalSolutionsReducers,
        clientSelection: clientSelectionReducer,

        // page specific slices
        contacts: contactsReducer,
        contactsGridSettings: contactsGridSettingsReducer,
        contactsSearchFilter: contactsSearchFilterReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
