import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import '../../../../portal/assets/css-fixes.css';
import Calendar from '../../../../portal/common/features/DatePicker/Calendar';
import InputBase from '../../../../portal/common/features/DatePicker/InputBase';
import DropdownWrapper from '../../../../portal/common/features/Wrappers/DropdownWrapper';
import useDropdownToggle from '../../../../portal/common/hooks/useDropdownToggle';

const DateFormat = 'MM-DD-YYYY';

function DateComponent(props) {
    const { languageSelected } = useSelector((state) => state.language);
    const [errorMsg, setErrorMsg] = useState(null);
    const [inErrorState, setInErrorState] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const calendarRef = useRef(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [{ name: 'offset', options: { offset: [0, 2] } }]
    });
    const { isOpen, handleToggle, handleKeyDown } = useDropdownToggle(calendarRef);

    useEffect(() => {
        if (update) update();
    }, [update]);

    const handleDateChanged = (newValue) => {
        if (validateFormat(newValue)) {
            props.onDateChanged(newValue);
        }
    };

    const handleChange = (value) => {
        props.onDateChanged(value);
    };

    /**
     * Validate the date
     */
    const validateFormat = (newValue) => {
        const DATEFORMAT = DateFormat;
        if (!moment(newValue, DATEFORMAT, true).isValid()) {
            props.onDateChanged('');
            if (!newValue) {
                setErrorMsg(props.valMsg);
                setInErrorState(true);
                // formGroupElementRef.current.classList.add('was-validated');
                // inputElementRef.current.setCustomValidity('invalid');
                return false;
            }
        }

        if (newValue.includes('/')) {
            return false;
        }

        if (props.customValidationFunction) {
            const result = props.customValidationFunction(newValue);
            if (result !== true) {
                props.onDateChanged('');
                setErrorMsg(result);
                setInErrorState(true);
                // formGroupElementRef.current.classList.add('was-validated');
                // inputElementRef.current.setCustomValidity('invalid');
                return false;
            }
        }

        // formGroupElementRef.current.classList.remove('was-validated');
        // inputElementRef.current.setCustomValidity('');
        setErrorMsg(null);
        setInErrorState(false);
        return true;
    };

    const checkValue = (newValue) => {
        if (newValue.length === 10)
            if (props.dateCheckObj.comparationValueMin && props.dateCheckObj.comparationValueMax) {
                const newValueDate = new Date(newValue.slice(newValue.length - 4), newValue.slice(0, 2) - 1, newValue.slice(3, 5));
                if (newValueDate < props.dateCheckObj.comparationValueMin || newValueDate > props.dateCheckObj.comparationValueMax) {
                    props.onDateChanged('');
                }
            }
    };

    const calcInputSize = () => {
        let sizeClass;

        if (props.small) {
            sizeClass = 'form-control-sm';
        } else if (props.medium) {
            sizeClass = '';
        } else if (props.large) {
            sizeClass = 'form-control-lg';
        } else {
            sizeClass = '';
        }

        return sizeClass;
    };

    const calcInputIconSize = () => {
        let iconSizeClass;

        if (props.small) {
            iconSizeClass = 'input-icon-sm';
        } else if (props.medium) {
            iconSizeClass = 'input-icon-md';
        } else if (props.large) {
            iconSizeClass = 'input-icon-lg';
        } else {
            iconSizeClass = 'input-icon-md';
        }

        return iconSizeClass;
    };

    const DATEFORMAT = DateFormat;
    let date = '';
    const icon = <FontAwesomeIcon icon={faCalendarDay} />;
    if (props.value) {
        if (props.value.length > 10) {
            date = moment(props.value).isValid() ? moment(props.value).format(DATEFORMAT) : props.value;
        } else {
            date = props.value;
        }
    }
    const labelClass = props.valMsg ? 'date_time-field-validation-error' : '';

    return (
        <div className='needs-validation'>
            <InputBase {...props}>
                <form className='date'>
                    <div className={`form-group ${props.className}`}>
                        {props.dateLabel && (
                            <label htmlFor='dateInput' className={labelClass}>
                                {props.dateLabel}
                            </label>
                        )}

                        <input
                            id='dateInput'
                            ref={setReferenceElement}
                            placeholder={props.placeholder}
                            type='text'
                            value={date}
                            className={`form-control ${calcInputSize()} dropdown-toggle`}
                            onChange={(event) => handleChange(event.target.value)}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    validateFormat(event.target.value);
                                    checkValue(event.target.value);
                                }
                            }}
                            autoComplete='off'
                            name={props.name}
                            disabled={props.disabled}
                            onKeyDown={handleKeyDown}
                            onClick={handleToggle}
                            onKeyUp={(event) => {
                                if (event.target.value) {
                                    checkValue(event.target.value);
                                }
                            }}
                        />
                        {!inErrorState && <div className={calcInputIconSize()}>{icon}</div>}
                        <div className='invalid-feedback order-1'>{errorMsg}</div>
                        {isOpen && (
                            <DropdownWrapper onClick={handleToggle}>
                                <div
                                    className='dropdown-menu show'
                                    ref={setPopperElement}
                                    style={{ margin: 0, ...styles.popper }}
                                    {...attributes.popper}
                                >
                                    <div ref={calendarRef}>
                                        <Calendar
                                            customFormat={(x) => {
                                                return moment(x).startOf('day').format(DateFormat);
                                            }}
                                            today={props.today}
                                            selectedDate={props.value}
                                            onDateChanged={handleDateChanged}
                                            locale={languageSelected.locale}
                                            language={languageSelected.code}
                                            onClick={handleToggle}
                                            callingSection={'clearances'}
                                            validateTextBox={checkValue}
                                            resetErrorOnClear={() => setInErrorState(false)}
                                        />
                                    </div>
                                </div>
                            </DropdownWrapper>
                        )}
                    </div>
                </form>
            </InputBase>
        </div>
    );
}

export default DateComponent;
