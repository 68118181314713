import { faPeopleCarry, faPlane, faShip, faTrain, faTruck, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MOTMap as MOTMapClearances } from '../../../clearances/common/constants/constants-clearances';
import { MOTMap as MOTMapDocuments } from '../../../documents/common/constants/constants-documents';

interface CarrierIconProps {
    mot?: keyof typeof MOTMapClearances;
    MOTMap?: typeof MOTMapClearances;
    motDocuments?: keyof typeof MOTMapDocuments;
    MOTMapDocuments?: typeof MOTMapDocuments;
}

const CarrierIcon: React.FC<CarrierIconProps> = ({ mot, MOTMap, motDocuments, MOTMapDocuments }) => {
    let icon;
    let iconName =
        motDocuments !== undefined && MOTMapDocuments !== undefined
            ? MOTMapDocuments[motDocuments]?.icon.toLowerCase()
            : mot !== undefined && MOTMap !== undefined
            ? MOTMap[mot]?.icon.toLowerCase()
            : undefined;

    switch (iconName) {
        case 'ship':
            icon = faShip;
            break;
        case 'airplane':
            icon = faPlane;
            break;
        case 'air':
            icon = faPlane;
            break;
        case 'person carrying goods':
            icon = faPeopleCarry;
            break;
        case 'pipeline':
            icon = faWaveSquare;
            break;
        case 'train':
            icon = faTrain;
            break;
        case 'truck':
            icon = faTruck;
            break;
        default:
            icon = null;
    }
    return <>{icon !== null && <FontAwesomeIcon icon={icon} />}</>;
};

export default CarrierIcon;
