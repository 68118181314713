import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { NewSubscriberRoles } from '../../../portal/common/constants/constants-portal';
import gridWidthPreferences from '../../../portal/common/grid-preferences/grid-width-preferences.json';
import { Notifications, SubscriptionsWithClientNumbersData } from '../../../portal/common/models/ResponseData';
import { SubscribeData, SubscriptionIdCustomerNumber } from '../../common/models/SubscriptionUpdate';
import {
    GET_SUBSCRIPTIONS_WITH_CLIENT_NUMBERS,
    GET_WIDTH_GRID_PREFERENCES,
    UNSUBSCRIBE,
    UNSUBSCRIBE_MULTIPLE,
    UPDATE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTIONS_BY_USER_AND_CLIENT
} from './actionTypes';

export const GetUserWidthGridSetting = createAsyncThunk(GET_WIDTH_GRID_PREFERENCES, async (payload, { rejectWithValue }) => {
    try {
        const json = gridWidthPreferences;
        return json;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetSubscriptionsWithClientNumbers = createAsyncThunk(
    GET_SUBSCRIPTIONS_WITH_CLIENT_NUMBERS,
    async (
        GetClearanceNotificationsRequest: {
            ProfileEmail: string | undefined | null;
            ProfileMobileNumber: string | undefined | null;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<SubscriptionsWithClientNumbersData>(
                'api/clearance/clearance/get-subscriptions-per-clientnumbers',
                GetClearanceNotificationsRequest
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const Unsubscribe = createAsyncThunk(UNSUBSCRIBE, async (payload: { subscriptionId: number }, { rejectWithValue }) => {
    try {
        const response = await axios.put<SubscribeData>('api/clearance/clearance/unsubscribe-subscription', {
            subscriptionId: payload.subscriptionId
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const MultipleUnsubscribe = createAsyncThunk(
    UNSUBSCRIBE_MULTIPLE,
    async (payload: { subscriptionIds: number[] }, { rejectWithValue }) => {
        try {
            const response = await axios.put<SubscribeData>('api/clearance/clearance/unsubscribe-subscriptions', {
                subscriptionIds: payload.subscriptionIds
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
export const UpdateSubscription = createAsyncThunk(
    UPDATE_SUBSCRIPTION,
    async (
        payload: {
            customerNumber: string;
            subscriptionId: null | number;
            milestones: null | Notifications[];
            email: null | string;
            phone: null | string;
            DocID: null | number;
            language: string | undefined;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<SubscribeData>('api/clearance/clearance/update-subscription-by-user-client', {
                SubscriptionId: payload.subscriptionId,
                customerNumber: payload.customerNumber,
                MilestoneNotifications: payload.milestones,
                Phone: payload.phone,
                Email: payload.email,
                DocID: payload.DocID,
                Language: payload.language
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UpdateSubscriptionsByUserAndClient = createAsyncThunk(
    UPDATE_SUBSCRIPTIONS_BY_USER_AND_CLIENT,
    async (
        payload: {
            SubscriptionIdCustomerNumberDyads: SubscriptionIdCustomerNumber[];
            FullName: null | string;
            Role: null | typeof NewSubscriberRoles[number]['value'];
            UserId: string | null;
            Email: string | null | undefined;
            Phone: string | null;
            Language: string;
            MilestoneNotifications_US: Notifications[];
            MilestoneNotifications_CA: Notifications[];
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post('api/clearance/clearance/update-subscriptions-by-user-client', {
                SubscriptionIdCustomerNumberDyads: payload.SubscriptionIdCustomerNumberDyads,
                FullName: payload.FullName,
                Role: payload.Role,
                UserId: payload.UserId,
                Email: payload.Email,
                Phone: payload.Phone,
                Language: payload.Language,
                MilestoneNotifications_CA: payload.MilestoneNotifications_CA,
                MilestoneNotifications_US: payload.MilestoneNotifications_US
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
