import { Grid, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import { imaging as Constants } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import { formatTranslated } from '../../utils/mlp-utils';

class DetailComponent extends GridDetailRow {
    handleSelect = (e) => {
        this.props.selectDetailTab(this.props.dataItem._entryNumber, e.selected);
    };

    componentDidMount() {
        if (this.props.dataItem.expanded) {
            if (!this.props.dataItem.details.images) {
                this.getShipmentImages(this.props.dataItem._entryNumber);
            }
        }
    }

    getShipmentImages = (entryNumber) => {
        $('#grid-loader').show();
        axios
            .get('/SearchShipment/ShipmentImages?entryNumber=' + entryNumber)
            .then((response) => {
                const images = response.data.images.map((image) => Object.assign({ selected: false }, image));
                this.props.setShipmentDetails('images', images, this.props.dataItem._entryNumber);
            })
            .catch((error) => {
                toastr.error(formatTranslated('LineDetailError_Label', this.props.translations.Phrases));
            })
            .finally(() => {
                if (!this.props.dataItem.details.lineDetails) {
                    this.getShipmentLineDetails(this.props.dataItem.FileNo);
                }
            });
    };

    getShipmentLineDetails = (fileNo) => {
        axios
            .get('/SearchShipment/ShipmentDetails?fileNo=' + fileNo)
            .then((response) => {
                this.props.setShipmentDetails('lineDetails', response.data, this.props.dataItem._entryNumber);
            })
            .catch((error) => {
                toastr.error(formatTranslated('LineDetailError_Label', this.props.translations.Phrases));
            })
            .finally(() => {
                $('#grid-loader').hide();
            });
    };

    imageSelectionChange = (event) => {
        this.props.imageSelectionChange(this.props.dataItem._entryNumber, event.dataItem._pid, !event.dataItem.selected);
    };

    imageHeaderSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.props.imageHeaderSelectionChange(this.props.dataItem._entryNumber, checked);
    };

    downloadImage = (image) => {
        let fileNames = {};
        const entryNum = this.props.dataItem._entryNumber;
        fileNames[entryNum] = [];
        fileNames[entryNum].push(image._fileS3Path);

        const downloadModel = {
            S3ImageFileNames: fileNames,
            EntryNumbers: {},
            ImagePackage: {
                ImagingPackageTypeID: Constants.PACKAGE_TYPE_ZIP
            }
        };
        actions.downloadImages(downloadModel, this.props.history);
    };

    columnDetailLineGeneration = (lineDetailGridOrderFields, detailColumnWidth) => {
        return Object.keys(lineDetailGridOrderFields).map((field, index) => {
            if (lineDetailGridOrderFields[field] === 'Double') {
                if (field.indexOf('Qty') !== -1 || field === 'GrossWeight') {
                    return (
                        <GridColumn
                            key={index}
                            title={this.props.translations.Phrases[field + '_Label']}
                            field={field}
                            width={detailColumnWidth[field]}
                            headerClassName='grid-column-header grid-column-right'
                            className='grid-column-right'
                            format='{0:n2}'
                        />
                    );
                } else if (field.indexOf('Rate') !== -1) {
                    return (
                        <GridColumn
                            key={index}
                            title={this.props.translations.Phrases[field + '_Label']}
                            field={field}
                            width={detailColumnWidth[field]}
                            headerClassName='grid-column-header grid-column-right'
                            className='grid-column-right'
                            format='{0:p2}'
                        />
                    );
                } else if (field === 'LineNo') {
                    return (
                        <GridColumn
                            key={index}
                            title={this.props.translations.Phrases[field + '_Label']}
                            field={field}
                            width={detailColumnWidth[field]}
                            headerClassName='grid-column-header'
                        />
                    );
                } else {
                    const width = field === 'AdjToEnterValueAmt' || field === 'OtherAdjAmt' ? '120px' : detailColumnWidth[field];
                    return (
                        <GridColumn
                            key={index}
                            title={this.props.translations.Phrases[field + '_Label']}
                            field={field}
                            width={width}
                            headerClassName='grid-column-header grid-column-right'
                            className='grid-column-right'
                            format='{0:c}'
                        />
                    );
                }
            } else {
                let width = detailColumnWidth[field];
                if (field === 'Currency') {
                    width = '90px';
                } else if (field === 'DestinationCode') {
                    width = '110px';
                }
                return (
                    <GridColumn
                        key={index}
                        title={this.props.translations.Phrases[field + '_Label']}
                        field={field}
                        width={width}
                        headerClassName='grid-column-header'
                    />
                );
            }
        });
    };

    render() {
        const images = this.props.dataItem.details.images;
        const imagesTab =
            images && images.length > 0 ? (
                <TabStripTab title='Images'>
                    <Grid
                        filterable={false}
                        pageable={false}
                        groupable={false}
                        resizable={false}
                        sortable={false}
                        scrollable='none'
                        pageSize={7}
                        reorderable={false}
                        selectedField='selected'
                        data={images}
                        onSelectionChange={this.imageSelectionChange}
                        onHeaderSelectionChange={this.imageHeaderSelectionChange}
                    >
                        <GridColumn
                            field='selected'
                            width='50px'
                            headerClassName='grid-column-right'
                            className='grid-column-right'
                            headerSelectionValue={images.length > 0 && images.findIndex((image) => image.selected === false) === -1}
                        />
                        <GridColumn
                            field='_metadataFormat'
                            title={this.props.translations.Phrases['ImagesDocumentType_Label']}
                            width='250px'
                            cell={(props) => {
                                const docType = props.dataItem._metadataFormat.toUpperCase();
                                return <td>{formatTranslated(docType, this.props.translations.Phrases)}</td>;
                            }}
                        />
                        <GridColumn
                            field='_imageDate'
                            title={this.props.translations.Phrases['ImagesImageDate_Label']}
                            width='250px'
                            cell={(props) => {
                                return (
                                    <td>
                                        {moment(props.dataItem._imageDate)
                                            .toDate()
                                            .toLocaleDateString()
                                            .replace(/[^ -~]/g, '')}
                                    </td>
                                );
                            }}
                        />
                        <GridColumn
                            cell={(props) => {
                                return (
                                    <td>
                                        <i className='fa fa-arrow-circle-o-down' onClick={() => this.downloadImage(props.dataItem)}></i>
                                    </td>
                                );
                            }}
                        />
                    </Grid>
                </TabStripTab>
            ) : null;

        const lineDetails = this.props.dataItem.details.lineDetails;
        const lineDetailsTab = lineDetails ? (
            <TabStripTab title='Line Details'>
                <Grid
                    filterable={false}
                    sortable={false}
                    pageable={false}
                    scrollable='scrollable'
                    groupable={false}
                    resizable={false}
                    pageSize={3}
                    reorderable={false}
                    data={lineDetails.shipments[0].Detail}
                >
                    {this.columnDetailLineGeneration(lineDetails.gridOrder, lineDetails.detailColumnWidth)}
                </Grid>
            </TabStripTab>
        ) : null;

        if (!lineDetails) {
            return null;
        } else {
            return (
                <div className='sticky-grid'>
                    <TabStrip selected={this.props.dataItem.detailTabIndex} onSelect={this.handleSelect}>
                        {imagesTab}
                        {lineDetailsTab}
                    </TabStrip>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default withRouter(connect(mapStateToProps)(DetailComponent));
