import { Link } from 'react-router-dom';
import useTranslation from '../../../portal/common/hooks/useTranslation';

const RequestUpdateDropdown = () => {
    const translate = useTranslation();
    const dropMenu = [
        translate('RequestAnUpdateYourName_Label'),
        translate('RequestAnUpdateCompanyName_Label'),
        translate('RequestAnUpdateContactInfo_Label'),
        translate('RequestAnUpdateUserRole_Label')
    ];

    return (
        <div className='dropdown'>
            <button
                className='btn btn-secondary btn-sm btn-alert-info dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {translate('RequestAnUpdate_Label')}
            </button>

            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <div className='dropdown-heading dropdown-header d-flex justify-content-between pb-3'>
                    <div className='name'>
                        <p className='mb-0'> {translate('RequestAnUpdateWhatInformation_Label')}</p>
                    </div>
                </div>
                {dropMenu.map((item, index) => (
                    <Link
                        key={index}
                        className='dropdown-item d-flex justify-content-between align-items-stretch'
                        to={{ pathname: '/Profile/RequestUpdate', state: { requestType: item } }}
                    >
                        <div className='mr-3 d-flex align-items-center'>{dropMenu[index]}</div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default RequestUpdateDropdown;
