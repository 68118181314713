import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_CLEARANCES_PATH } from '../../../portal/common/constants/constants-portal';
import { ShipmentDetailsData } from '../../common/models/ResponseData';
import { GET_CLEARANCE_DETAILS } from './actionTypes';

export const GetShipmentDetails = createAsyncThunk(
    GET_CLEARANCE_DETAILS,
    async (
        payload: {
            customsInfoID: string;
            GetClearanceNotificationsRequest: {
                ProfileEmail: string | undefined;
                ProfileMobileNumber: string | undefined;
            };
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<ShipmentDetailsData>(
                `${API_CLEARANCES_PATH}/clearance/details/${payload.customsInfoID}`,
                payload.GetClearanceNotificationsRequest
            );

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);