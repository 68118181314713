import { Component, default as React } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SearchBox from '../../common/Searchbox/Searchbox';
import { accessManagement as constants } from '../../constants/mlp-constants';
import * as actionCreators from '../../store/actions/index';

class SalesforceUserSearch extends Component {
    state = {
        value: ''
    };
    onValueChanged = (val) => {
        this.setState({
            value: val
        });
    };
    selectSalesForceContact = (salesForceContact) => {
        let vm = this.props.vm;
        vm = { ...vm, SelectedSfUser: salesForceContact };
        this.props.onSelectSalesforceContact(vm, salesForceContact);
        <Redirect to='/AccessManagement/AccessManagement/NewClientAdmin'></Redirect>;
    };

    render() {
        return (
            <SearchBox
                label=''
                placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                name='User.Email'
                url={constants.GET_USERS_FOR_EXISTING_CLIENT_ADMIN_URL}
                formatResults={this.props.formatResultBuilder}
                minChars={4}
                valMsg={this.props.validationMessage?.length ? this.props.translations.Phrases[this.props.validationMessage] : ''}
                urlParameterName='originalClientAdminId'
                urlParameterValue={this.props.vm.OriginalClientAdmin.UserId}
                onSelectItem={this.selectSalesForceContact}
                value={this.state.value}
                onValueChanged={this.onValueChanged}
            ></SearchBox>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        validationMessage: state.userSearch.validationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectSalesforceContact: (vm, salesForceContact) => dispatch(actionCreators.selectSalesforceContact(vm, salesForceContact))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesforceUserSearch);
