import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { GetProfileData } from './redux/actions/profile';
import ProfileGrid from './screens/ProfileGrid/ProfileGrid';
import RequestSuccess from './screens/ProfileGrid/RequestSuccess';
import RequestUpdate from './screens/ProfileGrid/RequestUpdate';

const Profile = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetProfileData());
    }, [dispatch]);

    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path='/Profile' exact component={ProfileGrid} />
                    <Route path='/Profile/RequestUpdate' exact component={RequestUpdate} />
                    <Route path='/Profile/RequestSuccess' exact component={RequestSuccess} />
                </Switch>
            </div>
        </main>
    );
};

export default React.memo(Profile);
