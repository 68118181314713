import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../portal/axios-portal';
import { API_CLEARANCES_PATH } from '../../../portal/common/constants/constants-portal';
import { ColumnSettings } from '../../../portal/common/models/GridPreferences';
import { GET_IMAGING_GRID_PREFERENCES, SAVE_IMAGING_GRID_PREFERENCES } from './imagingActionTypes';

export const GetUserImagingGridSetting = createAsyncThunk(
    GET_IMAGING_GRID_PREFERENCES,
    async (payload: { area: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ColumnSettings[]>(
                `${API_CLEARANCES_PATH}/clearance/get-clearances-grid-configuration/${payload.area}`
            );

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const SaveUserImagingGridSetting = createAsyncThunk(
    SAVE_IMAGING_GRID_PREFERENCES,
    async (
        payload: {
            GridColumns: ColumnSettings[];
            area: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(`${API_CLEARANCES_PATH}/clearance/save-clearances-grid-configuration`, payload);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
