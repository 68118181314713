import React from 'react';
import MilestonesSelection from '../../../../../portal/common/features/MilestonesSelection';
import useTranslation from '../../../../../portal/common/hooks/useTranslation';
import { Notifications } from '../../../../../portal/common/models/ResponseData';
// onHoldToggleHandlerPass and shouldSwitch are used in NotificationsDropdown in clearances only
interface MilestonesSectionProps {
    country: string;
    notifications: null | Notifications;
    noneMilestonesSelected?: (noneSelected: boolean) => void;
    handleMilestonesChange: (obj: null | Notifications, notification: null | string, value: null | string | boolean) => void;
}

const MilestonesSection: React.FC<MilestonesSectionProps> = ({
    country = '',
    notifications,
    noneMilestonesSelected,
    handleMilestonesChange
}) => {
    const translate = useTranslation();

    return (
        <section className='border-bottom py-1'>
            <h5 className='mb-2'>{translate('SelectMilestonesDropdown_Label')}</h5>
            <form>
                <MilestonesSelection
                    country={country}
                    notifications={notifications}
                    showSelectAll={true}
                    showCountry={false}
                    handleMilestonesChange={handleMilestonesChange}
                    noneSelected={noneMilestonesSelected}
                />
            </form>
        </section>
    );
};

export default MilestonesSection;
